export const checkIsPending = (_currentSheetName: string, arr: any) => {
  switch (_currentSheetName) {
    //?-------------------- Common -------------------- */

    //----------------- 01 ---------------- */
    case 'invcontact':
      return arr['approval_status']['contact'];

    //---------------- 02 ----------------- */
    case 'invboardapprovedmemo':
      return arr['approval_status']['memo'];

    //?-------------------- Company -------------------- */
    //---------------- 01 ----------------- */
    case 'invcompdefinitive':
      return arr['approval_status']['company'];
    //---------------- 02 ----------------- */
    case 'invcompproduct':
      return arr['approval_status']['product'];
    //------------------ 03 --------------- */
    case 'invcompfs':
      return arr['approval_status']['fs'];
    //--------------------------------- */

    //?-------------------- Fund -------------------- */
    //----------------- 01 ---------------- */
    case 'invfunddefinitive':
      return arr['approval_status']['fund'];
    //----------------- 02 ---------------- */
    case 'invfundnav':
      return arr['approval_status']['nav'];
    //----------------- 03 ---------------- */
    case 'invfundportfolio':
      return arr['approval_status']['port'];
    //----------------- 04 ---------------- */
    case 'invfundbenchmark':
      return arr['approval_status']['benchmark'];
    //---------------- 05 ----------------- */
    case 'invfundcommitmentandwatermark':
      return arr['approval_status']['commitment'];

    //?-------------------- Operation -------------------- */

    //-------------- 01 ------------------ */

    case 'invbankaccount':
      return arr['approval_status']['account'];

    //--------------- 02 ------------------ */

    case 'invbankcapital':
      return arr['approval_status']['capital'];

    //---------------- 03 ----------------- */
    case 'invcertificate':
      return arr['approval_status']['certificate'];

    //---------------- 04 ----------------- */

    case 'invcompadjustequity':
      return arr['approval_status']['adjequity'];
    //----------------- 05 ---------------- */

    case 'invcompadjustpar':
      return arr['approval_status']['adjpar'];

    //---------------- 06 ----------------- */

    case 'invcompbot':
      return arr['approval_status']['bot'];

    //--------------- 07 ------------------ */

    case 'invcompcashflow':
      return arr['approval_status']['compcashflow'];

    //------------- 08 -------------------- */
    case 'invcompdirector':
      return arr['approval_status']['director'];

    //---------------- 09 ----------------- */

    case 'invcompdividend':
      return arr['approval_status']['dividend'];

    //--------------- 10 ------------------ */

    case 'invcompfair':
      return arr['approval_status']['compfair'];

    //---------------- 11 ----------------- */

    case 'invcompwarrant':
      return arr['approval_status']['warrant'];

    //---------------- 12 ----------------- */

    case 'invfundcashflow':
      return arr['approval_status']['fundcashflow'];

    //************** NotFound ********************* */
    //------------------------------------//
    default:
      return '';
  }
};
