// ? ------------------------------ Operation Table ---------------------------------------

/*
Table Header

A1. Fund ID / fund_id
-----------------------

B1. Fund Name / [x] (mockup: fund_name)
B2. Date / txn_date
B3. Cashflow Direction / cashflow_type
B4. Currency / currency
B5. Cashflow AC / cashflow_amount
B6. % of BBL Contribution / current_stake

-------------------------
C1. Approval
C2. Detail
*/

export interface InvFundCashflowBodyTableInterface {
  sheet_id: string;
  fund_id: string;
  fund_name: string;
  txn_date: string;
  cashflow_type: string;
  currency: string;
  cashflow_amount: string;
  current_stake: string;
  approval: object;
  detail: string;
}

export function createInvFundCashflowBodyTable(
  sheet_id: string,
  fund_id: string,
  fund_name: string,
  txn_date: string,
  cashflow_type: string,
  currency: string,
  cashflow_amount: string,
  current_stake: string,
  approval: object,
  detail: string,
): InvFundCashflowBodyTableInterface {
  return {
    sheet_id,
    fund_id,
    fund_name,
    txn_date,
    cashflow_type,
    currency,
    cashflow_amount,
    current_stake,
    approval,
    detail,
  };
}

//-------------------------//
export interface InvFundCashflowHeaderTableInterface {
  id: keyof InvFundCashflowBodyTableInterface;
  label: string;
}
