import { AssoSubFlag, Currency, FixedThbRate, Holding, Ifrs9, Nationality, Ownership, Port, Product } from "./inv-enums";

export enum Evaluation {
	MarketValue = 'MARKET_VALUE',
	BookValue   = 'BOOK_VALUE',
	Dcf         = 'DCF',
	Ddm         = 'DDM',
	Cost        = 'COST',
	OptionModel = 'OPTION_MODEL',
}
export enum MarketableSecurity {
	Listed    = 'LISTED',
	NonListed = 'NON_LISTED',
}

interface InvCompProductBaseInterface {
	product_id           : string, 	 // --- Autogen
	bi_id                : string,   // --- Autogen from INVCOMPDEFINITIVE
	rm_id                : string,   // --- Autogen from INVCOMPDEFINITIVE
	company_id           : string,   // --- Autogen from Mastersheet
	company_name         : string,   // --- DROPDOWN from INVCOMPDEFINITIVE
	product_name         : string,   // --- Autogen from Mastersheet - BI ID
	equity_short_name    : string,   // --- Autogen from Invcompdefinitive
	data_date            : string,   // date string
	first_investment_date: string,   // date string
	market_listed        : string,
	remark               : string,

	// Product Features
	coupon_rate      : number,
	conversion_price : number,
	issue_date       : string,   // date string
	maturity_date    : string,   // date string
	conversion_ratio : number,
	underlying       : string,
	conversion_period: string,   // date string
	strike_price     : number,

	// Accounting Purposes
	internal_acctcode           : string,               // --- Autogen from MASTERSHEET
	internal_accdes             : string,               // --- Autogen from MASTERSHEET
	internal_procode            : number,               // --- Autogen from MASTERSHEET
	internal_prodes             : string,               // --- Autogen from MASTERSHEET
	impair_account_code         : string,               // --- Autogen from MASTERSHEET
	interest_impair_account_code: string,               // --- Autogen from MASTERSHEET
	infra_group_code            : string,               // --- Autogen from Mastersheet
	currency                    : Currency,
	valuation_method            : Evaluation,
	investment_port             : Port,
	redeemable                  : boolean,              // Redeemable / Non-Redeemable, --- Autogen from Mastersheet AC Questionare
	asso_sub_flag               : AssoSubFlag,          // --- Autogen from Mastersheet AC Questionare
	investment_instrument       : Holding,
	issue_location              : Nationality,          // --- Autogen from Mastersheet AC Questionare
	issuer_nationality          : Nationality,          // --- Autogen from Mastersheet AC Questionare
	listed_company_flag         : boolean,              // Fixed: 'NO' (false), --- Autogen from Mastersheet AC Questionare
	ifrs9_instrument            : Ifrs9,                // --- Autogen from Mastersheet AC Questionare
	investment_type             : boolean,              // Tdr / Non-Tdr, --- Autogen from Mastersheet AC Questionare
	marketable_security         : MarketableSecurity,   // --- Autogen from Mastersheet AC Questionare
	hedge_currency              : boolean,              // Hedge / Non-Hedge, --- Autogen from Mastersheet AC Questionare
	fixed_rate_thb_flag         : FixedThbRate,         // --- Autogen from Mastersheet AC Questionare (Linked from Currency Hedging AC)
	product_type                : Product,               // --- Autogen from Mastersheet AC Questionare
	ownership_type              : Ownership,             // --- Autogen from Mastersheet AC Questionare
	traded_to_subsidiary        : boolean,              // Yes / No, --- Autogen from Mastersheet AC Questionare
	no_of_share                 : number,               // integer, Autogen from INVCOMPCASHFLOW
	book_value                  : number,               // --- Autogen from INVCOMPCASHFLOW
	allowance_impair_ccy        : number,               // --- Autogen from INVCOMPCASHFLOW
	net_cost_ccy                : number,               // --- Autogen from INVCOMPCASHFLOW
	investment_cost_thb         : number,               // --- Autogen FORMULAR
	allowance_impair_thb        : number,               // --- Autogen FORMULAR
	allowance_permanent         : number,
	net_cost_thb                : number,               // --- Autogen from INVCOMPCASHFLOW
	current_stake               : number,               // --- Autogen FORMULAR

	// Thai Convertible Bond Calculation (Amortization, Accured and Receive) - For Thai Convertible Bond - Only for BH #1 and #2, Rest = Key-In
	amortize_th             : number,   // --- Autogen FORMULAR -> = (Par - Cost)/ระยะเวลาตั้งแต่ Amortize ครั้งสุดท้าย ถึงวัน Due ของหุ้น * จำนวนวันตั้งแต่ Amortize สุดท้ายถึงวัน Value Date
	interest_accrued_th     : number,   // --- Autogen FORMULAR -> = (Principle Amount * (Days/365 Days) * % Interest Rate) / 100
	interest_receive_th     : number,   // --- Autogen FORMULAR -> = (Principle Amount * (Days/365 Days) * % Interest Rate) / 100
	withholding_tax_th      : number,   // --- Autogen FORMULAR -> 1%
	net_interest_received_th: number,   // --- Autogen FORMULAR -> = Interest Receive - Withholding Tax

	// Foreign Convertible Bond Calculation (Amortization, Accured and Receive) -  For Foreign Convertible Bond
	amortize             : number,
	interest_accrued     : number,
	interest_receive     : number,
	withholding_tax      : number,
	net_interest_received: number,
}

export type InvCompProduct = InvCompProductBaseInterface;

export type InvCompProductSubmit = InvCompProductBaseInterface & {
	
	//sheet id Ref
	parent_id: string,
	sheet_id: string,
};




export type InvCompProductEntry = Pick<
	InvCompProduct,
	'product_id' |
	'allowance_impair_ccy' |
	'allowance_permanent' |
	'bi_id' |
	'book_value' |
	'company_name' |
	'currency' |
	'current_stake' |
	'first_investment_date' |
	'investment_cost_thb' |
	'investment_instrument' |
	'investment_port' |
	'no_of_share' |
	'product_name' |
	'strike_price'
>[];


export type InvCompProductAll = InvCompProduct & InvCompProductSubmit;