import { atom } from 'recoil';
import {
  arrBroker,
  arrCashflowDirection,
  arrCurrency,
  arrHolding,
  arrPort,
  arrCompTransaction,
} from '../common';

const _sheetName = 'invcompcashflow';

//?------- Parameter Design ---------------
/*

------- Label / ID ------------
displayName = variable for display name in label.
sourceSystemName = variable for bank system name.
apiName = variable for call api. 

-------- Input ----------------
description = input placeholder.
inputLength = custom length limit of character.
inputType = (checkbox | date | radio | select | textfield) type of input.
inputClass = Custom format masking or validation.
possibleValue = (array) Value for dropdownlist / checkbox / radio.

-------- Boolean --------------
isRequire = (true/false) require field. can't be null.

isLinkValue = (true/false) Link the value from other input (linkValueId)
linkValueId = ID of input to Link data (display when isLinkValue == true).

isAutoGenerate = (true/false) auto generate value.
generateSource = source of auto generate (display when isAutoGenerate == true).

*/
/**
 textfield
 - NoLimitFree
 - NoLimitCountNumber
 - NoLimitFloat

 date
 - General

select
 - General

 */

const jsonData: { [key: string]: any } = {
  sheetName: _sheetName,
  inputInfo: [
    // ? --------------------------------- Start ---------------------------------------//
    // !---------------------------- 000.1 ---------------------------------//
    {
      displayName: 'Product ID',
      sourceSystemName: 'PRODUCT_ID',
      apiName: 'product_id',

      description: 'Product ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 001 ---------------------------------//
    {
      displayName: 'BI_ID',
      sourceSystemName: 'BI_ID',
      apiName: 'bi_id',

      description: 'Business ID from Investment',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 002 ---------------------------------//
    {
      displayName: 'RM ID',
      sourceSystemName: 'RM_ID',
      apiName: 'rm_id',

      description: 'ID generated from bank',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 003 ---------------------------------//
    {
      displayName: 'Company ID',
      sourceSystemName: 'COMPANY_ID',
      apiName: 'company_id',

      description: 'Company ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 004 ---------------------------------//
    {
      displayName: 'Company Name',
      sourceSystemName: 'COMPANY_NAME',
      apiName: 'company_name',

      description: 'Full legal name of the company',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 005 ---------------------------------//
    {
      displayName: 'Product Name',
      sourceSystemName: 'PRODUCT_NAME',
      apiName: 'product_name',

      description: 'Company name classified by type of products',
      inputLength: '',
      inputType: 'select',
      inputClass: 'Autocomplete',
      possibleValue: ['/product/list'],
      displayValue: ['/product/list'],

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: true,
    },

    // ---------------------------- 006 ---------------------------------//
    {
      displayName: 'Transaction ID',
      sourceSystemName: 'TXN_ID',
      apiName: 'txn_id',

      description: 'Transaction ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromServer',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: true,
    },

    // ---------------------------- 007 ---------------------------------//
    {
      displayName: 'First Investment Date',
      sourceSystemName: 'FIRST_INVESTMENT_DATE',
      apiName: 'first_investment_date',

      description: 'The date BBL made the first transfer to the firm',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 008 ---------------------------------//
    {
      displayName: 'Transaction Date',
      sourceSystemName: 'TXN_DATE',
      apiName: 'txn_date',

      description: 'Transaction Date',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 009 ---------------------------------//
    {
      displayName: 'Investment Instrument',
      sourceSystemName: 'INVESTMENT_INSTRUMENT',
      apiName: 'investment_instrument',

      description: 'Type of securities BBL hold in the company',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: arrHolding,
      displayValue: arrHolding,

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 010 ---------------------------------//
    {
      displayName: 'Remark',
      sourceSystemName: 'REMARK',
      apiName: 'remark',

      description: 'Remark (If any) ',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 011 ---------------------------------//
    {
      displayName: 'Portfolio Classification AC',
      sourceSystemName: 'INVESTMENT_PORT',
      apiName: 'investment_port',

      description: 'BBL internal accounting record for accounting purpose',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: arrPort,
      displayValue: arrPort,

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 012 ---------------------------------//
    {
      displayName: 'Currency AC',
      sourceSystemName: 'CURRENCY',
      apiName: 'currency',

      description: 'Denominated Currency',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: arrCurrency,
      displayValue: arrCurrency,

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 013 ---------------------------------//
    {
      displayName: 'Transaction Type',
      sourceSystemName: 'TXN_TYPE',
      apiName: 'txn_type',

      description:
        'Type of transaction: Buy, Buy - Partially Paid Up, Sell, Drawdown, Redemption, Stock Dividend, Interest, Impairment, Reversed Impairment',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrCompTransaction,
      displayValue: arrCompTransaction,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 014 ---------------------------------//
    {
      displayName: 'Cashflow Direction',
      sourceSystemName: 'CASHFLOW_DIRECTION',
      apiName: 'cashflow_direction',

      description: 'Can be either cash inflow or outflow',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: arrCashflowDirection,
      displayValue: arrCashflowDirection,

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'txn_type',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 015 ---------------------------------//
    {
      displayName: 'Exchange Rate',
      sourceSystemName: 'EXCHANGE_RATE',
      apiName: 'exchange_rate',

      description: 'Exchange Rate',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 016 ---------------------------------//
    {
      displayName: 'No. of Share',
      sourceSystemName: 'NO_OF_SHARE',
      apiName: 'no_of_share',

      description: 'Number of share',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitCountNumber',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 017 ---------------------------------//
    {
      displayName: 'Price per Share',
      sourceSystemName: 'PRICE_PER_SHARE',
      apiName: 'price_per_share',

      description: 'Price/Share',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 018 ---------------------------------//
    {
      displayName: 'Cashflow AC',
      sourceSystemName: 'CASHFLOW_AMOUNT',
      apiName: 'cashflow_amount',

      description: 'Amount of money transferred',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 019 ---------------------------------//
    {
      displayName: 'Cashflow Adjustment AC',
      sourceSystemName: 'CASHFLOW_ADJUSTMENT',
      apiName: 'cashflow_adjustment',

      description: 'Cashflow Adjustment',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 020 ---------------------------------//
    {
      displayName: 'Net Cashflow (Original CCY) AC',
      sourceSystemName: 'NET_CASHFLOW',
      apiName: 'net_cashflow',

      description: 'Net Cashflow (Original Currency)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 021 ---------------------------------//
    {
      displayName: 'Net Cashflow (THB) AC',
      sourceSystemName: 'NET_CASHFLOW_THB',
      apiName: 'net_cashflow_thb',

      description: 'net_cashflow_thb',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 022 ---------------------------------//
    {
      displayName: 'Broker Name AC',
      sourceSystemName: 'BROKERAGE_NAME',
      apiName: 'brokerage_name',

      description: 'Name of Brokers used to execute the trasaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: arrBroker,
      displayValue: arrBroker,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 023 ---------------------------------//
    {
      displayName: 'Commission AC',
      sourceSystemName: 'COMMISSION',
      apiName: 'commission',

      description: 'Commission for Thai Equity',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 024 ---------------------------------//
    {
      displayName: 'VAT & Levey Fee AC',
      sourceSystemName: 'VAT_LEVEY_FEE',
      apiName: 'vat_levey_fee',

      description: 'VAT & Levey Fee for Thai Equity',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 025 ---------------------------------//
    {
      displayName: 'Commission (Include VAT) AC',
      sourceSystemName: 'COMMISSION_VAT',
      apiName: 'commission_vat',

      description: 'Commission (Include VAT) for Thai Equity',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 026 ---------------------------------//
    {
      displayName: 'Withholding Tax AC',
      sourceSystemName: 'WITHHOLDING_TAX',
      apiName: 'withholding_tax',

      description: 'Withholding Tax for Thai Equity',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 027 ---------------------------------//
    {
      displayName: 'Custodian Fee AC',
      sourceSystemName: 'CUSTODIAN_FEE',
      apiName: 'custodian_fee',

      description: 'Custodian Fee for Foreign Equity',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 028 ---------------------------------//
    {
      displayName: 'Advisory Fee KC AC',
      sourceSystemName: 'ADVISORY_FEE_KC',
      apiName: 'advisory_fee_kc',

      description: 'Advisory Fee KC for Foreign Equity',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 029 ---------------------------------//
    {
      displayName: 'Miscellaneous Expenses AC',
      sourceSystemName: 'MISCELLANEOUS_EXP',
      apiName: 'miscellaneous_exp',

      description: 'Miscellaneous Expenses for Foreign Equity',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 030 ---------------------------------//
    {
      displayName: 'Allowance-Impairment AC',
      sourceSystemName: 'ALLOWANCE_IMPAIRMENT',
      apiName: 'allowance_impairment',

      description: 'Allowance-Impairment',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 031 ---------------------------------//
    {
      displayName: 'Allowance-Permanent AC',
      sourceSystemName: 'ALLOWANCE_PERMANENT',
      apiName: 'allowance_permanent',

      description: 'Allowance-Permanent',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 032 ---------------------------------//
    {
      displayName: 'Total No. of Share (Pre Transaction)',
      sourceSystemName: 'NO_OF_SHARE_PRETRANSACTION',
      apiName: 'no_of_share_pretransaction',

      description: 'Total number of share - Pre Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 033 ---------------------------------//
    {
      displayName: 'Investment Cost (Orginal CCY) (Pre Transaction) AC',
      sourceSystemName: 'INVESTMENT_COST_PRETRANSACTION_CCY',
      apiName: 'investment_cost_pretransaction_ccy',

      description: 'Investment Cost (Orginal CCY) - Pre Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 034 ---------------------------------//
    {
      displayName: 'Investment Cost (THB) (Pre Transaction) AC',
      sourceSystemName: 'INVESTMENT_COST_PRETRANSACTION_THB',
      apiName: 'investment_cost_pretransaction_thb',

      description: 'Investment Cost (THB) - Pre Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 035 ---------------------------------//
    {
      displayName: '% Hold (Pre Transaction)',
      sourceSystemName: 'CURRENT_STAKE_PRETRANSACTION',
      apiName: 'current_stake_pretransaction',

      description: '% holding in the firm - Pre transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 036 ---------------------------------//
    {
      displayName: 'Total No. of Share (Post Transaction)',
      sourceSystemName: 'NO_OF_SHARE_POSTTRANSACTION',
      apiName: 'no_of_share_posttransaction',

      description: 'Total number of share - Post Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 037 ---------------------------------//
    {
      displayName: 'Investment Cost (Orginal CCY) (Post Transaction) AC',
      sourceSystemName: 'INVESTMENT_COST',
      apiName: 'investment_cost',

      description: 'Investment Cost (Orginal CCY)  - Post Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 038 ---------------------------------//
    {
      displayName: 'Investment Cost (THB) (Post Transaction) AC',
      sourceSystemName: 'INVESTMENT_COST_THB',
      apiName: 'investment_cost_thb',

      description: 'Investment Cost (THB)  - Post Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 039 ---------------------------------//
    {
      displayName: '% Hold (Post Transaction)',
      sourceSystemName: 'CURRENT_STAKE_POSTTRANSACTION',
      apiName: 'current_stake_posttransaction',

      description: '% holding in the firm - Post Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ? --------------------------------- End ---------------------------------------//
  ],
};

export const invcompcashflow = atom({
  key: 'invcompcashflow',
  default: jsonData,
});
