import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import { RenderHeader } from './createModalComp/RenderHeader';
//Icon

import CloseIcon from '@mui/icons-material/Close';

//Button
import Button from '@mui/material/Button';

//Chips
import Chip from '@mui/material/Chip';

import LayoutStyles from '../../../../styles/Layout.module.css';

//State
import {
  isCreateModal,
  isYesNoModal,
  isGenerateCompleteState,
  isFirstLoadFormState,
  isLoadedAutogenState,
  isUpdateInputJSONState,
  currentIdState,
  currentSheetNameState,
} from '../../../../states/index';
import {
  portalStatusState,
  sheetNameState,
  autogenDropdownListState,
} from '../../../../states/index';

//Current Status
import {
  currentCaseNameState,
  previousCaseNameState,
  currentFillFormStepState,
  currentCreateModalModeState,
  currentSelectedNameState,
  currentSelectedProductNameState,
  currentSelectedProductName2State,
  currentSelectedCompanyNameState,
  currentSelectedFundQuoteState,
  currentSelectedCompCashFlowState,
  currentSelectedFundCashFlowState,
  currentSelectedPortState,
  currentSelectedFsState,
  currentSelectedTXNIdState,
  currentSelectedBIidState,
  currentSelectedCompanyIdState,
  currentSelectedFundIDState,
  currentSelectedRmIdState,
} from '../../../../states/index';

//Current Fill Data
import {
  defaultFillInvBankAccountState,
  defaultFillInvBankCapitalState,
  defaultFillInvBoardApprovedMemoState,
  defaultFillInvCertificateState,
  defaultFillInvCompAdjustEquityState,
  defaultFillInvCompAdjustParState,
  defaultFillInvCompBotState,
  defaultFillInvCompCashFlowState,
  defaultFillInvCompDefinitiveState,
  defaultFillInvCompDirectorState,
  defaultFillInvCompDividendState,
  defaultFillInvCompFairState,
  defaultFillInvCompFsState,
  defaultFillInvCompProductState,
  defaultFillInvCompWarrantState,
  defaultFillInvContactState,
  defaultFillInvFundBenchmarkState,
  defaultFillInvFundCashflowState,
  defaultFillInvFundCommitmentAndWatermarkState,
  defaultFillInvFundDefinitiveState,
  defaultFillInvFundNavState,
  defaultFillInvFundPortfolioState,
  currentFillInvBankAccountState,
  currentFillInvBankCapitalState,
  currentFillInvBoardApprovedMemoState,
  currentFillInvCertificateState,
  currentFillInvCompAdjustEquityState,
  currentFillInvCompAdjustParState,
  currentFillInvCompBotState,
  currentFillInvCompCashFlowState,
  currentFillInvCompDefinitiveState,
  currentFillInvCompDirectorState,
  currentFillInvCompDividendState,
  currentFillInvCompFairState,
  currentFillInvCompFsState,
  currentFillInvCompProductState,
  currentFillInvCompWarrantState,
  currentFillInvContactState,
  currentFillInvFundBenchmarkState,
  currentFillInvFundCashflowState,
  currentFillInvFundCommitmentAndWatermarkState,
  currentFillInvFundDefinitiveState,
  currentFillInvFundNavState,
  currentFillInvFundPortfolioState,
  currentMasterSheetState,
} from '../../../../states/index';

import { isSubmitStepState, isFirstPageStepState } from '../../../../states/index';

//Custom
import RenderNextButton from './createModalComp/RenderNextButton';
import RenderPage from './createModalComp/RenderPage';
import { groupBy } from '../function/groupBy';

import * as Formula from '../../../../formula/index';
import * as InvModels from '@invport/models';

function ButtonLeftLabel({ _caseName }: { _caseName: string }) {
  switch (_caseName) {
    case 'delete':
      return <>{'Cancel'}</>;
    case 'approve':
      return <>{'Back'}</>;
    case 'create':
      return <>{'Back'}</>;
    case 'edit':
      return <>{'Cancel'}</>;
    case 'edit_form':
      return <>{'Cancel'}</>;
    case 'close':
      return <>{'Back'}</>;
    default:
      return <div></div>;
  }
}

export default function CreateModal({
  caseName,
  sheetName,
  objectName,
  inputJSON,
  dataJSON,
  onGetAutogenList,
}: {
  caseName: string;
  sheetName: string;
  objectName: string;
  inputJSON: object;
  dataJSON: object;
  onGetAutogenList: () => void;
}) {
  // console.log('caseName : ' + caseName + ' , sheetName: ' + sheetName);

  const currentId = useRecoilValue(currentIdState);

  const currentSheetName = useRecoilValue(currentSheetNameState);

  const currentMasterSheet = useRecoilValue(currentMasterSheetState);

  const [currentSelectedName, setCurrentSelectedName] = useRecoilState(
    currentSelectedNameState,
  );

  const [currentSelectedProductName, setCurrentSelectedProductName] = useRecoilState(
    currentSelectedProductNameState,
  );

  const [currentSelectedProductName2, setCurrentSelectedProductName2] = useRecoilState(
    currentSelectedProductName2State,
  );

  const [currentSelectedCompanyName, setCurrentSelectedCompanyName] = useRecoilState(
    currentSelectedCompanyNameState,
  );

  const [currentSelectedFundQuote, setCurrentSelectedFundQuote] = useRecoilState(
    currentSelectedFundQuoteState,
  );

  const [currentSelectedCompCashFlow, setCurrentSelectedCompCashFlow] = useRecoilState(
    currentSelectedCompCashFlowState,
  );

  const [currentSelectedFundCashFlow, setCurrentSelectedFundCashFlow] = useRecoilState(
    currentSelectedFundCashFlowState,
  );

  const [currentSelectedPort, setCurrentSelectedPort] = useRecoilState(
    currentSelectedPortState,
  );

  const [currentSelectedTXNId, setCurrentSelectedTXNId] = useRecoilState(
    currentSelectedTXNIdState,
  );

  const [currentSelectedFs, setCurrentSelectedFs] =
    useRecoilState(currentSelectedFsState);

  const [currentSelectedBIid, setCurrentSelectedBIid] = useRecoilState(
    currentSelectedBIidState,
  );
  const [currentSelectedCompanyId, setCurrentSelectedCompanyId] = useRecoilState(
    currentSelectedCompanyIdState,
  );
  const [currentSelectedFundId, setCurrentSelectedFundId] = useRecoilState(
    currentSelectedFundIDState,
  );
  const [currentSelectedRmId, setCurrentSelectedRmId] = useRecoilState(
    currentSelectedRmIdState,
  );

  //-----------------------Current Fill Data -------------------------------------//
  const [currentFillInvBankAccount, setCurrentFillInvBankAccount] = useRecoilState(
    currentFillInvBankAccountState,
  );
  const [currentFillInvBankCapital, setCurrentFillInvBankCapital] = useRecoilState(
    currentFillInvBankCapitalState,
  );

  const [currentFillInvBoardApprovedMemo, setCurrentFillInvBoardApprovedMemo] =
    useRecoilState(currentFillInvBoardApprovedMemoState);

  const [currentFillInvCertificate, setCurrentFillInvCertificate] = useRecoilState(
    currentFillInvCertificateState,
  );

  const [currentFillInvCompAdjustEquity, setCurrentFillInvCompAdjustEquity] =
    useRecoilState(currentFillInvCompAdjustEquityState);
  const [currentFillInvCompAdjustPar, setCurrentFillInvCompAdjustPar] = useRecoilState(
    currentFillInvCompAdjustParState,
  );
  const [currentFillInvCompBot, setCurrentFillInvCompBot] = useRecoilState(
    currentFillInvCompBotState,
  );
  const [currentFillInvCompCashFlow, setCurrentFillInvCompCashFlow] = useRecoilState(
    currentFillInvCompCashFlowState,
  );

  const [currentFillInvCompDefinitive, setCurrentFillInvCompDefinitive] = useRecoilState(
    currentFillInvCompDefinitiveState,
  );

  const [currentFillInvCompDirector, setCurrentFillInvCompDirector] = useRecoilState(
    currentFillInvCompDirectorState,
  );
  const [currentFillInvCompDividend, setCurrentFillInvCompDividend] = useRecoilState(
    currentFillInvCompDividendState,
  );

  const [currentFillInvCompFair, setCurrentFillInvCompFair] = useRecoilState(
    currentFillInvCompFairState,
  );

  const [currentFillInvCompFs, setCurrentFillInvCompFs] = useRecoilState(
    currentFillInvCompFsState,
  );
  const [currentFillInvCompProduct, setCurrentFillInvCompProduct] = useRecoilState(
    currentFillInvCompProductState,
  );
  const [currentFillInvCompWarrant, setCurrentFillInvCompWarrant] = useRecoilState(
    currentFillInvCompWarrantState,
  );
  const [currentFillInvContact, setCurrentFillInvContact] = useRecoilState(
    currentFillInvContactState,
  );

  const [currentFillInvFundBenchmark, setCurrentFillInvFundBenchmark] = useRecoilState(
    currentFillInvFundBenchmarkState,
  );
  const [currentFillInvFundCashflow, setCurrentFillInvFundCashflow] = useRecoilState(
    currentFillInvFundCashflowState,
  );
  const [
    currentFillInvFundCommitmentWatermark,
    setCurrentFillInvFundCommitmentWatermark,
  ] = useRecoilState(currentFillInvFundCommitmentAndWatermarkState);
  const [currentFillInvFundDefinitive, setCurrentFillInvFundDefinitive] = useRecoilState(
    currentFillInvFundDefinitiveState,
  );

  const [currentFillInvFundNav, setCurrentFillInvFundNav] = useRecoilState(
    currentFillInvFundNavState,
  );
  const [currentFillInvFundPortfolio, setCurrentFillInvFundPortfolio] = useRecoilState(
    currentFillInvFundPortfolioState,
  );

  //---------------------Default------------------------------//

  const defaultFillInvBankAccount = useRecoilValue(defaultFillInvBankAccountState);
  const defaultFillInvBankCapital = useRecoilValue(defaultFillInvBankCapitalState);

  const defaultFillInvBoardApprovedMemo = useRecoilValue(
    defaultFillInvBoardApprovedMemoState,
  );

  const defaultFillInvCertificate = useRecoilValue(defaultFillInvCertificateState);

  const defaultFillInvCompAdjustEquity = useRecoilValue(
    defaultFillInvCompAdjustEquityState,
  );
  const defaultFillInvCompAdjustPar = useRecoilValue(defaultFillInvCompAdjustParState);
  const defaultFillInvCompBot = useRecoilValue(defaultFillInvCompBotState);
  const defaultFillInvCompCashFlow = useRecoilValue(defaultFillInvCompCashFlowState);
  const defaultFillInvCompDefinitive = useRecoilValue(defaultFillInvCompDefinitiveState);
  const defaultFillInvCompDirector = useRecoilValue(defaultFillInvCompDirectorState);
  const defaultFillInvCompDividend = useRecoilValue(defaultFillInvCompDividendState);
  const defaultFillInvCompFair = useRecoilValue(defaultFillInvCompFairState);
  const defaultFillInvCompFs = useRecoilValue(defaultFillInvCompFsState);
  const defaultFillInvCompProduct = useRecoilValue(defaultFillInvCompProductState);
  const defaultFillInvCompWarrant = useRecoilValue(defaultFillInvCompWarrantState);
  const defaultFillInvContact = useRecoilValue(defaultFillInvContactState);

  const defaultFillInvFundBenchmark = useRecoilValue(defaultFillInvFundBenchmarkState);
  const defaultFillInvFundCashflow = useRecoilValue(defaultFillInvFundCashflowState);
  const defaultFillInvFundCommitmentWatermark = useRecoilValue(
    defaultFillInvFundCommitmentAndWatermarkState,
  );
  const defaultFillInvFundDefinitive = useRecoilValue(defaultFillInvFundDefinitiveState);

  const defaultFillInvFundNav = useRecoilValue(defaultFillInvFundNavState);
  const defaultFillInvFundPortfolio = useRecoilValue(defaultFillInvFundPortfolioState);

  //------------------------------------------------------------//
  const setGenerateCompleteState = useSetRecoilState(isGenerateCompleteState);
  const portalStatus = useRecoilValue(portalStatusState);
  const sheetNameList: { [key: string]: any } = useRecoilValue(sheetNameState);

  const sheetNameList_systemName: { [key: string]: any } = groupBy(
    sheetNameList[portalStatus]['subsheet'],
    'systemName',
  );

  // console.log(sheetName);

  const currentCreateModalMode = useRecoilValue(currentCreateModalModeState);

  let currentSystemSheetName = '';

  if (sheetName === 'non') {
  } else {
    currentSystemSheetName = sheetNameList_systemName[sheetName][0]['systemName'];
  }

  let typoHeaderDisplay = RenderHeader(
    sheetName,
    sheetNameList_systemName,
    caseName,
    currentCreateModalMode,
  );

  //----------------------------------------------------------------//
  const setOpenYesNoModal = useSetRecoilState(isYesNoModal);
  const handleYesNoModalOpen = () => {
    setOpenYesNoModal(true);
  };

  //Current
  const [currentCaseName, setCurrentCaseName] = useRecoilState(currentCaseNameState);
  const [previousCaseName, setPreviousCaseName] = useRecoilState(previousCaseNameState);
  const autogenDropdownList = useRecoilValue(autogenDropdownListState);
  const [isUpdateInputJSON, setIsUpdateInputJSON] =
    useRecoilState(isUpdateInputJSONState);

  //Page State
  const [page, setPage] = useRecoilState(currentFillFormStepState);
  const [isSubmit, setIsSubmit] = useRecoilState(isSubmitStepState);
  const [isFirstPage, setIsFirstPage] = useRecoilState(isFirstPageStepState);
  const pageTotal = 3;
  //--------------------------------------//
  const openCreateModal = useRecoilValue(isCreateModal);
  const [isFirstLoadForm, setIsFirstLoadForm] = useRecoilState(isFirstLoadFormState);
  const isLoadedAutogen = useRecoilValue(isLoadedAutogenState);

  const handleClose = () => {
    setCurrentCaseName('close');
    setPreviousCaseName(currentCaseName);
    // console.log(currentCaseName);
    setOpenYesNoModal(true);

    // setIsSubmit(false);
    // setIsFirstPage(true);
    // setPage(1);
  };

  const currentFillValue = (_currentSystemSheetName: string) => {
    let inputJSON: { [key: string]: any } = {};

    switch (_currentSystemSheetName) {
      //--------------------------------- */
      case 'invcompdefinitive':
        inputJSON = {
          current: currentFillInvCompDefinitive,
          default: defaultFillInvCompDefinitive,
        };
        break;
      //--------------------------------- */
      case 'invcompproduct':
        inputJSON = {
          current: currentFillInvCompProduct,
          default: defaultFillInvCompProduct,
        };

        break;
      //--------------------------------- */
      case 'invcompfs':
        inputJSON = {
          current: currentFillInvCompFs,
          default: defaultFillInvCompFs,
        };

        break;
      //--------------------------------- */
      case 'invcompdirector':
        inputJSON = {
          current: currentFillInvCompDirector,
          default: defaultFillInvCompDirector,
        };
        break;

      //--------------------------------- */
      case 'invfunddefinitive':
        inputJSON = {
          current: currentFillInvFundDefinitive,
          default: defaultFillInvFundDefinitive,
        };
        break;

      //--------------------------------- */
      case 'invfundnav':
        inputJSON = currentFillInvFundNav;

        inputJSON = {
          current: currentFillInvFundNav,
          default: defaultFillInvFundNav,
        };
        break;

      //--------------------------------- */
      case 'invfundportfolio':
        inputJSON = {
          current: currentFillInvFundPortfolio,
          default: defaultFillInvFundPortfolio,
        };
        break;

      //--------------------------------- */
      case 'invfundbenchmark':
        inputJSON = {
          current: currentFillInvFundBenchmark,
          default: defaultFillInvFundBenchmark,
        };
        break;

      //--------------------------------- */
      case 'invfundcommitmentandwatermark':
        inputJSON = {
          current: currentFillInvFundCommitmentWatermark,
          default: defaultFillInvFundCommitmentWatermark,
        };
        break;

      //--------------------------------- */
      case 'invcertificate':
        inputJSON = {
          current: currentFillInvCertificate,
          default: defaultFillInvCertificate,
        };
        break;

      //--------------------------------- */
      case 'invcontact':
        inputJSON = {
          current: currentFillInvContact,
          default: defaultFillInvContact,
        };
        break;

      //--------------------------------- */
      case 'invboardapprovedmemo':
        inputJSON = {
          current: currentFillInvBoardApprovedMemo,
          default: defaultFillInvBoardApprovedMemo,
        };
        break;

      //--------------------------------- */
      case 'invbankaccount':
        inputJSON = {
          current: currentFillInvBankAccount,
          default: defaultFillInvBankAccount,
        };
        break;

      //--------------------------------- */
      case 'invbankcapital':
        inputJSON = {
          current: currentFillInvBankCapital,
          default: defaultFillInvBankCapital,
        };
        break;

      //--------------------------------- */
      case 'invfundcashflow':
        inputJSON = {
          current: currentFillInvFundCashflow,
          default: defaultFillInvFundCashflow,
        };
        break;

      //--------------------------------- */
      case 'invcompcashflow':
        inputJSON = {
          current: currentFillInvCompCashFlow,
          default: defaultFillInvCompCashFlow,
        };
        break;

      //--------------------------------- */
      case 'invcompdividend':
        inputJSON = {
          current: currentFillInvCompDividend,
          default: defaultFillInvCompDividend,
        };
        break;

      //--------------------------------- */
      case 'invcompadjustequity':
        inputJSON = {
          current: currentFillInvCompAdjustEquity,
          default: defaultFillInvCompAdjustEquity,
        };
        break;

      //--------------------------------- */
      case 'invcompadjustpar':
        inputJSON = {
          current: currentFillInvCompAdjustPar,
          default: defaultFillInvCompAdjustPar,
        };

        break;

      //--------------------------------- */
      case 'invcompwarrant':
        inputJSON = {
          current: currentFillInvCompWarrant,
          default: defaultFillInvCompWarrant,
        };

        break;

      //--------------------------------- */
      case 'invcompfair':
        inputJSON = {
          current: currentFillInvCompFair,
          default: defaultFillInvCompFair,
        };

        break;

      //--------------------------------- */
      case 'invcompbot':
        inputJSON = {
          current: currentFillInvCompBot,
          default: defaultFillInvCompBot,
        };

        break;

      //************** NotFound ********************* */
      //------------------------------------//
      default:
        inputJSON = {};
        break;
    }
    return inputJSON;
  };

  //----------------------------------------------------------------

  let prefillValue = {};

  if (currentCaseName === 'create') {
    let _defaultValue = currentFillValue(currentSystemSheetName);
    prefillValue = _defaultValue['default'];
  } else if (currentCaseName === 'edit_form' || currentCaseName === 'edit') {
    prefillValue = dataJSON;
  }

  const methods = useForm({ defaultValues: prefillValue });

  const { handleSubmit, control, reset } = methods;

  const handleResetForm = () => {
    reset(prefillValue);
  };

  const hadleSetGenerateCompleteState = () => {
    setGenerateCompleteState(true);
  };

  //---------------- onChange Click Next --------------
  const handleNext = () => {
    // console.log('handleNext');
    if (page === pageTotal) {
    } else if (page === 2) {
      setIsSubmit(true);
      setPage(page + 1);
    } else if (page === 1) {
      onGetAutogenList();

      if (isFirstLoadForm === false) {
        setGenerateCompleteState(false);
        setIsFirstLoadForm(true);
      }

      handleResetForm();
      setIsFirstPage(false);
      setPage(page + 1);
    }
  };

  //---------------- onChange Click Back ----------------

  const handleBack = () => {
    if (page === pageTotal) {
      setIsSubmit(false);
      setPage(page - 1);
    } else if (page === 2) {
      setIsFirstPage(true);
      setPage(page - 1);
    } else if (page === 1) {
    }
  };

  // console.log(isFirstReset);
  const onSubmit = (data: any) => {
    //!---- MasterSheet mock up -----
    const fx_rate = 0.2;
    const exchange_rate = 30;
    //!----------------------------

    setIsUpdateInputJSON(true);
    const rm_idArr: { [key: string]: any } = currentMasterSheet[12]['data'];

    const rm_idArr_groupByCompanyName = groupBy(rm_idArr, 'company_name');

    // console.log(rm_idArr_groupByCompanyName);

    switch (currentSystemSheetName) {
      //----------------- 01 ---------------- */
      case 'invcontact':
        const setContact = () => {
          const invContactAutogen: InvModels.InvContact = {
            ...data,
            id: currentId.main_id_value, //Definitive
            name: currentId.main_name, //Definitive
          };
          setCurrentFillInvContact(invContactAutogen);
        };
        setContact();
        break;

      //---------------- 02 ----------------- */
      case 'invboardapprovedmemo':
        const setMemo = () => {
          const selectedData: { [key: string]: any } = currentSelectedTXNId;
          const invBoardApprovedMemoAutogen: InvModels.InvBoardApprovedMemo = {
            ...data,
            txn_id: selectedData['txn_id'],
            id: currentId.main_id_value, //Definitive
            name: currentId.main_name, //Definitive
          };
          setCurrentFillInvBoardApprovedMemo(invBoardApprovedMemoAutogen);
        };
        setMemo();
        break;

      //?-------------------- Company -------------------- */
      //---------------- 01 ----------------- */
      case 'invcompdefinitive':
        const setComp = () => {
          const rm_id =
            rm_idArr_groupByCompanyName[currentSelectedCompanyId.company_name][0]['id'];

          const invCompDefinitiveAutogen: InvModels.InvCompDefinitive = {
            ...data,
            rm_id: rm_id, //MasterSheet
            company_id: currentSelectedCompanyId.id, //MasterSheet
            company_name_en: currentSelectedCompanyId.company_name,
          };
          setCurrentFillInvCompDefinitive(invCompDefinitiveAutogen);
        };
        setComp();
        break;
      //---------------- 02 ----------------- */
      case 'invcompproduct':
        const setProduct = () => {
          //!----mock up---------
          const total_paid_up = 10;
          const principle_amout = 10;
          const days = 10;
          const interest_rate = 0.2;
          //!--------------------
          const investment_cost_thb = Formula.productFormula_investment_cost_thb(
            data['book_value'],
            fx_rate,
          );

          const allowance_impair_thb = Formula.productFormula_investment_cost_thb(
            data['allowance_impair_ccy'],
            fx_rate,
          );

          const current_stake = Formula.productFormula_current_stake(
            data['no_of_share'],
            total_paid_up,
          );

          const amortize_th = Formula.productFormula_amortize_th();

          const interest_accrued_th = Formula.productFormula_interest_accrued_th(
            principle_amout,
            days,
            interest_rate,
          );

          const interest_receive_th = Formula.productFormula_interest_receive_th(
            principle_amout,
            days,
            interest_rate,
          );

          const withholding_tax_th = Formula.productFormula_withholding_tax_th();

          const net_interest_received_th =
            Formula.productFormula_net_interest_received_th(
              interest_receive_th,
              withholding_tax_th,
            );
          const rm_id = rm_idArr_groupByCompanyName[currentId.main_name][0]['id'];

          const invCompProductAutogen: InvModels.InvCompProduct = {
            ...data,
            product_id: 'Server', //Server
            company_id: currentId.main_id_value, //MasterSheet
            product_name: currentSelectedBIid.product_name, //MasterSheet
            internal_acctcode: 'MasterSheet', //MasterSheet
            internal_accdes: 'MasterSheet', //MasterSheet
            internal_procode: 'MasterSheet', //MasterSheet
            internal_prodes: 'MasterSheet', //MasterSheet
            impair_account_code: 'MasterSheet', //MasterSheet
            interest_impair_account_code: 'MasterSheet', //MasterSheet
            infra_group_code: 'MasterSheet', //MasterSheet
            company_name: currentId.main_name, //Definitive
            bi_id: currentSelectedBIid.id, //Definitive
            rm_id: rm_id, //Definitive
            equity_short_name: 'Definitive', //Definitive
            no_of_share: 'get from invcompcashflow', //OtherSheet : invcompcashflow
            book_value: 'get from invcompcashflow', //OtherSheet : invcompcashflow
            allowance_impair_ccy: 'get from invcompcashflow', //OtherSheet : invcompcashflow
            net_cost_ccy: 'get from invcompcashflow', //OtherSheet : invcompcashflow
            net_cost_thb: 'get from invcompcashflow', //OtherSheet : invcompcashflow
            investment_cost_thb: investment_cost_thb, //Formula
            allowance_impair_thb: allowance_impair_thb, //Formula
            current_stake: current_stake, //Formula
            amortize_th: amortize_th, //Formula
            interest_accrued_th: interest_accrued_th, //Formula
            interest_receive_th: interest_receive_th, //Formula
            withholding_tax_th: withholding_tax_th, //Formula
            net_interest_received_th: net_interest_received_th, //Formula
          };

          setCurrentFillInvCompProduct(invCompProductAutogen);
        };
        setProduct();
        break;
      //------------------ 03 --------------- */
      case 'invcompfs':
        const setFs = () => {
          //!----mock up-----
          const total_equity = 88000000;
          const net_income = 88000000;
          const paid_up_capital_no_of_share = 18500000;
          //!----------------

          let book_value_per_share = Formula.fsFormula_book_value_per_share(
            total_equity,
            paid_up_capital_no_of_share,
          );

          let earning_per_share = Formula.fsFormula_earning_per_share(
            net_income,
            paid_up_capital_no_of_share,
          );

          const invCompFsAutogen: InvModels.InvCompFs = {
            ...data,
            fs_id: 'Server', //Server
            company_name: currentId.main_name, //Definitive
            company_id: currentId.main_id_value, //Definitive
            currency: 'Definitive', //Definitive
            reporting_date: '22/02/2022', // ENLITE/SET/Bloomberg
            fs_year: 2000, // ENLITE/SET/Bloomberg
            fs_quarter: 300, // ENLITE/SET/Bloomberg
            value_country: 40, // ENLITE/SET/Bloomberg
            revenue: 50, // ENLITE/SET/Bloomberg
            cost_of_good_sold: 50, // ENLITE/SET/Bloomberg
            gross_profit: 100, // ENLITE/SET/Bloomberg
            ebitda: 66, // ENLITE/SET/Bloomberg
            ebit: 120, // ENLITE/SET/Bloomberg
            net_income: net_income, // ENLITE/SET/Bloomberg
            total_cash: 5000000, // ENLITE/SET/Bloomberg
            total_current_asset: 5000000, // ENLITE/SET/Bloomberg
            total_asset: 28900000, // ENLITE/SET/Bloomberg
            total_current_liability: 82600000, // ENLITE/SET/Bloomberg
            total_liability: 95000002, // ENLITE/SET/Bloomberg
            total_equity: total_equity, // ENLITE/SET/Bloomberg
            interest_bearing_debt: 0.5, // ENLITE/SET/Bloomberg
            registered_capital_no_of_share: 952, // ENLITE/SET/Bloomberg
            registered_capital_value: 888, // ENLITE/SET/Bloomberg
            paid_up_capital_no_of_share: paid_up_capital_no_of_share, // ENLITE/SET/Bloomberg
            paid_up_capital_value: 9832, // ENLITE/SET/Bloomberg
            book_value_per_share: book_value_per_share, //Formula
            earning_per_share: earning_per_share, //Formula
          };

          setCurrentFillInvCompFs(invCompFsAutogen);
        };
        setFs();
        break;
      //--------------------------------- */

      //?-------------------- Fund -------------------- */
      //----------------- 01 ---------------- */
      case 'invfunddefinitive':
        const setFund = () => {
          const invFundDefinitiveAutogen: InvModels.InvFundDefinitive = {
            ...data,
            currency: currentSelectedFundQuote.currency, // Dropdownlist child: fund_type
            bi_id: currentSelectedBIid.id, //MasterSheet
            rm_id: currentSelectedRmId.id, //MasterSheet
            fund_id: currentSelectedFundId.id, //MasterSheet
            fund_name: currentSelectedFundId.fund_name, //MasterSheet
            internal_acctcode: 'MasterSheet', //MasterSheet
            internal_accdes: 'MasterSheet', //MasterSheet
            internal_procode: 'MasterSheet', //MasterSheet
            internal_prodes: 'MasterSheet', //MasterSheet
            // company_id_1: 'OtherSheet', //OtherSheet : invfundportfolio //!ไม่มี endpoint -----> mockup
            // company_id_2: 'OtherSheet', //OtherSheet : invfundportfolio //!ไม่มี endpoint -----> mockup
            // company_id_3: 'OtherSheet', //OtherSheet : invfundportfolio //!ไม่มี endpoint -----> mockup
          };
          setCurrentFillInvFundDefinitive(invFundDefinitiveAutogen);
        };
        setFund();
        break;
      //----------------- 02 ---------------- */
      case 'invfundnav':
        const setNav = () => {
          const fundQuote: { [key: string]: any } = currentSelectedFundQuote;
          const invFundNavAutogen: InvModels.InvFundNav = {
            ...data,
            fund_quote: currentSelectedFundQuote.fund_quote,
            fund_id: fundQuote['id'], // Dropdownlist child: fund_quote
            currency: currentSelectedFundQuote.currency, // Dropdownlist child: fund_quote
          };
          setCurrentFillInvFundNav(invFundNavAutogen);
        };
        setNav();
        break;
      //----------------- 03 ---------------- */
      case 'invfundportfolio':
        const setPortfolio = () => {
          const fundQuote: { [key: string]: any } = currentSelectedFundQuote;
          const company: { [key: string]: any } = currentSelectedCompanyName;

          const invFundPortfolioAutogen: InvModels.InvFundPortfolio = {
            ...data,
            company_name: currentSelectedCompanyName.company_name_en, // Dropdownlist child: company_name
            fund_quote: currentSelectedFundQuote.fund_quote,
            fund_id: fundQuote['id'], // Dropdownlist child: fund_quote
            company_id: company['id'], // Dropdownlist child: company_name
          };
          setCurrentFillInvFundPortfolio(invFundPortfolioAutogen);
        };
        setPortfolio();
        break;
      //----------------- 04 ---------------- */
      case 'invfundbenchmark':
        const setBenchmark = () => {
          const fundQuote: { [key: string]: any } = currentSelectedFundQuote;
          const invFundBenchmarkAutogen: InvModels.InvFundBenchmark = {
            ...data,
            fund_quote: currentSelectedFundQuote.fund_quote,
            fund_id: fundQuote['id'], // Dropdownlist child: fund_quote
          };
          setCurrentFillInvFundBenchmark(invFundBenchmarkAutogen);
        };
        setBenchmark();
        break;
      //---------------- 05 ----------------- */
      case 'invfundcommitmentandwatermark':
        const setCommitment = () => {
          const fundQuote: { [key: string]: any } = currentSelectedFundQuote;
          const invFundCommitmentAndWatermarkAutogen: InvModels.InvFundCommitmentAndWatermark =
            {
              ...data,
              fund_quote: currentSelectedFundQuote.fund_quote,
              fund_id: fundQuote['id'], // Dropdownlist child: fund_quote
              currency: fundQuote['currency'], // Dropdownlist child: fund_quote
            };

          setCurrentFillInvFundCommitmentWatermark(invFundCommitmentAndWatermarkAutogen);
        };

        setCommitment();
        break;

      //?-------------------- Operation -------------------- */

      //-------------- 01 ------------------ */

      case 'invbankaccount':
        const setAccount = () => {
          let idType = '';
          let nameType = '';
          const selectedData: { [key: string]: any } = currentSelectedName;
          const arr = Object.keys(currentSelectedName);

          if (arr.includes('company_id')) {
            idType = 'company_id';
            nameType = 'company_name_en';
          } else if (arr.includes('fund_id')) {
            idType = 'fund_id';
            nameType = 'fund_name';
          }

          const invBankAccountAutogen: InvModels.InvBankAccount = {
            ...data,
            name: selectedData[nameType],
            id: selectedData[idType], // Dropdownlist child: name
          };
          setCurrentFillInvBankAccount(invBankAccountAutogen);
        };
        setAccount();
        break;

      //--------------- 02 ------------------ */

      case 'invbankcapital':
        const setCapital = () => {
          setCurrentFillInvBankCapital(data);
        };
        setCapital();
        break;

      //---------------- 03 ----------------- */
      case 'invcertificate':
        const setCertificate = () => {
          const invCertificateAutogen: InvModels.InvCertificate = {
            ...data,
            name: currentSelectedProductName.product_name,
            product_id: currentSelectedProductName.product_id, // Dropdownlist child: name
            company_id: currentSelectedCompanyName.company_id, // Dropdownlist child: name
            book_value: currentSelectedProductName.book_value, // Dropdownlist child: name
            par_per_share: currentSelectedFs.par_value, // Dropdownlist child: name
            total_share: currentSelectedProductName.no_of_share, // Dropdownlist child: name
            currency: currentSelectedProductName.currency, // Dropdownlist child: name
            id: 'MaterSheet', //MasterSheet
          };
          setCurrentFillInvCertificate(invCertificateAutogen);
        };

        setCertificate();
        break;

      //---------------- 04 ----------------- */

      case 'invcompadjustequity':
        const setAdjEquity = () => {
          const investment_cost_pretransaction = currentSelectedProductName.book_value;
          const allowance_impairment = currentSelectedProductName.allowance_impair_ccy;
          const allowance_permanent = currentSelectedProductName.allowance_permanent;
          //-------------------------------------------

          const allowance_permanent_thb =
            Formula.adjEquityFormula_allowance_permanent_thb(
              investment_cost_pretransaction,
              allowance_impairment,
              allowance_permanent,
            );
          const adjusted_equity_posttransaction =
            Formula.adjEquityFormula_adjusted_equity_posttransaction(
              'Profit',
              865,
              allowance_permanent_thb,
              6888,
              5555,
              5000,
            );
          const adjusted_equity_pretransaction =
            Formula.adjEquityFormula_adjusted_equity_pretransaction(
              adjusted_equity_posttransaction,
            );

          const adjusted_cost = Formula.adjEquityFormula_adjusted_cost(
            allowance_permanent_thb,
            adjusted_equity_posttransaction,
          );

          const invCompAdjustEquityAutogen: InvModels.InvCompAdjustEquity = {
            ...data,
            product_name: currentSelectedProductName.product_name,
            bi_id: currentSelectedCompanyName.bi_id, // Dropdownlist child: product_name
            rm_id: currentSelectedCompanyName.rm_id, // Dropdownlist child: product_name
            company_id: currentSelectedCompanyName.company_id, // Dropdownlist child: product_name
            company_name: currentSelectedCompanyName.company_name_en, // Dropdownlist child: product_name
            investment_instrument: currentSelectedProductName.investment_instrument, // Dropdownlist child: product_name
            investment_port: currentSelectedProductName.investment_port, // Dropdownlist child: product_name
            currency: currentSelectedProductName.currency, // Dropdownlist child: product_name
            investment_cost_pretransaction: investment_cost_pretransaction, // Dropdownlist child: product_name
            investment_cost_pretransaction_thb:
              currentSelectedProductName.investment_cost_thb, // Dropdownlist child: product_name
            allowance_impairment: allowance_impairment, // Dropdownlist child: product_name
            allowance_permanent: allowance_permanent, // Dropdownlist child: product_name
            allowance_permanent_thb: allowance_permanent_thb, //Formula
            adjusted_equity_pretransaction: adjusted_equity_pretransaction, //Formula
            adjusted_equity_posttransaction: adjusted_equity_posttransaction, //Formula
            adjusted_cost: adjusted_cost, //Formula
            txn_id: 'Sever', //Server
            infra_group_code: 'MasterSheet', //MaterSheet
          };
          setCurrentFillInvCompAdjustEquity(invCompAdjustEquityAutogen);
        };
        setAdjEquity();
        break;
      //----------------- 05 ---------------- */

      case 'invcompadjustpar':
        const setAdjPar = () => {
          //from fill-form
          const adjusted_par_method = data['adjusted_par_method'];
          const par_value_posttransaction = data['par_value_posttransaction'];
          const paid_up_capital_no_of_share_posttransaction =
            data['paid_up_capital_no_of_share_posttransaction'];

          const paid_up_capital_no_of_share_pretransaction =
            currentSelectedFs.paid_up_capital_no_of_share;

          const cashflow_amount_posttransaction =
            data['cashflow_amount_posttransaction'];

          //---------------------------//
          const no_of_share_pretransaction =
            Formula.adjParFormula_no_of_share_pretransaction();
          const investment_cost_pretransaction =
            Formula.adjParFormula_investment_cost_pretransaction();
          const investment_cost_pretransaction_thb =
            Formula.adjParFormula_investment_cost_pretransaction_thb();
          const par_value_pretransaction =
            Formula.adjParFormula_par_value_pretransaction();
          const no_of_share_posttransaction =
            Formula.adjParFormula_no_of_share_posttransaction(
              adjusted_par_method,
              no_of_share_pretransaction,
              par_value_pretransaction,
              par_value_posttransaction,
              paid_up_capital_no_of_share_posttransaction,
              paid_up_capital_no_of_share_pretransaction,
            );
          const current_stake_pretransaction =
            Formula.adjParFormula_current_stake_pretransaction(
              no_of_share_pretransaction,
              paid_up_capital_no_of_share_pretransaction,
            );

          const cashflow_amount_posttransaction_thb =
            Formula.adjParFormula_cashflow_amount_posttransaction_thb(
              cashflow_amount_posttransaction,
              fx_rate,
            );
          const investment_cost = Formula.adjParFormula_investment_cost(
            adjusted_par_method,
            no_of_share_pretransaction,
            investment_cost_pretransaction,
            par_value_posttransaction,
            par_value_pretransaction,
            paid_up_capital_no_of_share_posttransaction,
            paid_up_capital_no_of_share_pretransaction,
          );

          const investment_cost_thb = Formula.adjParFormula_investment_cost_thb(
            investment_cost,
            fx_rate,
          );

          const invCompAdjustParAutogen: InvModels.InvCompAdjustPar = {
            ...data,
            product_name: currentSelectedProductName.product_name,
            bi_id: currentSelectedCompanyName.bi_id, // Dropdownlist child: product_name
            rm_id: currentSelectedCompanyName.rm_id, // Dropdownlist child: product_name
            company_id: currentSelectedCompanyName.company_id, // Dropdownlist child: product_name
            company_name: currentSelectedCompanyName.company_name_en, // Dropdownlist child: product_name
            investment_port: currentSelectedProductName.investment_port, // Dropdownlist child: product_name
            currency: currentSelectedProductName.currency, // Dropdownlist child: product_name
            paid_up_capital_no_of_share_pretransaction:
              currentSelectedProductName.no_of_share, // Dropdownlist child: product_name
            current_stake_posttransaction: currentSelectedProductName.current_stake, // Dropdownlist child: product_name
            no_of_share_pretransaction: no_of_share_pretransaction, //Formula
            investment_cost_pretransaction: investment_cost_pretransaction, //Formula
            investment_cost_pretransaction_thb: investment_cost_pretransaction_thb, //Formula
            par_value_pretransaction: par_value_pretransaction, //Formula
            no_of_share_posttransaction: no_of_share_posttransaction, //Formula
            current_stake_pretransaction: current_stake_pretransaction, //Formula
            investment_cost_thb: investment_cost_thb, //Formula
            cashflow_amount_posttransaction_thb: cashflow_amount_posttransaction_thb, //Formula
            investment_cost: investment_cost, //Formula
            txn_id: 'Server', //Server
          };

          setCurrentFillInvCompAdjustPar(invCompAdjustParAutogen);
        };
        setAdjPar();
        break;

      //---------------- 06 ----------------- */

      case 'invcompbot':
        //!----mock up-----
        //from invcompfs
        const paid_up_capital_no_of_share = 111;
        //from invcompcashflow
        const no_of_share_posttransaction = 500;
        //!----------------
        const setBot = () => {
          const current_stake_bot = Formula.botFormula_current_stake(
            no_of_share_posttransaction,
            paid_up_capital_no_of_share,
          );
          const invCompBotAutogen: InvModels.InvCompBot = {
            ...data,
            company_name: currentSelectedCompanyName.company_name_en,
            rm_id: currentSelectedCompanyName.rm_id, // Dropdownlist child: company_name
            bi_id: currentSelectedCompanyName.bi_id, // Dropdownlist child: company_name
            company_id: currentSelectedCompanyName.company_id, // Dropdownlist child: company_name
            current_stake: current_stake_bot, //Formula
          };
          setCurrentFillInvCompBot(invCompBotAutogen);
        };
        setBot();
        break;

      //--------------- 07 ------------------ */

      case 'invcompcashflow':
        const setCompCashFlow = () => {
          //!------- Mockup --------

          const broker_rate = 500;
          //!------------------------
          //from fill-form
          const no_of_share = data['no_of_share'];
          const price_per_share = data['price_per_share'];
          const cashflow_adjustment = data['cashflow_adjustment'];

          //-------------------------------------------//
          const cashflow_amount = Formula.compCashFlowFormula_cashflow_amount(
            no_of_share,
            price_per_share,
          );
          const net_cashflow = Formula.compCashFlowFormula_net_cashflow(
            cashflow_amount,
            cashflow_adjustment,
          );
          const net_cashflow_thb = Formula.compCashFlowFormula_net_cashflow_thb(
            cashflow_amount,
            cashflow_adjustment,
            exchange_rate,
          );
          const commission = Formula.compCashFlowFormula_commission(
            cashflow_amount,
            broker_rate,
          );
          const vat_levey_fee = Formula.compCashFlowFormula_vat_levey_fee(commission);
          const commission_vat = Formula.compCashFlowFormula_commission_vat(
            commission,
            vat_levey_fee,
          );
          const withholding_tax =
            Formula.compCashFlowFormula_withholding_tax(commission);
          const no_of_share_pretransaction =
            Formula.compCashFlowFormula_no_of_share_pretransaction();
          const investment_cost_pretransaction_ccy =
            Formula.compCashFlowFormula_investment_cost_pretransaction_ccy();
          const investment_cost_pretransaction_thb =
            Formula.compCashFlowFormula_investment_cost_pretransaction_thb();
          const current_stake_pretransaction =
            Formula.compCashFlowFormula_current_stake_pretransaction();
          const no_of_share_posttransaction =
            Formula.compCashFlowFormula_no_of_share_posttransaction();
          const investment_cost = Formula.compCashFlowFormula_investment_cost();
          const investment_cost_thb = Formula.compCashFlowFormula_investment_cost_thb();
          const current_stake_posttransaction =
            Formula.compCashFlowFormula_current_stake_posttransaction();

          const invCompCashFlowAutogen: InvModels.InvCompCashFlow = {
            ...data,
            product_name: currentSelectedProductName.product_name,
            cashflow_direction: '500', // Dropdownlist child: txn_type
            product_id: currentSelectedProductName.product_id, // Dropdownlist child: product_name
            company_name: currentSelectedProductName.company_name, // Dropdownlist child: product_name
            company_id: currentSelectedCompanyName.company_id, // Dropdownlist child: product_name
            first_investment_date: currentSelectedProductName.first_investment_date, // Dropdownlist child: product_name
            investment_instrument: currentSelectedProductName.investment_instrument, // Dropdownlist child: product_name
            investment_port: currentSelectedProductName.investment_port, // Dropdownlist child: product_name
            currency: currentSelectedProductName.currency, // Dropdownlist child: product_name
            cashflow_amount: cashflow_amount, //Formula
            net_cashflow: net_cashflow, //Formula
            net_cashflow_thb: net_cashflow_thb, //Formula
            commission: commission, //Formula
            vat_levey_fee: vat_levey_fee, //Formula
            commission_vat: commission_vat, //Formula
            withholding_tax: withholding_tax, //Formula
            no_of_share_pretransaction: no_of_share_pretransaction, //Formula
            investment_cost_pretransaction_ccy: investment_cost_pretransaction_ccy, //Formula
            investment_cost_pretransaction_thb: investment_cost_pretransaction_thb, //Formula
            current_stake_pretransaction: current_stake_pretransaction, //Formula
            no_of_share_posttransaction: no_of_share_posttransaction, //Formula
            investment_cost: investment_cost, //Formula
            investment_cost_thb: investment_cost_thb, //Formula
            current_stake_posttransaction: current_stake_posttransaction, //Formula
            txn_id: 'Server', //Server
            bi_id: 'MaterSheet', //MaterSheet
            rm_id: 'MaterSheet', //MaterSheet
            brokerage_name: 'MaterSheet', //MaterSheet
            exchange_rate: exchange_rate, //MaterSheet
          };
          setCurrentFillInvCompCashFlow(invCompCashFlowAutogen);
        };
        setCompCashFlow();
        break;

      //------------- 08 -------------------- */
      case 'invcompdirector':
        const setDirector = () => {
          const invCompDirectorAutogen: InvModels.InvCompDirector = {
            ...data,
            bi_id: '500', // Dropdownlist child: company_name
            company_id: '500', // Dropdownlist child: company_name
          };
          setCurrentFillInvCompDirector(invCompDirectorAutogen);
        };
        setDirector();
        break;

      //---------------- 09 ----------------- */

      case 'invcompdividend':
        const setDividend = () => {
          //from fill-form
          const no_of_share = currentSelectedProductName.no_of_share;
          const dividend = data['dividend'];
          const dividend_adjustment = data['dividend_adjustment'];
          const withholding_tax = data['withholding_tax'];

          //-----------------------------------------//
          const dividend_amount = Formula.dividendFormula_dividend_amount(
            no_of_share,
            dividend,
            dividend_adjustment,
          );
          const net_dividend_amount = Formula.dividendFormula_net_dividend_amount(
            dividend_amount,
            withholding_tax,
          );

          const invCompDividendAutogen: InvModels.InvCompDividend = {
            ...data,
            product_name: currentSelectedProductName.product_name,
            company_name: currentSelectedProductName.company_name, // Dropdownlist child: product_name
            currency: currentSelectedProductName.currency, // Dropdownlist child: product_name
            no_of_shares: no_of_share, // Dropdownlist child: product_name
            company_id: currentSelectedCompanyName.company_id, // Dropdownlist child: product_name
            dividend_amount: dividend_amount, //Formula
            net_dividend_amount: net_dividend_amount, //Formula
          };
          setCurrentFillInvCompDividend(invCompDividendAutogen);
        };

        setDividend();

        break;

      //--------------- 10 ------------------ */

      case 'invcompfair':
        const setCompFair = () => {
          const operating_year = Formula.compFairFormula_operating_year('2021');
          const invCompFairAutogen: InvModels.InvCompFair = {
            ...data,
            company_name: currentSelectedCompanyName.company_name_en, // Dropdownlist child: company_name
            operating_year: operating_year, //Formula
          };
          setCurrentFillInvCompFair(invCompFairAutogen);
        };

        setCompFair();

        break;

      //---------------- 11 ----------------- */

      case 'invcompwarrant':
        const setWarrant = () => {
          //!----mockup-----//
          const investment_cost_pretransaction_1 = 10000;
          const investment_cost_pretransaction_2 = 10000;

          //!----------------

          //--------------------------------------------//
          //from fill-form
          const warrant_type = data['warrant_type'];
          const no_of_share_record_date = data['no_of_share_record_date'];
          const exercise_ratio = data['exercise_ratio'];

          const no_of_warrant_pretransaction = data['no_of_warrant_pretransaction'];
          const no_of_share_pretransaction = data['no_of_share_pretransaction'];
          const no_of_exercised = data['no_of_exercised'];
          const strike_price = data['strike_price'];

          const paid_up_capital_no_of_share_pretransaction =
            data['paid_up_capital_no_of_share_pretransaction'];
          const paid_up_capital_no_of_share_posttransaction =
            data['paid_up_capital_no_of_share_posttransaction'];

          //-----------------------------------------//

          const no_of_warrant = Formula.warrantFormula_no_of_warrant(
            warrant_type,
            no_of_share_record_date,
            exercise_ratio,
          );
          const no_of_warrant_posttransaction =
            Formula.warrantFormula_no_of_warrant_posttransaction(
              warrant_type,
              no_of_warrant_pretransaction,
              no_of_warrant,
            );
          const no_of_share_posttransaction =
            Formula.warrantFormula_no_of_share_posttransaction(
              warrant_type,
              no_of_share_pretransaction,
              exercise_ratio,
              no_of_warrant,
            );
          const cashflow_ac = Formula.warrantFormula_cashflow_ac(
            warrant_type,
            no_of_exercised,
            strike_price,
          );
          const cost_ac = Formula.warrantFormula_cost_ac(
            warrant_type,
            no_of_warrant,
            no_of_share_pretransaction,
            strike_price,
          );
          const cost_ac_thb = Formula.warrantFormula_cost_ac_thb(cost_ac, fx_rate);
          const investment_cost_pretransaction =
            Formula.warrantFormula_investment_cost_pretransaction(
              investment_cost_pretransaction_1,
              investment_cost_pretransaction_2,
            );
          const investment_cost_pretransaction_thb =
            Formula.warrantFormula_investment_cost_pretransaction_thb();
          const current_stake_pretransaction =
            Formula.warrantFormula_current_stake_pretransaction(
              no_of_share_pretransaction,
              paid_up_capital_no_of_share_pretransaction,
            );
          const investment_cost = Formula.warrantFormula_investment_cost(
            investment_cost_pretransaction,
            cost_ac,
          );
          const investment_cost_thb = Formula.warrantFormula_investment_cost_thb(
            investment_cost,
            exchange_rate,
          );
          const current_stake_posttransaction =
            Formula.warrantFormula_current_stake_posttransaction(
              no_of_share_pretransaction,
              paid_up_capital_no_of_share_posttransaction,
            );

          const invCompWarrantAutogen: InvModels.InvCompWarrant = {
            ...data,
            company_name: currentSelectedCompanyName.company_name_en, // Dropdownlist child: product_name
            warrant_name: currentSelectedProductName.product_name, // Dropdownlist child: product_name //! เปลี่ยนเป็น endpoint เรียก warrant ล่าสุด
            bi_id: currentSelectedCompanyName.bi_id, // Dropdownlist child: product_name
            rm_id: currentSelectedCompanyName.rm_id, // Dropdownlist child: product_name
            company_id: currentSelectedCompanyName.company_id, // Dropdownlist child: product_name
            product_name: currentSelectedProductName.product_name, // Dropdownlist child: product_name
            product_id: currentSelectedProductName.product_id, // Dropdownlist child: product_name
            product_name_2: currentSelectedProductName2.product_name, // Dropdownlist child: product_name
            product_id_2: currentSelectedProductName2.product_id, // Dropdownlist child: product_name
            investment_instrument: currentSelectedProductName.investment_instrument, // Dropdownlist child: product_name
            investment_port: currentSelectedProductName.investment_port, // Dropdownlist child: product_name
            currency: currentSelectedProductName.currency, // Dropdownlist child: product_name
            no_of_warrant: no_of_warrant, //Formula
            no_of_warrant_posttransaction: no_of_warrant_posttransaction, //Formula
            no_of_share_posttransaction: no_of_share_posttransaction, //Formula
            cashflow_ac: cashflow_ac, //Formula
            cost_ac: cost_ac, //Formula
            cost_ac_thb: cost_ac_thb, //Formula
            investment_cost_pretransaction: investment_cost_pretransaction, //Formula
            investment_cost_pretransaction_thb: investment_cost_pretransaction_thb, //Formula
            current_stake_pretransaction: current_stake_pretransaction, //Formula
            investment_cost: investment_cost, //Formula
            investment_cost_thb: investment_cost_thb, //Formula
            current_stake_posttransaction: current_stake_posttransaction, //Formula
            exchange_rate: exchange_rate, //MaterSheet
            txn_id: 'get from invfundportfolio', //OtherSheet : invfundportfolio //!ไม่มี endpoint -----> mockup
          };
          setCurrentFillInvCompWarrant(invCompWarrantAutogen);
        };

        setWarrant();

        break;

      //---------------- 12 ----------------- */

      case 'invfundcashflow':
        const setFundCashFlow = () => {
          //from fill-form
          const cashflow_amount = data['cashflow_amount'];
          const total_partnership_paid_amount = data['total_partnership_paid_amount'];
          //-----------------------------------------//
          const current_stake_fundcashflow = Formula.fundCashFlow_current_stake(
            cashflow_amount,
            total_partnership_paid_amount,
          );
          const invFundCashflowAutogen: InvModels.InvFundCashflow = {
            ...data,
            fund_quote: currentSelectedFundQuote.fund_quote,
            fund_id: currentSelectedFundQuote.fund_id, //Dropdownlist child: fund_quote
            currency: currentSelectedFundQuote.currency, //Dropdownlist child: fund_quote
            current_stake: current_stake_fundcashflow, //Formula
            txn_id: 'MaterSheet', //MaterSheet
            brokerage_name: 'MaterSheet', //MaterSheet
          };
          setCurrentFillInvFundCashflow(invFundCashflowAutogen);
        };
        setFundCashFlow();
        break;

      //************** NotFound ********************* */
      //------------------------------------//
      default:
        break;
    }
    setIsUpdateInputJSON(false);
  };

  // if (Object.keys(inputJSON).length === 0 && Object.keys(prefillValue).length === 0 && openCreateModal=== true) {
  //?---------------------------------------------- Current Autogen------------------------------------------------
  const handleSetCurrentList = (selectedValue: any, nameId: string) => {
    // setCurrentSelectedPort
    // setCurrentSelectedFundCashFlow
    // setCurrentSelectedCompCashFlow

    switch (nameId) {
      case 'name':
        if (currentSheetName === 'invcertificate') {
          setCurrentSelectedProductName(selectedValue['data1']);
          setCurrentSelectedCompanyName(selectedValue['data2']);
          setCurrentSelectedFs(selectedValue['data3']);
        } else {
          setCurrentSelectedName(selectedValue['data1']);
        }

        break;
      //------------------------------------------//
      case 'product_name':
        setCurrentSelectedProductName(selectedValue['data1']);
        setCurrentSelectedCompanyName(selectedValue['data2']);

        break;
      //------------------------------------------//
      case 'product_name_2':
        setCurrentSelectedProductName2(selectedValue['data1']);

        break;
      //------------------------------------------//
      case 'fund_quote':
      
        setCurrentSelectedFundQuote(selectedValue['data1']);
        break;

      //------------------------------------------//
      case 'company_id':
        if (currentSheetName === 'invcompdefinitive') {
          setCurrentSelectedCompanyId(selectedValue);
        } else {
          setCurrentSelectedCompanyName(selectedValue['data1']);
        }
        break;
      //------------------------------------------//
      case 'txn_id':
        setCurrentSelectedTXNId(selectedValue['data1']);
        break;

      //------------------------------------------//
      case 'bi_id':
        setCurrentSelectedBIid(selectedValue);
        break;

      //------------------------------------------//
      case 'fund_id':
        setCurrentSelectedFundId(selectedValue);
        break;

      //------------------------------------------//
      case 'rm_id':
        setCurrentSelectedRmId(selectedValue);
        break;

      //------------------------------------------//
      case 'company_name':
      
        setCurrentSelectedCompanyName(selectedValue['data1']);

        break;

      //------------------------------------------//
      default:
        break;
    }
  };

  //*********************************************** Render ************************************************** */

  if (Object.keys(inputJSON).length === 0) {
    return <div></div>;
  } else {
    return (
      <div>
        <Modal open={openCreateModal} className={LayoutStyles.ModalBackgroundFade}>
          <Box className={LayoutStyles.ModalL_Box}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              container
              className={LayoutStyles.ModalPaper}
              component="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                container
                className={LayoutStyles.ModalL_Header}
                alignItems="center"
              >
                <Grid
                  item
                  container
                  xs={10}
                  sm={10}
                  md={10}
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={1}
                >
                  {isFirstPage ? (
                    <div></div>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      spacing={1}
                      container
                    >
                      <Grid item>
                        <Typography
                          variant="h6"
                          color="primary"
                          gutterBottom
                          component="div"
                        >
                          {typoHeaderDisplay}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Grid item>
                    <IconButton onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              {/* ------------ */}
              <Grid item xs={12} sm={12} md={12} className={LayoutStyles.ModalL_Body}>
                {/* -------------------------------------------- Row 2/1   ---------------------------------------*/}
                <Grid container>
                  <RenderPage
                    _control={control}
                    _currentSystemSheetName={currentSystemSheetName}
                    _typoHeaderDisplay={typoHeaderDisplay}
                    _fieldData={currentFillValue(currentSystemSheetName)}
                    _handleResetForm={handleResetForm}
                    _hadleSetGenerateCompleteState={hadleSetGenerateCompleteState}
                    _inputJSON={inputJSON}
                    _page={page}
                    _caseName={caseName}
                    _previousCaseName={previousCaseName}
                    _autogenList={autogenDropdownList}
                    _isLoadedAutogen={isLoadedAutogen}
                    _isUpdateInputJSON={isUpdateInputJSON}
                    setCurrentList={handleSetCurrentList}
                  />
                </Grid>
                {/* --------------------------------------------------------------------------------------------------*/}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                container
                className={LayoutStyles.ModalL_Footer}
              >
                {/* --------------------- */}
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    {/* <Chip
                      color="primary"
                      label={<ButtonLeftLabel _caseName={caseName} />}
                      variant="outlined"
                      clickable
                      onClick={handleBack}
                      disabled={isFirstPage}
                      className={ElementStyles.ButtonModalLeft}
                    /> */}

                    {isSubmit ? (
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleBack}
                        disabled={isFirstPage}
                      >
                        <ButtonLeftLabel _caseName={caseName} />
                      </Button>
                    ) : (
                      <div></div>
                    )}
                  </Grid>
                </Grid>

                {/* --------------------- */}
                <Grid
                  xs={4}
                  sm={4}
                  md={4}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  item
                >
                  <Grid item>
                    <Typography variant="body1" gutterBottom component="div">
                      {isFirstPage ? (
                        <div></div>
                      ) : (
                        <Chip
                          color="primary"
                          label={
                            isSubmit ? (
                              <b>{'Step 2 :  Summary'}</b>
                            ) : (
                              <b>{'Step 1 :  Fill Form'}</b>
                            )
                          }
                          variant="outlined"
                          disabled={true}
                        />
                      )}
                    </Typography>
                  </Grid>
                </Grid>

                {/* --------------------- */}
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  container
                >
                  {/* ----------------------- */}

                  <Grid item>
                    <RenderNextButton
                      _page={page}
                      _isLoadedAutogen={isLoadedAutogen}
                      onNext={handleNext}
                      onSubmitToAPI={handleYesNoModalOpen}
                      onSubmitForm={handleSubmit(onSubmit)}
                    />

                    {/* {isSubmit ? (
                      <Button
                        color="secondary"
                        variant="contained"
                        onMouseDown={handleYesNoModalOpen}
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        onMouseDown={handleNext}
                        disabled={!isLoadedAutogen}
                      >
                        Next
                      </Button>
                    )} */}
                  </Grid>
                </Grid>
                {/* --------------------- */}
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </div>
    );
  }
}
