import { atom } from 'recoil';

//--------------------------- Current AutoGen Value ----------------------
export const currentListCompanyState = atom({
  key: 'currentListCompany',
  default: {},
});

export const currentListFundState = atom({
  key: 'currentListFund',
  default: {},
});

export const currentListProductState = atom({
  key: 'currentListProduct',
  default: {},
});

export const currentListFsState = atom({
  key: 'currentListFs',
  default: {},
});

export const currentListCompCashFlowState = atom({
  key: 'currentListCompCashFlow',
  default: {},
});

export const currentListFundCashFlowState = atom({
  key: 'currentListFundCashFlow',
  default: {},
});

export const currentListPortState = atom({
  key: 'currentListPort',
  default: {},
});

export const isLoadedAutogenState = atom({
  key: 'isLoadedAutogen',
  default: true,
});

export const autogenDropdownListState = atom({
  key: 'autogenDropdownList',
  default: [],
});
