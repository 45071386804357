export function RenderHeader(
  sheetName: string,
  sheetNameList: object,
  caseName: string,
  currentCreateModalMode: string,
) {
  let typoHeaderDisplay = '';

  const sheetNameListData: { [key: string]: any } = sheetNameList;

  if (sheetName === 'non') {
    typoHeaderDisplay = 'notfund';
  } else {
    if (caseName === 'create' && currentCreateModalMode === 'create') {
      typoHeaderDisplay = 'Create New ' + sheetNameListData[sheetName][0]['displayName'];
    }
    if (caseName === 'edit_form' && currentCreateModalMode === 'create') {
      typoHeaderDisplay = 'Create New ' + sheetNameListData[sheetName][0]['displayName'];
    } else if (caseName === 'close' && currentCreateModalMode === 'create') {
      typoHeaderDisplay = 'Create New ' + sheetNameListData[sheetName][0]['displayName'];
    } else if (caseName === 'edit' && currentCreateModalMode === 'edit') {
      typoHeaderDisplay = 'Edit ' + sheetNameListData[sheetName][0]['displayName'];
    } else if (caseName === 'edit_form' && currentCreateModalMode === 'edit') {
      typoHeaderDisplay = 'Edit ' + sheetNameListData[sheetName][0]['displayName'];
    } else if (caseName === 'close' && currentCreateModalMode === 'edit') {
      typoHeaderDisplay = 'Edit ' + sheetNameListData[sheetName][0]['displayName'];
    }
  }

  //   console.log(typoHeaderDisplay);
  return typoHeaderDisplay;
}
