import { atom } from 'recoil';
import { arrCurrency, arrHolding, arrIncome, arrPort } from '../common';

const _sheetName = 'invcompadjustequity';

//?------- Parameter Design ---------------
/*

------- Label / ID ------------
displayName = variable for display name in label.
sourceSystemName = variable for bank system name.
apiName = variable for call api. 

-------- Input ----------------
description = input placeholder.
inputLength = custom length limit of character.
inputType = (checkbox | date | radio | select | textfield) type of input.
inputClass = Custom format masking or validation.
possibleValue = (array) Value for dropdownlist / checkbox / radio.

-------- Boolean --------------
isRequire = (true/false) require field. can't be null.

isLinkValue = (true/false) Link the value from other input (linkValueId)
linkValueId = ID of input to Link data (display when isLinkValue == true).

isAutoGenerate = (true/false) auto generate value.
generateSource = source of auto generate (display when isAutoGenerate == true).

*/
/**
 textfield
 - NoLimitFree
 - NoLimitCountNumber
 - NoLimitFloat

 date
 - General

select
 - General

 */

const jsonData: { [key: string]: any } = {
  sheetName: _sheetName,
  inputInfo: [
    // ? --------------------------------- Start ---------------------------------------//
    // ! ---------------------------- 000 ---------------------------------//
    {
      displayName: 'Product id',
      sourceSystemName: 'PRODUCT_ID',
      apiName: 'product_id',

      description: 'Product id',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 001 ---------------------------------//
    {
      displayName: 'BI_ID',
      sourceSystemName: 'BI_ID',
      apiName: 'bi_id',

      description: 'Business ID from Investment',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 002 ---------------------------------//
    {
      displayName: 'RM ID',
      sourceSystemName: 'RM_ID',
      apiName: 'rm_id',

      description: 'ID generated from bank',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 003 ---------------------------------//
    {
      displayName: 'Company ID',
      sourceSystemName: 'COMPANY_ID',
      apiName: 'company_id',

      description: 'Company ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 004 ---------------------------------//
    {
      displayName: 'Company Name',
      sourceSystemName: 'COMPANY_NAME',
      apiName: 'company_name',

      description: 'Full legal name of the company',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 005 ---------------------------------//
    {
      displayName: 'Product Name',
      sourceSystemName: 'PRODUCT_NAME',
      apiName: 'product_name',

      description: 'Company name classified by type of products',
      inputLength: '',
      inputType: 'select',
      inputClass: 'Autocomplete',
      possibleValue: ['/product/list'],
      displayValue: ['/product/list'],

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: true,
    },

    // ---------------------------- 006 ---------------------------------//
    {
      displayName: 'Intra Group Code AC',
      sourceSystemName: 'INFRA_GROUP_CODE',
      apiName: 'infra_group_code',

      description: 'Intra Group Code for subsidiary company',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 007 ---------------------------------//
    {
      displayName: 'Transaction ID',
      sourceSystemName: 'TXN_ID',
      apiName: 'txn_id',

      description: 'Transaction ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromServer',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 008 ---------------------------------//
    {
      displayName: 'Reporting Date',
      sourceSystemName: 'REPORTING_DATE',
      apiName: 'reporting_date',

      description: 'Reporting Date',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 009 ---------------------------------//
    {
      displayName: 'Recording Date',
      sourceSystemName: 'TXN_DATE',
      apiName: 'txn_date',

      description: 'Recording Date',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 010 ---------------------------------//
    {
      displayName: 'Remark',
      sourceSystemName: 'REMARK',
      apiName: 'remark',

      description: 'Remark (If any)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 011 ---------------------------------//
    {
      displayName: 'Investment Instrument AC',
      sourceSystemName: 'INVESTMENT_INSTRUMENT',
      apiName: 'investment_instrument',

      description: 'Type of securities BBL hold in the company',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: arrHolding,
      displayValue: arrHolding,

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 012 ---------------------------------//
    {
      displayName: 'Portfolio Classification AC',
      sourceSystemName: 'INVESTMENT_PORT',
      apiName: 'investment_port',

      description: 'BBL internal accounting record for accounting purpose',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: arrPort,
      displayValue: arrPort,

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 013 ---------------------------------//
    {
      displayName: 'Currency AC',
      sourceSystemName: 'CURRENCY',
      apiName: 'currency',

      description: 'Denominated Currency',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: arrCurrency,
      displayValue: arrCurrency,

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 014 ---------------------------------//
    {
      displayName: 'No. of Share',
      sourceSystemName: 'NO_OF_SHARE',
      apiName: 'no_of_share',

      description: 'Number of share',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 015 ---------------------------------//
    {
      displayName: 'Investment Cost (Orginal CCY) AC',
      sourceSystemName: 'INVESTMENT_COST_PRETRANSACTION',
      apiName: 'investment_cost_pretransaction',

      description: 'Investment Cost (Orginal CCY) - Pre Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 016 ---------------------------------//
    {
      displayName: 'Investment Cost (THB) AC',
      sourceSystemName: 'INVESTMENT_COST_PRETRANSACTION',
      apiName: 'investment_cost_pretransaction_thb',

      description: 'Investment Cost (THB) - Pre Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 017 ---------------------------------//
    {
      displayName: 'Alowance Impairment (Orginal CCY) AC',
      sourceSystemName: 'ALLOWANCE_IMPAIRMENT',
      apiName: 'allowance_impairment',

      description: 'Allowance-Impairment (Orginal CCY) - Pre Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 018 ---------------------------------//
    {
      displayName: 'Allowance Permanent (Orginal CCY) AC',
      sourceSystemName: 'ALLOWANCE_PERMANENT',
      apiName: 'allowance_permanent',

      description: 'Allowance Permanent (Orginal CCY) - Pre Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 019 ---------------------------------//
    {
      displayName: 'Cost After Impairment/Permanent (THB) AC',
      sourceSystemName: 'ALLOWANCE_PERMANENT_THB',
      apiName: 'allowance_permanent_thb',

      description: 'Cost After Impairment/Permanent (THB)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 020 ---------------------------------//
    {
      displayName: 'Adjusted Equity Amount (THB) (Pre Transaction) AC',
      sourceSystemName: 'ADJUSTED_EQUITY_PRETRANSACTION',
      apiName: 'adjusted_equity_pretransaction',

      description: 'Adjusted Equity Amount (THB) (Pre Transaction)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 021 ---------------------------------//
    {
      displayName: 'Adjust Equity Direction',
      sourceSystemName: 'INCOME',
      apiName: 'income',

      description: 'Direction to mark profit or loss for equity adjustment',
      inputLength: '',
      inputType: 'radio',
      inputClass: 'General',
      possibleValue: [true, false],
      displayValue: arrIncome,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 022 ---------------------------------//
    {
      displayName: 'Adjusted Equity Amount (THB) AC',
      sourceSystemName: 'TOTAL_ADJUSTED_EQUITY',
      apiName: 'total_adjusted_equity',

      description: 'Adjusted Equity Amount (THB)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 023 ---------------------------------//
    {
      displayName: 'Adjusted Equity Amount (THB) (Post Transaction) AC',
      sourceSystemName: 'ADJUSTED_EQUITY_POSTTRANSACTION',
      apiName: 'adjusted_equity_posttransaction',

      description: 'Adjusted Equity Amount (THB) (Post Transaction) ',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 024 ---------------------------------//
    {
      displayName: 'Total Cost after Adjusted Equity AC',
      sourceSystemName: 'ADJUSTED_COST',
      apiName: 'adjusted_cost',

      description: 'Total Cost after Adjusted Equity',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ? --------------------------------- End ---------------------------------------//
  ],
};

export const invcompadjustequity = atom({
  key: 'invcompadjustequity',
  default: jsonData,
});
