import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import ElementStyles from '../../../../styles/Element.module.css';

import { thaiDateTime, isValidDate, thaiDate } from '../function/datetime';

function DisplayText({ text }: { text: string }) {
  switch (text) {
    case 'APPROVED':
      return (
        <Typography
          sx={{ color: '#33691e' }}
          variant="body2"
          gutterBottom
          component="div"
        >
          <b>{text}</b>
        </Typography>
      );
    case 'PENDING':
      return (
        <Typography
          sx={{ color: '#e65100' }}
          variant="body2"
          gutterBottom
          component="div"
        >
          <b>{text}</b>
        </Typography>
      );
    case 'EMPTY':
      return (
        <Typography
          sx={{ color: '#9e9e9e' }}
          variant="body2"
          gutterBottom
          component="div"
        >
          <b>{text}</b>
        </Typography>
      );

    default:
      return (
        <Typography variant="body2" gutterBottom component="div">
          {text}
        </Typography>
      );
  }
}

function FieldDisplay({
  fieldLabel,
  fieldData,
  inputType,
  displayValueArr,
}: {
  fieldLabel: string;
  fieldData: any;
  inputType: string;
  displayValueArr: string[];
}) {
  // fieldData use 'any' for multiple value type;

  let valueDisplay: string = '';
  // console.log(inputType);
  if (inputType === 'date') {
    let date = new Date(fieldData);

    if (isValidDate(date) === true) {
      valueDisplay = thaiDate(date);
    } else {
      valueDisplay = '-';
    }
  } else if (inputType === 'radio') {
    if (
      fieldData === true ||
      fieldData === false ||
      fieldData === 'true' ||
      fieldData === 'false'
    ) {
      if (fieldData === true || fieldData === 'true') {
        valueDisplay = displayValueArr[0].toString();
      } else if (fieldData === false || fieldData === 'false') {
        valueDisplay = displayValueArr[1].toString();
      }
    } else {
      valueDisplay = fieldData;
    }
  } else {
    valueDisplay = fieldData;
  }
  // console.log(valueDisplay);
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      container
      className={ElementStyles.FieldDisplay}
    >
      <Grid item xs={4} sm={4} md={4}>
        <Typography
          variant="body2"
          style={{ fontWeight: 600 }}
          gutterBottom
          component="div"
        >
          {fieldLabel + ':'}
        </Typography>
      </Grid>

      <Grid item xs={8} sm={8} md={8}>
        <DisplayText text={valueDisplay} />
      </Grid>
    </Grid>
  );
}

export default FieldDisplay;
