import { Broker, Currency, Holding, Port } from './inv-enums';

export enum CompTransaction {
	Buy                = 'BUY',
	BuyPartiallyPaidUp = 'BUY_PARTIALLY_PAID_UP',
	Sell               = 'SELL',
	Drawdown           = 'DRAWDOWN',
	Redemption         = 'REDEMPTION',
	StockDividend      = 'STOCK_DIVIDEND',
	Impairment         = 'IMPAIRMENT',
	ReversedImpairment = 'REVERSED_IMPAIRMENT',
}
export enum CashflowDirection {
	Inflow  = 'INFLOW',
	Outflow = 'OUTFLOW',
}

interface InvCompCashFlowBaseInterface {
	bi_id   : string,   // --- Autogen from MASTERSHEET (BI ID)
	rm_id   : string,   // --- Autogen from MASTERSHEET (RM ID)
	txn_id  : string,   // --- Autogen
	txn_date: string,   // date string
	remark  : string,

	// Accounting Purposes
	txn_type                          : CompTransaction,
	cashflow_direction                : CashflowDirection,   // --- Autogen from transaction type
	exchange_rate                     : string,              // --- Autogen from MASTERSHEET (Currency)
	no_of_share                       : number,              // integer
	price_per_share                   : number,
	cashflow_amount                   : number,              // --- Autogen FORMULAR -> = No. of Share * Price per share
	cashflow_adjustment               : number,
	net_cashflow                      : number,              // --- Autogen FORMULAR -> = Cashflow AC + Cashflow Adjustment
	net_cashflow_thb                  : number,              // --- Autogen FORMULAR -> = (Cashflow AC + Cashflow Adjustment) * Exchange Rate
	brokerage_name                    : Broker,              // --- Autogen from MASTERSHEET (Broker Data)
	commission                        : number,              // --- Autogen FORMULAR -> = Cashflow * Broker Rate (Linked to Broker Name)
	vat_levey_fee                     : number,              // --- Autogen FORMULAR -> = Commission * 7%
	commission_vat                    : number,              // --- Autogen FORMULAR -> = Commission + VAT&Levey Fee
	withholding_tax                   : number,              // --- Autogen FORMULAR -> = Commission * 3%
	custodian_fee                     : number,
	advisory_fee_kc                   : number,
	miscellaneous_exp                 : number,
	allowance_impairment              : number,              // --- Key-In and Link to INVCOMPPRODUCT (?)
	allowance_permanent               : number,              // --- Key-In and Link to INVCOMPPRODUCT (?)
	no_of_share_pretransaction        : number,              // --- Autogen FORMULAR -> Before transaction date
	investment_cost_pretransaction_ccy: number,              // --- Autogen FORMULAR -> Before transaction date
	investment_cost_pretransaction_thb: number,              // --- Autogen FORMULAR -> Before transaction date
	current_stake_pretransaction      : number,              // --- Autogen FORMULAR -> Before transaction date
	no_of_share_posttransaction       : number,              // --- Autogen FORMULAR and linked to INVCOMPPRODUCT -> = Before transaction date +/- change of transaction
	investment_cost                   : number,              // --- Autogen FORMULAR and linked to INVCOMPPRODUCT -> = Before transaction date +/- change of transaction
	investment_cost_thb               : number,              // --- Autogen FORMULAR and linked to INVCOMPPRODUCT -> = Before transaction date +/- change of transaction
	current_stake_posttransaction     : number,              // --- Autogen FORMULAR and linked to INVCOMPPRODUCT -> = Before transaction date +/- change of transaction
}

export type InvCompCashFlow = InvCompCashFlowBaseInterface & {
	company_name         : string,    // --- Autogen from INVCOMPPRODUCT
	product_name         : string,    // --- DROPDOWN from INVCOMPPRODUCT
	first_investment_date: string,    // date string, --- Autogen from INVCOMPPRODUCT
	investment_instrument: Holding,   // --- Autogen from INVCOMPPRODUCT

	// Accounting Purposes
	investment_port: Port,       // --- Autogen from INVCOMPPRODUCT
	currency       : Currency,   // --- Autogen from INVCOMPPRODUCT
};
export type InvCompCashFlowSubmit = InvCompCashFlowBaseInterface & {
	company_id: string,   // --- Autogen from INVCOMPDEFINITIVE
	// InvCompProduct.product_id >
	//     company_name,
	//     product_name,
	//     first_investment_date,
	//     investment_instrument,
	//     investment_port,
	//     currency
	product_id: string,

	//sheet id Ref
	sheet_id: string,
};
export type InvCompCashFlowEntry = Pick<
	InvCompCashFlow,
	'txn_id' |
	'net_cashflow' |
	'investment_cost' |
	'investment_cost_thb'
>;

export type InvCompCashFlowAll = InvCompCashFlow & InvCompCashFlowSubmit;