
/*
Table Header

A1. ID / id
-----------------------

B1. Product Name /name
B2. Certificate No. / certificate_no
B3. Currency / currency
B4. No.of Share / no_of_share
B5. Investment Cost (Orginal CCY) AC / book_value
B6. Custodian Name / custodian

-------------------------
C1. Approval
C2. Detail
*/

export interface InvCertificateBodyTableInterface {
  sheet_id: string;
  id: string;
  name: string;
  certificate_no: string;
  currency: string;
  no_of_share: string;
  book_value: string;
  custodian: string;
  approval: object;
  detail: string;
}

export function createInvCertificateBodyTable(
  sheet_id: string,
  id: string,
  name: string,
  certificate_no: string,
  currency: string,
  no_of_share: string,
  book_value: string,
  custodian: string,
  approval: object,
  detail: string,
): InvCertificateBodyTableInterface {
  return {
    sheet_id,
    id,
    name,
    certificate_no,
    currency,
    no_of_share,
    book_value,
    custodian,
    approval,
    detail,
  };
}

//-------------------------//
export interface InvCertificateHeaderTableInterface {
  id: keyof InvCertificateBodyTableInterface;
  label: string;
}
