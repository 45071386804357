import { Currency } from "./inv-enums";

export enum Commitment {
	Commitment      = 'COMMITMENT',
	HighWatermark   = 'HIGH_WATERMARK',
	MaximumDrawdown = 'MAXIMUM_DRAWDOWN',
}

interface InvFundCommitmentAndWatermarkBaseInterface {
	data_date        : string,       // date string
	type             : Commitment,
	investor_name    : string,
	commitment_amount: number,
}

export type InvFundCommitmentAndWatermark = InvFundCommitmentAndWatermarkBaseInterface & {
	fund_id   : string,     // --- Autogen from INVFUNDDEFINITIVE
	fund_quote: string,     // --- DROPDOWN from INVFUNDDEFINITIVE
	currency  : Currency,   // --- Autogen from INVFUNDDEFINITIVE
};
export type InvFundCommitmentAndWatermarkSubmit = InvFundCommitmentAndWatermarkBaseInterface & {
	fund_id: string,   // InvFundDefinitive.fund_id > fund_quote, currency

	//sheet id Ref
	parent_id: string,
	sheet_id: string,
};

export type InvFundCommitmentAndWatermarkAll = InvFundCommitmentAndWatermark & InvFundCommitmentAndWatermarkSubmit;