import { useRecoilValue } from 'recoil';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import OutlinedInput from '@mui/material/OutlinedInput';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import ElementStyles from '../../../../styles/Element.module.css';
import {
  labelSizeState,
  labelGridState,
  inputGridState,
  currentSheetNameState,
  currentListCompanyState,
  currentListProductState,
  currentListFsState,
  currentListCompCashFlowState,
  currentListFundState,
} from '../../../../states/index';
import {
  MtAccountCode,
  MtBiId,
  MtBotCalendar,
  MtBrokerData,
  MtCompanyId,
  MtCountry,
  MtCurrency,
  MtFundId,
  MtIntraGroup,
  MtMsciIndustry,
  MtPortClassification,
  MtProductCode,
  MtRmId,
} from '@invport/models';

import {
  fundListDataInterface,
  fsListDataInterface,
  productListDataInterface,
  companyListDataInterface,
  compCashFlowListDataInterface,
} from '../../../../models/index';

import { groupBy } from '../function/groupBy';

export default function SelectComp({
  label,
  placeholder,
  helperText,
  nameId,
  parentId,
  inputClass,
  isRequire,
  isActive,
  possibleValue,
  displayValue,
  onInputChange,
  value,
  setCurrentList,
}: {
  label: string;
  placeholder: string;
  helperText: string;
  nameId: string;
  parentId: string;
  inputClass: string;
  isRequire: boolean;
  isActive: boolean;
  possibleValue: any[];
  displayValue: any[];
  onInputChange: any;
  value: any;
  setCurrentList: (selectedValue: any, nameId: string) => void;
}) {
  const labelFontSize = useRecoilValue(labelSizeState);
  const labelGrid = useRecoilValue(labelGridState);
  const inputGrid = useRecoilValue(inputGridState);

  const currentListCompany = useRecoilValue(currentListCompanyState);
  const currentListFs = useRecoilValue(currentListFsState);
  const currentSheetName = useRecoilValue(currentSheetNameState);

  let groupByid_currentListCompany: { [key: string]: any } = [];
  let groupByid_currentListFs: { [key: string]: any } = [];

  //--------------------------- For currentListCompCashFlow--------------------
  if (
    currentSheetName === 'invcertificate' ||
    currentSheetName === 'invcompadjustequity' ||
    currentSheetName === 'invcompadjustpar'
  ) {
    if (Object.keys(currentListFs).length === 0) {
    } else {
      groupByid_currentListFs = groupBy(currentListFs, 'company_id');
    }
  }

  //--------------------------- For currentListCompany --------------------
  if (nameId === 'product_name' || nameId === 'product_name_2') {
    if (Object.keys(currentListCompany).length === 0) {
    } else {
      groupByid_currentListCompany = groupBy(currentListCompany, 'company_name_en');
    }
  } else if (currentSheetName === 'invcertificate' && nameId === 'name') {
    if (Object.keys(currentListCompany).length === 0) {
    } else {
      groupByid_currentListCompany = groupBy(currentListCompany, 'company_name_en');
    }
  }

  switch (inputClass) {
    //--------------------------------- General ------------------------- */

    case 'General': {
      return (
        <Container className={ElementStyles.FormContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            container
          >
            <Grid
              xs={labelGrid}
              sm={labelGrid}
              md={labelGrid}
              item
              container
              alignItems="center"
            >
              <Grid item>
                <Typography
                  variant={labelFontSize}
                  className={ElementStyles.FormLabelText}
                >
                  <b>{label + ':'}</b>
                </Typography>
              </Grid>
            </Grid>

            <Grid xs={inputGrid} sm={inputGrid} md={inputGrid} item>
              <FormControl fullWidth>
                <Select
                  disabled={!isActive}
                  value={value}
                  onChange={onInputChange}
                  className={
                    isActive
                      ? ElementStyles.FormOutlinedInputActive
                      : ElementStyles.FormOutlinedInputDisable
                  }
                  input={<OutlinedInput key={nameId} id={nameId} name={nameId} />}
                >
                  {Object.keys(displayValue).map((index: any) => {
                    let subId = nameId + '_' + index;
                    return (
                      <MenuItem key={subId} value={possibleValue[index]}>
                        {displayValue[index]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Container>
      );
    }

    //--------------------------------- Autocomplete ------------------------- */
    case 'Autocomplete': {
      return (
        <Container className={ElementStyles.FormContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            container
          >
            <Grid
              xs={labelGrid}
              sm={labelGrid}
              md={labelGrid}
              item
              container
              alignItems="center"
            >
              <Grid item>
                <Typography
                  variant={labelFontSize}
                  className={ElementStyles.FormLabelText}
                >
                  <b>{label + ':'}</b>
                </Typography>
              </Grid>
            </Grid>

            <Grid xs={inputGrid} sm={inputGrid} md={inputGrid} item>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id={nameId}
                  options={possibleValue}
                  className={
                    isActive
                      ? ElementStyles.FormOutlinedInputActive
                      : ElementStyles.FormOutlinedInputDisable
                  }
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(event: any, values: any | null) => {
                    let sumData = {};

                    const emptyData = {};

                    const defaultCompanyData: companyListDataInterface = {
                      company_id: '',
                      bi_id: '',
                      company_name_en: '',
                      company_name_th: '',
                      rm_id: '',
                    };

                    const defaultFsData: fsListDataInterface = {
                      fs_id: '',
                      company_id: '',
                      paid_up_capital_no_of_share: 0,
                      par_value: 0,
                    };

                    // console.log(values);

                    if (
                      nameId === 'product_name' ||
                      nameId === 'name' ||
                      nameId === 'product_name_2'
                    ) {
                      if (
                        (currentSheetName === 'invcertificate' && nameId === 'name') ||
                        (currentSheetName === 'invcompadjustequity' &&
                          nameId === 'product_name') ||
                        (currentSheetName === 'invcompadjustpar' &&
                          nameId === 'product_name')
                      ) {
                        // console.log(groupByid_currentListCompany);
                        if (
                          Object.keys(currentListCompany).length === 0 ||
                          Object.keys(currentListFs).length === 0
                        ) {
                          sumData = {
                            data1: values['data'],
                            data2: defaultCompanyData,
                            data3: defaultFsData,
                          };

                          console.log(sumData);
                        } else {
                          const company_name = values['data']['company_name'];

                          const companyData: companyListDataInterface =
                            groupByid_currentListCompany[company_name][0];

                          const company_id = companyData.company_id;

                          const fsData: fsListDataInterface =
                            groupByid_currentListFs[company_id][0];
                          sumData = {
                            data1: values['data'],
                            data2: companyData,
                            data3: fsData,
                          };
                        }
                      } else if (
                        nameId === 'product_name' ||
                        nameId === 'product_name_2'
                      ) {
                        if (Object.keys(currentListCompany).length === 0) {
                          sumData = {
                            data1: values['data'],
                            data2: defaultCompanyData,
                            data3: emptyData,
                          };
                        } else {
                          const company_name = values['data']['company_name'];
                          const companyData: companyListDataInterface =
                            groupByid_currentListCompany[company_name][0];

                          sumData = {
                            data1: values['data'],
                            data2: companyData,
                            data3: emptyData,
                          };
                        }
                      } else if (nameId === 'name') {
                        sumData = {
                          data1: values['data'],
                          data2: emptyData,
                          data3: emptyData,
                        };
                      }
                    } else if (nameId === 'company_id') {
                      const data: MtCompanyId = values['data'];
                      setCurrentList(data, nameId);
                    } else if (nameId === 'fund_id') {
                      const data: MtFundId = values['data'];
                      setCurrentList(data, nameId);
                    } else if (nameId === 'bi_id') {
                      const data: MtBiId = values['data'];
                      setCurrentList(data, nameId);
                    } else if (nameId === 'rm_id') {
                      const data: MtRmId = values['data'];
                      setCurrentList(data, nameId);
                    } else if (nameId === 'fund_quote') {
                      console.log(values['data']);
                      sumData = {
                        data1: values['data'],
                        data2: emptyData,
                        data3: emptyData,
                      };
                    }else if (nameId === 'company_name') {
                      console.log(values['data']);
                      sumData = {
                        data1: values['data'],
                        data2: emptyData,
                        data3: emptyData,
                      };
                    }else {
                      sumData = {
                        data1: values['data'],
                        data2: emptyData,
                        data3: emptyData,
                      };
                    }
                    //--------------------------------//
                    if (
                      nameId === 'company_id' ||
                      nameId === 'fund_id' ||
                      nameId === 'bi_id' ||
                      nameId === 'rm_id'
                    ) {
                    } else {
                      setCurrentList(sumData, nameId);
                    }

                    // console.log(values);
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Container>
      );
    }

    //******************************************************** NotFound ********************************************************** */
    //------------------------------------//
    default: {
      return <p>NotFound TextField InputClass</p>;
    }
  }
}
