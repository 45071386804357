import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

//api
//Common
import { getContact, getMemo } from '../../../api/index';

import {
  requestContactMainInterface,
  requestMemoMainInterface,
} from '../../../../models/index';


//Company
import { getDetailCompany, getProduct, getFs } from '../../../api/index';
import {
  requestProductMainInterface,
  requestFsMainInterface,
  OrderBy,
} from '../../../../models/index';

//fund
import {
  getDetailFund,
  getNav,
  getPort,
  getBenchmark,
  getCommitment,
} from '../../../api/index';

import {
  requestNavMainInterface,
  requestPortMainInterface,
  requestBenchmarkMainInterface,
  requestCommitmentMainInterface,
} from '../../../../models/index';

import { requestDetailInterface } from '../../../../models/index';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

//Icon
import CloseIcon from '@mui/icons-material/Close';

//Chips

import LayoutStyles from '../../../../styles/Layout.module.css';

//ListMenu
import List from '@mui/material/List';

import ButtonMenu from '../button/ButtonMenu';

//Custom elelment
import ProgressLoading from '../element/ProgressLoading';

import MainInformation from '../detail/mainSheetDetail/MainInformation';
//State
import { isMainDetailModal } from '../../../../states/index';
import {
  sheetNameState,
  currentInputJSONState,
  isUpdateInputJSONState,
} from '../../../../states/index';
import {
  tableConfigState,
  currentSubSheetapprovalJSONState,
  currentIsEmptyState,
  currentModalHeaderState,
  currentIdState,
  currentSheetTypeState,
  currentapprovalJSONState,
  subProfileRowsState,
  isCallingAPIState,
  currentRowsLengthState,
  currentSearchKeywordState,
} from '../../../../states/index';

//Common State
import { invboardapprovedmemo, invcontact } from '../../../../states/index';

//Company State
import { invcompdefinitive, invcompproduct, invcompfs } from '../../../../states/index';

//Fund State
import {
  invfunddefinitive,
  invfundportfolio,
  invfundnav,
  invfundbenchmark,
  invfundcommitmentandwatermark,
} from '../../../../states/index';
import { approvalName } from '../function/approvalName';


export default function MainDetailModal({
  caseName,
  sheetName,
  portalMode,
  dataJSON,
  onReloadData,
}: {
  caseName: string;
  sheetName: string;
  portalMode: string;
  dataJSON: object;
  onReloadData: (
    _currentSystemSheetName: string,
    _sheet_id: string,
    _rowsLengthTotal: number,
    _currentSearchKeyword: string,
    _currentSortBy: string,
  ) => void;
}) {
  const [openMainDetailModal, setOpenMainDetailModal] =
    useRecoilState(isMainDetailModal);
  const currentRowsLength = useRecoilValue(currentRowsLengthState);
  const currentSearchKeyword = useRecoilValue(currentSearchKeywordState);

  const handleClose = () => {
    if (portalMode === 'company_profile') {
      onReloadData(
        'invcompdefinitive',
        currentId['main_sheet_id'].toString(),
        currentRowsLength,
        currentSearchKeyword,
        '',
      );
    } else if (portalMode === 'fund_profile') {
      onReloadData(
        'invfunddefinitive',
        currentId['main_sheet_id'].toString(),
        currentRowsLength,
        currentSearchKeyword,
        '',
      );
    }

    setOpenMainDetailModal(false);
  };

  const sheetList = useRecoilValue(sheetNameState);
  const currentModalHeader = useRecoilValue(currentModalHeaderState);
  const currentId: { [key: string]: any } = useRecoilValue(currentIdState);
  const currentSheetType = useRecoilValue(currentSheetTypeState);

  const currentSubSheetapprovalJSON: { [key: string]: any } = useRecoilValue(
    currentSubSheetapprovalJSONState,
  );

  

  //EMPTY | PENDING | APPROVED
  // const sheetStatusArr = ['EMPTY', 'PENDING', 'APPROVED'];
  // console.log(randomIntFromInterval(0, 2));

  //?-------------------------------------------------- Sheet Data ----------------------------------------------------------------------
  //Company Sheet
  const invcompdefinitiveData = useRecoilValue(invcompdefinitive);
  const invcompproductData = useRecoilValue(invcompproduct);
  const invcompfsData = useRecoilValue(invcompfs);

  //Fund Sheet
  const invfunddefinitiveData = useRecoilValue(invfunddefinitive);
  const invfundportfolioData = useRecoilValue(invfundportfolio);
  const invfundnavData = useRecoilValue(invfundnav);
  const invfundbenchmarkData = useRecoilValue(invfundbenchmark);
  const invfundcommitmentandwatermarkData = useRecoilValue(
    invfundcommitmentandwatermark,
  );

  //Common Sheet
  const invboardapprovedmemoData = useRecoilValue(invboardapprovedmemo);

  const invcontactData = useRecoilValue(invcontact);

  const currentapprovalJSON = useRecoilValue(currentapprovalJSONState);

  const [subProfileRows, setSubProfileRows] = useRecoilState(subProfileRowsState);

  const [isCallingAPI, setIsCallingAPI] = useRecoilState(isCallingAPIState);
  const [isUpdatedData, setIsUpdatedData] = useState(true);

  const currentIsEmpty = useRecoilValue(currentIsEmptyState);

  //CreateModal

  const [currentInputJSON, setCurrentInputJSON] = useRecoilState(currentInputJSONState);
  const [isUpdateInputJSON, setIsUpdateInputJSON] =
    useRecoilState(isUpdateInputJSONState);

    const tableConfig = useRecoilValue(tableConfigState);


  if (
    isUpdateInputJSON === true &&
    openMainDetailModal === true &&
    isCallingAPI === false
  ) {
    if (isUpdatedData === false) {
      setIsUpdateInputJSON(false);
      setIsUpdatedData(true);
    }
  }

  const handleButtonMenu = (currentSheetName: string) => async () => {
    let _currentJSON: { [key: string]: any } = {
      inputJSON: {},
      subProfileJSON: {},
    };

    let sheet_id_str = currentId['main_sheet_id'].toString();

    let requestCompanyDetail: requestDetailInterface = {
      sheet_id: sheet_id_str,
    };

    let companyDetail: { [key: string]: any } = await getDetailCompany(
      requestCompanyDetail,
    );
    _currentJSON = {
      inputJSON: invcompdefinitiveData,
      subProfileJSON: companyDetail['data'],
    };

    // let testCall =
    // callSubTableApi(currentSheetName,sheet_id_str,invcompdefinitiveData,invcompproductData,invcompfsData,invfunddefinitiveData,invfundportfolioData,invfundnavData,invfundbenchmarkData,invfundcommitmentandwatermarkData,invboardapprovedmemoData,invcontactData);

    // console.log(testCall);

    switch (currentSheetName) {
      //?-------------------- Common -------------------- */

      //----------------- 01 ---------------- */
      case 'invcontact':
        let requestContact: requestContactMainInterface = {
          sheet_id: sheet_id_str,
          page: 1,
          page_length: tableConfig['defaultRowsLength'],
          sort_by: '',
          order: OrderBy.asc,
          filter: '',
        };

        let contactList: { [key: string]: any } = await getContact(requestContact);
        // console.log(contactList);

        _currentJSON = {
          inputJSON: invcontactData,
          subProfileJSON: contactList['data'],
        };

        break;

      //---------------- 02 ----------------- */
      case 'invboardapprovedmemo':
        let requestMemo: requestMemoMainInterface = {
          sheet_id: sheet_id_str,
          page: 1,
          page_length: tableConfig['defaultRowsLength'],
          sort_by: '',
          order: OrderBy.asc,
          filter: '',
        };

        let MemoList: { [key: string]: any } = await getMemo(requestMemo);

        // console.log(MemoList);
        _currentJSON = {
          inputJSON: invboardapprovedmemoData,
          subProfileJSON: MemoList['data'],
        };
        break;

      //?-------------------- Company -------------------- */
      //---------------- 01 ----------------- */
      case 'invcompdefinitive':
        let requestCompanyDetail: requestDetailInterface = {
          sheet_id: sheet_id_str,
        };

        let getCompanyDetail: { [key: string]: any } = await getDetailCompany(
          requestCompanyDetail,
        );

        _currentJSON = {
          inputJSON: invcompdefinitiveData,
          subProfileJSON: getCompanyDetail['data'],
        };
        break;
      //---------------- 02 ----------------- */
      case 'invcompproduct':
        let requestProduct: requestProductMainInterface = {
          sheet_id: sheet_id_str,
          page: 1,
          page_length: tableConfig['defaultRowsLength'],
          sort_by: '',
          order: OrderBy.asc,
          filter: '',
        };

        let productList: { [key: string]: any } = await getProduct(requestProduct);
        _currentJSON = {
          inputJSON: invcompproductData,
          subProfileJSON: productList['data'],
        };
        break;
      //------------------ 03 --------------- */
      case 'invcompfs':
        let requestFs: requestFsMainInterface = {
          sheet_id: sheet_id_str,
          page: 1,
          page_length: tableConfig['defaultRowsLength'],
          sort_by: '',
          order: OrderBy.asc,
          filter: '',
        };

        let fsList: { [key: string]: any } = await getFs(requestFs);
        // console.log(fsList);
        _currentJSON = {
          inputJSON: invcompfsData,
          subProfileJSON: fsList['data'],
        };
        break;
      //--------------------------------- */

      //?-------------------- Fund -------------------- */
      //----------------- 01 ---------------- */
      case 'invfunddefinitive':
        let requestFundDetail: requestDetailInterface = {
          sheet_id: sheet_id_str,
        };

        let getFundDetail: { [key: string]: any } = await getDetailFund(
          requestFundDetail,
        );

        _currentJSON = {
          inputJSON: invfunddefinitiveData,
          subProfileJSON: getFundDetail['data'],
        };
        break;
      //----------------- 02 ---------------- */
      case 'invfundnav':
        let requestNav: requestNavMainInterface = {
          sheet_id: sheet_id_str,
          page: 1,
          page_length: tableConfig['defaultRowsLength'],
          sort_by: '',
          order: OrderBy.asc,
          filter: '',
        };

        let navList: { [key: string]: any } = await getNav(requestNav);
        // console.log(_currentJSON);

        _currentJSON = {
          inputJSON: invfundnavData,
          subProfileJSON: navList['data'],
        };
        break;
      //----------------- 03 ---------------- */
      case 'invfundportfolio':
        let requestPort: requestPortMainInterface = {
          sheet_id: sheet_id_str,
          page: 1,
          page_length: 20,
          sort_by: '',
          order: OrderBy.asc,
          filter: '',
        };

        let portList: { [key: string]: any } = await getPort(requestPort);

        _currentJSON = {
          inputJSON: invfundportfolioData,
          subProfileJSON: portList['data'],
        };
        break;
      //----------------- 04 ---------------- */
      case 'invfundbenchmark':
        let requestBenchmark: requestBenchmarkMainInterface = {
          sheet_id: sheet_id_str,
          page: 1,
          page_length: tableConfig['defaultRowsLength'],
          sort_by: '',
          order: OrderBy.asc,
          filter: '',
        };

        let benchmarkList: { [key: string]: any } = await getBenchmark(requestBenchmark);

        _currentJSON = {
          inputJSON: invfundbenchmarkData,
          subProfileJSON: benchmarkList['data'],
        };
        break;
      //---------------- 05 ----------------- */
      case 'invfundcommitmentandwatermark':
        let requestCommitment: requestCommitmentMainInterface = {
          sheet_id: sheet_id_str,
          page: 1,
          page_length: tableConfig['defaultRowsLength'],
          sort_by: '',
          order: OrderBy.asc,
          filter: '',
        };

        let commitmentList: { [key: string]: any } = await getCommitment(
          requestCommitment,
        );

        _currentJSON = {
          inputJSON: invfundcommitmentandwatermarkData,
          subProfileJSON: commitmentList['data'],
        };
        break;

      //************** NotFound ********************* */
      //------------------------------------//
      default:
        break;
    }
    setCurrentInputJSON(_currentJSON['inputJSON']);
    setSubProfileRows(_currentJSON['subProfileJSON']);
    setIsCallingAPI(false);
    setIsUpdatedData(false);
  };

  return (
    <div>
      {/* //------------ Main Modal -------------- */}
      <Modal open={openMainDetailModal} className={LayoutStyles.ModalBackgroundFade}>
        <Box className={LayoutStyles.ModalXL_Box}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            container
            className={LayoutStyles.ModalPaper}
          >
            {/* ---- */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              container
              className={LayoutStyles.ModalXL_Header}
              alignItems="center"
            >
              <Grid
                item
                container
                xs={10}
                sm={10}
                md={10}
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Typography variant="h6" color="primary" gutterBottom component="div">
                    <b>{currentModalHeader}</b>
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Grid item>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <Grid xs={12} sm={12} md={12} container item>
                <Typography
                  color="primary"
                  variant="subtitle2"
                  gutterBottom
                  component="div"
                >
                  {currentId['main_id_type'] + ' : ' + currentId['main_id_value']}
                </Typography>
              </Grid>

              {/* ---- */}
            </Grid>

            {/* -----------------------------------------------------------------------------*/}

            <Grid item xs={4} sm={4} md={4} className={LayoutStyles.ModalXL_BodyLeft}>
              <Box>
                <List>
                  {Object.keys(sheetList[portalMode]['subsheet']).map((index: any) => {
                    const labelId = `${portalMode}-id-${index}`;
                    const displayName =
                      sheetList[portalMode]['subsheet'][index]['displayName'];
                    const systemName =
                      sheetList[portalMode]['subsheet'][index]['systemName'];

                    const sheetType =
                      sheetList[portalMode]['subsheet'][index]['sheetType'];

                    const sheetStatus =
                      currentSubSheetapprovalJSON[approvalName(systemName)];

                    // console.log(currentSubSheetapprovalJSON);

                    // const sheetStatus = sheetStatusArr[2];
                    // const sheetStatus = sheetStatusArr[0];

                    return (
                      <ButtonMenu
                        key={labelId}
                        icon={'icon01'}
                        sheetDisplayName={displayName}
                        sheetName={systemName}
                        sheetType={sheetType}
                        sheetStautsArr={sheetStatus}
                        sheetSystemName={systemName}
                        onCallApi={handleButtonMenu(systemName)}
                      />
                    );
                  })}
                </List>
              </Box>
            </Grid>
            <Grid item xs={8} sm={8} md={8} className={LayoutStyles.ModalXL_BodyRight}>
              {/* <HistoryInformation /> */}
              {isUpdateInputJSON ? (
                <ProgressLoading typoLoading={'Loading content, please wait...'} />
              ) : (
                <MainInformation
                  sheet_type={currentSheetType}
                  inputJSON={currentInputJSON}
                  dataJSON={dataJSON}
                  approvalJSON={currentapprovalJSON}
                  subProfileJSON={subProfileRows}
                  isEmpty={currentIsEmpty}
                />
              )}
            </Grid>

            {/* --------------------------------------------------------------------------------------------------*/}

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              container
              className={LayoutStyles.ModalXL_Footer}
            ></Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
