import { useRecoilValue } from 'recoil';
import Grid from '@mui/material/Grid';
import FieldDisplay from './FieldDisplay';
import DetailHeader from './DetailHeader';
import { thaiDateTime, isValidDate } from '../function/datetime';
import { currentIdState, currentSheetNameState } from '../../../../states/index';

function ApprovalInformation({
  approvalJSON,
  isHistory,
  approvalName,
  isPending,
}: {
  approvalJSON: object;
  isHistory: boolean;
  approvalName: string;
  isPending: boolean;
}) {
  // console.log(approvalJSON);
  const currentId = useRecoilValue(currentIdState);
  const currentSheetName = useRecoilValue(currentSheetNameState);



  const approvalData: { [key: string]: any } = approvalJSON;

  const approval_status: string = approvalData['approval_status'][approvalName];
  const approve_date: string = approvalData['approve_date'];
  const approver: string = approvalData['approver'];
  const edit_date: string = approvalData['edit_date'];
  const editor: string = approvalData['editor'];
  const sheet_name: string = approvalData['sheet_name'];

  const approve_date_date = new Date(approve_date);
  const edit_date_date = new Date(edit_date);

  let approve_date_text = '-';

  if (isValidDate(approve_date_date) === true) {
    approve_date_text = thaiDateTime(approve_date_date);
  } else {
  }

  let edit_date_text = '-';

  if (isValidDate(edit_date_date) === true) {
    edit_date_text = thaiDateTime(edit_date_date);
  } else {
  }

  return (
    <>
      {isHistory ? (
        <DetailHeader
          typoHeader="Approval Information"
          headerType="general"
          isPending={isPending}
        />
      ) : (
        <DetailHeader
          typoHeader="Approval Information"
          headerType="approval"
          isPending={isPending}
        />
      )}

      {/* -------- Row 1 ---------- */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        container
      >
        <Grid item xs={6} sm={6} md={6}>
          <FieldDisplay
            fieldLabel="Sheet Name"
            fieldData={sheet_name}
            inputType="non"
            displayValueArr={['true', 'false']}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          <FieldDisplay
            fieldLabel="Last edited by"
            fieldData={editor}
            inputType="non"
            displayValueArr={['true', 'false']}
          />
        </Grid>
      </Grid>
      {/* -------- Row 2 ---------- */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        container
      >
        <Grid item xs={6} sm={6} md={6}>
          <FieldDisplay
            fieldLabel="Status"
            fieldData={approval_status}
            inputType="non"
            displayValueArr={['true', 'false']}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          <FieldDisplay
            fieldLabel="Last edited Date"
            fieldData={edit_date_text}
            inputType="non"
            displayValueArr={['true', 'false']}
          />
        </Grid>
      </Grid>
      {/* -------- Row 3 ---------- */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        container
      >
        <Grid item xs={6} sm={6} md={6}>
          <FieldDisplay
            fieldLabel="Approved by"
            fieldData={approver}
            inputType="non"
            displayValueArr={['true', 'false']}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          {/*  <FieldDisplay  fieldLabel='TestData'  fieldData='testLabel' /> */}
        </Grid>
      </Grid>

      {/* -------- Row 4 ---------- */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        container
      >
        <Grid item xs={6} sm={6} md={6}>
          <FieldDisplay
            fieldLabel="Approved Date"
            fieldData={approve_date_text}
            inputType="non"
            displayValueArr={['true', 'false']}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          {/*  <FieldDisplay  fieldLabel='TestData'  fieldData='testLabel' /> */}
        </Grid>
      </Grid>
    </>
  );
}

export default ApprovalInformation;
