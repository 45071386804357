/*
Table Header

A1. BI_ID / bi_id
A2. Company ID /company_id
-----------------------
B1. Product Name / product_name
B2. Recording Date / txn_date
B3. Portfolio Classifications / investment_port
B4. No. of Share / no_of_share
B5. Cost After Impair / allowance_permanent_thb
B6. Adjust Equity Amount Ac (THB) / total_adjusted_equity
B7. Adjust Equity Amount AC (THB) (Post transaction) / adjusted_equity_posttransaction
-------------------------
C1. Approval
C2. Detail
*/

export interface InvCompAdjustEquityBodyTableInterface {
  sheet_id: string;
  bi_id: string;
  company_id: string;
  product_name: string;
  txn_date: string;
  investment_port: string;
  no_of_share: string;
  allowance_permanent_thb: string;
  total_adjusted_equity: string;
  adjusted_equity_posttransaction: string;
  approval: object;
  detail: string;
}

export function createInvCompAdjustEquityBodyTable(
  sheet_id: string,
  bi_id: string,
  company_id: string,
  product_name: string,
  txn_date: string,
  investment_port: string,
  no_of_share: string,
  allowance_permanent_thb: string,
  total_adjusted_equity: string,
  adjusted_equity_posttransaction: string,
  approval: object,
  detail: string,
): InvCompAdjustEquityBodyTableInterface {
  return {
    sheet_id,
    bi_id,
    company_id,
    product_name,
    txn_date,
    investment_port,
    no_of_share,
    allowance_permanent_thb,
    total_adjusted_equity,
    adjusted_equity_posttransaction,
    approval,
    detail,
  };
}

//-------------------------//
export interface InvCompAdjustEquityHeaderTableInterface {
  id: keyof InvCompAdjustEquityBodyTableInterface;
  label: string;
}
