import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
//API

//Autogen api
import {
  getCompanyList,
  getProductList,
  getFsList,
  getCompCashFlowList,
  getFundList,
  getFundCashFlowList,
  getPortList,
} from './api/index';
//Autogen Master
import {
  getMtAccountCodeList,
  getMtBiIdList,
  getMtBotCalendarList,
  getMtBrokerDataList,
  getMtCompanyIdList,
  getMtCountryList,
  getMtCurrencyList,
  getMtFundIdList,
  getMtIntraGroupList,
  getMtMsciIndustryList,
  getMtPortClassification,
  getMtProductCodeList,
  getMtRmIdList,
} from './api/index';

import {
  companyListMockupResponse,
  fundListMockupResponse,
  fsListMockupResponse,
  productListMockupResponse,
  compCashFlowListMockupResponse,
  portListMockupResponse,
  fundCashFlowListMockupResponse,
} from '../mockup/autogen_list';

//Common
import { getContact, getMemo } from './api/index';

//Company
import { getCompany, getProduct, getFs } from './api/index';

//Fund
import { getFund, getNav, getPort, getBenchmark, getCommitment } from './api/index';

//Operation
import {
  getAccount,
  getCapital,
  getCertificate,
  getAdjEquity,
  getAdjPar,
  getBot,
  getCompCashFlow,
  getDirector,
  getDividend,
  getCompFair,
  getWarrant,
  getFundCashFlow,
} from './api/index';

//--- Model --------
import {
  FundTableBodyData,
  CompanyTableBodyData,
  requestFundMainInterface,
  requestCompanyMainInterface,
  OrderBy,
} from '../models/index';

import {
  requestContactMainInterface,
  requestMemoMainInterface,
  requestProductMainInterface,
  requestFsMainInterface,
  requestNavMainInterface,
  requestPortMainInterface,
  requestBenchmarkMainInterface,
  requestCommitmentMainInterface,
} from '../models/index';

import {
  requestAccountMainInterface,
  requestCapitalMainInterface,
  requestCertificateMainInterface,
  requestAdjEquityMainInterface,
  requestAdjParMainInterface,
  requestBotMainInterface,
  requestCompCashFlowMainInterface,
  requestDirectorMainInterface,
  requestDividendMainInterface,
  requestCompFairMainInterface,
  requestWarrantMainInterface,
  requestFundCashFlowMainInterface,
} from '../models/index';

import {
  createFundTableBodyData,
  createCompanyTableBodyData,
  createInvBankAccountBodyTable,
  createInvBankCapitalBodyTable,
  createInvCertificateBodyTable,
  createInvCompAdjustEquityBodyTable,
  createInvCompAdjustParBodyTable,
  createInvCompBotBodyTable,
  createInvCompCashFlowBodyTable,
  createInvCompDirectorBodyTable,
  createInvCompDividendBodyTable,
  createInvCompFairBodyTable,
  createInvCompWarrantBodyTable,
  createInvFundCashflowBodyTable,
} from '../models/index';

import {
  currentMasterSheetState,
  currentListCompanyState,
  currentListProductState,
  currentListFsState,
  currentListCompCashFlowState,
  currentListFundState,
  currentListFundCashFlowState,
  currentListPortState,
  operationRowsState,
  companyRowsState,
  fundRowsState,
  subProfileRowsState,
  isLoadedMasterRowsState,
  isLoadedAutogenState,
  isCallingAPIState,
} from '../states/index';

import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import SiderBar from './components/navbar/SideBar';
import TopBar from './components/navbar/TopBar';
import CompanyProfileCard from './components/01_companyProfile/CompanyProfileCard';
import FundProfileCard from './components/02_fundProfile/FundProfileCard';
import OperationCard from './components/03_operation/OperationCard';

import { portalStatusState, isLoggedInState, isFirstLoadState } from '../states/index';

import {
  currentInputJSONState,
  currentSheetNameState,
  currentCaseNameState,
  currentModalHeaderState,
  currentSubNameState,
  sheetNameState,
} from '../states/index';

import {
  currentInvBankAccountState,
  currentInvBankCapitalState,
  currentInvBoardApprovedMemoState,
  currentInvCertificateState,
  currentInvCompAdjustEquityState,
  currentInvCompAdjustParState,
  currentInvCompBotState,
  currentInvCompCashFlowState,
  currentInvCompDefinitiveState,
  currentInvCompDirectorState,
  currentInvCompDividendState,
  currentInvCompFairState,
  currentInvCompFsState,
  currentInvCompProductState,
  currentInvCompWarrantState,
  currentInvContactState,
  currentInvFundBenchmarkState,
  currentInvFundCashflowState,
  currentInvFundCommitmentAndWatermarkState,
  currentInvFundDefinitiveState,
  currentInvFundNavState,
  currentInvFundPortfolioState,
} from '../states/index';

import MainDetailModal from './components/common/modal/MainDetailModal';
import CreateModal from './components/common/modal/CreateModal';
import SuccessModal from './components/common/modal/SuccessModal';
import DetailModal from './components/common/modal/DetailModal';
import DetailHistoryModal from './components/common/modal/DetailHistoryModal';
import HistoryModal from './components/common/modal/HistoryModal';
import YesNoModal from './components/common/modal/YesNoModal';
import LogoutModal from './components/common/modal/LogoutModal';
import ErrorModal from './components/common/modal/ErrorModal';

import ElementStyles from '../styles/Element.module.css';

import LayoutStyles from '../styles/Layout.module.css';

import { groupBy } from './components/common/function/groupBy';

const drawerWidth = 256;

function RenderPortalCard({
  portalMode,
  onReloadData,
}: {
  portalMode: string;
  onReloadData: (
    _currentSystemSheetName: string,
    _sheet_id: string,
    _rowsLengthTotal: number,
    _currentSearchKeyword: string,
    _currentSortBy: string,
  ) => void;
}) {
  switch (portalMode) {
    case 'company_profile': {
      return <CompanyProfileCard onPaginationNextClick={onReloadData} />;
    }

    case 'fund_profile': {
      return <FundProfileCard onPaginationNextClick={onReloadData} />;
    }

    case 'operation': {
      return <OperationCard onPaginationNextClick={onReloadData} />;
    }

    default: {
      return <p>{'NotFound PortalCard'}</p>;
    }
  }
}

export default function InvestmentportPage() {
  //Current State
  const [currentMasterSheet, setCurrentMasterSheet] = useRecoilState(
    currentMasterSheetState,
  );

  //-----------------------Current  Data -------------------------------------//
  const currentInvBankAccount = useRecoilValue(currentInvBankAccountState);
  const currentInvBankCapital = useRecoilValue(currentInvBankCapitalState);
  const currentInvBoardApprovedMemo = useRecoilValue(currentInvBoardApprovedMemoState);
  const currentInvCertificate = useRecoilValue(currentInvCertificateState);
  const currentInvCompAdjustEquity = useRecoilValue(currentInvCompAdjustEquityState);
  const currentInvCompAdjustPar = useRecoilValue(currentInvCompAdjustParState);
  const currentInvCompBot = useRecoilValue(currentInvCompBotState);
  const currentInvCompCashFlow = useRecoilValue(currentInvCompCashFlowState);
  const currentInvCompDefinitive = useRecoilValue(currentInvCompDefinitiveState);
  const currentInvCompDirector = useRecoilValue(currentInvCompDirectorState);
  const currentInvCompDividend = useRecoilValue(currentInvCompDividendState);
  const currentInvCompFair = useRecoilValue(currentInvCompFairState);
  const currentInvCompFs = useRecoilValue(currentInvCompFsState);
  const currentInvCompProduct = useRecoilValue(currentInvCompProductState);
  const currentInvCompWarrant = useRecoilValue(currentInvCompWarrantState);
  const currentInvContact = useRecoilValue(currentInvContactState);
  const currentInvFundBenchmark = useRecoilValue(currentInvFundBenchmarkState);
  const currentInvFundCashflow = useRecoilValue(currentInvFundCashflowState);
  const currentInvFundCommitmentWatermark = useRecoilValue(
    currentInvFundCommitmentAndWatermarkState,
  );
  const currentInvFundDefinitive = useRecoilValue(currentInvFundDefinitiveState);
  const currentInvFundNav = useRecoilValue(currentInvFundNavState);
  const currentInvFundPortfolio = useRecoilValue(currentInvFundPortfolioState);

  const currentValue = (_currentSystemSheetName: string) => {
    let inputJSON: { [key: string]: any } = {};
    switch (_currentSystemSheetName) {
      //--------------------------------- */
      case 'invcompdefinitive':
        inputJSON = currentInvCompDefinitive;
        break;
      //--------------------------------- */
      case 'invcompproduct':
        inputJSON = currentInvCompProduct;
        break;
      //--------------------------------- */
      case 'invcompfs':
        inputJSON = currentInvCompFs;
        break;
      //--------------------------------- */
      case 'invcompdirector':
        inputJSON = currentInvCompDirector;
        break;

      //--------------------------------- */
      case 'invfunddefinitive':
        inputJSON = currentInvFundDefinitive;
        break;

      //--------------------------------- */
      case 'invfundnav':
        inputJSON = currentInvFundNav;
        break;

      //--------------------------------- */
      case 'invfundportfolio':
        inputJSON = currentInvFundPortfolio;
        break;

      //--------------------------------- */
      case 'invfundbenchmark':
        inputJSON = currentInvFundBenchmark;
        break;

      //--------------------------------- */
      case 'invfundcommitmentandwatermark':
        inputJSON = currentInvFundCommitmentWatermark;
        break;

      //--------------------------------- */
      case 'invcertificate':
        inputJSON = currentInvCertificate;
        break;

      //--------------------------------- */
      case 'invcontact':
        inputJSON = currentInvContact;
        break;

      //--------------------------------- */
      case 'invboardapprovedmemo':
        inputJSON = currentInvBoardApprovedMemo;
        break;

      //--------------------------------- */
      case 'invbankaccount':
        inputJSON = currentInvBankAccount;
        break;

      //--------------------------------- */
      case 'invbankcapital':
        inputJSON = currentInvBankCapital;
        break;

      //--------------------------------- */
      case 'invfundcashflow':
        inputJSON = currentInvFundCashflow;
        break;

      //--------------------------------- */
      case 'invcompcashflow':
        inputJSON = currentInvCompCashFlow;
        break;

      //--------------------------------- */
      case 'invcompdividend':
        inputJSON = currentInvCompDividend;
        break;

      //--------------------------------- */
      case 'invcompadjustequity':
        inputJSON = currentInvCompAdjustEquity;
        break;

      //--------------------------------- */
      case 'invcompadjustpar':
        inputJSON = currentInvCompAdjustPar;
        break;

      //--------------------------------- */
      case 'invcompwarrant':
        inputJSON = currentInvCompWarrant;
        break;

      //--------------------------------- */
      case 'invcompfair':
        inputJSON = currentInvCompFair;
        break;

      //--------------------------------- */
      case 'invcompbot':
        inputJSON = currentInvCompBot;
        break;

      //************** NotFound ********************* */
      //------------------------------------//
      default:
        inputJSON = {};
        break;
    }
    return inputJSON;
  };
  const setIsCallingAPI = useSetRecoilState(isCallingAPIState);

  const portalStatus = useRecoilValue(portalStatusState);
  const currentCaseName = useRecoilValue(currentCaseNameState);
  const currentModalHeader = useRecoilValue(currentModalHeaderState);
  const currentSubName = useRecoilValue(currentSubNameState);

  const currentSheetName = useRecoilValue(currentSheetNameState);
  const currentInputJSON = useRecoilValue(currentInputJSONState);

  let currentDataJSON = currentValue(currentSheetName);

  const sheetList = useRecoilValue(sheetNameState);
  let displayNameJSON: { [key: string]: any } = groupBy(
    sheetList[portalStatus]['subsheet'],
    'systemName',
  );
  let diaplayName = '';

  if (currentSheetName === 'non') {
    diaplayName = '';
  } else {
    diaplayName = displayNameJSON[currentSheetName][0]['displayName'];
  }

  const setIsLoadedAutogen = useSetRecoilState(isLoadedAutogenState);

  const setIsLoggedIn = useSetRecoilState(isLoggedInState);
  const [isFistLoad, setIsFistLoad] = useRecoilState(isFirstLoadState);

  if (isFistLoad === false) {
    setIsLoggedIn(true);
    setIsFistLoad(true);
  }

  const setOperationRows = useSetRecoilState(operationRowsState);
  const setCompanyRows = useSetRecoilState(companyRowsState);
  const setFundRows = useSetRecoilState(fundRowsState);
  const setSubProfileRows = useSetRecoilState(subProfileRowsState);
  const setIsLoadedRows = useSetRecoilState(isLoadedMasterRowsState);

  useEffect(() => {
    (async () => {
      const arr = await Promise.all([
        getMtAccountCodeList(),
        getMtBiIdList(),
        getMtBotCalendarList(),
        getMtBrokerDataList(),
        getMtCompanyIdList(),
        getMtCountryList(),
        getMtCurrencyList(),
        getMtFundIdList(),
        getMtIntraGroupList(),
        getMtMsciIndustryList(),
        getMtPortClassification(),
        getMtProductCodeList(),
        getMtRmIdList(),
      ]);
      setCurrentMasterSheet(arr);
    })();
  }, []);

  const handleReloadData = (
    _currentSystemSheetName: string,
    _sheet_id: string,
    _rowsLengthTotal: number,
    _currentSearchKeyword: string,
    _currentSortBy: string,
  ) => {
    let rowsData: any[] = [];
    setIsCallingAPI(true);

    switch (_currentSystemSheetName) {
      //?-------------------- Common -------------------- */

      //----------------- 01 ---------------- */
      case 'invcontact':
        const getContactRows = async () => {
          let requestContact: requestContactMainInterface = {
            sheet_id: _sheet_id,
            page: 1,
            page_length: _rowsLengthTotal,
            sort_by: _currentSortBy,
            order: OrderBy.asc,
            filter: _currentSearchKeyword,
          };

          let contactList: { [key: string]: any } = await getContact(requestContact);

          setSubProfileRows(contactList['data']);
          setIsCallingAPI(false);
        };

        getContactRows();

        break;

      //---------------- 02 ----------------- */
      case 'invboardapprovedmemo':
        const getMemoRows = async () => {
          let requestMemo: requestMemoMainInterface = {
            sheet_id: _sheet_id,
            page: 1,
            page_length: _rowsLengthTotal,
            sort_by: _currentSortBy,
            order: OrderBy.asc,
            filter: _currentSearchKeyword,
          };

          let memoList: { [key: string]: any } = await getMemo(requestMemo);
          setSubProfileRows(memoList['data']);
          setIsCallingAPI(false);
        };
        getMemoRows();
        break;

      //?-------------------- Company -------------------- */
      //---------------- 01 ----------------- */
      case 'invcompdefinitive':
        const getCompanyRows = async () => {
          let rowsData: CompanyTableBodyData[] = [];

          const requestCompanyMain: requestCompanyMainInterface = {
            page: 1,
            page_length: _rowsLengthTotal,
            sort_by: _currentSortBy,
            order: OrderBy.asc,
            filter: _currentSearchKeyword,
          };

          const getCompanyData: { [key: string]: any } = await getCompany(
            requestCompanyMain,
          );

          // console.log(getCompanyData);

          Object.keys(getCompanyData['data']).map((index: string) => {
            let sheet_id = getCompanyData['data'][index]['sheet_id'];
            let bi_id = getCompanyData['data'][index]['bi_id'];
            let company_id = getCompanyData['data'][index]['company_id'];
            let company_name_en = getCompanyData['data'][index]['company_name_en'];
            let company_name_th = getCompanyData['data'][index]['company_name_th'];
            let approval_status = getCompanyData['data'][index]['approval_status'];

            let member = createCompanyTableBodyData(
              sheet_id,
              bi_id,
              company_id,
              company_name_en,
              approval_status,
              'detail button',
            );

            rowsData.push(member);
          });
          setCompanyRows(rowsData);
          setIsCallingAPI(false);
          setIsLoadedRows(true);
        };
        getCompanyRows();
        break;
      //---------------- 02 ----------------- */
      case 'invcompproduct':
        const getProductRows = async () => {
          let requestProduct: requestProductMainInterface = {
            sheet_id: _sheet_id,
            page: 1,
            page_length: _rowsLengthTotal,
            sort_by: _currentSortBy,
            order: OrderBy.asc,
            filter: _currentSearchKeyword,
          };

          let productList: { [key: string]: any } = await getProduct(requestProduct);
          setSubProfileRows(productList['data']);
          setIsCallingAPI(false);
        };
        getProductRows();
        break;
      //------------------ 03 --------------- */
      case 'invcompfs':
        const getFsRows = async () => {
          let requestFs: requestFsMainInterface = {
            sheet_id: _sheet_id,
            page: 1,
            page_length: _rowsLengthTotal,
            sort_by: _currentSortBy,
            order: OrderBy.asc,
            filter: _currentSearchKeyword,
          };

          let fsList: { [key: string]: any } = await getFs(requestFs);
          setSubProfileRows(fsList['data']);
          setIsCallingAPI(false);
        };
        getFsRows();
        break;
      //--------------------------------- */

      //?-------------------- Fund -------------------- */
      //----------------- 01 ---------------- */
      case 'invfunddefinitive':
        const getFundRows = async () => {
          let rowsData: FundTableBodyData[] = [];

          const requestFundMain: requestFundMainInterface = {
            page: 1,
            page_length: _rowsLengthTotal,
            sort_by: _currentSortBy,
            order: OrderBy.asc,
            filter: _currentSearchKeyword,
          };

          const getFundData: { [key: string]: any } = await getFund(requestFundMain);

          // console.log(getFundData);
          Object.keys(getFundData['data']).map((index: string) => {
            let sheet_id = getFundData['data'][index]['sheet_id'];
            let bi_id = getFundData['data'][index]['bi_id'];
            let fund_id = getFundData['data'][index]['fund_id'];
            let fund_name_en = getFundData['data'][index]['fund_name'];
            let fund_name_th = getFundData['data'][index]['fund_thainame'];
            let approval_status = getFundData['data'][index]['approval_status'];

            let member = createFundTableBodyData(
              sheet_id,
              bi_id,
              fund_id,
              fund_name_en,
              approval_status,
              'detail button',
            );

            rowsData.push(member);
          });
          setFundRows(rowsData);
          setIsCallingAPI(false);
          setIsLoadedRows(true);
        };
        getFundRows();
        break;
      //----------------- 02 ---------------- */
      case 'invfundnav':
        const getNavRows = async () => {
          let requestNav: requestNavMainInterface = {
            sheet_id: _sheet_id,
            page: 1,
            page_length: _rowsLengthTotal,
            sort_by: _currentSortBy,
            order: OrderBy.asc,
            filter: _currentSearchKeyword,
          };

          let navList: { [key: string]: any } = await getNav(requestNav);
          setSubProfileRows(navList['data']);
          setIsCallingAPI(false);
        };
        getNavRows();
        break;
      //----------------- 03 ---------------- */
      case 'invfundportfolio':
        const getPortRows = async () => {
          let requestPort: requestPortMainInterface = {
            sheet_id: _sheet_id,
            page: 1,
            page_length: _rowsLengthTotal,
            sort_by: _currentSortBy,
            order: OrderBy.asc,
            filter: _currentSearchKeyword,
          };

          let portList: { [key: string]: any } = await getPort(requestPort);
          setSubProfileRows(portList['data']);
          setIsCallingAPI(false);
        };
        getPortRows();
        break;
      //----------------- 04 ---------------- */
      case 'invfundbenchmark':
        const getBenchmarkRows = async () => {
          let requestBenchmark: requestBenchmarkMainInterface = {
            sheet_id: _sheet_id,
            page: 1,
            page_length: _rowsLengthTotal,
            sort_by: _currentSortBy,
            order: OrderBy.asc,
            filter: _currentSearchKeyword,
          };

          let benchmarkList: { [key: string]: any } = await getBenchmark(
            requestBenchmark,
          );
          setSubProfileRows(benchmarkList['data']);
          setIsCallingAPI(false);
        };
        getBenchmarkRows();
        break;
      //---------------- 05 ----------------- */
      case 'invfundcommitmentandwatermark':
        const getCommitmentRows = async () => {
          let requestCommitment: requestCommitmentMainInterface = {
            sheet_id: _sheet_id,
            page: 1,
            page_length: _rowsLengthTotal,
            sort_by: _currentSortBy,
            order: OrderBy.asc,
            filter: _currentSearchKeyword,
          };

          let commitmentList: { [key: string]: any } = await getCommitment(
            requestCommitment,
          );
          setSubProfileRows(commitmentList['data']);
          setIsCallingAPI(false);
        };
        getCommitmentRows();
        break;

      //?-------------------- Operation -------------------- */

      //-------------- 01 ------------------ */

      case 'invbankaccount':
        const getAccountRows = async () => {
          const requestAccountMain: requestAccountMainInterface = {
            page: 1,
            page_length: _rowsLengthTotal,
            sort_by: _currentSortBy,
            order: OrderBy.asc,
            filter: _currentSearchKeyword,
          };

          const getAccountData: { [key: string]: any } = await getAccount(
            requestAccountMain,
          );

          Object.keys(getAccountData['data']).map((index: string) => {
            let arr = getAccountData['data'][index];

            let sheet_id = arr['sheet_id'];
            // let bi_id = 'non';

            let id = arr['sheet_data']['id'];
            let name = arr['sheet_data']['name'];
            let date = arr['sheet_data']['date'];
            let account_no = arr['sheet_data']['account_no'];
            let account_name = arr['sheet_data']['account_name'];
            let account_bank_name = arr['sheet_data']['account_bank_name'];
            let approval = arr['approval_status'];

            let member = createInvBankAccountBodyTable(
              sheet_id,
              id,
              name,
              date,
              account_no,
              account_name,
              account_bank_name,
              approval,

              'detail button',
            );
            rowsData.push(member);
          });

          // console.log(getAccountData);
          setOperationRows(rowsData);
          setIsCallingAPI(false);
          setIsLoadedRows(true);
        };

        getAccountRows();
        break;

      //--------------- 02 ------------------ */

      case 'invbankcapital':
        const getCapitalRows = async () => {
          const requestCapitalMain: requestCapitalMainInterface = {
            page: 1,
            page_length: _rowsLengthTotal,
            sort_by: _currentSortBy,
            order: OrderBy.asc,
            filter: _currentSearchKeyword,
          };

          const getCapitalData: { [key: string]: any } = await getCapital(
            requestCapitalMain,
          );

          // console.log(getCapitalData);

          Object.keys(getCapitalData['data']).map((index: string) => {
            let arr = getCapitalData['data'][index];

            let sheet_id = arr['sheet_id'];
            // let bi_id = 'non';
            // let id = arr['id'];

            let data_date = arr['data_date'];
            let cap_status = arr['status'];
            let tier_1_capital: number = parseFloat(arr['tier_1_capital']);
            let tier_2_capital: number = parseFloat(arr['tier_2_capital']);
            let total: number = tier_1_capital + tier_2_capital;
            let approval = arr['approval_status'];

            let member = createInvBankCapitalBodyTable(
              sheet_id,
              data_date,
              cap_status,
              tier_1_capital.toString(),
              tier_2_capital.toString(),
              total.toString(),
              approval,
              'detail button',
            );
            rowsData.push(member);
          });

          // console.log(getCapitalData);
          setOperationRows(rowsData);
          setIsCallingAPI(false);
          setIsLoadedRows(true);
        };
        getCapitalRows();

        break;

      //---------------- 03 ----------------- */
      case 'invcertificate':
        const getCertificateRows = async () => {
          const requestCertificateMain: requestCertificateMainInterface = {
            page: 1,
            page_length: _rowsLengthTotal,
            sort_by: _currentSortBy,
            order: OrderBy.asc,
            filter: _currentSearchKeyword,
          };

          const getCertificateData: { [key: string]: any } = await getCertificate(
            requestCertificateMain,
          );

          Object.keys(getCertificateData['data']).map((index: string) => {
            let arr = getCertificateData['data'][index];

            let sheet_id = arr['sheet_id'];
            // let bi_id = 'non';
            let id = arr['id'];

            let name = arr['name'];
            let certificate_no = arr['certificate_no'];
            let currency = arr['currency'];
            let no_of_share = arr['no_of_share'];
            let book_value = arr['book_value'];
            let custodian = arr['custodian'];
            let approval = arr['approval_status'];

            let member = createInvCertificateBodyTable(
              sheet_id,
              id,
              name,
              certificate_no,
              currency,
              no_of_share,
              book_value,
              custodian,
              approval,

              'detail button',
            );
            rowsData.push(member);
          });

          // console.log(getCertificateData);
          setOperationRows(rowsData);
          setIsCallingAPI(false);
          setIsLoadedRows(true);
        };
        getCertificateRows();

        break;

      //---------------- 04 ----------------- */

      case 'invcompadjustequity':
        const getAdjEquityRows = async () => {
          const requestAdjEquityMain: requestAdjEquityMainInterface = {
            page: 1,
            page_length: _rowsLengthTotal,
            sort_by: _currentSortBy,
            order: OrderBy.asc,
            filter: _currentSearchKeyword,
          };

          const getAdjEquityData: { [key: string]: any } = await getAdjEquity(
            requestAdjEquityMain,
          );
          Object.keys(getAdjEquityData['data']).map((index: string) => {
            let arr = getAdjEquityData['data'][index];

            let sheet_id = arr['sheet_id'];
            let bi_id = 'non';
            let company_id = arr['company_id'];
            let product_name = arr['product_name'];
            let txn_date = arr['txn_date'];
            let investment_port = arr['investment_port'];
            let no_of_share = arr['no_of_share'];
            let allowance_permanent_thb = arr['allowance_permanent_thb'];
            let total_adjusted_equity = arr['total_adjusted_equity'];
            let adjusted_equity_posttransaction = arr['adjusted_equity_posttransaction'];
            let approval = arr['approval_status'];

            let member = createInvCompAdjustEquityBodyTable(
              sheet_id,
              bi_id,
              company_id,
              product_name,
              txn_date,
              investment_port,
              no_of_share,
              allowance_permanent_thb,
              total_adjusted_equity,
              adjusted_equity_posttransaction,
              approval,
              'detail button',
            );
            rowsData.push(member);
          });

          // console.log(getAdjEquityData);
          setOperationRows(rowsData);
          setIsCallingAPI(false);
          setIsLoadedRows(true);
        };
        getAdjEquityRows();

        break;
      //----------------- 05 ---------------- */

      case 'invcompadjustpar':
        const getAdjParRows = async () => {
          const requestAdjParMain: requestAdjParMainInterface = {
            page: 1,
            page_length: _rowsLengthTotal,
            sort_by: _currentSortBy,
            order: OrderBy.asc,
            filter: _currentSearchKeyword,
          };

          const getAdjParData: { [key: string]: any } = await getAdjPar(
            requestAdjParMain,
          );

          Object.keys(getAdjParData['data']).map((index: string) => {
            let arr = getAdjParData['data'][index];

            let sheet_id = arr['sheet_id'];
            let bi_id = 'non';
            let company_id = arr['company_id'];
            let product_name = arr['product_name'];
            let txn_date = arr['txn_date'];
            let adjusted_par_method = arr['adjusted_par_method'];
            let no_of_share_posttransaction = arr['no_of_share_posttransaction'];
            let investment_cost = arr['investment_cost'];
            let par_value_posttransaction = arr['par_value_posttransaction'];
            let approval = arr['approval_status'];

            let member = createInvCompAdjustParBodyTable(
              sheet_id,
              bi_id,
              company_id,
              product_name,
              txn_date,
              adjusted_par_method,
              no_of_share_posttransaction,
              investment_cost,
              par_value_posttransaction,
              approval,
              'detail button',
            );
            rowsData.push(member);
          });

          // console.log(getAdjParData);
          setOperationRows(rowsData);
          setIsCallingAPI(false);
          setIsLoadedRows(true);
        };
        getAdjParRows();

        break;

      //---------------- 06 ----------------- */

      case 'invcompbot':
        const getBotRows = async () => {
          const requestBotMain: requestBotMainInterface = {
            page: 1,
            page_length: _rowsLengthTotal,
            sort_by: _currentSortBy,
            order: OrderBy.asc,
            filter: _currentSearchKeyword,
          };

          const getBotData: { [key: string]: any } = await getBot(requestBotMain);

          Object.keys(getBotData['data']).map((index: string) => {
            let arr = getBotData['data'][index];

            let sheet_id = arr['sheet_id'];
            let bi_id = 'non';
            let company_id = arr['company_id'];
            let recording_date = arr['recording_date'];
            let document_no = arr['document_no'];
            let request_type = arr['request_type'];
            let document_start_date = arr['document_start_date'];
            let document_end_date = arr['document_end_date'];
            let approval = arr['approval_status'];

            let member = createInvCompBotBodyTable(
              sheet_id,
              company_id,
              bi_id,
              recording_date,
              document_no,
              request_type,
              document_start_date,
              document_end_date,
              approval,
              'detail button',
            );
            rowsData.push(member);
          });

          // console.log(getBotData);
          setOperationRows(rowsData);
          setIsCallingAPI(false);
          setIsLoadedRows(true);
        };
        getBotRows();

        break;

      //--------------- 07 ------------------ */

      case 'invcompcashflow':
        const getCompCashFlowRows = async () => {
          const requestCompCashFlowMain: requestCompCashFlowMainInterface = {
            page: 1,
            page_length: _rowsLengthTotal,
            sort_by: _currentSortBy,
            order: OrderBy.asc,
            filter: _currentSearchKeyword,
          };

          const getCompCashFlowData: { [key: string]: any } = await getCompCashFlow(
            requestCompCashFlowMain,
          );

          Object.keys(getCompCashFlowData['data']).map((index: string) => {
            let arr = getCompCashFlowData['data'][index];

            let sheet_id = arr['sheet_id'];
            let bi_id = 'non';

            let company_id = arr['company_id'];
            let company_name = arr['company_name'];
            let txn_date = arr['txn_date'];
            let txn_type = arr['txn_type'];
            let currency = arr['currency'];
            let no_of_share = arr['no_of_share'];
            let price_per_share = arr['price_per_share'];
            let net_cashflow = arr['net_cashflow'];
            let approval = arr['approval_status'];

            let member = createInvCompCashFlowBodyTable(
              sheet_id,
              bi_id,
              company_id,
              company_name,
              txn_date,
              txn_type,
              currency,
              no_of_share,
              price_per_share,
              net_cashflow,
              approval,
              'detail button',
            );
            rowsData.push(member);
          });

          // console.log(getCompCashFlowData);
          setOperationRows(rowsData);
          setIsCallingAPI(false);
          setIsLoadedRows(true);
        };
        getCompCashFlowRows();

        break;

      //------------- 08 -------------------- */
      case 'invcompdirector':
        const getDirectorRows = async () => {
          const requestDirectorMain: requestDirectorMainInterface = {
            page: 1,
            page_length: _rowsLengthTotal,
            sort_by: _currentSortBy,
            order: OrderBy.asc,
            filter: _currentSearchKeyword,
          };

          const getDirectorData: { [key: string]: any } = await getDirector(
            requestDirectorMain,
          );

          Object.keys(getDirectorData['data']).map((index: string) => {
            let arr = getDirectorData['data'][index];

            let sheet_id = arr['sheet_id'];
            let bi_id = 'non';

            let company_id = arr['sheet_data']['company_id'];
            let committee_name = arr['sheet_data']['committee_name'];
            let bbl_representative = arr['sheet_data']['bbl_representative'];
            let bbl_representative_internalposition =
              arr['sheet_data']['bbl_representative_internalposition'];
            let approval = arr['approval_status'];

            let bbl_representative_text = '';

            if (bbl_representative === true) {
              bbl_representative_text = 'Yes';
            } else {
              bbl_representative_text = 'No';
            }

            let member = createInvCompDirectorBodyTable(
              sheet_id,
              bi_id,
              company_id,
              committee_name,
              bbl_representative_text,
              bbl_representative_internalposition,
              approval,
              'detail button',
            );
            rowsData.push(member);
          });

          // console.log(getDirectorData);
          setOperationRows(rowsData);
          setIsCallingAPI(false);
          setIsLoadedRows(true);
        };
        getDirectorRows();

        break;

      //---------------- 09 ----------------- */

      case 'invcompdividend':
        const getDividendRows = async () => {
          const requestDividendMain: requestDividendMainInterface = {
            page: 1,
            page_length: _rowsLengthTotal,
            sort_by: _currentSortBy,
            order: OrderBy.asc,
            filter: _currentSearchKeyword,
          };

          const getDividendData: { [key: string]: any } = await getDividend(
            requestDividendMain,
          );

          Object.keys(getDividendData['data']).map((index: string) => {
            let arr = getDividendData['data'][index];

            let sheet_id = arr['sheet_id'];
            // let bi_id = 'non';
            let company_id = arr['company_id'];
            let company_name = arr['company_name'];
            let product_name = arr['product_name'];
            let data_date = arr['data_date'];
            let currency = arr['currency'];
            let no_of_shares = arr['no_of_shares'];
            let dividend = arr['dividend'];
            let dividend_amount = arr['dividend_amount'];
            let approval = arr['approval_status'];

            let member = createInvCompDividendBodyTable(
              sheet_id,
              company_id,
              company_name,
              product_name,
              data_date,
              currency,
              no_of_shares,
              dividend,
              dividend_amount,
              approval,
              'detail button',
            );
            rowsData.push(member);
          });

          // console.log(getDividendData);
          setOperationRows(rowsData);
          setIsCallingAPI(false);
          setIsLoadedRows(true);
        };
        getDividendRows();

        break;

      //--------------- 10 ------------------ */

      case 'invcompfair':
        const getCompFairRows = async () => {
          const requestCompFairMain: requestCompFairMainInterface = {
            page: 1,
            page_length: _rowsLengthTotal,
            sort_by: _currentSortBy,
            order: OrderBy.asc,
            filter: _currentSearchKeyword,
          };

          const getCompFairData: { [key: string]: any } = await getCompFair(
            requestCompFairMain,
          );

          Object.keys(getCompFairData['data']).map((index: string) => {
            let arr = getCompFairData['data'][index];

            let sheet_id = arr['sheet_id'];
            // let bi_id = 'non';
            let company_id = arr['company_id'];
            let company_name = arr['company_name'];
            let data_date = arr['data_date'];
            let wacc = arr['wacc'];
            let terminal_growth = arr['terminal_growth'];
            let fair_value = arr['fair_value'];
            let approval = arr['approval_status'];

            let member = createInvCompFairBodyTable(
              sheet_id,
              company_id,
              company_name,
              data_date,
              wacc,
              terminal_growth,
              fair_value,
              approval,

              'detail button',
            );
            rowsData.push(member);
          });

          // console.log(getCompFairData);
          setOperationRows(rowsData);
          setIsCallingAPI(false);
          setIsLoadedRows(true);
        };
        getCompFairRows();

        break;

      //---------------- 11 ----------------- */

      case 'invcompwarrant':
        const getWarrantRows = async () => {
          const requestWarrantMain: requestWarrantMainInterface = {
            page: 1,
            page_length: _rowsLengthTotal,
            sort_by: _currentSortBy,
            order: OrderBy.asc,
            filter: _currentSearchKeyword,
          };

          const getWarrantData: { [key: string]: any } = await getWarrant(
            requestWarrantMain,
          );

          Object.keys(getWarrantData['data']).map((index: string) => {
            let arr = getWarrantData['data'][index];

            let sheet_id = arr['sheet_id'];
            let bi_id = 'non';
            let company_id = arr['company_id'];
            let product_name = arr['product_name'];
            let txn_date = arr['txn_date'];
            let warrant_name = arr['warrant_name'];
            let warrant_type = arr['warrant_type'];
            let no_of_share_pretransaction = arr['no_of_share_pretransaction'];
            let approval = arr['approval_status'];

            let member = createInvCompWarrantBodyTable(
              sheet_id,
              bi_id,
              company_id,
              product_name,
              txn_date,
              warrant_name,
              warrant_type,
              no_of_share_pretransaction,
              approval,
              'detail button',
            );

            rowsData.push(member);
          });

          // console.log(getWarrantData);
          setOperationRows(rowsData);
          setIsCallingAPI(false);
          setIsLoadedRows(true);
        };
        getWarrantRows();

        break;

      //---------------- 12 ----------------- */

      case 'invfundcashflow':
        const getFundCashFlowRows = async () => {
          const requestFundCashFlowMain: requestFundCashFlowMainInterface = {
            page: 1,
            page_length: _rowsLengthTotal,
            sort_by: _currentSortBy,
            order: OrderBy.asc,
            filter: _currentSearchKeyword,
          };

          const getFundCashFlowData: { [key: string]: any } = await getFundCashFlow(
            requestFundCashFlowMain,
          );

          Object.keys(getFundCashFlowData['data']).map((index: string) => {
            let arr = getFundCashFlowData['data'][index];

            let sheet_id = arr['sheet_id'];
            // let bi_id = 'non';

            let fund_id = arr['fund_id'];
            let fund_name = arr['fund_name'];
            let txn_date = arr['txn_date'];
            let cashflow_type = arr['cashflow_type'];
            let currency = arr['currency'];
            let cashflow_amount = arr['cashflow_amount'];
            let current_stake = arr['current_stake'];
            let approval = arr['approval_status'];

            let member = createInvFundCashflowBodyTable(
              sheet_id,
              fund_id,
              fund_name,
              txn_date,
              cashflow_type,
              currency,
              cashflow_amount,
              current_stake,
              approval,

              'detail button',
            );

            rowsData.push(member);
          });

          // console.log(getFundCashFlowData);
          setOperationRows(rowsData);
          setIsCallingAPI(false);
          setIsLoadedRows(true);
        };
        getFundCashFlowRows();

        break;

      //************** NotFound ********************* */
      //------------------------------------//
      default:
        break;
    }
  };

  //Current
  const [currentListCompany, setCurrentListCompany] = useRecoilState(
    currentListCompanyState,
  );

  const [currentListFund, setCurrentListFund] = useRecoilState(currentListFundState);
  const [currentListProduct, setCurrentListProduct] = useRecoilState(
    currentListProductState,
  );
  const [currentListFs, setCurrentListFs] = useRecoilState(currentListFsState);
  const [currentListCompCashFlow, setCurrentListCompCashFlow] = useRecoilState(
    currentListCompCashFlowState,
  );

  const [currentListFundCashFlow, setCurrentListFundCashFlow] = useRecoilState(
    currentListFundCashFlowState,
  );

  const [currentListPort, setCurrentListPort] = useRecoilState(currentListPortState);

  const getConditionArr = (sheetName: string) => {
    switch (sheetName) {
      //?-------------------- Common -------------------- */

      //----------------- 01 ---------------- */
      case 'invcontact':
        return [''];

      //---------------- 02 ----------------- */
      case 'invboardapprovedmemo':
        return ['compCashFlow', 'fundCashFlow'];

      //?-------------------- Company -------------------- */
      //---------------- 01 ----------------- */
      case 'invcompdefinitive':
        return [''];
      //---------------- 02 ----------------- */
      case 'invcompproduct':
        return ['compCashFlow'];
      //------------------ 03 --------------- */
      case 'invcompfs':
        return [''];
      //--------------------------------- */

      //?-------------------- Fund -------------------- */
      //----------------- 01 ---------------- */
      case 'invfunddefinitive':
        return ['port'];
      //----------------- 02 ---------------- */
      case 'invfundnav':
        return ['fund'];
      //----------------- 03 ---------------- */
      case 'invfundportfolio':
        return ['company', 'fund'];
      //----------------- 04 ---------------- */
      case 'invfundbenchmark':
        return ['fund'];
      //---------------- 05 ----------------- */
      case 'invfundcommitmentandwatermark':
        return ['fund'];

      //?-------------------- Operation -------------------- */

      //-------------- 01 ------------------ */

      case 'invbankaccount':
        return ['company', 'fund'];

      //--------------- 02 ------------------ */

      case 'invbankcapital':
        return [''];

      //---------------- 03 ----------------- */
      case 'invcertificate':
        return ['company', 'product', 'fs'];

      //---------------- 04 ----------------- */

      case 'invcompadjustequity':
        return ['company', 'product', 'fs'];
      //----------------- 05 ---------------- */

      case 'invcompadjustpar':
        return ['company', 'product', 'fs'];

      //---------------- 06 ----------------- */

      case 'invcompbot':
        return ['company', 'company'];

      //--------------- 07 ------------------ */

      case 'invcompcashflow':
        return ['company', 'product'];

      //------------- 08 -------------------- */
      case 'invcompdirector':
        return ['company'];

      //---------------- 09 ----------------- */

      case 'invcompdividend':
        return ['company', 'product'];

      //--------------- 10 ------------------ */

      case 'invcompfair':
        return ['company'];

      //---------------- 11 ----------------- */

      case 'invcompwarrant':
        return ['company', 'product', 'port'];

      //---------------- 12 ----------------- */

      case 'invfundcashflow':
        return ['fund'];

      //************** NotFound ********************* */
      //------------------------------------//
      default:
        return [''];
    }
  };

  const handleGetAutogenList = () => {
    setIsLoadedAutogen(false);

    const getAutoGenData = async () => {
      let conditionArr = getConditionArr(currentSheetName);

      const last = conditionArr.length - 1;

      const getByCondition = (listName: string[]) => {
        const count = listName.length;
        const arr: any[] = [];

        for (let index = 0; index < count; index++) {
          let apiName = listName[index];

          switch (apiName) {
            //--------------------------------------//
            case 'fund':
              arr.push(getFundList());
              break;

            //--------------------------------------//
            case 'company':
              arr.push(getCompanyList());
              break;

            //--------------------------------------//
            case 'product':
              arr.push(getProductList());
              break;

            //--------------------------------------//
            case 'fs':
              arr.push(getFsList());
              break;

            //--------------------------------------//
            case 'compCashFlow':
              arr.push(getCompCashFlowList());
              break;

            //--------------------------------------//
            case 'fundCashFlow':
              arr.push(getFundCashFlowList());
              break;

            //--------------------------------------//
            case 'port':
              arr.push(getPortList());
              break;

            //************** NotFound ********************* */
            //------------------------------------//
            default:
              break;
          }
        }
        return arr;
      };

      const autoGenList: { [key: string]: any } = await Promise.all(
        getByCondition(conditionArr),
      );

      for (let index = 0; index < conditionArr.length; index++) {
        let getArrIndex = conditionArr.indexOf(conditionArr[index]);

        if (
          currentSheetName === 'invfundnav' ||
          currentSheetName === 'invfundportfolio' ||
          currentSheetName === 'invfundbenchmark' ||
          currentSheetName === 'invfundcommitmentandwatermark'
        ) {
          switch (conditionArr[index]) {
            //--------------------------------------//
            case 'fund':
              setCurrentListFund(autoGenList[getArrIndex]['data']);
              //!----Mockup-----
              // setCurrentListFund(fundListMockupResponse);
              break;

            //--------------------------------------//
            case 'company':
              setCurrentListCompany(autoGenList[getArrIndex]['data']);
              //!----Mockup-----
              // setCurrentListCompany(companyListMockupResponse);
              break;

            //--------------------------------------//
            case 'product':
              // setCurrentListProduct(autoGenList[getArrIndex]['data']);
              //!----Mockup-----
              setCurrentListProduct(productListMockupResponse);
              break;

            //--------------------------------------//
            case 'fs':
              // setCurrentListFs(autoGenList[getArrIndex]['data']);
              //!----Mockup-----
              setCurrentListFs(fsListMockupResponse);
              break;

            //--------------------------------------//
            case 'compCashFlow':
              // setCurrentListCompCashFlow(autoGenList[getArrIndex]['data']);
              //!----Mockup-----
              setCurrentListCompCashFlow(compCashFlowListMockupResponse);
              break;

            //--------------------------------------//
            case 'fundCashFlow':
              // setCurrentListFundCashFlow(autoGenList[getArrIndex]['data']);
              //!----Mockup-----
              setCurrentListFundCashFlow(fundCashFlowListMockupResponse);
              break;

            //--------------------------------------//
            case 'port':
              setCurrentListPort(autoGenList[getArrIndex]['data']);
              //!----Mockup-----
              // setCurrentListPort(portListMockupResponse);
              break;

            //************** NotFound ********************* */
            //------------------------------------//
            default:
              break;
          }
        } else {
          switch (conditionArr[index]) {
            //--------------------------------------//
            case 'fund':
              // setCurrentListFund(autoGenList[getArrIndex]['data']);
              //!----Mockup-----
              setCurrentListFund(fundListMockupResponse);
              break;

            //--------------------------------------//
            case 'company':
              // setCurrentListCompany(autoGenList[getArrIndex]['data']);
              //!----Mockup-----
              setCurrentListCompany(companyListMockupResponse);
              break;

            //--------------------------------------//
            case 'product':
              // setCurrentListProduct(autoGenList[getArrIndex]['data']);
              //!----Mockup-----
              setCurrentListProduct(productListMockupResponse);
              break;

            //--------------------------------------//
            case 'fs':
              // setCurrentListFs(autoGenList[getArrIndex]['data']);
              //!----Mockup-----
              setCurrentListFs(fsListMockupResponse);
              break;

            //--------------------------------------//
            case 'compCashFlow':
              // setCurrentListCompCashFlow(autoGenList[getArrIndex]['data']);
              //!----Mockup-----
              setCurrentListCompCashFlow(compCashFlowListMockupResponse);
              break;

            //--------------------------------------//
            case 'fundCashFlow':
              // setCurrentListFundCashFlow(autoGenList[getArrIndex]['data']);
              //!----Mockup-----
              setCurrentListFundCashFlow(fundCashFlowListMockupResponse);
              break;

            //--------------------------------------//
            case 'port':
              // setCurrentListPort(autoGenList[getArrIndex]['data']);
              //!----Mockup-----
              setCurrentListPort(portListMockupResponse);
              break;

            //************** NotFound ********************* */
            //------------------------------------//
            default:
              break;
          }
        }

        if (index === last) {
          setIsLoadedAutogen(true);
        }
      }
    };

    getAutoGenData();
  };

  /*
  **for Check Current Value***

  let currentState = {
    currentSheetName: currentSheetName,
    currentInputJSON: currentInputJSON,
    currentDataJSON: currentDataJSON,
  };
  
  console.log(currentState);
  */

  if (isFistLoad === true) {
    return (
      <>
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
          {/* --------------------- SiderBar ---------------------------*/}
          <Box component="nav" className={LayoutStyles.SideBar}>
            <SiderBar onMenuClick={handleReloadData} drawerWidth={drawerWidth} />
          </Box>

          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            {/* --------------------- TopBar ---------------------------*/}
            <TopBar />

            {/* --------------------- Content ---------------------------*/}
            <Box
              component="main"
              className={LayoutStyles.MainCanvas}
              sx={{ flex: 1, py: 6, px: 4 }}
            >
              <Grid>
                {/* //------------ ErrorModal Modal -------------- */}
                <ErrorModal />
                {/* //------------ LogoutModal Modal -------------- */}
                <LogoutModal />
                {/* //------------ YesNoModal Modal -------------- */}

                <YesNoModal
                  caseName={currentCaseName}
                  sheetName={currentSheetName}
                  objectName={currentModalHeader}
                  displaySheetName={diaplayName}
                />

                {/* //------------ Success Modal -------------- */}

                <SuccessModal
                  caseName={currentCaseName}
                  sheetName={currentSheetName}
                  objectName={currentModalHeader}
                  displaySheetName={diaplayName}
                  onReloadData={handleReloadData}
                />

                {/* //------------ Create Modal -------------- */}

                <CreateModal
                  caseName={currentCaseName}
                  sheetName={currentSheetName}
                  objectName={currentModalHeader}
                  inputJSON={currentInputJSON}
                  dataJSON={currentDataJSON}
                  onGetAutogenList={handleGetAutogenList}
                />

                {/* //------------ Detail Modal -------------- */}
                <DetailHistoryModal
                  sheetName={currentSheetName}
                  objectName={currentModalHeader}
                  subObjectName={currentSubName}
                  caseName={currentCaseName}
                  inputJSON={currentInputJSON}
                  dataJSON={currentDataJSON}
                />
                {/* //------------ History Modal -------------- */}
                <HistoryModal
                  caseName={currentCaseName}
                  sheetName={currentSheetName}
                  subObjectName={currentSubName}
                  objectName={currentModalHeader}
                />

                {/* //------------ Detail Modal -------------- */}
                <DetailModal
                  sheetName={currentSheetName}
                  objectName={currentModalHeader}
                  subObjectName={diaplayName}
                  caseName={currentCaseName}
                  inputJSON={currentInputJSON}
                  dataJSON={currentDataJSON}
                  onReloadData={handleReloadData}
                />

                {/* //------------ Main Modal -------------- */}
                <MainDetailModal
                  caseName={currentCaseName}
                  sheetName={currentSheetName}
                  portalMode={portalStatus}
                  dataJSON={currentDataJSON}
                  onReloadData={handleReloadData}
                />

                {/* ---------------------------------- */}
                <RenderPortalCard
                  portalMode={portalStatus}
                  onReloadData={handleReloadData}
                />
              </Grid>
            </Box>
          </Box>
        </Box>
      </>
    );
  } else {
    return <div></div>;
  }
}
