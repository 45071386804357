import { useRecoilState } from 'recoil';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';

//Icon
import ErrorIcon from '@mui/icons-material/Error';
import { isLoginModal } from '../../../../states/index';

//Chips
import Chip from '@mui/material/Chip';
import ElementStyles from '../../../../styles/Element.module.css';
import LayoutStyles from '../../../../styles/Layout.module.css';

export default function LoginModal() {
  const [openLoginModal, setOpenLoginModal] = useRecoilState(isLoginModal);

  const handleClose = () => {
    setOpenLoginModal(false);
  };

  return (
    <div>
      <Modal open={openLoginModal} className={LayoutStyles.ModalBackgroundFade}>
        <Box className={LayoutStyles.ModalS_Box}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            container
            className={LayoutStyles.ModalPaper}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              container
              className={LayoutStyles.ModalL_Header}
              alignItems="center"
            >
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item>
                  <ErrorIcon color="error" />
                </Grid>
                <Grid item>
                  <Typography color="error" variant="h6" gutterBottom component="div">
                    <b>Login Failure</b>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={LayoutStyles.ModalS_Body}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="left"
            >
              {/* -------------------------------------------- Row 2/1   ---------------------------------------*/}

              <Grid item>
                <Typography variant="subtitle2" align="center" component="div">
                  {
                    'Please check your account permissions or contact your Administrator and try again'
                  }
                </Typography>
              </Grid>

              {/* --------------------------------------------------------------------------------------------------*/}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              container
              className={LayoutStyles.ModalS_Footer}
            >
              {/* --------------------------------- */}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                direction="row"
                justifyContent="center"
                alignItems="center"
                container
              >
                <Grid item>
                  <Chip
                    color="primary"
                    sx={{ width: 60 }}
                    className={ElementStyles.ButtonModalLeft}
                    label="Ok"
                    onClick={handleClose}
                    clickable
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
