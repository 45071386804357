import { atom } from 'recoil';
import { arrCommitment, arrCurrency } from '../common';

const _sheetName = 'invfundcommitmentandwatermark';

//?------- Parameter Design ---------------
/*

------- Label / ID ------------
displayName = variable for display name in label.
sourceSystemName = variable for bank system name.
apiName = variable for call api. 

-------- Input ----------------
description = input placeholder.
inputLength = custom length limit of character.
inputType = (checkbox | date | radio | select | textfield) type of input.
inputClass = Custom format masking or validation.
possibleValue = (array) Value for dropdownlist / checkbox / radio.

-------- Boolean --------------
isRequire = (true/false) require field. can't be null.

isLinkValue = (true/false) Link the value from other input (linkValueId)
linkValueId = ID of input to Link data (display when isLinkValue == true).

isAutoGenerate = (true/false) auto generate value.
generateSource = source of auto generate (display when isAutoGenerate == true).

*/
/**
 textfield
 - NoLimitFree
 - NoLimitCountNumber

 date
 - General

select
 - General

 */

const jsonData: { [key: string]: any } = {
  sheetName: _sheetName,
  inputInfo: [
    // ? --------------------------------- Start ---------------------------------------//
    // ---------------------------- 001 ---------------------------------//
    {
      displayName: 'Fund ID',
      sourceSystemName: 'Fund ID',
      apiName: 'fund_id',

      description: 'Fund ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'fund_quote',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 002 ---------------------------------//
    {
      displayName: 'Fund Quote',
      sourceSystemName: 'FUND_QUOTE',
      apiName: 'fund_quote',

      description: 'Short abbreviation name (internally defined)',
      inputLength: '',
      inputType: 'select',
      inputClass: 'Autocomplete',
      possibleValue: ['/fund/list'],
      displayValue: ['/fund/list'],

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: true,
    },

    // ---------------------------- 003 ---------------------------------//
    {
      displayName: 'Date',
      sourceSystemName: 'DATA_DATE',
      apiName: 'data_date',

      description: 'Date analysts obtain the data',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 004 ---------------------------------//
    {
      displayName: 'Type',
      sourceSystemName: 'TYPE',
      apiName: 'type',

      description: 'Commitment or (High Watermark or Maximum Drawdown)',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrCommitment,
      displayValue: arrCommitment,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 005 ---------------------------------//
    {
      displayName: 'Currency',
      sourceSystemName: 'CURRENCY',
      apiName: 'currency',

      description: 'Denominated Currency ',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: arrCurrency,
      displayValue: arrCurrency,

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'fund_quote',

      isAutoGenerate: true,
      isActive: true,
    },

    // ---------------------------- 006 ---------------------------------//
    {
      displayName: 'Investor Name',
      sourceSystemName: 'INVESTOR_NAME',
      apiName: 'investor_name',

      description: 'Legal name of top 10 investors in the fund',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 007 ---------------------------------//
    {
      displayName: 'Commitment Amount',
      sourceSystemName: 'COMMITMENT_AMOUNT',
      apiName: 'commitment_amount',

      description: 'Commitment amount of each investors',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ? --------------------------------- End ---------------------------------------//
  ],
};

export const invfundcommitmentandwatermark = atom({
  key: 'invfundcommitmentandwatermark',
  default: jsonData,
});
