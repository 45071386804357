import { atom } from 'recoil';

const _sheetName = 'invbankaccount';

//?------- Parameter Design ---------------
/*

------- Label / ID ------------
displayName = variable for display name in label.
sourceSystemName = variable for bank system name.
apiName = variable for call api. 

-------- Input ----------------
description = input placeholder.
inputLength = custom length limit of character.
inputType = (checkbox | date | radio | select | textfield) type of input.
inputClass = Custom format masking or validation.
possibleValue = (array) Value for dropdownlist / checkbox / radio.

-------- Boolean --------------
isRequire = (true/false) require field. can't be null.

isLinkValue = (true/false) Link the value from other input (linkValueId)
linkValueId = ID of input to Link data (display when isLinkValue == true).

isAutoGenerate = (true/false) auto generate value.
generateSource = source of auto generate (display when isAutoGenerate == true).

*/
/**
 textfield
 - NoLimitFree
 - NoLimitCountNumber
 - NoLimitFloat

 date
 - General

select
 - General

 */

const jsonData: { [key: string]: any } = {
  sheetName: _sheetName,
  inputInfo: [
    // ? --------------------------------- Start ---------------------------------------//
    // ---------------------------- 001 ---------------------------------//
    {
      displayName: 'ID',
      sourceSystemName: 'ID',
      apiName: 'id',

      description: 'Fund ID/Company ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 002 ---------------------------------//
    {
      displayName: 'Name',
      sourceSystemName: 'NAME',
      apiName: 'name',

      description: 'Full legal name of the fund/company',
      inputLength: '',
      inputType: 'select',
      inputClass: 'Autocomplete',
      possibleValue: ['/company/list', '/fund/list'],
      displayValue: ['/company/list', '/fund/list'],

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: true,
    },

    // ---------------------------- 003 ---------------------------------//
    {
      displayName: 'Date',
      sourceSystemName: 'DATE',
      apiName: 'date',

      description: 'Data Date',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 004 ---------------------------------//
    {
      displayName: 'Beneficiary Account Number',
      sourceSystemName: 'ACCOUNT_NO',
      apiName: 'account_no',

      description: 'Account number of receipient',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 005 ---------------------------------//
    {
      displayName: 'Beneficiary Account Name',
      sourceSystemName: 'ACCOUNT_NAME',
      apiName: 'account_name',

      description: 'Recipient name',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 006 ---------------------------------//
    {
      displayName: 'Beneficiary Bank Name',
      sourceSystemName: 'ACCOUNT_BANK_NAME',
      apiName: 'account_bank_name',

      description: "Recipient bank's name",
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 007 ---------------------------------//
    {
      displayName: 'Beneficiary Bank Address',
      sourceSystemName: 'BANK_ADDRESS',
      apiName: 'bank_address',

      description: "Recipient bank's address",
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 008 ---------------------------------//
    {
      displayName: 'Beneficiary Bank Swift Code',
      sourceSystemName: 'BANK_SWIFT_CODE',
      apiName: 'bank_swift_code',

      description: 'SWIFT Code',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 009 ---------------------------------//
    {
      displayName: 'Beneficiary Bank ABA Number',
      sourceSystemName: 'ABA_NO',
      apiName: 'aba_no',

      description: 'ABA number',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitCountNumber',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 010 ---------------------------------//
    {
      displayName: 'Correspondant Bank Name',
      sourceSystemName: 'CORR_NAME',
      apiName: 'corr_name',

      description: "Correspondant bank's name",
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 011 ---------------------------------//
    {
      displayName: 'Correspondant Bank SWIFT Code',
      sourceSystemName: "CORR_SWIFT_CODE",
      apiName: 'corr_swift_code',

      description: "Correspondant bank's swift code",
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitCountNumber',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

   

    // ? --------------------------------- End ---------------------------------------//
  ],
};

export const invbankaccount = atom({
  key: 'invbankaccount',
  default: jsonData,
});
