/*
Table Header

A1. BI_ID / bi_id
A2. Company ID / company_id
-----------------------

B1. Company Name / company_name
B2. BBL's representative / bbl_representative
B3. Committee Name / committee_name 
B4. BBL's representative internal position / bbl_representative_internalposition

-------------------------
C1. Approval
C2. Detail
*/

export interface InvCompDirectorBodyTableInterface {
  sheet_id: string;
  bi_id: string;
  company_id: string;

  committee_name: string;
  bbl_representative: string;
  bbl_representative_internalposition: string;
  approval: object;
  detail: string;
}

export function createInvCompDirectorBodyTable(
  sheet_id: string,
  bi_id: string,
  company_id: string,

  committee_name: string,
  bbl_representative: string,
  bbl_representative_internalposition: string,
  approval: object,
  detail: string,
): InvCompDirectorBodyTableInterface {
  return {
    sheet_id,
    bi_id,
    company_id,

    committee_name,
    bbl_representative,
    bbl_representative_internalposition,
    approval,
    detail,
  };
}

//-------------------------//
export interface InvCompDirectorHeaderTableInterface {
  id: keyof InvCompDirectorBodyTableInterface;
  label: string;
}
