import { atom } from 'recoil';

import { arrCompStage,arrYn,arrYnValue, arrSector, arrAlternative, arrConsolidation, arrOwnership } from '../common';

const _sheetName = 'invcompdefinitive';

//?------- Parameter Design ---------------
/*

------- Label / ID ------------
displayName = variable for display name in label.
sourceSystemName = variable for bank system name.
apiName = variable for call api. 

-------- Input ----------------
description = input placeholder.
inputLength = custom length limit of character.
inputType = (checkbox | date | radio | select | textfield) type of input.
inputClass = Custom format masking or validation.
possibleValue = (array) Value for dropdownlist / checkbox / radio.

-------- Boolean --------------
isRequire = (true/false) require field. can't be null.

isLinkValue = (true/false) Link the value from other input (linkValueId)
linkValueId = ID of input to Link data (display when isLinkValue == true).

isAutoGenerate = (true/false) auto generate value.
generateSource = source of auto generate (display when isAutoGenerate == true).

*/
/**
 textfield
 - NoLimitFree
 - noLimitCountNumber

 date
 - General

select
 - General

 */

const jsonData: { [key: string]: any } = {
  sheetName: _sheetName,
  inputInfo: [
    // ? --------------------------------- Start ---------------------------------------//
    // !---------------------------- 001 ---------------------------------//
    // {
    //   displayName: 'BI ID',
    //   sourceSystemName: 'BI_ID', 
    //   description: 'Business ID from Investment',
    //   inputLength: '',
    //   inputType: 'textfield',
    //   inputClass: 'NoLimitFree',
    //   possibleValue: '',
    //   displayValue: '',

    //   isRequire: false,

    //   isLinkValue: false,
    //   linkValueId: '',

    //   isAutoGenerate: false,
    //   isActive: true,
    // },

    // ---------------------------- 002 ---------------------------------//
    {
      displayName: 'RM ID',
      sourceSystemName: 'RM_ID',
      apiName: 'rm_id',

      description: 'ID generated from bank',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 003 ---------------------------------//
    {
      displayName: 'Date',
      sourceSystemName: 'DATA_DATE',
      apiName: 'data_date',

      description: 'Date analysts obtain the data',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 004 ---------------------------------//
    {
      displayName: 'Company ID',
      sourceSystemName: 'COMPANY_ID',
      apiName: 'company_id',

      description: 'Company ID',
      inputLength: '',
      inputType: 'select',
      inputClass: 'Autocomplete',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: true,
    },

    // ---------------------------- 005 ---------------------------------//
    {
      displayName: 'Registration ID',
      sourceSystemName: 'REGISTERED_ID',
      apiName: 'registered_id',

      description: 'Registration ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 006 ---------------------------------//
    {
      displayName: 'Registered Date',
      sourceSystemName: 'REGISTERED_DATE',
      apiName: 'registered_date',

      description: 'Registered date of the firm',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 007 ---------------------------------//
    {
      displayName: 'Company Eng Name',
      sourceSystemName: 'COMPANY_ENGNAME',
      apiName: 'company_name_en',

      description: 'Full legal name of the company ',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 008 ---------------------------------//
    {
      displayName: 'Company Thai Name',
      sourceSystemName: 'COMPANY_THAINAME',
      apiName: 'company_name_th',

      description: 'Full legal name of the company in Thai',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 009 ---------------------------------//
    {
      displayName: 'Company Symbol',
      sourceSystemName: 'COMPANY_SYMBOL',
      apiName: 'company_symbol',

      description: 'Symbol name  from SET',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 010 ---------------------------------//
    {
      displayName: 'Equity-Short Name',
      sourceSystemName: 'EQUITY_SHORT_NAME',
      apiName: 'equity_short_name',

      description: 'Bloomberg Ticker',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 011 ---------------------------------//
    {
      displayName: 'Company Group',
      sourceSystemName: 'COMPANY_GROUP',
      apiName: 'company_group',

      description: 'SLL Group (Input company name)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 012 ---------------------------------//
    {
      displayName: 'Company Stage',
      sourceSystemName: 'COMPANY_STAGE',
      apiName: 'company_stage',

      description: 'Mainly relevant for startups, could be Seed, or Series A.....',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrCompStage,
      displayValue: arrCompStage,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 013 ---------------------------------//
    {
      displayName: 'Strategic Investment',
      sourceSystemName: 'STRATEGIC_INVESTMENT',
      apiName: 'strategic_investment',

      description: 'Strategic investment (Y/N)',
      inputLength: '',
      inputType: 'radio',
      inputClass: 'General',
      possibleValue: arrYnValue,
      displayValue: arrYn,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 014 ---------------------------------//
    {
      displayName: 'Exit',
      sourceSystemName: 'EXIT_FLAG',
      apiName: 'exit_flag',

      description: 'Whether company is fully liquidated from BBL portfolio',
      inputLength: '',
      inputType: 'radio',
      inputClass: 'General',
      possibleValue: arrYnValue,
      displayValue: arrYn,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 015 ---------------------------------//
    {
      displayName: 'Business Type EN',
      sourceSystemName: 'BUSINESS_TYPE_EN',
      apiName: 'business_type_en',

      description: 'Business type of the firm in English',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',

      possibleValue: arrYnValue,
      displayValue: arrYn,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 016 ---------------------------------//
    {
      displayName: 'Business Type TH',
      sourceSystemName: 'BUSINESS_TYPE_TH',
      apiName: 'business_type_th',

      description: 'Business type of the firm in Thai',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue:'',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 017 ---------------------------------//
    {
      displayName: 'MSCI Industry',
      sourceSystemName: 'MSCI_INDUSTRY',
      apiName: 'msci_industry',

      description: 'Industry classified by MSCI classification',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrSector,
      displayValue:arrSector,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 018 ---------------------------------//
    {
      displayName: 'Main Product 1',
      sourceSystemName: 'MAIN_PRODUCT_1',
      apiName: 'main_product_1',

      description: 'Main products / services / brands of the companies',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 019 ---------------------------------//
    {
      displayName: 'Main Product 2',
      sourceSystemName: 'MAIN_PRODUCT_2',
      apiName: 'main_product_2',

      description: 'Main products / services / brands of the companies',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 020 ---------------------------------//
    {
      displayName: 'Main Product 3',
      sourceSystemName: 'MAIN_PRODUCT_3',
      apiName: 'main_product_3',

      description: 'Main products / services / brands of the companies',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 021 ---------------------------------//
    {
      displayName: 'Main Product 4',
      sourceSystemName: 'MAIN_PRODUCT_4',
      apiName: 'main_product_4',

      description: 'Main products / services / brands of the companies',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 022 ---------------------------------//
    {
      displayName: 'Main Product 5',
      sourceSystemName: 'MAIN_PRODUCT_5',
      apiName: 'main_product_5',

      description: 'Main products / services / brands of the companies',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 023 ---------------------------------//
    {
      displayName: 'Country of Domicile',
      sourceSystemName: 'DOMICILE_COUNTRY',
      apiName: 'domicile_country',

      description: 'Country the company legally registerred in.',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 024 ---------------------------------//
    {
      displayName: 'Invested Country',
      sourceSystemName: 'INVESTED_COUNTRY',
      apiName: 'invested_country',

      description: 'Countries the company mainly operate in.',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 025 ---------------------------------//
    {
      displayName: 'Fund Ownership',
      sourceSystemName: 'FUND_ID',
      apiName: 'fund_id',

      description: 'Fund ID of fund who owns the company',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitCountNumber',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 026 ---------------------------------//
    {
      displayName: 'Address EN',
      sourceSystemName: 'ADDRESS_EN',
      apiName: 'address_en',

      description: "Company's Address in English",
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 027 ---------------------------------//
    {
      displayName: 'Address TH',
      sourceSystemName: 'ADDRESS_TH',
      apiName: 'address_th',

      description: "Company's Address in Thai",
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 028 ---------------------------------//
    {
      displayName: 'Remark',
      sourceSystemName: 'REMARK',
      apiName: 'remark',

      description: 'Remark (If any)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 029 ---------------------------------//
    {
      displayName: 'Alternative Classification',
      sourceSystemName: 'ALTERNATIVE_CLASS',
      apiName: 'alternative_class',

      description:
        'Classify if this investment is either alternative or non-alternative',
      inputLength: '',
      inputType: 'radio',
      inputClass: 'General',
      possibleValue: arrYnValue,
      displayValue: arrAlternative,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 030 ---------------------------------//
    {
      displayName: 'Fintech Classification',
      sourceSystemName: 'FINTECH_CLASS',
      apiName: 'fintech_class',

      description:
        'Classify if this investment classifies as Fintech (followed BOT reg)',
      inputLength: '',
      inputType: 'radio',
      inputClass: 'General',
      possibleValue: arrYnValue,
      displayValue: arrYn,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 031 ---------------------------------//
    {
      displayName: 'BOT Indistrial Classification AC',
      sourceSystemName: 'ISIC_CODE',
      apiName: 'isic_code',

      description: 'International Standard Industrial Classification (ISIC Code)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 032 ---------------------------------//
    {
      displayName: 'BOT Exclusion Flag AC',
      sourceSystemName: 'BOT_EXCLUSION_FLAG',
      apiName: 'bot_exclusion_flag',

      description: 'This company is excluded  for BOT Limit calculation',
      inputLength: '',
      inputType: 'radio',
      inputClass: 'General',
      possibleValue: arrYnValue,
      displayValue: arrYn,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 033 ---------------------------------//
    {
      displayName: 'Consolidated Flag AC',
      sourceSystemName: 'CONSOLIDATED_FLAG',
      apiName: 'consolidated_flag',

      description: 'Whether company is consolidated',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrConsolidation,
      displayValue: arrConsolidation,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 034 ---------------------------------//
    {
      displayName: 'Ownership Type AC',
      sourceSystemName: 'OWNERSHIP_TYPE',
      apiName: 'ownership_type',

      description:
        'Can be either Direct (Directly owned by BBL). or Indirect (Held by subsidiary/branch)',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrOwnership,
      displayValue: arrOwnership,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ? --------------------------------- End ---------------------------------------//
  ],
};

export const invcompdefinitive = atom({
  key: 'invcompdefinitive',
  default: jsonData,
});
