import { useRecoilValue } from 'recoil';

import CheckboxComp from '../CheckboxComp';
import DateComp from '../DateComp';
import RadioComp from '../RadioComp';
import SelectComp from '../SelectComp';
import TextFieldComp from '../TextFieldComp';

import {
  currentListCompanyState,
  currentListFundState,
  currentListProductState,
  currentListFsState,
  currentListCompCashFlowState,
  currentListFundCashFlowState,
  currentListPortState,
  currentMasterSheetState,

} from '../../../../../states/index';

import {
  MtAccountCode,
  MtBiId,
  MtBotCalendar,
  MtBrokerData,
  MtCompanyId,
  MtCountry,
  MtCurrency,
  MtFundId,
  MtIntraGroup,
  MtMsciIndustry,
  MtPortClassification,
  MtProductCode,
  MtRmId,
} from '@invport/models';

export default function GenerateInputField({
  label,
  placeholder,
  helperText,
  nameId,
  parentId,
  inputType,
  inputClass,
  isRequire,
  isActive,
  isAutoGenerate,
  possibleValue,
  displayValue,
  currentValue,
  onInputChange,
  currentSheetName,
  setCurrentList,
}: {
  label: string;
  placeholder: string;
  helperText: string;
  nameId: string;
  parentId: string;
  inputType: string;
  inputClass: string;
  isRequire: boolean;
  isActive: boolean;
  isAutoGenerate: boolean;
  possibleValue: [];
  displayValue: [];
  currentValue: any;
  onInputChange: () => void;
  currentSheetName: string;
  setCurrentList: (selectedValue: any, nameId: string) => void;
}) {
  // currentValue use 'any' for multiple value type;

  /*
  Using
  [] Checkbox
  [x] Date 
  [x] Radio
  [x] Select
  [x] Textfield
  */

  const currentListCompany = useRecoilValue(currentListCompanyState);
  const currentListFund = useRecoilValue(currentListFundState);
  const currentListProduct = useRecoilValue(currentListProductState);
  const currentListFs = useRecoilValue(currentListFsState);
  const currentListCompCashFlow = useRecoilValue(currentListCompCashFlowState);
  const currentListFundCashFlow = useRecoilValue(currentListFundCashFlowState);
  const currentListPort = useRecoilValue(currentListPortState);
  const currentMasterSheet = useRecoilValue(currentMasterSheetState);

 



  const getCheckDisplay = (sheetName: string, _nameId: string) => {
    let isDisplay = true;
    switch (sheetName) {
      //?-------------------- Common -------------------- */

      //----------------- 01 ---------------- */
      case 'invcontact':
        return isDisplay;

      //---------------- 02 ----------------- */
      case 'invboardapprovedmemo':
        return isDisplay;

      //?-------------------- Company -------------------- */
      //---------------- 01 ----------------- */
      case 'invcompdefinitive':
        return isDisplay;
      //---------------- 02 ----------------- */
      case 'invcompproduct':
        if (_nameId === 'product_id') {
          isDisplay = false;
        } else {
          isDisplay = true;
        }
        return isDisplay;

      //------------------ 03 --------------- */
      case 'invcompfs':
        if (_nameId === 'fs_id') {
          isDisplay = false;
        } else {
          isDisplay = true;
        }
        return isDisplay;

      //--------------------------------- */

      //?-------------------- Fund -------------------- */
      //----------------- 01 ---------------- */
      case 'invfunddefinitive':
        return isDisplay;
      //----------------- 02 ---------------- */
      case 'invfundnav':
        return isDisplay;
      //----------------- 03 ---------------- */
      case 'invfundportfolio':
        return isDisplay;
      //----------------- 04 ---------------- */
      case 'invfundbenchmark':
        return isDisplay;
      //---------------- 05 ----------------- */
      case 'invfundcommitmentandwatermark':
        return isDisplay;

      //?-------------------- Operation -------------------- */

      //-------------- 01 ------------------ */

      case 'invbankaccount':
        return isDisplay;

      //--------------- 02 ------------------ */

      case 'invbankcapital':
        return isDisplay;

      //---------------- 03 ----------------- */
      case 'invcertificate':
        if (_nameId === 'product_id' || _nameId === 'company_id') {
          isDisplay = false;
        } else {
          isDisplay = true;
        }
        return isDisplay;

      //---------------- 04 ----------------- */

      case 'invcompadjustequity':
        return isDisplay;
      //----------------- 05 ---------------- */

      case 'invcompadjustpar':
        return isDisplay;

      //---------------- 06 ----------------- */

      case 'invcompbot':
        return isDisplay;

      //--------------- 07 ------------------ */

      case 'invcompcashflow':
        if (_nameId === 'product_id') {
          isDisplay = false;
        } else {
          isDisplay = true;
        }
        return isDisplay;

      //------------- 08 -------------------- */
      case 'invcompdirector':
        return isDisplay;

      //---------------- 09 ----------------- */

      case 'invcompdividend':
        return isDisplay;

      //--------------- 10 ------------------ */

      case 'invcompfair':
        return isDisplay;

      //---------------- 11 ----------------- */

      case 'invcompwarrant':
        return isDisplay;

      //---------------- 12 ----------------- */

      case 'invfundcashflow':
        return isDisplay;

      //************** NotFound ********************* */
      //------------------------------------//
      default:
        return {};
    }
  };

  const getlist = (sheetName: string) => {
    switch (sheetName) {
      //?-------------------- Common -------------------- */

      //----------------- 01 ---------------- */
      case 'invcontact':
        return {};

      //---------------- 02 ----------------- */
      case 'invboardapprovedmemo':
        return {
          compCashFlow: currentListCompCashFlow,
          fundCashFlow: currentListFundCashFlow,
        };

      //?-------------------- Company -------------------- */
      //---------------- 01 ----------------- */
      case 'invcompdefinitive':
        return {};
      //---------------- 02 ----------------- */
      case 'invcompproduct':
        return {
          compCashFlow: currentListCompCashFlow,
        };
      //------------------ 03 --------------- */
      case 'invcompfs':
        return {};
      //--------------------------------- */

      //?-------------------- Fund -------------------- */
      //----------------- 01 ---------------- */
      case 'invfunddefinitive':
        return {
          port: currentListPort,
        };
      //----------------- 02 ---------------- */
      case 'invfundnav':
        return { fund: currentListFund };
      //----------------- 03 ---------------- */
      case 'invfundportfolio':
        return { fund: currentListFund, company: currentListCompany };
      //----------------- 04 ---------------- */
      case 'invfundbenchmark':
        return { fund: currentListFund };
      //---------------- 05 ----------------- */
      case 'invfundcommitmentandwatermark':
        return { fund: currentListFund };

      //?-------------------- Operation -------------------- */

      //-------------- 01 ------------------ */

      case 'invbankaccount':
        return { company: currentListCompany, fund: currentListFund };

      //--------------- 02 ------------------ */

      case 'invbankcapital':
        return {};

      //---------------- 03 ----------------- */
      case 'invcertificate':
        return {
          product: currentListProduct,
          fs: currentListFs,
          company: currentListCompany,
        };

      //---------------- 04 ----------------- */

      case 'invcompadjustequity':
        return {
          product: currentListProduct,
          fs: currentListFs,
          company: currentListCompany,
        };
      //----------------- 05 ---------------- */

      case 'invcompadjustpar':
        return {
          product: currentListProduct,
          fs: currentListFs,
          company: currentListCompany,
        };

      //---------------- 06 ----------------- */

      case 'invcompbot':
        return { company: currentListCompany };

      //--------------- 07 ------------------ */

      case 'invcompcashflow':
        return { product: currentListProduct, company: currentListCompany };

      //------------- 08 -------------------- */
      case 'invcompdirector':
        return { company: currentListCompany };

      //---------------- 09 ----------------- */

      case 'invcompdividend':
        return { product: currentListProduct, company: currentListCompany };

      //--------------- 10 ------------------ */

      case 'invcompfair':
        return { company: currentListCompany };

      //---------------- 11 ----------------- */

      case 'invcompwarrant':
        return {
          product: currentListProduct,
          company: currentListCompany,
          port: currentListPort,
        };

      //---------------- 12 ----------------- */

      case 'invfundcashflow':
        return { fund: currentListFund };

      //************** NotFound ********************* */
      //------------------------------------//
      default:
        return {};
    }
  };

  //?--------------------------------------------------Autogen Condition-----------------------------------------------------
  let dropdownlistValue = [];

  if (
    isAutoGenerate === true &&
    inputType === 'select' &&
    inputClass === 'Autocomplete'
  ) {
    let arr: object[] = [];
    const list: { [key: string]: any } = getlist(currentSheetName);
    const master: { [key: string]: any } = currentMasterSheet;

    // console.log(nameId);
    // console.log(list);

    if (nameId === 'company_name') {
      for (let index = 0; index < list['company'].length; index++) {
        const element = {
          label: list['company'][index]['company_name_en'],
          id: list['company'][index]['company_id'],
          data: list['company'][index],
        };
        arr.push(element);
      }
    } else if (nameId === 'fund_quote') {
      for (let index = 0; index < list['fund'].length; index++) {
        const element = {
          label: list['fund'][index]['fund_quote'],
          id: list['fund'][index]['fund_id'],
          data: list['fund'][index],
        };
        arr.push(element);
      }
    } else if (nameId === 'product_name' || nameId === 'product_name_2') {
      for (let index = 0; index < list['product'].length; index++) {
        const element = {
          label: list['product'][index]['product_name'],
          id: list['product'][index]['product_id'],
          data: list['product'][index],
        };

        arr.push(element);
      }
    } else if (nameId === 'name') {
      switch (currentSheetName) {
        //-------------- 01 ------------------ */

        case 'invbankaccount':
          const compArr = [];
          const compFund = [];

          for (let index = 0; index < list['company'].length; index++) {
            const element = {
              label: list['company'][index]['company_name_en'],
              id: list['company'][index]['company_id'],
              data: list['company'][index],
            };
            compArr.push(element);
          }

          for (let index = 0; index < list['fund'].length; index++) {
            const element = {
              label: list['fund'][index]['fund_name'],
              id: list['fund'][index]['fund_id'],
              data: list['fund'][index],
            };
            compFund.push(element);
          }

          arr = compArr.concat(compFund);

          break;

        //---------------- 03 ----------------- */
        case 'invcertificate':
          for (let index = 0; index < list['product'].length; index++) {
            const element = {
              label: list['product'][index]['product_name'],
              id: list['product'][index]['product_id'],
              data: list['product'][index],
            };

            arr.push(element);
          }
          break;

        //----------------NotFound - ----*/
        //------------------------------------//
        default:
          break;
      }
    } else if (nameId === 'txn_id' && currentSheetName === 'invboardapprovedmemo') {
      const compArr = [];
      const compFund = [];

      for (let index = 0; index < list['compCashFlow'].length; index++) {
        const element = {
          label: list['compCashFlow'][index]['txn_id'],
          id: list['compCashFlow'][index]['txn_id'],
          data: list['compCashFlow'][index],
        };
        compArr.push(element);
      }

      for (let index = 0; index < list['fundCashFlow'].length; index++) {
        const element = {
          label: list['fundCashFlow'][index]['txn_id'],
          id: list['fundCashFlow'][index]['txn_id'],
          data: list['fundCashFlow'][index],
        };
        compFund.push(element);
      }

      arr = compArr.concat(compFund);
    } else if (nameId === 'company_id') {
      for (let index = 0; index < master[4]['data'].length; index++) {
        const masterData: MtCompanyId = master[4]['data'][index];
        const element = {
          label: masterData.id,
          id: masterData.id,
          data: masterData,
        };
        // console.log(element);
        arr.push(element);
      }
    } else if (nameId === 'fund_id') {
      for (let index = 0; index < master[7]['data'].length; index++) {
        const masterData: MtFundId = master[7]['data'][index];
        const element = {
          label: masterData.id,
          id: masterData.id,
          data: masterData,
        };
        arr.push(element);
      }
    }else if (nameId === 'bi_id') {
      for (let index = 0; index < master[1]['data'].length; index++) {
        const masterData: MtBiId = master[1]['data'][index];
        const element = {
          label: masterData.id,
          id: masterData.id,
          data: masterData,
        };
        arr.push(element);
      }
    }else if (nameId === 'rm_id') {
      for (let index = 0; index < master[12]['data'].length; index++) {
        const masterData: MtRmId = master[12]['data'][index];
        const element = {
          label: masterData.id,
          id: masterData.id,
          data: masterData,
        };
        arr.push(element);
      }
    }
    dropdownlistValue = arr;
  } else {
    dropdownlistValue = possibleValue;
  }
  //?-------------------------------------------------------------------------------------------------------
  //****************************************** Render Input ************************************************** */
  if (getCheckDisplay(currentSheetName, nameId) === true) {
    switch (inputType) {
      //------------------------------------------------------------------------- */
      case 'checkbox': {
        return (
          <CheckboxComp
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            nameId={nameId}
            parentId={parentId}
            inputClass={inputClass}
            isRequire={true}
            isActive={isActive}
          />
        );
      }
      //------------------------------------------------------------------------- */
      case 'date': {
        return (
          <DateComp
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            nameId={nameId}
            parentId={parentId}
            inputClass={inputClass}
            isRequire={true}
            isActive={isActive}
            onInputChange={onInputChange}
            value={currentValue}
          />
        );
      }
      //------------------------------------------------------------------------- */
      case 'radio': {
        return (
          <RadioComp
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            nameId={nameId}
            parentId={parentId}
            inputClass={inputClass}
            isRequire={true}
            isActive={isActive}
            possibleValue={possibleValue}
            displayValue={displayValue}
            onInputChange={onInputChange}
            value={currentValue}
          />
        );
      }
      //------------------------------------------------------------------------- */
      case 'select': {
        return (
          <SelectComp
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            nameId={nameId}
            parentId={parentId}
            inputClass={inputClass}
            isRequire={true}
            isActive={isActive}
            possibleValue={dropdownlistValue}
            displayValue={isAutoGenerate ? dropdownlistValue : displayValue}
            onInputChange={onInputChange}
            value={currentValue}
            setCurrentList={setCurrentList}
          />
        );
      }
      //------------------------------------------------------------------------- */
      case 'textfield': {
        return (
          <TextFieldComp
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            nameId={nameId}
            parentValue={parentId}
            inputClass={inputClass}
            isRequire={true}
            isActive={isActive}
            onInputChange={onInputChange}
            value={currentValue}
          />
        );
      }

      //******************************************************** NotFound ********************************************************** */
      //------------------------------------//
      default: {
        return <p>NotFound inputType</p>;
      }
    }
  } else {
    return <div></div>;
  }
}
