// ****************************************************************************************
// ******************************** Sub Table ********************************************
// ****************************************************************************************

// ?------------------------------  SubProfile Table  ------------------------------------------
export interface SubProfileTableBodyData {
  approval_status: string;
  approve_date: string;
  approver: string;
  edit_date: string;
  editor: string;
  sheet_data: object;
  sheet_id: string;
  sheet_name: string;
  detail: string;
}
//
//Old Version
// export function createSubProfileTableBodyData(
//   sub_profile_name: string,
//   last_edited_date: string,
//   approval: string,
//   detail: string,
// ): SubProfileTableBodyData {
//   return {
//     sub_profile_name,
//     last_edited_date,
//     approval,
//     detail,
//   };
// }

export function createSubProfileTableBodyData(
  approval_status: string,
  approve_date: string,
  approver: string,
  edit_date: string,
  editor: string,
  sheet_data: object,
  sheet_id: string,
  sheet_name: string,
  detail: string,
): SubProfileTableBodyData {
  return {
    approval_status,
    approve_date,
    approver,
    edit_date,
    editor,
    sheet_data,
    sheet_id,
    sheet_name,
    detail,
  };
}

//-------------------------//
export interface SubProfileTableHeadData {
  id: keyof SubProfileTableBodyData;
  label: string;
}

// ?------------------------------ Previously Table  ------------------------------------------
export interface PreviouslyTableBodyData {
  current: boolean;
  approved_date: string;
  approved_by: string;
  detail: string;
}

//-------------------------//

export function createPreviouslyTableBodyData(
  current: boolean,
  approved_date: string,
  approved_by: string,
  detail: string,
): PreviouslyTableBodyData {
  return {
    current,
    approved_date,
    approved_by,
    detail,
  };
}

//-------------------------//
export interface PreviouslyTableHeadData {
  id: keyof PreviouslyTableBodyData;
  label: string;
}

// ****************************************************************************************
// ******************************** History Table ********************************************
// ****************************************************************************************

// ?------------------------------ History Table  ------------------------------------------
export interface HistoryTableBodyData {
  current: boolean;
  id: string;
  last_edited_date: string;
  approval: string;
  detail: string;
}

//-------------------------//

export function createHistoryTableBodyData(
  current: boolean,
  id: string,
  last_edited_date: string,
  approval: string,
  detail: string,
): HistoryTableBodyData {
  return {
    current,
    id,
    last_edited_date,
    approval,
    detail,
  };
}

//-------------------------//
export interface HistoryTableHeadData {
  id: keyof HistoryTableBodyData;
  label: string;
}

// ****************************************************************************************
//****************************** Common Function ****************************************
// ****************************************************************************************
export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

//-------------------------
export type Order = 'asc' | 'desc';

//-----------------/
export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
