/*
Table Header

A1. BI ID / bi_id
A2. Company ID / company_id

-----------------------

B1. Product Name / product_name
B2. Transaction Date / txn_date
B3. Warrant Name / warrant_name
B4. Transaction Type of Warrant / warrant_type
B5. No. of Warrant in this transaction / no_of_share_pretransaction

-------------------------
C1. Approval
C2. Detail
*/

export interface InvCompWarrantBodyTableInterface {
  sheet_id: string;
  bi_id: string;
  company_id: string;
  product_name: string;
  txn_date: string;
  warrant_name: string;
  warrant_type: string;
  no_of_share_pretransaction: string;
  approval: object;
  detail: string;
}

export function createInvCompWarrantBodyTable(
  sheet_id: string,
  bi_id: string,
  company_id: string,
  product_name: string,
  txn_date: string,
  warrant_name: string,
  warrant_type: string,
  no_of_share_pretransaction: string,
  approval: object,
  detail: string,
): InvCompWarrantBodyTableInterface {
  return {
    sheet_id,
    bi_id,
    company_id,
    product_name,
    txn_date,
    warrant_name,
    warrant_type,
    no_of_share_pretransaction,
    approval,
    detail,
  };
}

//-------------------------//
export interface InvCompWarrantHeaderTableInterface {
  id: keyof InvCompWarrantBodyTableInterface;
  label: string;
}
