import * as React from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import ButtonDetail from '../../button/ButtonDetail';
import ApprovalChip from '../../element/ApprovalChip';
import { arrYn, arrYnValue } from '../../../../../states/form/common';

import {
  InvCertificateBodyTableInterface,
  InvCertificateHeaderTableInterface,
  InvCompAdjustEquityBodyTableInterface,
  InvCompAdjustEquityHeaderTableInterface,
  InvCompCashFlowBodyTableInterface,
  InvCompCashFlowHeaderTableInterface,
  InvCompDividendBodyTableInterface,
  InvCompDividendHeaderTableInterface,
  InvBankAccountBodyTableInterface,
  InvBankAccountHeaderTableInterface,
  InvBankCapitalBodyTableInterface,
  InvBankCapitalHeaderTableInterface,
  InvCompAdjustParBodyTableInterface,
  InvCompAdjustParHeaderTableInterface,
  InvCompBotBodyTableInterface,
  InvCompBotHeaderTableInterface,
  InvCompDirectorBodyTableInterface,
  InvCompDirectorHeaderTableInterface,
  InvCompFairBodyTableInterface,
  InvCompFairHeaderTableInterface,
  InvCompWarrantBodyTableInterface,
  InvCompWarrantHeaderTableInterface,
  InvFundCashflowBodyTableInterface,
  InvFundCashflowHeaderTableInterface,
  Order,
} from '../../../../../models/index';

//? ---------------------------------InvCertificate------------------------------------//
export function InvCertificateHeadComp({
  order,
  orderBy,
  onRequestSort,
  rowHeadData,
}: {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof InvCertificateBodyTableInterface,
  ) => void;

  order: Order;
  orderBy: string;
  rowHeadData: any;
}) {
  //------------------------------------------------//
  const createSortHandler =
    (property: keyof InvCertificateBodyTableInterface) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const rowHead: InvCertificateHeaderTableInterface[] = rowHeadData;
  //------------------------------------------------//
  return (
    <TableHead>
      <TableRow>
        {rowHead.map((headCell) => {
          let isDetail: boolean = false;

          if (headCell.id === 'detail') {
            isDetail = true;
          } else {
            isDetail = false;
          }

          return (
            <TableCell
              key={headCell.id}
              align={isDetail ? 'right' : 'left'}
              padding="normal"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

//? --------------------------------- InvCompAdjustEquity------------------------------------//
export function InvCompAdjustEquityHeadComp({
  order,
  orderBy,
  onRequestSort,
  rowHeadData,
}: {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof InvCompAdjustEquityBodyTableInterface,
  ) => void;

  order: Order;
  orderBy: string;
  rowHeadData: any;
}) {
  //------------------------------------------------//
  const createSortHandler =
    (property: keyof InvCompAdjustEquityBodyTableInterface) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const rowHead: InvCompAdjustEquityHeaderTableInterface[] = rowHeadData;
  //------------------------------------------------//
  return (
    <TableHead>
      <TableRow>
        {rowHead.map((headCell) => {
          let isDetail: boolean = false;

          if (headCell.id === 'detail') {
            isDetail = true;
          } else {
            isDetail = false;
          }

          return (
            <TableCell
              key={headCell.id}
              align={isDetail ? 'right' : 'left'}
              padding="normal"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

//? ---------------------------------InvCompCashFlow------------------------------------//
export function InvCompCashFlowHeadComp({
  order,
  orderBy,
  onRequestSort,
  rowHeadData,
}: {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof InvCompCashFlowBodyTableInterface,
  ) => void;

  order: Order;
  orderBy: string;
  rowHeadData: any;
}) {
  //------------------------------------------------//
  const createSortHandler =
    (property: keyof InvCompCashFlowBodyTableInterface) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const rowHead: InvCompCashFlowHeaderTableInterface[] = rowHeadData;
  //------------------------------------------------//
  return (
    <TableHead>
      <TableRow>
        {rowHead.map((headCell) => {
          let isDetail: boolean = false;

          if (headCell.id === 'detail') {
            isDetail = true;
          } else {
            isDetail = false;
          }

          return (
            <TableCell
              key={headCell.id}
              align={isDetail ? 'right' : 'left'}
              padding="normal"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

//? ---------------------------------InvCompDividend------------------------------------//
export function InvCompDividendHeadComp({
  order,
  orderBy,
  onRequestSort,
  rowHeadData,
}: {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof InvCompDividendBodyTableInterface,
  ) => void;

  order: Order;
  orderBy: string;
  rowHeadData: any;
}) {
  //------------------------------------------------//
  const createSortHandler =
    (property: keyof InvCompDividendBodyTableInterface) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const rowHead: InvCompDividendHeaderTableInterface[] = rowHeadData;
  //------------------------------------------------//
  return (
    <TableHead>
      <TableRow>
        {rowHead.map((headCell) => {
          let isDetail: boolean = false;

          if (headCell.id === 'detail') {
            isDetail = true;
          } else {
            isDetail = false;
          }

          return (
            <TableCell
              key={headCell.id}
              align={isDetail ? 'right' : 'left'}
              padding="normal"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

//? ---------------------------------InvBankAccount------------------------------------//
export function InvBankAccountHeadComp({
  order,
  orderBy,
  onRequestSort,
  rowHeadData,
}: {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof InvBankAccountBodyTableInterface,
  ) => void;

  order: Order;
  orderBy: string;
  rowHeadData: any;
}) {
  //------------------------------------------------//
  const createSortHandler =
    (property: keyof InvBankAccountBodyTableInterface) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const rowHead: InvBankAccountHeaderTableInterface[] = rowHeadData;
  //------------------------------------------------//
  return (
    <TableHead>
      <TableRow>
        {rowHead.map((headCell) => {
          let isDetail: boolean = false;

          if (headCell.id === 'detail') {
            isDetail = true;
          } else {
            isDetail = false;
          }

          return (
            <TableCell
              key={headCell.id}
              align={isDetail ? 'right' : 'left'}
              padding="normal"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

//? ---------------------------------InvBankCapital------------------------------------//
export function InvBankCapitalHeadComp({
  order,
  orderBy,
  onRequestSort,
  rowHeadData,
}: {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof InvBankCapitalBodyTableInterface,
  ) => void;

  order: Order;
  orderBy: string;
  rowHeadData: any;
}) {
  //------------------------------------------------//
  const createSortHandler =
    (property: keyof InvBankCapitalBodyTableInterface) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const rowHead: InvBankCapitalHeaderTableInterface[] = rowHeadData;
  //------------------------------------------------//
  return (
    <TableHead>
      <TableRow>
        {rowHead.map((headCell) => {
          let isDetail: boolean = false;

          if (headCell.id === 'detail') {
            isDetail = true;
          } else {
            isDetail = false;
          }

          return (
            <TableCell
              key={headCell.id}
              align={isDetail ? 'right' : 'left'}
              padding="normal"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

//? ---------------------------------InvCompAdjustPar------------------------------------//
export function InvCompAdjustParHeadComp({
  order,
  orderBy,
  onRequestSort,
  rowHeadData,
}: {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof InvCompAdjustParBodyTableInterface,
  ) => void;

  order: Order;
  orderBy: string;
  rowHeadData: any;
}) {
  //------------------------------------------------//
  const createSortHandler =
    (property: keyof InvCompAdjustParBodyTableInterface) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const rowHead: InvCompAdjustParHeaderTableInterface[] = rowHeadData;
  //------------------------------------------------//
  return (
    <TableHead>
      <TableRow>
        {rowHead.map((headCell) => {
          let isDetail: boolean = false;

          if (headCell.id === 'detail') {
            isDetail = true;
          } else {
            isDetail = false;
          }

          return (
            <TableCell
              key={headCell.id}
              align={isDetail ? 'right' : 'left'}
              padding="normal"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

//? --------------------------------- InvCompBot------------------------------------//
export function InvCompBotHeadComp({
  order,
  orderBy,
  onRequestSort,
  rowHeadData,
}: {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof InvCompBotBodyTableInterface,
  ) => void;

  order: Order;
  orderBy: string;
  rowHeadData: any;
}) {
  //------------------------------------------------//
  const createSortHandler =
    (property: keyof InvCompBotBodyTableInterface) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const rowHead: InvCompBotHeaderTableInterface[] = rowHeadData;
  //------------------------------------------------//
  return (
    <TableHead>
      <TableRow>
        {rowHead.map((headCell) => {
          let isDetail: boolean = false;

          if (headCell.id === 'detail') {
            isDetail = true;
          } else {
            isDetail = false;
          }

          return (
            <TableCell
              key={headCell.id}
              align={isDetail ? 'right' : 'left'}
              padding="normal"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

//? ---------------------------------InvCompDirector------------------------------------//
export function InvCompDirectorHeadComp({
  order,
  orderBy,
  onRequestSort,
  rowHeadData,
}: {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof InvCompDirectorBodyTableInterface,
  ) => void;

  order: Order;
  orderBy: string;
  rowHeadData: any;
}) {
  //------------------------------------------------//
  const createSortHandler =
    (property: keyof InvCompDirectorBodyTableInterface) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const rowHead: InvCompDirectorHeaderTableInterface[] = rowHeadData;
  //------------------------------------------------//
  return (
    <TableHead>
      <TableRow>
        {rowHead.map((headCell) => {
          let isDetail: boolean = false;

          if (headCell.id === 'detail') {
            isDetail = true;
          } else {
            isDetail = false;
          }

          return (
            <TableCell
              key={headCell.id}
              align={isDetail ? 'right' : 'left'}
              padding="normal"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

//? ---------------------------------InvCompFair------------------------------------//
export function InvCompFairHeadComp({
  order,
  orderBy,
  onRequestSort,
  rowHeadData,
}: {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof InvCompFairBodyTableInterface,
  ) => void;

  order: Order;
  orderBy: string;
  rowHeadData: any;
}) {
  //------------------------------------------------//
  const createSortHandler =
    (property: keyof InvCompFairBodyTableInterface) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const rowHead: InvCompFairHeaderTableInterface[] = rowHeadData;
  //------------------------------------------------//
  return (
    <TableHead>
      <TableRow>
        {rowHead.map((headCell) => {
          let isDetail: boolean = false;

          if (headCell.id === 'detail') {
            isDetail = true;
          } else {
            isDetail = false;
          }

          return (
            <TableCell
              key={headCell.id}
              align={isDetail ? 'right' : 'left'}
              padding="normal"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

//? ---------------------------------InvCompWarrant------------------------------------//
export function InvCompWarrantHeadComp({
  order,
  orderBy,
  onRequestSort,
  rowHeadData,
}: {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof InvCompWarrantBodyTableInterface,
  ) => void;

  order: Order;
  orderBy: string;
  rowHeadData: any;
}) {
  //------------------------------------------------//
  const createSortHandler =
    (property: keyof InvCompWarrantBodyTableInterface) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const rowHead: InvCompWarrantHeaderTableInterface[] = rowHeadData;
  //------------------------------------------------//
  return (
    <TableHead>
      <TableRow>
        {rowHead.map((headCell) => {
          let isDetail: boolean = false;

          if (headCell.id === 'detail') {
            isDetail = true;
          } else {
            isDetail = false;
          }

          return (
            <TableCell
              key={headCell.id}
              align={isDetail ? 'right' : 'left'}
              padding="normal"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

//? ---------------------------------InvFundCashflow------------------------------------//
export function InvFundCashflowHeadComp({
  order,
  orderBy,
  onRequestSort,
  rowHeadData,
}: {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof InvFundCashflowBodyTableInterface,
  ) => void;

  order: Order;
  orderBy: string;
  rowHeadData: any;
}) {
  //------------------------------------------------//
  const createSortHandler =
    (property: keyof InvFundCashflowBodyTableInterface) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const rowHead: InvFundCashflowHeaderTableInterface[] = rowHeadData;
  //------------------------------------------------//
  return (
    <TableHead>
      <TableRow>
        {rowHead.map((headCell) => {
          let isDetail: boolean = false;

          if (headCell.id === 'detail') {
            isDetail = true;
          } else {
            isDetail = false;
          }

          return (
            <TableCell
              key={headCell.id}
              align={isDetail ? 'right' : 'left'}
              padding="normal"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

// ****************************************************************************** Render Operation Header ****************************************************************************************
export function OperationTableHeadComp({
  order,
  orderBy,
  onRequestSort,
  rowHeadData,
  currentSheetName,
}: {
  onRequestSort: any;
  order: Order;
  orderBy: string;
  rowHeadData: any;
  currentSheetName: string;
}) {
  switch (currentSheetName) {
    //--------------------------------- */
    case 'invcertificate':
      return (
        <InvCertificateHeadComp
          order={order}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          rowHeadData={rowHeadData}
        />
      );

    //--------------------------------- */
    case 'invcompadjustequity':
      return (
        <InvCompAdjustEquityHeadComp
          order={order}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          rowHeadData={rowHeadData}
        />
      );

    //--------------------------------- */
    case 'invcompcashflow':
      return (
        <InvCompCashFlowHeadComp
          order={order}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          rowHeadData={rowHeadData}
        />
      );

    //--------------------------------- */
    case 'invcompdividend':
      return (
        <InvCompDividendHeadComp
          order={order}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          rowHeadData={rowHeadData}
        />
      );

    //--------------------------------- */
    case 'invbankaccount':
      return (
        <InvBankAccountHeadComp
          order={order}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          rowHeadData={rowHeadData}
        />
      );

    //--------------------------------- */
    case 'invbankcapital':
      return (
        <InvBankCapitalHeadComp
          order={order}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          rowHeadData={rowHeadData}
        />
      );

    //--------------------------------- */
    case 'invcompadjustpar':
      return (
        <InvCompAdjustParHeadComp
          order={order}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          rowHeadData={rowHeadData}
        />
      );

    //--------------------------------- */
    case 'invcompbot':
      return (
        <InvCompBotHeadComp
          order={order}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          rowHeadData={rowHeadData}
        />
      );

    //--------------------------------- */
    case 'invcompdirector':
      return (
        <InvCompDirectorHeadComp
          order={order}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          rowHeadData={rowHeadData}
        />
      );

    //--------------------------------- */
    case 'invcompfair':
      return (
        <InvCompFairHeadComp
          order={order}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          rowHeadData={rowHeadData}
        />
      );

    //--------------------------------- */
    case 'invcompwarrant':
      return (
        <InvCompWarrantHeadComp
          order={order}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          rowHeadData={rowHeadData}
        />
      );

    //--------------------------------- */
    case 'invfundcashflow':
      return (
        <InvFundCashflowHeadComp
          order={order}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          rowHeadData={rowHeadData}
        />
      );

    //***************** NotFound ********************* */
    //------------------------------------//
    default:
      return <div></div>;
  }
}

// ****************************************************************************** Render Operation Body ****************************************************************************************
export function OperationTableBodyComp({
  rowData,
  labelId,
  detailModalClick,
  approvalClick,
  currentSheetName,
}: {
  rowData: any;
  labelId: any;
  detailModalClick: any;
  approvalClick: any;
  currentSheetName: string;
}) {
  // console.log(currentSheetName);
  switch (currentSheetName) {
    //--------------------------------- */
    case 'invcertificate':
      const custodianValue = rowData['custodian'];
      let custodianDisplay = '';

      if (custodianValue === 'true' || custodianValue === true) {
        custodianDisplay = arrYn[0];
      } else if (custodianValue === 'false' || custodianValue === false) {
        custodianDisplay = arrYn[1];
      }

      return (
        <TableRow hover key={rowData['id']}>
          <TableCell component="th" id={labelId} scope="rowData">
            {rowData['id']}
          </TableCell>
          <TableCell align="left">{rowData['name']}</TableCell>
          <TableCell align="left">{rowData['certificate_no']}</TableCell>
          <TableCell align="left">{rowData['currency']}</TableCell>
          <TableCell align="left">{rowData['no_of_share']}</TableCell>
          <TableCell align="left">{rowData['book_value']}</TableCell>
          <TableCell align="left">{custodianDisplay}</TableCell>
          <TableCell align="left">
            <ApprovalChip sheetStauts={rowData['approval']['certificate']} />
          </TableCell>
          {/* <TableCell align="left">
            <ButtonApproval isApprovalStatus={false} onClick={approvalClick} />
          </TableCell> */}
          {/* <TableCell align='left'>{rowData['detail']}</TableCell> */}
          <TableCell align="center">
            <ButtonDetail onClick={detailModalClick} />
          </TableCell>
        </TableRow>
      );

    //--------------------------------- */
    case 'invcompadjustequity':
      return (
        <TableRow hover key={rowData['company_id']}>
          <TableCell component="th" id={labelId} scope="rowData">
            {rowData['company_id']}
          </TableCell>
          <TableCell align="left">{rowData['product_name']}</TableCell>
          <TableCell align="left">{rowData['txn_date']}</TableCell>
          <TableCell align="left">{rowData['investment_port']}</TableCell>
          <TableCell align="left">{rowData['no_of_share']}</TableCell>
          <TableCell align="left">{rowData['allowance_permanent_thb']}</TableCell>
          <TableCell align="left">{rowData['total_adjusted_equity']}</TableCell>
          <TableCell align="left">
            {rowData['adjusted_equity_posttransaction']}
          </TableCell>
          <TableCell align="left">
            <ApprovalChip sheetStauts={rowData['approval']['adjequity']} />
          </TableCell>
          {/* <TableCell align="left">
            <ButtonApproval isApprovalStatus={false} onClick={approvalClick} />
          </TableCell> */}
          {/* <TableCell align='left'>{rowData['detail']}</TableCell> */}
          <TableCell align="center">
            <ButtonDetail onClick={detailModalClick} />
          </TableCell>
        </TableRow>
      );

    //--------------------------------- */
    case 'invcompcashflow':
      return (
        <TableRow hover key={rowData['company_id']}>
          <TableCell component="th" id={labelId} scope="rowData">
            {rowData['company_id']}
          </TableCell>
          <TableCell align="left">{rowData['company_name']}</TableCell>
          <TableCell align="left">{rowData['txn_date']}</TableCell>
          <TableCell align="left">{rowData['txn_type']}</TableCell>
          <TableCell align="left">{rowData['currency']}</TableCell>
          <TableCell align="left">{rowData['no_of_share']}</TableCell>
          <TableCell align="left">{rowData['price_per_share']}</TableCell>
          <TableCell align="left">{rowData['net_cashflow']}</TableCell>
          <TableCell align="left">
            <ApprovalChip sheetStauts={rowData['approval']['compcashflow']} />
          </TableCell>
          {/* <TableCell align="left">
            <ButtonApproval isApprovalStatus={false} onClick={approvalClick} />
          </TableCell> */}
          {/* <TableCell align='left'>{rowData['detail']}</TableCell> */}
          <TableCell align="center">
            <ButtonDetail onClick={detailModalClick} />
          </TableCell>
        </TableRow>
      );

    //--------------------------------- */
    case 'invcompdividend':
      return (
        <TableRow hover key={rowData['company_id']}>
          <TableCell component="th" id={labelId} scope="rowData">
            {rowData['company_id']}
          </TableCell>

          <TableCell align="left">{rowData['product_name']}</TableCell>
          <TableCell align="left">{rowData['data_date']}</TableCell>
          <TableCell align="left">{rowData['currency']}</TableCell>
          <TableCell align="left">{rowData['no_of_shares']}</TableCell>
          <TableCell align="left">{rowData['dividend']}</TableCell>
          <TableCell align="left">{rowData['dividend_amount']}</TableCell>
          <TableCell align="left">
            <ApprovalChip sheetStauts={rowData['approval']['dividend']} />
          </TableCell>
          {/* <TableCell align="left">
            <ButtonApproval isApprovalStatus={false} onClick={approvalClick} />
          </TableCell> */}
          {/* <TableCell align='left'>{rowData['detail']}</TableCell> */}
          <TableCell align="center">
            <ButtonDetail onClick={detailModalClick} />
          </TableCell>
        </TableRow>
      );

    //--------------------------------- */
    case 'invbankaccount':
      return (
        <TableRow hover key={rowData['id']}>
          <TableCell component="th" id={labelId} scope="rowData">
            {rowData['id']}
          </TableCell>
          <TableCell align="left">{rowData['name']}</TableCell>
          <TableCell align="left">{rowData['date']}</TableCell>
          <TableCell align="left">{rowData['account_no']}</TableCell>
          <TableCell align="left">{rowData['account_name']}</TableCell>
          <TableCell align="left">{rowData['account_bank_name']}</TableCell>
          <TableCell align="left">
            <ApprovalChip sheetStauts={rowData['approval']['account']} />
          </TableCell>
          {/* <TableCell align="left">
            <ButtonApproval isApprovalStatus={false} onClick={approvalClick} />
          </TableCell> */}
          {/* <TableCell align='left'>{rowData['detail']}</TableCell> */}
          <TableCell align="center">
            <ButtonDetail onClick={detailModalClick} />
          </TableCell>
        </TableRow>
      );

    //--------------------------------- */
    case 'invbankcapital':
      return (
        <TableRow hover key={rowData['data_date']}>
          <TableCell component="th" id={labelId} scope="rowData">
            {rowData['data_date']}
          </TableCell>
          <TableCell align="left">{rowData['status']}</TableCell>
          <TableCell align="left">{rowData['tier_1_capital']}</TableCell>
          <TableCell align="left">{rowData['tier_2_capital']}</TableCell>
          <TableCell align="left">{rowData['total']}</TableCell>
          <TableCell align="left">
            <ApprovalChip sheetStauts={rowData['approval']['capital']} />
          </TableCell>
          {/* <TableCell align="left">
            <ButtonApproval isApprovalStatus={false} onClick={approvalClick} />
          </TableCell> */}
          {/* <TableCell align='left'>{rowData['detail']}</TableCell> */}
          <TableCell align="center">
            <ButtonDetail onClick={detailModalClick} />
          </TableCell>
        </TableRow>
      );

    //--------------------------------- */
    case 'invcompadjustpar':
      return (
        <TableRow hover key={rowData['company_id']}>
          <TableCell component="th" id={labelId} scope="rowData">
            {rowData['company_id']}
          </TableCell>
          <TableCell align="left">{rowData['product_name']}</TableCell>
          <TableCell align="left">{rowData['txn_date']}</TableCell>
          <TableCell align="left">{rowData['adjusted_par_method']}</TableCell>
          <TableCell align="left">{rowData['no_of_share_posttransaction']}</TableCell>
          <TableCell align="left">{rowData['investment_cost']}</TableCell>
          <TableCell align="left">{rowData['par_value_posttransaction']}</TableCell>
          <TableCell align="left">
            <ApprovalChip sheetStauts={rowData['approval']['adjpar']} />
          </TableCell>
          {/* <TableCell align="left">
            <ButtonApproval isApprovalStatus={false} onClick={approvalClick} />
          </TableCell> */}
          {/* <TableCell align='left'>{rowData['detail']}</TableCell> */}
          <TableCell align="center">
            <ButtonDetail onClick={detailModalClick} />
          </TableCell>
        </TableRow>
      );

    //--------------------------------- */
    case 'invcompbot':
      return (
        <TableRow hover key={rowData['company_id']}>
          <TableCell component="th" id={labelId} scope="rowData">
            {rowData['company_id']}
          </TableCell>
          <TableCell align="left">{rowData['recording_date']}</TableCell>
          <TableCell align="left">{rowData['document_no']}</TableCell>
          <TableCell align="left">{rowData['request_type']}</TableCell>
          <TableCell align="left">{rowData['document_start_date']}</TableCell>
          <TableCell align="left">{rowData['document_end_date']}</TableCell>
          <TableCell align="left">
            <ApprovalChip sheetStauts={rowData['approval']['bot']} />
          </TableCell>
          {/* <TableCell align="left">
            <ButtonApproval isApprovalStatus={false} onClick={approvalClick} />
          </TableCell> */}
          {/* <TableCell align='left'>{rowData['detail']}</TableCell> */}
          <TableCell align="center">
            <ButtonDetail onClick={detailModalClick} />
          </TableCell>
        </TableRow>
      );

    //--------------------------------- */
    case 'invcompdirector':
      return (
        <TableRow hover key={rowData['company_id']}>
          <TableCell component="th" id={labelId} scope="rowData">
            {rowData['company_id']}
          </TableCell>
          <TableCell align="left">{rowData['committee_name']}</TableCell>
          <TableCell align="left">{rowData['bbl_representative']}</TableCell>
          <TableCell align="left">
            {rowData['bbl_representative_internalposition']}
          </TableCell>
          <TableCell align="left">
            <ApprovalChip sheetStauts={rowData['approval']['director']} />
          </TableCell>
          {/* <TableCell align="left">
            <ButtonApproval isApprovalStatus={false} onClick={approvalClick} />
          </TableCell> */}
          {/* <TableCell align='left'>{rowData['detail']}</TableCell> */}
          <TableCell align="center">
            <ButtonDetail onClick={detailModalClick} />
          </TableCell>
        </TableRow>
      );

    //--------------------------------- */
    case 'invcompfair':
      return (
        <TableRow hover key={rowData['company_id']}>
          <TableCell component="th" id={labelId} scope="rowData">
            {rowData['company_id']}
          </TableCell>
          <TableCell align="left">{rowData['company_name']}</TableCell>
          <TableCell align="left">{rowData['data_date']}</TableCell>
          <TableCell align="left">{rowData['wacc']}</TableCell>
          <TableCell align="left">{rowData['terminal_growth']}</TableCell>
          <TableCell align="left">{rowData['fair_value']}</TableCell>
          <TableCell align="left">
            <ApprovalChip sheetStauts={rowData['approval']['compfair']} />
          </TableCell>
          {/* <TableCell align="left">
            <ButtonApproval isApprovalStatus={false} onClick={approvalClick} />
          </TableCell> */}
          {/* <TableCell align='left'>{rowData['detail']}</TableCell> */}
          <TableCell align="center">
            <ButtonDetail onClick={detailModalClick} />
          </TableCell>
        </TableRow>
      );

    //--------------------------------- */
    case 'invcompwarrant':
      return (
        <TableRow hover key={rowData['company_id']}>
          <TableCell component="th" id={labelId} scope="rowData">
            {rowData['company_id']}
          </TableCell>
          <TableCell align="left">{rowData['product_name']}</TableCell>
          <TableCell align="left">{rowData['txn_date']}</TableCell>
          <TableCell align="left">{rowData['warrant_name']}</TableCell>
          <TableCell align="left">{rowData['warrant_type']}</TableCell>
          <TableCell align="left">{rowData['no_of_share_pretransaction']}</TableCell>
          <TableCell align="left">
            <ApprovalChip sheetStauts={rowData['approval']['warrant']} />
          </TableCell>
          {/* <TableCell align="left">
            <ButtonApproval isApprovalStatus={false} onClick={approvalClick} />
          </TableCell> */}
          {/* <TableCell align='left'>{rowData['detail']}</TableCell> */}
          <TableCell align="center">
            <ButtonDetail onClick={detailModalClick} />
          </TableCell>
        </TableRow>
      );

    //--------------------------------- */
    case 'invfundcashflow':
      return (
        <TableRow hover key={rowData['fund_id']}>
          <TableCell component="th" id={labelId} scope="rowData">
            {rowData['fund_id']}
          </TableCell>
          <TableCell align="left">{rowData['fund_name']}</TableCell>
          <TableCell align="left">{rowData['txn_date']}</TableCell>
          <TableCell align="left">{rowData['cashflow_type']}</TableCell>
          <TableCell align="left">{rowData['currency']}</TableCell>
          <TableCell align="left">{rowData['cashflow_amount']}</TableCell>
          <TableCell align="left">{rowData['current_stake']}</TableCell>
          <TableCell align="left">
            <ApprovalChip sheetStauts={rowData['approval']['fundcashflow']} />
          </TableCell>
          {/* <TableCell align="left">
            <ButtonApproval isApprovalStatus={false} onClick={approvalClick} />
          </TableCell> */}
          {/* <TableCell align='left'>{rowData['detail']}</TableCell> */}
          <TableCell align="center">
            <ButtonDetail onClick={detailModalClick} />
          </TableCell>
        </TableRow>
      );

    //***************** NotFound ********************* */
    //------------------------------------//
    default:
      return <div></div>;
  }
}
