import { useRecoilValue } from 'recoil';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import ElementStyles from '../../../../styles/Element.module.css';
import {
  labelSizeState,
  labelGridState,
  inputGridState,
} from '../../../../states/index';

export default function RadioComp({
  label,
  placeholder,
  helperText,
  nameId,
  parentId,
  inputClass,
  isRequire,
  isActive,
  possibleValue,
  displayValue,
  onInputChange,
  value,
}: {
  label: string;
  placeholder: string;
  helperText: string;
  nameId: string;
  parentId: string;
  inputClass: string;
  isRequire: boolean;
  isActive: boolean;
  possibleValue: boolean[];
  displayValue: string[];
  onInputChange: any;
  value: any;
}) {
  const labelFontSize = useRecoilValue(labelSizeState);
  const labelGrid = useRecoilValue(labelGridState);
  const inputGrid = useRecoilValue(inputGridState);

  switch (inputClass) {
    //--------------------------------- Custom Limit Free ------------------------- */
    case 'General': {
      return (
        <Container className={ElementStyles.FormContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            container
          >
            <Grid
              xs={labelGrid}
              sm={labelGrid}
              md={labelGrid}
              item
              container
              alignItems="center"
            >
              <Grid item>
                <Typography
                  variant={labelFontSize}
                  className={ElementStyles.FormLabelText}
                >
                  <b>{label + ':'}</b>
                </Typography>
              </Grid>
            </Grid>

            <Grid xs={inputGrid} sm={inputGrid} md={inputGrid} item>
              <RadioGroup
                key={nameId}
                id={nameId}
                name={nameId}
                value={value}
                onChange={onInputChange}
                row
              >
                {Object.keys(displayValue).map((index: any) => {
                  const valueId = nameId + '_' + index;

                  // console.log(valueId);

                  return (
                    <FormControlLabel
                      key={valueId}
                      id={valueId}
                      name={valueId}
                      value={possibleValue[index]}
                      control={<Radio key={valueId} id={valueId} name={valueId} />}
                      label={displayValue[index]}
                    />
                  );
                })}
              </RadioGroup>
            </Grid>
          </Grid>
        </Container>
      );
    }

    //******************************************************** NotFound ********************************************************** */
    //------------------------------------//
    default: {
      return <p>NotFound TextField InputClass</p>;
    }
  }
}
