import { atom } from 'recoil';
import {arrCurrency} from '../common';

const _sheetName = 'invboardapprovedmemo';

//?------- Parameter Design ---------------
/*

------- Label / ID ------------
displayName = variable for display name in label.
sourceSystemName = variable for bank system name.
apiName = variable for call api. 

-------- Input ----------------
description = input placeholder.
inputLength = custom length limit of character.
inputType = (checkbox | date | radio | select | textfield) type of input.
inputClass = Custom format masking or validation.
possibleValue = (array) Value for dropdownlist / checkbox / radio.

-------- Boolean --------------
isRequire = (false/false) require field. can't be null.

isLinkValue = (false/false) Link the value from other input (linkValueId)
linkValueId = ID of input to Link data (display when isLinkValue == false).

isAutoGenerate = (false/false) auto generate value.
generateSource = source of auto generate (display when isAutoGenerate == false).

*/
/**
 textfield
 - NoLimitFree
 - NoLimitCountNumber

 date
 - General

select
 - General

 */

const jsonData: { [key: string]: any } = {
  sheetName: _sheetName,
  inputInfo: [
    // ? --------------------------------- Start ---------------------------------------//
    // ---------------------------- 001 ---------------------------------//
    {
      displayName: 'ID',
      sourceSystemName: 'ID',
      apiName: 'id',

      description: 'Fund ID/Company ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromDefinitive',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 002 ---------------------------------//
    {
      displayName: 'Name',
      sourceSystemName: 'NAME',
      apiName: 'name',

      description: 'Full legal name of the fund/company',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromDefinitive',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 003 ---------------------------------//
    {
      displayName: 'Date',
      sourceSystemName: 'DATA_DATE',
      apiName: 'data_date',

      description: 'Record Date of Key- In Data',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 004 ---------------------------------//
    {
      displayName: 'Transaction ID',
      sourceSystemName: 'TXN_ID',
      apiName: 'txn_id',

      description: 'Transaction ID',
      inputLength: '',
      inputType: 'select',
      inputClass: 'Autocomplete',
      possibleValue: ['/fundCashflow/list','/companyCashFlow/list'],
      displayValue: ['/fundCashflow/list','/companyCashFlow/list'],

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: true,
    },

    // ---------------------------- 005 ---------------------------------//
    {
      displayName: 'BID Memo Number',
      sourceSystemName: 'BID_MEMO_NO',
      apiName: 'bid_memo_no',

      description: 'Internal running number for BID memo',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 006 ---------------------------------//
    {
      displayName: 'BID Memo Date',
      sourceSystemName: 'BID_MEMO_DATE',
      apiName: 'bid_memo_date',

      description: 'Date on BID memo',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 007 ---------------------------------//
    {
      displayName: 'Memorandum Request',
      sourceSystemName: 'MEMO_REQUEST',
      apiName: 'memo_request',

      description: 'Memorandum Request (Objective)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 008 ---------------------------------//
    {
      displayName: 'Memorandum Description',
      sourceSystemName: 'MEMO_DESCRIPTION',
      apiName: 'memo_description',

      description: 'Memorandum Description',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 009 ---------------------------------//
    {
      displayName: 'Executive Board Approve No.',
      sourceSystemName: 'EXEC_BOARD_APPROVAL_NO',
      apiName: 'exec_board_approval_no',

      description: 'Executive Board Approval Number',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 010 ---------------------------------//
    {
      displayName: 'Executive Board Approve Date',
      sourceSystemName: 'EXEC_BOARD_APPROVAL_DATE',
      apiName: 'exec_board_approval_date',

      description: 'Executive Board Approval Date',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 011 ---------------------------------//
    {
      displayName: 'Board Approve No.',
      sourceSystemName: 'BOARD_APPROVAL_NO',
      apiName: 'board_approval_no',

      description: 'Board Approval Number',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 012 ---------------------------------//
    {
      displayName: 'Board Approve Date',
      sourceSystemName: 'BOARD_APPROVAL_DATE',
      apiName: 'board_approval_date',

      description: 'Board Approval Date',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 013 ---------------------------------//
    {
      displayName: 'Approval Budget Currency',
      sourceSystemName: 'APPROVAL_BUDGET_CURRENCY',
      apiName: 'approval_budget_currency',

      description: 'Approved Budget Currency',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrCurrency,
      displayValue: arrCurrency,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 014 ---------------------------------//
    {
      displayName: 'Approval Budget Amount',
      sourceSystemName: 'APPROVAL_BUDGET_AMOUNT',
      apiName: 'approval_budget_amount',

      description: 'Approved Budget Amount',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 015 ---------------------------------//
    {
      displayName: 'Effective BBL Commitment',
      sourceSystemName: 'BBL_COMMITMENT_AMT',
      apiName: 'bbl_commitment_amt',

      description: 'BBL final commitment amount at final closing',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 016 ---------------------------------//
    {
      displayName: 'Effective Fund Commitment',
      sourceSystemName: 'FUND_COMMITMENT_AMT',
      apiName: 'fund_commitment_amt',

      description: 'Total fund commitment amount at final closing',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 017 ---------------------------------//
    {
      displayName: 'Investment Thesis ',
      sourceSystemName: 'INVESTMENT_THESIS',
      apiName: 'investment_thesis',

      description: 'Investment Thesis when we intially consider to invest',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 018 ---------------------------------//
    {
      displayName: 'Expected IRR',
      sourceSystemName: 'EXPECTED_IRR',
      apiName: 'expected_irr',

      description: '% Expected IRR when we intially consider to invest',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 019 ---------------------------------//
    {
      displayName: 'Remark',
      sourceSystemName: 'REMARK',
      apiName: 'remark',

      description: 'Remark (If any)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ? --------------------------------- End ---------------------------------------//
  ],
};

export const invboardapprovedmemo = atom({
  key: 'invboardapprovedmemo',
  default: jsonData,
});
