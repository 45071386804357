import { atom, atomFamily } from 'recoil';

//?---------------------------------------------- Display State ------------------------------------------------------------

//'body1' | 'body2'| 'button' | 'caption' | 'h1' | 'h2'| 'h3' | 'h4'| 'h5'| 'h6' | 'inherit'| 'overline' | 'subtitle1' | 'subtitle2'| string
export const labelSizeState = atom<any>({
  key: 'labelSize',
  default: 'subtitle2',
});

//Range 1-12
export const labelGridState = atom<any>({
  key: 'labelGrid',
  default: 4,
});

//Range 1-12
export const inputGridState = atom<any>({
  key: 'inputGrid',
  default: 8,
});

//?---------------------------------------------- Value State ------------------------------------------------------------

let _id: any = '';
let _type: any = '';
let _value: any[] = [];
let _parent: any = '';

export const fieldValueState = atomFamily({
  key: 'fieldValue',
  default: {
    id: _id,
    type: _type,
    value: _value,
    parent: _parent,
  },
});
