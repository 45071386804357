import { useRecoilValue } from 'recoil';
import ApprovalInformation from '../ApprovalInformation';
import Sheetlnformation from '../Sheetlnformation';
import SubProfileInformation from '../SubProfileInformation';
import PreviouslyInfomation from '../PreviouslyInfomation';
import EmptyPage from '../../element/EmptyPage';
import { SubProfileTableBodyData } from '../../../../../models/index';
import { approvalName } from '../../function/approvalName';

//Custom elelment
import ProgressLoading from '../../element/ProgressLoading';

import {
  currentSheetNameState,
  isCallingAPIState,
  sheetNameState,
  portalStatusState,
  isMainDetailModal,
} from '../../../../../states/index';

import { groupBy } from '../../function/groupBy';
import { checkIsPending } from '../../function/checkIsPending';

function MainInformation({
  sheet_type,
  inputJSON,
  dataJSON,
  approvalJSON,
  subProfileJSON,
  isEmpty,

}: {
  sheet_type: string;
  inputJSON: object;
  dataJSON: object;
  approvalJSON: object;
  subProfileJSON: SubProfileTableBodyData[];
  isEmpty: boolean;

}) {

 
  const openMainDetailModal = useRecoilValue(isMainDetailModal);

  const currentSheetName = useRecoilValue(currentSheetNameState);
  const sheetNameJSON = useRecoilValue(sheetNameState);
  const portalMode = useRecoilValue(portalStatusState);

  const isCallingAPI = useRecoilValue(isCallingAPIState);

  const sheetNameJSON_groupByDisplayName: { [key: string]: any } = groupBy(
    sheetNameJSON[portalMode]['subsheet'],
    'systemName',
  );

  const displaySheetName =
    sheetNameJSON_groupByDisplayName[currentSheetName][0]['displayName'];

  let isPending = false;

  if (openMainDetailModal === true) {
    if (checkIsPending(currentSheetName, approvalJSON) === 'PENDING') {
      isPending = true;
    } else {
      isPending = false;
    }
  }

  if (isCallingAPI === true) {
    return <ProgressLoading typoLoading={'Loading content, please wait...'} />;
  } else {
    switch (sheet_type) {
      case 'primary':
        return (
          <>
            <ApprovalInformation
              approvalJSON={approvalJSON}
              isHistory={false}
              approvalName={approvalName(currentSheetName)}
              isPending={isPending}
            />
            <br />
            <Sheetlnformation
              inputJSON={inputJSON}
              dataJSON={dataJSON}
              headerType="edit"
              isPending={isPending}
            />
          </>
        );

      // --------------------------------------------------------------------------//
      case 'oneToMany':
        return (
          <>
            {isEmpty ? (
              <EmptyPage sheetNameDisplay={displaySheetName} />
            ) : (
              <SubProfileInformation
                typoHeader={displaySheetName}
                subProfileJSON={subProfileJSON}
              />
            )}
          </>
        );

      // --------------------------------------------------------------------------//
      case 'transaction':
        return (
          <>
            {isEmpty ? (
              <EmptyPage sheetNameDisplay={displaySheetName} />
            ) : (
              <PreviouslyInfomation typoHeader={displaySheetName} />
            )}
          </>
        );

      // --------------------------------------------------------------------------//

      default:
        return <div>non</div>;
    }
  }
}

export default MainInformation;
