import {
  companyListDataInterface,
  productListDataInterface,
  fsListDataInterface,
  fundListDataInterface,
  compCashFlowListDataInterface,
  fundCashflowListDataInterface,
  portDataListInterface,
  FundTransaction,
  Currency,
  Port,
  Holding,
  Broker,
} from '../models/index';

const company_id_1 = 'c1111111';
const company_id_2 = 'c2222222';
const company_id_3 = 'c3333333';

const proudct_id_1 = 'P1111111';
const proudct_id_2 = 'P2222222';
const proudct_id_3 = 'P3333333';

const fs_id_1 = 'FS1111111';
const fs_id_2 = 'FS2222222';
const fs_id_3 = 'FS3333333';

const fund_id_1 = 'F1111111';
const fund_id_2 = 'F2222222';
const fund_id_3 = 'F3333333';

const txn_id_1 = 'TXN1111111';
const txn_id_2 = 'TXN2222222';
const txn_id_3 = 'TXN3333333';
const txn_id_4 = 'TXN4444444';
const txn_id_5 = 'TXN5555555';
const txn_id_6 = 'TXN6666666';

export const companyListMockupResponse: companyListDataInterface[] = [
  {
    company_id: company_id_1,
    bi_id: company_id_1,
    company_name_en: 'Company 1',
    company_name_th: 'บริษัท 1',
    rm_id: company_id_1,
  },
  {
    company_id: company_id_2,
    bi_id: company_id_2,
    company_name_en: 'Company 2',
    company_name_th: 'บริษัท 2',
    rm_id: company_id_2,
  },
  {
    company_id: company_id_3,
    bi_id: company_id_3,
    company_name_en: 'Company 3',
    company_name_th: 'บริษัท 3',
    rm_id: company_id_3,
  },
];

export const productListMockupResponse: productListDataInterface[] = [
  {
    product_id: proudct_id_1,
    allowance_impair_ccy: 10000000,
    allowance_permanent: 10000000,
    book_value: 10,
    company_name: 'Company 1',
    currency: Currency.Aed,
    current_stake: 10000000,
    first_investment_date: '22/02/2022',
    investment_cost_thb: 10000000,
    investment_instrument: Holding.Warrant,
    investment_port: Port.Associated,
    no_of_share: 10000000,
    product_name: 'Product 1',
    strike_price: 10000000,
  },
  {
    product_id: proudct_id_2,
    allowance_impair_ccy: 20000000,
    allowance_permanent: 20000000,
    book_value: 20,
    company_name: 'Company 1',
    currency: Currency.Aed,
    current_stake: 20000000,
    first_investment_date: '22/02/2022',
    investment_cost_thb: 20000000,
    investment_instrument: Holding.Etf,
    investment_port: Port.Subsidiary,
    no_of_share: 20000000,
    product_name: 'Product 2',
    strike_price: 20000000,
  },
  {
    product_id: proudct_id_3,
    allowance_impair_ccy: 30000000,
    allowance_permanent: 30000000,
    book_value: 30,
    company_name: 'Company 1',
    currency: Currency.Aed,
    current_stake: 30000000,
    first_investment_date: '22/02/2022',
    investment_cost_thb: 30000000,
    investment_instrument: Holding.PreferredStock,
    investment_port: Port.FvplTrading,
    no_of_share: 30000000,
    product_name: 'Product 3',
    strike_price: 30000000,
  },
];

export const fsListMockupResponse: fsListDataInterface[] = [
  {
    fs_id: fs_id_1,
    company_id: company_id_1,
    paid_up_capital_no_of_share: 10000000,
    par_value: 10000000,
  },
  {
    fs_id: fs_id_2,
    company_id: company_id_2,
    paid_up_capital_no_of_share: 20000000,
    par_value: 20000000,
  },
  {
    fs_id: fs_id_3,
    company_id: company_id_3,
    paid_up_capital_no_of_share: 30000000,
    par_value: 30000000,
  },
];

export const fundListMockupResponse: fundListDataInterface[] = [
  {
    fund_id: fund_id_1,
    currency: Currency.Aed,
    fund_name: 'Fund 1',
    fund_quote: 'Fund_quote 1',
    fund_thainame: 'กองทุน 1',
  },
  {
    fund_id: fund_id_2,
    currency: Currency.Bnd,
    fund_name: 'Fund 2',
    fund_quote: 'Fund_quote 2',
    fund_thainame: 'กองทุน 2',
  },
  {
    fund_id: fund_id_3,
    currency: Currency.Cny,
    fund_name: 'Fund 3',
    fund_quote: 'Fund_quote 3',
    fund_thainame: 'กองทุน 3',
  },
];

export const compCashFlowListMockupResponse: compCashFlowListDataInterface[] = [
  {
    txn_id: txn_id_1,
    net_cashflow: 50000,
    investment_cost: 50002,
    investment_cost_thb: 50003,
  },
  {
    txn_id: txn_id_2,
    net_cashflow: 40000,
    investment_cost: 40002,
    investment_cost_thb: 40003,
  },
  {
    txn_id: txn_id_3,
    net_cashflow: 30000,
    investment_cost: 30002,
    investment_cost_thb: 30003,
  },
];

const newNumber = 5522;

export const fundCashFlowListMockupResponse: fundCashflowListDataInterface[] = [
  {
    fund_id: fund_id_1,
    txn_date: '20/22/22',
    txn_id: txn_id_4,
    txn_type: FundTransaction.Dividend,
    recyclable_flag: true,
    cashflow_type: true,
    cashflow_amount: newNumber,
    total_partnership_paid_amount: newNumber,
    current_stake: newNumber,
    unit_price: newNumber,
    unit_no: newNumber,
    brokerage_name: Broker.Asp003,
    commission: newNumber,
    vat_levey_fee: newNumber,
    commission_vat: newNumber,
    withholding_tax: newNumber,
    custodian_fee: newNumber,
    miscellaneous_exp: newNumber,
    fund_transfer_fee: newNumber,
    underwriting_fee: newNumber,
  },
  {
    fund_id: fund_id_2,
    txn_date: '20/22/22',
    txn_id: txn_id_5,
    txn_type: FundTransaction.Dividend,
    recyclable_flag: true,
    cashflow_type: true,
    cashflow_amount: newNumber,
    total_partnership_paid_amount: newNumber,
    current_stake: newNumber,
    unit_price: newNumber,
    unit_no: newNumber,
    brokerage_name: Broker.Asp003,
    commission: newNumber,
    vat_levey_fee: newNumber,
    commission_vat: newNumber,
    withholding_tax: newNumber,
    custodian_fee: newNumber,
    miscellaneous_exp: newNumber,
    fund_transfer_fee: newNumber,
    underwriting_fee: newNumber,
  },
  {
    fund_id: fund_id_3,
    txn_date: '20/22/22',
    txn_id: txn_id_6,
    txn_type: FundTransaction.Dividend,
    recyclable_flag: true,
    cashflow_type: true,
    cashflow_amount: newNumber,
    total_partnership_paid_amount: newNumber,
    current_stake: newNumber,
    unit_price: newNumber,
    unit_no: newNumber,
    brokerage_name: Broker.Asp003,
    commission: newNumber,
    vat_levey_fee: newNumber,
    commission_vat: newNumber,
    withholding_tax: newNumber,
    custodian_fee: newNumber,
    miscellaneous_exp: newNumber,
    fund_transfer_fee: newNumber,
    underwriting_fee: newNumber,
  },
];

export const portListMockupResponse: portDataListInterface[] = [
  {
    data_date: '20/22/2022', // date string
    currency: Currency.Aed,
    investment_cost: newNumber,
    fair_value: newNumber,
    fund_id: fund_id_1, // InvFundDefinitive.fund_id > fund_quote
    company_id: company_id_1, // InvCompDefinitive.company_id > company_name
  },
  {
    data_date: '20/22/2022', // date string
    currency: Currency.Dkk,
    investment_cost: newNumber,
    fair_value: newNumber,
    fund_id: fund_id_1, // InvFundDefinitive.fund_id > fund_quote
    company_id: company_id_2, // InvCompDefinitive.company_id > company_name
  },

  {
    data_date: '20/22/2022', // date string
    currency: Currency.Bhd,
    investment_cost: newNumber,
    fair_value: newNumber,
    fund_id: fund_id_1, // InvFundDefinitive.fund_id > fund_quote
    company_id: company_id_2, // InvCompDefinitive.company_id > company_name
  },
];
