import { atom } from 'recoil';

/*
displayName = For Display in UI.
sheetName = Lower-case name for front-end variable.
sheetType = parameter: primary | oneToMany | transaction  
systemName = sheet name in system/database/api.
description = sheet description.

{ displayName: 'xxxxxxxx', sheetName: 'xxxx', sheetType: 'xxxxxx', systemName: 'xxxxxxxx',description:'xxxxxx' },
 */

//----------------------- JSON Object groupBy function ------------------------
//source: https://stackoverflow.com/questions/14446511/most-efficient-method-to-groupby-on-an-array-of-objects
const groupBy = function (xs: any, key: any) {
  return xs.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};


//---------------------------------------------//

const sheetNameList: { [key: string]: any } = {
  company_profile: {
    portalname: 'Company Profile',
    subsheet: [
      { displayName: 'Company Profile', sheetName: 'company_profile', sheetType: 'primary', systemName: 'invcompdefinitive', description:'Data about company that BBL invested e.g., Company short name, Registered ID, Demicile Country, Industry, Currency etc.' },
      { displayName: 'Product Profile', sheetName: 'product_profile', sheetType: 'oneToMany', systemName: 'invcompproduct', description:'Daily General Transaction Detail ( Buy, Buy - Partially Paid Up, Sell, Drawdown, Redemption, Stock Dividend, Interest, Impairment, Reversed Impairment)' },
      { displayName: 'Financial Statement', sheetName: 'financial_statement', sheetType: 'oneToMany', systemName: 'invcompfs',description:'Data from Financial Statement of Security e.g., EBIT, Net Income, Total Asset etc.' },
      { displayName: 'Contact Information', sheetName: 'contact_information', sheetType: 'oneToMany', systemName: 'invcontact',description:"Fund/Comapny's Contact Information" },
      { displayName: 'Board Approved Memorandum', sheetName: 'board_approved_memorandum', sheetType: 'oneToMany', systemName: 'invboardapprovedmemo',description:'Record of Board Approved Memorandum ' },
    ],
  },

  fund_profile: {
    portalname: 'Fund Profile',
    subsheet: [
      { displayName: 'Fund Profile', sheetName: 'fund_profile', sheetType: 'primary', systemName: 'invfunddefinitive',description:"Data about fund e.g., Fund ID, Fund Type, Vintage Year, Fund's Benchmark etc." },
      { displayName: 'Fund NAV', sheetName: 'fund_nav', sheetType: 'oneToMany', systemName: 'invfundnav',description:"Fund's NAV and Highlights financial data from fund's statement" },
      { displayName: 'Fund Portfolio', sheetName: 'fund_portfolio', sheetType: 'oneToMany', systemName: 'invfundportfolio',description:"Fund's Portfolio" },
      { displayName: 'Fund Benchmark', sheetName: 'fund_benchmark', sheetType: 'oneToMany', systemName: 'invfundbenchmark',description:'Fund Benchmark Detail' },
      { displayName: 'Fund Commitment', sheetName: 'fund_commitment', sheetType: 'oneToMany', systemName: 'invfundcommitmentandwatermark',description:"Fund's Commitment Data" },
      { displayName: 'Contact Information', sheetName: 'contact_information', sheetType: 'oneToMany', systemName: 'invcontact',description:"Fund/Comapny's Contact Information" },
      { displayName: 'Board Approved Memorandum', sheetName: 'board_approved_memorandum', sheetType: 'oneToMany', systemName: 'invboardapprovedmemo',description:'Record of Board Approved Memorandum ' },
    ],
  },

  operation: {
    portalname: 'Operation',
    subsheet: [
      { displayName: 'Account Information', sheetName: 'account_information', sheetType: 'transaction', systemName: 'invbankaccount',description:'Account Information of Company and fund' },
      { displayName: 'BBL Capital', sheetName: 'bbl_capital', sheetType: 'transaction', systemName: 'invbankcapital',description:"BBL's Capital, Tier1 & Tier2 Capital" },
      { displayName: 'Certificate Security Record', sheetName: 'certificate_security_record', sheetType: 'transaction', systemName: 'invcertificate',description:'Certificate Security Record e.g., Certificate number, Net Cost' },
      { displayName: 'Adjusted Equity Transactions', sheetName: 'adjusted_equity_transactions', sheetType: 'transaction', systemName: 'invcompadjustequity',description:'Adjust Equity Transaction Detail' },
      { displayName: 'Adjusted Par Transactions', sheetName: 'adjusted_par_transactions', sheetType: 'transaction', systemName: 'invcompadjustpar',description:'Adjust Par Transactions Detail' },
      { displayName: 'BOT Approval Document', sheetName: 'bot_approval_document', sheetType: 'transaction', systemName: 'invcompbot',description:'BOT Approval Document Detail e.g., Document number from BOT ,Date of Approval' },
      { displayName: 'Company Cashflow', sheetName: 'company_cashflow', sheetType: 'transaction', systemName: 'invcompcashflow',description:'Daily General Transaction Detail ( Buy, Buy - Partially Paid Up, Sell, Drawdown, Redemption, Stock Dividend, Interest, Impairment, Reversed Impairment)' },
      { displayName: 'Board Director', sheetName: 'board_director', sheetType: 'oneToMany', systemName: 'invcompdirector',description:'Company Director Detail' },
      { displayName: 'Dividend', sheetName: 'dividend', sheetType: 'transaction', systemName: 'invcompdividend',description:'Dividend Payment Detail' },
      { displayName: 'Fair Value', sheetName: 'fair_value', sheetType: 'transaction', systemName: 'invcompfair',description:'DCF value' },
      { displayName: 'Warrant Transactions', sheetName: 'warrant_transactions', sheetType: 'transaction', systemName: 'invcompwarrant',description:'Warrant Transactions Detail' },
      { displayName: 'Fund Cashflow', sheetName: 'fund_cashflow', sheetType: 'transaction', systemName: 'invfundcashflow',description:'Daily Transaction of Fund' },  
    ],
  },
};


export const sheetNameState = atom({
  key: 'featurename',
  default: sheetNameList,
});



//----- API

export const testAPIState = atom<{ [key: string]: any }>({
  key: 'testAPI',
  default: {},
});
