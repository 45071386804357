import { atom } from 'recoil';
import { arrCurrency, arrPaymentMethod, arrDividendType } from '../common';

const _sheetName = 'invcompdividend';

//?------- Parameter Design ---------------
/*

------- Label / ID ------------
displayName = variable for display name in label.
sourceSystemName = variable for bank system name.
apiName = variable for call api. 

-------- Input ----------------
description = input placeholder.
inputLength = custom length limit of character.
inputType = (checkbox | date | radio | select | textfield) type of input.
inputClass = Custom format masking or validation.
possibleValue = (array) Value for dropdownlist / checkbox / radio.

-------- Boolean --------------
isRequire = (true/false) require field. can't be null.

isLinkValue = (true/false) Link the value from other input (linkValueId)
linkValueId = ID of input to Link data (display when isLinkValue == true).

isAutoGenerate = (true/false) auto generate value.
generateSource = source of auto generate (display when isAutoGenerate == true).

*/
/**
 textfield
 - NoLimitFree
 - NoLimitCountNumber
 - NoLimitFloat

 date
 - General

select
 - General

 */

const jsonData: { [key: string]: any } = {
  sheetName: _sheetName,
  inputInfo: [
    // ? --------------------------------- Start ---------------------------------------//
    // ! ---------------------------- 000.1 ---------------------------------//
    {
      displayName: 'Product id',
      sourceSystemName: 'PRODUCT_ID',
      apiName: 'product_id',

      description: 'Product id',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 001 ---------------------------------//
    {
      displayName: 'Company ID',
      sourceSystemName: 'COMPANY_ID',
      apiName: 'company_id',

      description: 'Company ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 002 ---------------------------------//
    {
      displayName: 'Company Name',
      sourceSystemName: 'COMPANY_NAME',
      apiName: 'company_name',

      description: 'Full legal name of the company',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 003 ---------------------------------//
    {
      displayName: 'Product Name',
      sourceSystemName: 'PRODUCT_NAME',
      apiName: 'product_name',

      description: 'Company name classified by type of products',
      inputLength: '',
      inputType: 'select',
      inputClass: 'Autocomplete',
      possibleValue: ['/product/list'],
      displayValue: ['/product/list'],

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: true,
    },

    // ---------------------------- 004 ---------------------------------//
    {
      displayName: 'Date',
      sourceSystemName: 'DATA_DATE',
      apiName: 'data_date',

      description: 'Date',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 004.1 ---------------------------------//
    {
      displayName: 'Data Type',
      sourceSystemName: 'DATA_DATE',
      apiName: 'data_type',

      description: 'Data Type',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrDividendType,
      displayValue: arrDividendType,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 005 ---------------------------------//
    {
      displayName: 'Book Closing Date AC',
      sourceSystemName: 'BOOK_CLOSING_DATE',
      apiName: 'book_closing_date',

      description: 'Book closing date (AKA XD)',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 006 ---------------------------------//
    {
      displayName: 'Currency AC',
      sourceSystemName: 'CURRENCY',
      apiName: 'currency',

      description: 'Base Currency',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: arrCurrency,
      displayValue: arrCurrency,

      isRequire: false,

      isLinkValue: false,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 007 ---------------------------------//
    {
      displayName: 'Number of shares',
      sourceSystemName: 'NO_OF_SHARES',
      apiName: 'no_of_shares',

      description: 'Outstanding shares as of record date (XD)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 008 ---------------------------------//
    {
      displayName: 'Dividend (Per Share) AC',
      sourceSystemName: 'DIVIDEND',
      apiName: 'dividend',

      description: 'Dividend per share',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 009 ---------------------------------//
    {
      displayName: 'Dividend Adjustment AC',
      sourceSystemName: 'DIVIDEND_ADJUSTMENT',
      apiName: 'dividend_adjustment',

      description: 'Dividend Adjustment',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 010 ---------------------------------//
    {
      displayName: 'Total Dividend Amount AC',
      sourceSystemName: 'DIVIDEND_AMOUNT',
      apiName: 'dividend_amount',

      description: 'Total Dividend Amount',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 011 ---------------------------------//
    {
      displayName: 'Withholding Tax AC',
      sourceSystemName: 'WITHHOLDING_TAX',
      apiName: 'withholding_tax',

      description: 'Withholding Tax',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 012 ---------------------------------//
    {
      displayName: 'Net Dividend Amount AC',
      sourceSystemName: 'NET_DIVIDEND_AMOUNT',
      apiName: 'net_dividend_amount',

      description: 'Net Dividend Amount (after withholding tax)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 013 ---------------------------------//
    {
      displayName: 'Payment Method AC',
      sourceSystemName: 'PAYMENT_METHOD',
      apiName: 'payment_method',

      description: 'Payment method to receive dividend from the firm',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrPaymentMethod,
      displayValue: arrPaymentMethod,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 014 ---------------------------------//
    {
      displayName: 'Payment Date AC',
      sourceSystemName: 'PAYMENT_DATE',
      apiName: 'payment_date',

      description: 'Dividend Payment Date',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 015 ---------------------------------//
    {
      displayName: 'Bank Name',
      sourceSystemName: 'BANK_NAME',
      apiName: 'bank_name',

      description: 'Name of bank that issues cheque or make a transfer',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 016 ---------------------------------//
    {
      displayName: 'Cheque Recorded Date',
      sourceSystemName: 'CHEQUE_RECORDED_DATE',
      apiName: 'cheque_recorded_date',

      description: 'Date that recorded on the cheque',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 017 ---------------------------------//
    {
      displayName: 'Cheque Number',
      sourceSystemName: 'CHEQUE_NUMBER',
      apiName: 'cheque_number',

      description: 'Cheque number if the payment made by cheque',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 018 ---------------------------------//
    {
      displayName: 'Cheque Received Date',
      sourceSystemName: 'CHEQUE_RECEIVED_DATE',
      apiName: 'cheque_received_date',

      description: 'Date that we receive cheque from the firm (esp non-listed)',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 019 ---------------------------------//
    {
      displayName: 'Details',
      sourceSystemName: 'DETAILS',
      apiName: 'details',

      description: 'Detials of Dividend',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ? --------------------------------- End ---------------------------------------//
  ],
};

export const invcompdividend = atom({
  key: 'invcompdividend',
  default: jsonData,
});
