/*
Table Header

A1. Company ID / company_id
A2. BI ID / bi_id
-----------------------

B1. Recording Date / recording_date
B2. Document No. / document_no
B3. Requested Type / request_type
B4. Document Start Date / document_start_date
B5. Document End Date / document_end_date

-------------------------
C1. Approval
C2. Detail
*/

export interface InvCompBotBodyTableInterface {
  sheet_id: string;
  company_id: string;
  bi_id: string;
  recording_date: string;
  document_no: string;
  request_type: string;
  document_start_date: string;
  document_end_date: string;
  approval: object;
  detail: string;
}

export function createInvCompBotBodyTable(
  sheet_id: string,
  company_id: string,
  bi_id: string,
  recording_date: string,
  document_no: string,
  request_type: string,
  document_start_date: string,
  document_end_date: string,
  approval: object,
  detail: string,
): InvCompBotBodyTableInterface {
  return {
    sheet_id,
    company_id,
    bi_id,
    recording_date,
    document_no,
    request_type,
    document_start_date,
    document_end_date,
    approval,
    detail,
  };
}

//-------------------------//
export interface InvCompBotHeaderTableInterface {
  id: keyof InvCompBotBodyTableInterface;
  label: string;
}
