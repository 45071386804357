import { Currency, Holding } from "./inv-enums";

export enum WarrantStatus {
	WarrantReceived   = 'WARRANT_RECEIVED',
	WarrantsExercised = 'WARRANTS_EXERCISED',
	WarrantsExpired   = 'WARRANTS_EXPIRED',
}

interface InvCompWarrantBaseInterface {
	product_name                 : string,          // --- DROPDOWN from INVCOMPPRODUCT
	product_name_2               : string,          // --- DROPDOWN from INVCOMPPRODUCT
	txn_date                     : string,          // date string
	record_date                  : string,          // date string
	warrant_name                 : string,          // --- Link to Warrant from INVCOMPPRODUCT (?)
	warrant_type                 : WarrantStatus,
	no_of_share_pretransaction   : number,          // integer, Linked from output - daily report on pre transaction date
	no_of_share_record_date      : number,          // integer, Linked from output (daily report on Recording date (XW)); for others - keep blank
	no_of_warrant_pretransaction : number,          // integer, Linked from output - daily report
	exercise_ratio               : number,          // integer
	no_of_warrant                : number,          // integer, Autogen FORMULAR -> (see the workbook sheet)
	no_of_warrant_posttransaction: number,          // integer, Autogen FORMULAR -> (see the workbook sheet)
	no_of_share_posttransaction  : number,          // integer, Autogen FORMULAR -> (see the workbook sheet)
	remark                       : string,

	// Accounting Purposes
	exchange_rate                     : number,   // --- Autogen from MASTERSHEET (Currency)
	cashflow_ac                       : number,   // --- Autogen FORMULAR
	cost_ac                           : number,   // --- Autogen FORMULAR
	cost_ac_thb                       : number,   // --- Autogen FORMULAR
	investment_cost_pretransaction    : number,   // --- Autogen FORMULAR from daily report on date before transaction -> 
	investment_cost_pretransaction_thb: number,   // --- Autogen FORMULAR -> 
	current_stake_pretransaction      : number,   // --- Autogen FORMULAR -> = Total No. of Share (Pre Transaction) /Paid Up Capital_No. of  Shares (Pre Transaction)
}

export type InvCompWarrant = InvCompWarrantBaseInterface & {
	bi_id                : string,    // --- Autogen from INVCOMPDEFINITIVE
	rm_id                : string,    // --- Autogen from INVCOMPDEFINITIVE
	company_id           : string,    // --- Autogen from INVCOMPDEFINITIVE
	company_name         : string,    // --- Autogen from INVCOMPPRODUCT
	investment_instrument: Holding,   // --- Autogen from INVCOMPPRODUCT
	// warrant_name      : string,    // --- Link to Warrant from INVCOMPPRODUCT (?)
	strike_price         : number,    // integer, --- For Warrant Exercised - Autogen from INVCOMPPRODUCT

	// Accounting Purposes
	investment_port              : string,     // --- Autogen from INVCOMPPRODUCT
	currency                     : Currency,   // --- Autogen from INVCOMPPRODUCT
	investment_cost              : number,     // --- Autogen FORMULAR and linked to INVCOMPPRODUCT -> = Investment Cost (Orginal CCY) (Pre Transaction) AC + Cost (Original CCY) AC
	investment_cost_thb          : number,     // --- Autogen FORMULAR and linked to INVCOMPPRODUCT -> = Investment Cost (Orginal CCY) (Post Transaction) AC * Exchange Rate
	current_stake_posttransaction: number,     // --- Autogen FORMULAR and linked to INVCOMPPRODUCT -> = Total No. of Share (Post Transaction) /Paid Up Capital_No. of  Shares (Post Transaction)
};
export type InvCompWarrantSubmit = InvCompWarrantBaseInterface & {
	company_id: string,   // InvCompDefinitive.company_id > bi_id, rm_id
	// InvCompProduct.product_id | InvCompProduct.product_id_2 >
	//     company_name,
	//     investment_instrument,
	//     warrant_name (?),
	//     strike_price,
	//     investment_port,
	//     currency,
	//     investment_cost (book_value, with additional calculations),
	//     investment_cost_thb,
	//     current_stake_posttransaction (current_stake, with additional calculations)
	product_id  : string,   // InvCompProduct.product_id > product_name
	product_id_2: string,   // InvCompProduct.product_id > product_name
	// InvCompCashFlow.txn_id >
	//     cashflow_ac (net_cashflow, with additional calculations), (?)
	//     cost_ac (investment_cost, with additional calculations), (?)
	//     cost_ac_thb (investment_cost_thb, with additional calculations) (?)
	txn_id      : string,    // --- Autogen and link to INVCOMPCASHFLOW

	//sheet id Ref
	sheet_id: string,
};

export type InvCompWarrantAll = InvCompWarrant & InvCompWarrantSubmit;