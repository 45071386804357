import { atom } from 'recoil';
import { arrCurrency, arrHolding, arrWarrantStatus } from '../common';

const _sheetName = 'invcompwarrant';

//?------- Parameter Design ---------------
/*

------- Label / ID ------------
displayName = variable for display name in label.
sourceSystemName = variable for bank system name.
apiName = variable for call api. 

-------- Input ----------------
description = input placeholder.
inputLength = custom length limit of character.
inputType = (checkbox | date | radio | select | textfield) type of input.
inputClass = Custom format masking or validation.
possibleValue = (array) Value for dropdownlist / checkbox / radio.

-------- Boolean --------------
isRequire = (true/false) require field. can't be null.

isLinkValue = (true/false) Link the value from other input (linkValueId)
linkValueId = ID of input to Link data (display when isLinkValue == true).

isAutoGenerate = (true/false) auto generate value.
generateSource = source of auto generate (display when isAutoGenerate == true).

*/
/**
 textfield
 - NoLimitFree
 - NoLimitCountNumber
 - NoLimitFloat

 date
 - General

select
 - General

 */

const jsonData: { [key: string]: any } = {
  sheetName: _sheetName,
  inputInfo: [
    // ? --------------------------------- Start ---------------------------------------//
    // ! ---------------------------- 000.1 ---------------------------------//
    {
      displayName: 'Product id',
      sourceSystemName: 'PRODUCT_ID',
      apiName: 'product_id',

      description: 'Product id',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ! ---------------------------- 000.2 ---------------------------------//
    {
      displayName: 'Product id 2',
      sourceSystemName: 'PRODUCT_ID_2',
      apiName: 'product_id_2',

      description: 'Product id 2',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 001 ---------------------------------//
    {
      displayName: 'BI_ID',
      sourceSystemName: 'BI_ID',
      apiName: 'bi_id',

      description: 'Business ID from Investment',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 002 ---------------------------------//
    {
      displayName: 'RM ID',
      sourceSystemName: 'RM_ID',
      apiName: 'rm_id',

      description: 'ID generated from bank',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 003 ---------------------------------//
    {
      displayName: 'Company ID',
      sourceSystemName: 'COMPANY_ID',
      apiName: 'company_id',

      description: 'Company ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 004 ---------------------------------//
    {
      displayName: 'Company Name',
      sourceSystemName: 'COMPANY_NAME',
      apiName: 'company_name',

      description: 'Full legal name of the company',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 005 ---------------------------------//
    {
      displayName: 'Product Name',
      sourceSystemName: 'PRODUCT_NAME',
      apiName: 'product_name',

      description: 'Company name classified by type of products',
      inputLength: '',
      inputType: 'select',
      inputClass: 'Autocomplete',
      possibleValue: ['/product/list'],
      displayValue: ['/product/list'],

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: true,
    },

    // !---------------------------- 005.1 ---------------------------------//
    {
      displayName: 'Product Name 2 (Optional)',
      sourceSystemName: 'PRODUCT_NAME_2',
      apiName: 'product_name_2',

      description: 'Company name classified by type of products 2 (Optional)',
      inputLength: '',
      inputType: 'select',
      inputClass: 'Autocomplete',
      possibleValue: ['/product/list'],
      displayValue: ['/product/list'],

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: true,
    },

    // ---------------------------- 006 ---------------------------------//
    {
      displayName: 'Transaction ID',
      sourceSystemName: 'TXN_ID',
      apiName: 'txn_id',

      description: 'Transaction ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromOtherSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 007 ---------------------------------//
    {
      displayName: 'Transaction Date',
      sourceSystemName: 'TXN_DATE',
      apiName: 'txn_date',

      description: 'Transaction Date',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 008 ---------------------------------//
    {
      displayName: 'Recording Date (XW)',
      sourceSystemName: 'RECORD_DATE',
      apiName: 'record_date',

      description: 'Recording Date (XW Date)',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 009 ---------------------------------//
    {
      displayName: 'Investment Instrument',
      sourceSystemName: 'INVESTMENT_INSTRUMENT',
      apiName: 'investment_instrument',

      description: 'Type of securities BBL hold in the company',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: arrHolding,
      displayValue: arrHolding,

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 010 ---------------------------------//
    {
      displayName: 'Warrant Name',
      sourceSystemName: 'WARRANT_NAME',
      apiName: 'warrant_name',

      description: 'Warrant name in BBL Port',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 011 ---------------------------------//
    {
      displayName: 'Transaction Type of Warrant',
      sourceSystemName: 'WARRANT_TYPE',
      apiName: 'warrant_type',

      description:
        'Type of warrant transactions - warrant received, warrant exercised, warrant expired',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrWarrantStatus,
      displayValue: arrWarrantStatus,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 012 ---------------------------------//
    {
      displayName: 'No. of Shares on pre-transaction date',
      sourceSystemName: 'NO_OF_SHARE_PRETRANSACTION',
      apiName: 'no_of_share_pretransaction',

      description: 'No. of Current Shares in BBL Port',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 013 ---------------------------------//
    {
      displayName: 'No. of Shares on recording date',
      sourceSystemName: 'NO_OF_SHARE_RECORD_DATE',
      apiName: 'no_of_share_record_date',

      description: 'No. of Recorded Shares as of XW Date',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 014 ---------------------------------//
    {
      displayName: 'No. of Warrants pre transaction',
      sourceSystemName: 'NO_OF_WARRANT_PRETRANSACTION',
      apiName: 'no_of_warrant_pretransaction',

      description: 'No. of Warrants pre transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 015 ---------------------------------//
    {
      displayName: 'Exercise Ratio',
      sourceSystemName: 'EXERCISE_RATIO',
      apiName: 'exercise_ratio',

      description: 'Exeicise ratio to receive warrant',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 016 ---------------------------------//
    {
      displayName: 'No. of Warrants in this transaction',
      sourceSystemName: 'NO_OF_WARRANT',
      apiName: 'no_of_warrant',

      description: 'No. of Warrants Received',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 017 ---------------------------------//
    {
      displayName: 'No. of Warrants post transaction',
      sourceSystemName: 'NO_OF_WARRANT_POSTTRANSACTION',
      apiName: 'no_of_warrant_posttransaction',

      description: 'No. of Warrants post transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 018 ---------------------------------//
    {
      displayName: 'No. of Shares post-transaction',
      sourceSystemName: 'NO_OF_SHARE_POSTTRANSACTION',
      apiName: 'no_of_share_posttransaction',

      description: 'No. of Shares post-transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 019 ---------------------------------//
    {
      displayName: 'Strike Price',
      sourceSystemName: 'STRIKE_PRICE',
      apiName: 'strike_price',

      description: 'Conversion Price of warrant',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 020 ---------------------------------//
    {
      displayName: 'Remark',
      sourceSystemName: 'REMARK',
      apiName: 'remark',

      description: 'Remark (If any)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 021 ---------------------------------//
    {
      displayName: 'Portfolio Classification AC',
      sourceSystemName: 'INVESTMENT_PORT',
      apiName: 'investment_port',

      description: 'BBL internal accounting record for accounting purpose',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 022 ---------------------------------//
    {
      displayName: 'Currency AC',
      sourceSystemName: 'CURRENCY',
      apiName: 'currency',

      description: 'Denominated Currency',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: arrCurrency,
      displayValue: arrCurrency,

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 023 ---------------------------------//
    {
      displayName: 'Exchange Rate',
      sourceSystemName: 'EXCHANGE_RATE',
      apiName: 'exchange_rate',

      description: 'Exchange Rate',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 024 ---------------------------------//
    {
      displayName: 'Cashflow AC',
      sourceSystemName: 'CASHFLOW_AC',
      apiName: 'cashflow_ac',

      description: 'Warrant cashflow transactions',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 025 ---------------------------------//
    {
      displayName: 'Cost (Original CCY) AC',
      sourceSystemName: 'COST_AC',
      apiName: 'cost_ac',

      description: 'Accounting cost (Original CCY)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 026 ---------------------------------//
    {
      displayName: 'Cost (THB) AC',
      sourceSystemName: 'COST_AC_THB',
      apiName: 'cost_ac_thb',

      description: 'Accounting cost (Original CCY)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // !---------------------------- 027 ---------------------------------//
    // {
    //   displayName: 'Total No. of Share (Pre Transaction)',
    //   sourceSystemName: 'NO_OF_SHARE_PRETRANSACTION',
    //   apiName: 'no_of_share_pretransaction',

    //   description: 'Total number of share - Pre Transaction',
    //   inputLength: '',
    //   inputType: 'textfield',
    //   inputClass: 'NoLimitFloat',
    //   possibleValue: '',
    //   displayValue: '',

    //   isRequire: false,

    //   isLinkValue: false,
    //   linkValueId: '',

    //   isAutoGenerate: false,
    //   isActive: true,
    // },

    // ---------------------------- 028 ---------------------------------//
    {
      displayName: 'Investment Cost (Orginal CCY) (Pre Transaction) AC',
      sourceSystemName: 'INVESTMENT_COST_PRETRANSACTION',
      apiName: 'investment_cost_pretransaction',

      description: 'Investment Cost (Orginal CCY) - Pre Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 029 ---------------------------------//
    {
      displayName: 'Investment Cost (THB) (Pre Transaction) AC',
      sourceSystemName: 'INVESTMENT_COST_PRETRANSACTION_THB',
      apiName: 'investment_cost_pretransaction_thb',

      description: 'Investment Cost (THB) - Pre Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 030 ---------------------------------//
    {
      displayName: '% Hold (Pre Transaction)',
      sourceSystemName: 'CURRENT_STAKE_PRETRANSACTION',
      apiName: 'current_stake_pretransaction',

      description: '% holding in the firm - Pre transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 031 ---------------------------------//
    {
      displayName: 'Total No. of Share (Post Transaction)',
      sourceSystemName: 'NO_OF_SHARE_POSTTRANSACTION',
      apiName: 'no_of_share_posttransaction',

      description: 'Total number of share - Post Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 032 ---------------------------------//
    {
      displayName: 'Investment Cost (Orginal CCY) (Post Transaction) AC',
      sourceSystemName: 'INVESTMENT_COST',
      apiName: 'investment_cost',

      description: 'Investment Cost (Orginal CCY)  - Post Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 033 ---------------------------------//
    {
      displayName: 'Investment Cost (THB) (Post Transaction) AC',
      sourceSystemName: 'INVESTMENT_COST_THB',
      apiName: 'investment_cost_thb',

      description: 'Investment Cost (THB)  - Post Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 034 ---------------------------------//
    {
      displayName: '% Hold (Post Transaction)',
      sourceSystemName: 'CURRENT_STAKE_POSTTRANSACTION',
      apiName: 'current_stake_posttransaction',

      description: '% holding in the firm - Post Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ? --------------------------------- End ---------------------------------------//
  ],
};

export const invcompwarrant = atom({
  key: 'invcompwarrant',
  default: jsonData,
});
