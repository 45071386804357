/*
Table Header

A1. non
-----------------------

B1. Date / data_date
B2. Status /status
B3. Tier 1 / tier_1_capital
B4. Tier 2 / tier_2_capital
B5. Total (Tier1+2) / B3+B4 Calcurate

-------------------------
C1. Approval
C2. Detail
*/

export interface InvBankCapitalBodyTableInterface {
  sheet_id: string;
  data_date: string;
  status: string;
  tier_1_capital: string;
  tier_2_capital: string;
  total: string;
  approval: object;
  detail: string;
}

export function createInvBankCapitalBodyTable(
  sheet_id: string,
  data_date: string,
  status: string,
  tier_1_capital: string,
  tier_2_capital: string,
  total: string,
  approval: object,
  detail: string,
): InvBankCapitalBodyTableInterface {
  return {
    sheet_id,
    data_date,
    status,
    tier_1_capital,
    tier_2_capital,
    total,
    approval,
    detail,
  };
}

//-------------------------//
export interface InvBankCapitalHeaderTableInterface {
  id: keyof InvBankCapitalBodyTableInterface;
  label: string;
}
