import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
//Icon
import EditIcon from '@mui/icons-material/Edit'; //Edit
import AddIcon from '@mui/icons-material/Add';

function RenderLayout({
  typoHeader,
  typoSubHeader,
  typoIcon,
}: {
  typoHeader: string;
  typoSubHeader: string;
  typoIcon: object;
}) {
  return (
    <div>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        container
      >
        {/* ------------- */}

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          container
        >
          <Grid xs={1} sm={1} md={1} item>
            {typoIcon}
          </Grid>

          <Grid xs={11} sm={11} md={11} item>
            <Typography color="primary" variant="h4" gutterBottom component="div">
              {typoHeader}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          container
        >
          <Grid xs={1} sm={1} md={1} item></Grid>
          <Grid xs={11} sm={11} md={11} item>
            <Typography color="primary" variant="body1" gutterBottom component="div">
              {typoSubHeader}
            </Typography>
          </Grid>
        </Grid>
        {/* ------------- */}
      </Grid>
    </div>
  );
}

export default function RenderPrePage({
  _caseName,
  _previousCaseName,
  _typoHeaderDisplay,
}: {
  _caseName: string;
  _previousCaseName: string;
  _typoHeaderDisplay: string;
}) {
  if (_caseName === 'create') {
    return (
      <RenderLayout
        typoHeader={_typoHeaderDisplay}
        typoSubHeader={'Click next to fill form.'}
        typoIcon={<AddIcon color="primary" sx={{ fontSize: 40 }} />}
      />
    );
  } else if (_caseName === 'close' && _previousCaseName === 'create') {
    return (
      <RenderLayout
        typoHeader={_typoHeaderDisplay}
        typoSubHeader={'Click next to fill form.'}
        typoIcon={<AddIcon color="primary" sx={{ fontSize: 40 }} />}
      />
    );
  } else if (_caseName === 'edit' || _caseName === 'edit_form') {
    return (
      <RenderLayout
        typoHeader={_typoHeaderDisplay}
        typoSubHeader={'Click next to fill form.'}
        typoIcon={<EditIcon color="primary" sx={{ fontSize: 40 }} />}
      />
    );
  } else if (_caseName === 'close' && _previousCaseName === 'edit') {
    return (
      <RenderLayout
        typoHeader={_typoHeaderDisplay}
        typoSubHeader={'Click next to fill form.'}
        typoIcon={<EditIcon color="primary" sx={{ fontSize: 40 }} />}
      />
    );
  } else if (_caseName === 'close' && _previousCaseName === 'edit_form') {
    return (
      <RenderLayout
        typoHeader={_typoHeaderDisplay}
        typoSubHeader={'Click next to fill form.'}
        typoIcon={<EditIcon color="primary" sx={{ fontSize: 40 }} />}
      />
    );
  } else {
    return <div></div>;
  }
}
