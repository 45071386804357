// ?------------------------------ Fund Table ------------------------------------------
export interface FundTableBodyData {
  sheet_id: string;
  bi_id: string;
  fund_id: string;
  fund_name: string;
  approval: object;
  detail: string;
}

export function createFundTableBodyData(
  sheet_id: string,
  bi_id: string,
  fund_id: string,
  fund_name: string,
  approval: object,
  detail: string,
): FundTableBodyData {
  return {
    sheet_id,
    bi_id,
    fund_id,
    fund_name,
    approval,
    detail,
  };
}

//-------------------------//
export interface FundTableHeadData {
  id: keyof FundTableBodyData;
  label: string;
}
