import axios from '../axios-instance';

import {
  API_SERVER_URL,
  API_COMPANY_URL,
  API_SUBMIT_URL,
  API_APPROVE_URL,
  API_CANCEL_URL,
  API_DETAIL_URL,
  API_HISTORY_URL,
} from '../../../config';

import { InvCompDefinitiveSubmit, requestCompanyMainInterface } from '@invport/models';

import {
  requestApproveInterface,
  requestCancelInterface,
  requestHistoryInterface,
  requestDetailInterface,
} from '@invport/models';

import {
  responseApproveInterface,
  responseCancelInterface,
  responseHistoryInterface,
  responseDetailInterface,
  responseSubmitInterface,
} from '@invport/models';

export const getCompany = async (
  requestMainBody: requestCompanyMainInterface,
): Promise<requestCompanyMainInterface> => {
  //  console.log(axios.);

  return (
    await axios.post<requestCompanyMainInterface>(
      `${API_SERVER_URL}/${API_COMPANY_URL}`,
      requestMainBody,
    )
  ).data;
};

//submit
export const submitCompany = async (
  requestSubmitBody: InvCompDefinitiveSubmit,
): Promise<responseSubmitInterface> =>
  (
    await axios.post<responseSubmitInterface>(
      `${API_SERVER_URL}/${API_COMPANY_URL}/${API_SUBMIT_URL}`,
      requestSubmitBody,
    )
  ).data;

//approve
export const approveCompany = async (
  id: requestApproveInterface,
): Promise<responseApproveInterface> =>
  (
    await axios.post<responseApproveInterface>(
      `${API_SERVER_URL}/${API_COMPANY_URL}/${API_APPROVE_URL}`,
      id,
    )
  ).data;

//cancel
export const cancelCompany = async (
  requestCancel: requestCancelInterface,
): Promise<responseCancelInterface> =>
  (
    await axios.post<responseCancelInterface>(
      `${API_SERVER_URL}/${API_COMPANY_URL}/${API_CANCEL_URL}`,
      requestCancel,
    )
  ).data;

//detail
export const getDetailCompany = async (
  requestDetailBody: requestDetailInterface,
): Promise<responseDetailInterface> =>
  (
    await axios.post<responseDetailInterface>(
      `${API_SERVER_URL}/${API_COMPANY_URL}/${API_DETAIL_URL}`,
      requestDetailBody,
    )
  ).data;

//histroy
export const getHistoryCompany = async (
  requestHistoryBody: requestHistoryInterface,
): Promise<responseHistoryInterface> =>
  (
    await axios.post<responseHistoryInterface>(
      `${API_SERVER_URL}/${API_COMPANY_URL}/${API_HISTORY_URL}`,
      requestHistoryBody,
    )
  ).data;
