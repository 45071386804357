//---------------------------------------- Import React --------------------------------------
//React
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import ElementStyles from '../../../../styles/Element.module.css';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';

import Chip from '@mui/material/Chip';

import DescriptionIcon from '@mui/icons-material/Description';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import StarIcon from '@mui/icons-material/Star';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CircularProgress from '@mui/material/CircularProgress';

import {
  invcompdefinitive,
  invfunddefinitive,
  invboardapprovedmemo,
  invcontact,
  invcompfs,
  invcompproduct,
  invfundbenchmark,
  invfundcommitmentandwatermark,
  invfundnav,
  invfundportfolio,
} from '../../../../states/index';

import {
  invcertificate,
  invcompdirector,
  invfundcashflow,
  invbankaccount,
  invbankcapital,
  invcompcashflow,
  invcompdividend,
  invcompfair,
  invcompadjustpar,
  invcompadjustequity,
  invcompwarrant,
  invcompbot,
} from '../../../../states/index';

import {
  isCallingAPIState,
  isCreateModal,
  isUpdateInputJSONState,
  isFirstLoadFormState,
  isGenerateCompleteState,
} from '../../../../states/index';

import {
  currentInputJSONState,
  currentSheetNameState,
  currentSheetTypeState,
  currentCaseNameState,
  currentIsMainApprovedState,
  currentCreateModalModeState,
  currentIsEmptyState,
} from '../../../../states/index';

//User
import { userProfileState } from '../../../../states/index';

function IconComp({ sheetType }: { sheetType: string }) {
  switch (sheetType) {
    case 'primary':
      return <StarIcon />;
    case 'oneToMany':
      return <DescriptionIcon />;
    case 'transaction':
      return <DescriptionOutlinedIcon />;
    default:
      return <div></div>;
  }
}

function StatusComp({
  sheetStauts,
  onChipClick,
  currentIsMainApproved,
  isGenerateCompleteStatus,
  isViewer,
  isDisabled,
}: {
  sheetStauts: string;
  onChipClick: () => void;
  currentIsMainApproved: boolean;
  isGenerateCompleteStatus: boolean;
  isViewer: boolean;
  isDisabled: boolean;
}) {
  let isChipDisabled = false;
  if (currentIsMainApproved === false && isDisabled === true) {
    isChipDisabled = true;
  } else if (currentIsMainApproved === true && isDisabled === true) {
    isChipDisabled = false;
  } else {
    isChipDisabled = isDisabled;
  }

  switch (sheetStauts) {
    case 'APPROVED':
      return (
        <Chip
          sx={{ backgroundColor: '#c8e6c9', color: '#33691e' }}
          label="Approved"
          disabled={isDisabled}
        />
      );
    case 'PENDING':
      return (
        <Chip
          sx={{ backgroundColor: '#ffe0b2', color: '#e65100' }}
          label="Pending"
          disabled={isDisabled}
        />
      );
    case 'EMPTY':
      if (isViewer === true) {
        return <Chip color="primary" label="Empty" disabled />;
      } else {
        return (
          <Chip
            icon={
              isGenerateCompleteStatus ? (
                <AddCircleIcon />
              ) : (
                <CircularProgress size={20} />
              )
            }
            color="primary"
            label={isGenerateCompleteStatus ? 'Add' : 'Loading'}
            clickable
            onClick={onChipClick}
            disabled={isChipDisabled}
          />
        );
      }

    default:
      return (
        <Chip
          sx={{ backgroundColor: '#eeeeee', color: '#bdbdbd' }}
          label="Not Found Status"
          disabled={isDisabled}
        />
      );
  }
}

function ButtonMenu({
  icon,
  sheetDisplayName,
  sheetName,
  sheetStautsArr,
  sheetType,
  sheetSystemName,
  onCallApi,
}: {
  icon: string;
  sheetDisplayName: string;
  sheetName: string;
  sheetStautsArr: any;
  sheetType: string;
  sheetSystemName: string;
  onCallApi: any;
}) {
  // console.log(sheetStauts);

  const setCurrentSheetType = useSetRecoilState(currentSheetTypeState);
  const setCurrentCaseName = useSetRecoilState(currentCaseNameState);
  const setCurrentCreateModalModeState = useSetRecoilState(currentCreateModalModeState);
  const setCurrentIsEmpty = useSetRecoilState(currentIsEmptyState);

  const [currentSheetName, setCurrentSheetName] = useRecoilState(currentSheetNameState);
  const currentIsMainApproved = useRecoilValue(currentIsMainApprovedState);

  const isGenerateComplete = useRecoilValue(isGenerateCompleteState);
  const userProfile = useRecoilValue(userProfileState);
  const [isCallingAPI, setIsCallingAPI] = useRecoilState(isCallingAPIState);

  let isViewer = false;

  if (userProfile.role === 'VIEWER' || userProfile.role === 'CHECKER') {
    isViewer = true;
  } else {
    isViewer = false;
  }

  let isSelect = false;

  if (sheetSystemName === currentSheetName) {
    isSelect = true;
  } else {
    isSelect = false;
  }

  let sheetStauts = '';

  if (sheetName === 'invcompdefinitive' || sheetName === 'invfunddefinitive') {
    sheetStauts = sheetStautsArr;
  } else {
    if (sheetStautsArr.length === 0) {
      sheetStauts = 'EMPTY';
    } else {
      let arrData = [];

      for (let index = 0; index < sheetStautsArr.length; index++) {
        const element: { [key: string]: any } = sheetStautsArr[index];

        arrData.push(element['status']);

        if (index === sheetStautsArr.length - 1) {
          if (arrData.includes('PENDING')) {
            sheetStauts = 'PENDING';
          } else {
            sheetStauts = 'APPROVED';
          }
        }
      }
    }
  }

  const handleSetObjectSheet = () => {
    if (sheetStauts === 'EMPTY') {
      setCurrentIsEmpty(true);
    } else {
      setCurrentIsEmpty(false);
    }

    setCurrentSheetName(sheetSystemName);
    setCurrentSheetType(sheetType);
    onCallApi();
    setIsCallingAPI(true);
    setIsUpdateInputJSON(true);
  };

  const setCurrentInputJSON = useSetRecoilState(currentInputJSONState);
  const setOpenCreateModal = useSetRecoilState(isCreateModal);
  const [isUpdateInputJSON, setIsUpdateInputJSON] =
    useRecoilState(isUpdateInputJSONState);
  const setIsFirstLoadForm = useSetRecoilState(isFirstLoadFormState);

  //?------------------------------Input JSON ----------------------------------//

  //Common
  const invcontactData = useRecoilValue(invcontact);
  const invcertificateData = useRecoilValue(invcertificate);
  const invboardapprovedmemoData = useRecoilValue(invboardapprovedmemo);
  const invcompdirectorData = useRecoilValue(invcompdirector);

  //Mian Comp InputJSON
  const invcompdefinitiveData = useRecoilValue(invcompdefinitive);
  const invcompfsData = useRecoilValue(invcompfs);
  const invcompproductData = useRecoilValue(invcompproduct);

  //Main Fund InputJSON
  const invfunddefinitiveData = useRecoilValue(invfunddefinitive);
  const invfundbenchmarkData = useRecoilValue(invfundbenchmark);
  const invfundcommitmentandwatermarkData = useRecoilValue(
    invfundcommitmentandwatermark,
  );
  const invfundnavData = useRecoilValue(invfundnav);
  const invfundportfolioData = useRecoilValue(invfundportfolio);

  //Operation InputJSON
  const invfundcashflowData = useRecoilValue(invfundcashflow);
  const invbankaccountData = useRecoilValue(invbankaccount);
  const invbankcapitalData = useRecoilValue(invbankcapital);
  const invcompcashflowData = useRecoilValue(invcompcashflow);
  const invcompdividendData = useRecoilValue(invcompdividend);
  const invcompfairData = useRecoilValue(invcompfair);
  const invcompadjustparData = useRecoilValue(invcompadjustpar);
  const invcompadjustequityData = useRecoilValue(invcompadjustequity);
  const invcompwarrantData = useRecoilValue(invcompwarrant);
  const invcompbotData = useRecoilValue(invcompbot);

  let currentValue = (_currentSheetName: string) => {
    // let currentTableData = {
    //   _currentInputJSON: {},
    //   currentHeaders: {},
    // };

    let _currentInputJSON = {};
    switch (_currentSheetName) {
      //--------------------------------- */
      case 'invcompdefinitive':
        return (_currentInputJSON = invcompdefinitiveData);

      //--------------------------------- */
      case 'invfunddefinitive':
        return (_currentInputJSON = invfunddefinitiveData);

      //--------------------------------- */
      case 'invfundcashflow':
        return (_currentInputJSON = invfundcashflowData);

      //--------------------------------- */
      case 'invbankaccount':
        return (_currentInputJSON = invbankaccountData);

      //--------------------------------- */
      case 'invbankcapital':
        return (_currentInputJSON = invbankcapitalData);

      //--------------------------------- */
      case 'invcompcashflow':
        return (_currentInputJSON = invcompcashflowData);

      //--------------------------------- */
      case 'invcompdividend':
        return (_currentInputJSON = invcompdividendData);

      //--------------------------------- */
      case 'invcompfair':
        return (_currentInputJSON = invcompfairData);

      //--------------------------------- */
      case 'invcompadjustpar':
        return (_currentInputJSON = invcompadjustparData);

      //--------------------------------- */
      case 'invcompadjustequity':
        return (_currentInputJSON = invcompadjustequityData);

      //--------------------------------- */
      case 'invcompwarrant':
        return (_currentInputJSON = invcompwarrantData);

      //--------------------------------- */
      case 'invcompbot':
        return (_currentInputJSON = invcompbotData);

      //--------------------------------- */
      case 'invcertificate':
        return (_currentInputJSON = invcertificateData);

      //--------------------------------- */
      case 'invcompdirector':
        return (_currentInputJSON = invcompdirectorData);

      //--------------------------------- */
      case 'invcontact':
        return (_currentInputJSON = invcontactData);

      //--------------------------------- */
      case 'invboardapprovedmemo':
        return (_currentInputJSON = invboardapprovedmemoData);

      //--------------------------------- */
      case 'invcompfs':
        return (_currentInputJSON = invcompfsData);

      //--------------------------------- */
      case 'invcompproduct':
        return (_currentInputJSON = invcompproductData);

      //--------------------------------- */
      case 'invfundbenchmark':
        return (_currentInputJSON = invfundbenchmarkData);

      //--------------------------------- */
      case 'invfundcommitmentandwatermark':
        return (_currentInputJSON = invfundcommitmentandwatermarkData);

      //--------------------------------- */
      case 'invfundnav':
        return (_currentInputJSON = invfundnavData);

      //--------------------------------- */
      case 'invfundportfolio':
        return (_currentInputJSON = invfundportfolioData);

      //************** NotFound ********************* */
      //------------------------------------//
      default:
        return (_currentInputJSON = {});
    }
  };

  const handleChipClick = () => {
    if (sheetStauts === 'EMPTY') {
      setIsFirstLoadForm(false);
      setCurrentCaseName('create');
      setCurrentCreateModalModeState('create');
      setCurrentSheetName(sheetSystemName);
      setCurrentSheetType(sheetType);
      setIsUpdateInputJSON(true);
      setCurrentIsEmpty(true);

      setOpenCreateModal(true);
    } else {
      setCurrentIsEmpty(false);
    }
  };

  let isDisableMenu = false;

  //------------------------------------//
  if (currentIsMainApproved === false) {
    if (
      sheetSystemName === 'invcompdefinitive' ||
      sheetSystemName === 'invfunddefinitive'
    ) {
      isDisableMenu = false;
    } else {
      isDisableMenu = true;
    }
  } else {
    if (isCallingAPI === true) {
      isDisableMenu = true;
    } else {
      if (sheetStauts === 'EMPTY' || isGenerateComplete === false) {
        isDisableMenu = true;
      } else {
        isDisableMenu = false;
      }
    }
  }

  //------------------------------------//

  if (isUpdateInputJSON === true) {
    let _currentInputJSON = currentValue(currentSheetName);
    setCurrentInputJSON(_currentInputJSON);
    setIsUpdateInputJSON(false);
  }
  // console.log(isGenerateComplete);
  return (
    <ListItem
      className={
        isSelect
          ? ElementStyles.MainDetailModalListItem_Active
          : ElementStyles.MainDetailModalListItem_NonActive
      }
      key={'listItem_' + sheetSystemName}
      disablePadding
      secondaryAction={
        <StatusComp
          sheetStauts={sheetStauts}
          currentIsMainApproved={currentIsMainApproved}
          onChipClick={handleChipClick}
          isGenerateCompleteStatus={isGenerateComplete}
          isViewer={isViewer}
          isDisabled={isDisableMenu}
        />
      }
    >
      <ListItemButton
        key={'listbutton_' + sheetSystemName}
        onClick={handleSetObjectSheet}
        // disabled={isDisableMenu}
      >
        <ListItemIcon>
          <IconComp sheetType={sheetType} />
        </ListItemIcon>
        <ListItemText>{sheetDisplayName}</ListItemText>
      </ListItemButton>
    </ListItem>
  );
}

export default ButtonMenu;
