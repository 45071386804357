// ? ------------------------------ Operation Table ---------------------------------------

/*
Table Header

A2. BI_ID /bi_id
A1. Company ID /company_id
-----------------------

B1. Product Name / product_name
B2. Transaction Date / txn_date
B3. Adjusted Par Method AC / adjusted_par_method
B4. No. of Shares on post-transaction / no_of_share_posttransaction
B5. Investment cost post transaction / investment_cost
B6. Par (post) / par_value_posttransaction

-------------------------
C1. Approval
C2. Detail
*/

export interface InvCompAdjustParBodyTableInterface {
  sheet_id: string;
  bi_id: string;
  company_id: string;
  product_name: string;
  txn_date: string;
  adjusted_par_method: string;
  no_of_share_posttransaction: string;
  investment_cost: string;
  par_value_posttransaction: string;
  approval: object;
  detail: string;
}

export function createInvCompAdjustParBodyTable(
  sheet_id: string,
  bi_id: string,
  company_id: string,
  product_name: string,
  txn_date: string,
  adjusted_par_method: string,
  no_of_share_posttransaction: string,
  investment_cost: string,
  par_value_posttransaction: string,
  approval: object,
  detail: string,
): InvCompAdjustParBodyTableInterface {
  return {
    sheet_id,
    bi_id,
    company_id,
    product_name,
    txn_date,
    adjusted_par_method,
    no_of_share_posttransaction,
    investment_cost,
    par_value_posttransaction,
    approval,
    detail,
  };
}

//-------------------------//
export interface InvCompAdjustParHeaderTableInterface {
  id: keyof InvCompAdjustParBodyTableInterface;
  label: string;
}
