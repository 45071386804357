import {
	AssoSubFlag,
	Currency,
	FixedThbRate,
	Fund,
	Ifrs9,
	Nationality,
	Ownership,
	Port,
	Product,
	Sector
} from './inv-enums';

export enum Asset {
	Buyout                     = 'BUYOUT',
	Growth                     = 'GROWTH',
	HedgeFunds                 = 'HEDGE_FUNDS',
	Infrastructure             = 'INFRASTRUCTURE',
	RealEstate                 = 'REAL_ESTATE',
	Secondary                  = 'SECONDARY',
	SpecialSituationAndOthers  = 'SPECIAL_SITUATION_AND_OTHERS',
	VentureCapital             = 'VENTURE_CAPITAL',
	MoneyMarketFund            = 'MONEY_MARKET_FUND',
	FixedIncomeFund            = 'FIXED_INCOME_FUND',
	MixedFund                  = 'MIXED_FUND',
	EquityFund                 = 'EQUITY_FUND',
	ForeignInvestmentFund      = 'FOREIGN_INVESTMENT_FUND',
	AlternativeFund            = 'ALTERNATIVE_FUND',
	Rmf                        = 'RMF',
	Ltf                        = 'LTF',
	Ssf                        = 'SSF',
	MutualFundForProvidentFund = 'MUTUAL_FUND_FOR_PROVIDENT_FUND',
	ForeignFixedIncomeTermFund = 'FOREIGN_FIXED_INCOME_TERM_FUND',
}
export enum HarvestingFactor {
	CapitalInvestmentPercentage     = 'CAPITAL_INVESTMENT_PERCENTAGE',
	NetAssetValuePercentage         = 'NET_ASSET_VALUE_PERCENTAGE',
	OutstandingCommitmentPercentage = 'OUTSTANDING_COMMITMENT_PERCENTAGE',
}
export enum DistributionMethod {
	EuropeanWaterfall          = 'EUROPEAN_WATERFALL',
	AmericanWaterfall          = 'AMERICAN_WATERFALL',
	MutualFundDividendPolicy   = 'MUTUAL_FUND_DIVIDEND_POLICY',
	MutualFundNoDividendPolicy = 'MUTUAL_FUND_NO_DIVIDEND_POLICY',
	Others                     = 'OTHERS',
	NotApplicable              = 'N/A',
}
export enum FundStatus {
	Active   = 'ACTIVE',
	Inactive = 'INACTIVE',
}

// Binary enum(s) below are to be mapped to booleans (1st: true, 2nd: false)
export enum Alternative {
	Alternative    = 'ALTERNATIVE',
	NonAlternative = 'NON_ALTERNATIVE',
}

interface InvFundDefinitiveBaseInterface {
	bi_id                       : string,               // --- Autogen from Mastersheet (BI ID)
	rm_id                       : string,               // --- Autogen from Mastersheet (RM ID)
	data_date                   : string,               // date string
	fund_id                     : string,               // --- Autogen from Mastersheet (Fund ID)
	registered_date             : string,               // date string
	registered_id               : number,               // integer
	fund_name                   : string,
	fund_thainame               : string,
	fund_quote                  : string,
	fund_status                 : FundStatus,
	first_investment_date       : string,               // date string
	first_closing_date          : string,               // date string
	final_closing_date          : string,               // date string
	fund_vintage                : number,               // integer
	asset_management_company    : string,
	fund_type                   : Fund,
	asset_class                 : Asset,
	registered_address          : string,
	ho_country                  : string,
	domicile_country            : string,
	investment_geography        : string,
	invested_country            : string,
	investment_period           : number,               // integer
	fund_term                   : number,               // integer
	extension_period            : string,
	investment_period_multiplier: string,
	investment_period_pc        : number,
	harvesting_period_multiplier: HarvestingFactor,
	harvesting_period_pc        : number,
	distribution_method         : DistributionMethod,
	preferred_return            : number,
	gp_catchup                  : number,
	carry_interest              : number,
	hurdle_rate                 : number,
	focused_sector_1            : Sector,
	focused_sector_2            : Sector,
	focused_sector_3            : Sector,
	focused_sector_4            : Sector,
	focused_sector_5            : Sector,
	co_investment_flag          : boolean,              // Yes / No
	gp_exposure                 : boolean,              // Yes / No
	company_id_1                : string,               // --- Autogen from INVFUNDPORTFOLIO
	company_id_2                : string,               // --- Autogen from INVFUNDPORTFOLIO
	company_id_3                : string,               // --- Autogen from INVFUNDPORTFOLIO
	remark                      : string,

	// Risk Management Purposes
	fund_risk_level    : number,    // integer, 1-8
	alternative_class  : boolean,   // Alternative / Non-Alternative
	fintech_class      : boolean,   // Yes / No
	currency_risk_flag : boolean,   // Yes / No

	// Portal Access
	investor_portal: boolean,   // Yes / No
	portal_site    : string,
	portal_username: string,
	portal_password: string,
	portal_question: string,
	portal_answer  : string,

	// Accounting Purposes
	internal_acctcode   : string,         // --- Autogen from Mastersheet Account Code
	internal_accdes     : string,         // --- Autogen from Mastersheet Account Code
	internal_procode    : string,         // --- Autogen from Mastersheet Product Code
	internal_prodes     : string,         // --- Autogen from Mastersheet Product Code
	currency            : Currency,       // --- Linked from Fund Type in Row 16
	valuation_method    : number,         // integer, --- Fixed: 'NAV' (unknown enum ?)
	investment_port     : Port,
	redeemable          : boolean,        // Redeemable / Non-Redeemable
	asso_sub_flag       : AssoSubFlag,
	issue_location      : Nationality,
	issuer_nationality  : Nationality,
	listed_company_flag : boolean,        // Fixed: 'NO' (false)
	product_type        : Product,
	investment_type     : boolean,        // Tdr / Non-Tdr
	intra_group_code    : string,
	isic_code           : string,
	bot_exclusion_flag  : boolean,        // Yes / No
	ownership_type      : Ownership,
	traded_to_subsidiary: boolean,        // Yes / No
	ifrs9_instrument    : Ifrs9,
	hedge_currency      : boolean,        // Hedge / Non-Hedge
	fixed_rate_thb_flag : FixedThbRate,
}

export type InvFundDefinitive = InvFundDefinitiveBaseInterface;
export type InvFundDefinitiveSubmit = InvFundDefinitiveBaseInterface & {
	//sheet id Ref
	sheet_id: string,
};
export type InvFundDefinitiveEntry = Pick<
	InvFundDefinitive,
	'fund_id' |
	'currency' |
	'fund_name' |
	'fund_quote' |
	'fund_thainame'
>[];


export type InvFundDefinitiveAll = InvFundDefinitive & InvFundDefinitiveSubmit;