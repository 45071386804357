import axios from './axios-instance';
import { API_SERVER_URL } from '../../config';

const API_LOGIN_URL = `login`;
const API_LOGOUT_URL = `logout`;

export const getLogin = async () =>
  (await axios.get(`${API_SERVER_URL}/${API_LOGIN_URL}`)).data;

export const getLogout = async () =>
  (await axios.delete(`${API_SERVER_URL}/${API_LOGOUT_URL}`)).data;

