import { useHistory } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { isLoggedInState } from '../states/index';

//api
import { getLogin } from './api/index';

//Element
import Chip from '@mui/material/Chip';

//Layout
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import WindowIcon from '@mui/icons-material/Window';

//Style
//@ts-ignore
import ElementStyles from '../styles/Element.module.css';
//@ts-ignore
import LayoutStyles from '../styles/Layout.module.css';

import LoginModal from './components/common/modal/LoginModal';
import { isLoginModal } from '../states/index';

//Custom Comp
import BBLLogoWhite from './components/common/logo/BBLLogoWhite';

function LoginPage() {
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
  const setOpenLoginModal = useSetRecoilState(isLoginModal);

  const history = useHistory();

  //! -------------- API :  /login --------------------------//
  const onLoginPass = async () => {
    const login = await getLogin();
    // console.log(login.result.success);
    if (login.result.success === true) {
      setIsLoggedIn(true);
    } else {
      setOpenLoginModal(true);
    }
  };

  if (isLoggedIn === true) {
    // console.log("Log in")
    history.push(`/investent_portfolio`);
    // window.location.replace(`/investent_portfolio`);
  }

  return (
    <>
      <LoginModal />
      <Grid container className={LayoutStyles.LoginRoot}>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          className={LayoutStyles.LoginImage}
          direction="column"
          alignItems="center"
          justifyContent="space-around"
          spacing={5}
        >
          <Grid item>
            {/* <Box className={ElementStyles.BBLLogoBox}> */}
            <BBLLogoWhite logoClassName={ElementStyles.LogoLogin} />
            {/* </Box> */}
          </Grid>

          <Grid item>
            <Box className={LayoutStyles.ModalLogin_Box}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                container
                className={LayoutStyles.ModalLoginPaper}
              >
                {/* ---- */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  container
                  className={LayoutStyles.ModalLogin_Header}
                  alignItems="center"
                >
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item className={LayoutStyles.LoginPortalName}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        align="center"
                        component="div"
                        className={ElementStyles.LoginText}
                      >
                        <b>Investment Portfolio</b>
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* ---- */}
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className={LayoutStyles.ModalLogin_Body}
                  container
                >
                  {/* -----------------------------------------------------------------------------*/}
                  <Grid
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    container
                    spacing={1}
                  >


                    <Grid item>
                      <Chip
                        sx={{ width: 160 }}
                        avatar={<WindowIcon className={ElementStyles.IconChips} />}
                        color="secondary"
                        label={<b>Login with OA </b>}
                        clickable
                        onClick={onLoginPass}
                      />
                    </Grid>
                  </Grid>

                  {/* --------------------------------------------------------------------------------------------------*/}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  container
                  className={LayoutStyles.ModalLogin_Footer}
                >
                  <Grid
                    container
                    item
                    justifyContent="center"
                    className={LayoutStyles.LoginDescription}
                  >
                    <Typography
                      variant="caption"
                      gutterBottom
                      component="div"
                      align="center"
                      className={ElementStyles.textLight02}
                    >
                      Powered by Bangkok Bank Innohub
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default LoginPage;
