import { useRecoilState } from 'recoil';

import { tableOrderByState, tableOrder } from '../../../../states/index';
import { CompanyTableBodyData, FundTableBodyData } from '../../../../models/index';

import {
  CompanyTableHeadComp,
  CompanyTableBodyComp,
} from './company/InvCompDefinitiveTable';
import { FundTableHeadComp, FundTableBodyComp } from './fund/InvFundDefinitiveTable';
import {
  OperationTableHeadComp,
  OperationTableBodyComp,
} from './operation/OperationTable';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import Chip from '@mui/material/Chip';

import ElementStyles from '../../../../styles/Element.module.css';

//*********************************************** TableHead Render *********************************************
export function CreateButtonRender({
  portalMode,
  createOnClick,
  isLoadedRows,
}: {
  portalMode: string;
  createOnClick: () => void;
  isLoadedRows: boolean;
}) {
  switch (portalMode) {
    case 'company_profile': {
      return (
        <Chip
          icon={<AddCircleIcon />}
          color="primary"
          className={ElementStyles.ButtonAdd}
          label={'New Company Profile'}
          onClick={createOnClick}
          clickable
          disabled={!isLoadedRows}
        />
      );
    }
    //------------------------------------//
    case 'fund_profile': {
      return (
        <Chip
          icon={<AddCircleIcon />}
          color="primary"
          className={ElementStyles.ButtonAdd}
          label={'New Fund Profile'}
          onClick={createOnClick}
          clickable
          disabled={!isLoadedRows}
        />
      );
    }

    //------------------------------------//
    case 'operation': {
      return (
        <Chip
          icon={<AddCircleIcon />}
          color="primary"
          className={ElementStyles.ButtonAdd}
          label={'New Transaction'}
          onClick={createOnClick}
          clickable
          disabled={!isLoadedRows}
        />
      );
    }

    //------------------------------------//
    default: {
      return <div>{'NotFound Create Button'}</div>;
    }
  }
}

// *********************************************** TableHead Render ***********************************************
export function TableHeadRender({
  rowHeadData,
  portalMode,
  currentSheetName,
}: {
  rowHeadData: any;
  portalMode: string;
  currentSheetName: string;
}) {
  const [order, setOrder] = useRecoilState(tableOrder);
  const [orderBy, setOrderBy] = useRecoilState(tableOrderByState);

  const handleCompanyRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof CompanyTableBodyData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFundRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof FundTableBodyData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOperationRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  switch (portalMode) {
    case 'company_profile': {
      return (
        <CompanyTableHeadComp
          order={order}
          orderBy={orderBy}
          onRequestSort={handleCompanyRequestSort}
          rowHeadData={rowHeadData}
        />
      );
    }
    //------------------------------------//
    case 'fund_profile': {
      return (
        <FundTableHeadComp
          order={order}
          orderBy={orderBy}
          onRequestSort={handleFundRequestSort}
          rowHeadData={rowHeadData}
        />
      );
    }

    //------------------------------------//
    case 'operation': {
      return (
        <OperationTableHeadComp
          order={order}
          orderBy={orderBy}
          onRequestSort={handleOperationRequestSort}
          rowHeadData={rowHeadData}
          currentSheetName={currentSheetName}
        />
      );
    }

    //------------------------------------//
    default: {
      return <div>{'NotFound TableHead'}</div>;
    }
  }
}

// *********************************************** TableBody Render ***********************************************
export function TableBodyRender({
  rowData,
  labelId,
  portalMode,
  detailModalClick,
  approvalClick,
  currentSheetName,
}: {
  rowData: any;
  labelId: any;
  portalMode: string;
  detailModalClick: any;
  approvalClick: any;
  currentSheetName: string;
}) {
  switch (portalMode) {
    case 'company_profile': {
      return (
        <CompanyTableBodyComp
          rowData={rowData}
          labelId={labelId}
          detailModalClick={detailModalClick}
          approvalClick={approvalClick}
        />
      );
    }
    //------------------------------------//
    case 'fund_profile': {
      return (
        <FundTableBodyComp
          rowData={rowData}
          labelId={labelId}
          detailModalClick={detailModalClick}
          approvalClick={approvalClick}
        />
      );
    }

    //------------------------------------//
    case 'operation': {
      return (
        <OperationTableBodyComp
          rowData={rowData}
          labelId={labelId}
          detailModalClick={detailModalClick}
          approvalClick={approvalClick}
          currentSheetName={currentSheetName}
        />
      );
    }

    //------------------------------------//
    default: {
      return <div>{'NotFound TableBody'}</div>;
    }
  }
}
