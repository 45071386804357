/*
Table Header

A1. ID / id
-----------------------

B1. Name / name
B2. Date / date
B3. Beneficiary Account Number / account_no
B4. Beneficiary Account Name / account_name
B5. Beneficiary Bank Name / account_bank_name

-------------------------
C1. Approval
C2. Detail
*/

export interface InvBankAccountBodyTableInterface {
  sheet_id: string;
  id: string;
  name: string;
  date: string;
  account_no: string;
  account_name: string;
  account_bank_name: string;
  approval: object;
  detail: string;
}

export function createInvBankAccountBodyTable(
  sheet_id: string,
  id: string,
  name: string,
  date: string,
  account_no: string,
  account_name: string,
  account_bank_name: string,
  approval: object,
  detail: string,
): InvBankAccountBodyTableInterface {
  return {
    sheet_id,
    id,
    name,
    date,
    account_no,
    account_name,
    account_bank_name,
    approval,
    detail,
  };
}

//-------------------------//
export interface InvBankAccountHeaderTableInterface {
  id: keyof InvBankAccountBodyTableInterface;
  label: string;
}
