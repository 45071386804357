import { atom } from 'recoil';
import {
  arrAsset,
  arrDistributionMethod,
  arrFund,
  arrFundStatus,
  arrHarvestingFactor,
  arrSector,
  arrYnValue,
  arrYn,
  arrFundRiskLevel,
  arrAlternative,
  arrCurrency,
  arrPort,
  arrRedeemable,
  arrAssoSubFlag,
  arrNationality,
  arrProduct,
  arrTdr,
  arrOwnership,
  arrIfrs9,
  arrHedgeCurrency,
  arrFixedThbRate,
} from '../common';

const _sheetName = 'invcompdefinitive';

//?------- Parameter Design ---------------
/*

------- Label / ID ------------
displayName = variable for display name in label.
sourceSystemName = variable for bank system name.
apiName = variable for call api. 

-------- Input ----------------
description = input placeholder.
inputLength = custom length limit of character.
inputType = (checkbox | date | radio | select | textfield) type of input.
inputClass = Custom format masking or validation.
possibleValue = (array) Value for dropdownlist / checkbox / radio.

-------- Boolean --------------
isRequire = (true/false) require field. can't be null.

isLinkValue = (true/false) Link the value from other input (linkValueId)
linkValueId = ID of input to Link data (display when isLinkValue == true).

isAutoGenerate = (true/false) auto generate value.
generateSource = source of auto generate (display when isAutoGenerate == true).

*/
/**
 textfield
 - NoLimitFree
 - noLimitCountNumber

 date
 - General

select
 - General

 */

const jsonData: { [key: string]: any } = {
  sheetName: _sheetName,
  inputInfo: [
    // ? --------------------------------- Start ---------------------------------------//
    // ---------------------------- 001 ---------------------------------//
    {
      displayName: 'BI ID',
      sourceSystemName: 'BI_ID',
      apiName: 'bi_id',

      description: 'Business ID from Investment',
      inputLength: '',
      inputType: 'select',
      inputClass: 'Autocomplete',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: true,
    },

    // ---------------------------- 001.1 ---------------------------------//
    {
      displayName: 'RM ID',
      sourceSystemName: 'RM_ID',
      apiName: 'rm_id',

      description: 'RM ID',
      inputLength: '',
      inputType: 'select',
      inputClass: 'Autocomplete',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: true,
    },

    // ---------------------------- 002 ---------------------------------//
    {
      displayName: 'Date',
      sourceSystemName: 'DATA_DATE',
      apiName: 'data_date',

      description: 'Date analysts obtain the data',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 003 ---------------------------------//
    {
      displayName: 'Fund ID',
      sourceSystemName: 'FUND_ID',
      apiName: 'fund_id',

      description: 'Fund ID',
      inputLength: '',
      inputType: 'select',
      inputClass: 'Autocomplete',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: true,
    },

    // ---------------------------- 004 ---------------------------------//
    {
      displayName: 'Registered Date',
      sourceSystemName: 'REGISTERED_DATE',
      apiName: 'registered_date',

      description: 'Registered date of the fund',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 005 ---------------------------------//
    {
      displayName: 'Registered ID',
      sourceSystemName: 'REGISTERED_ID',
      apiName: 'registered_id',

      description: 'Registered ID of the fund',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 006 ---------------------------------//
    {
      displayName: 'Fund Name',
      sourceSystemName: 'FUND_NAME',
      apiName: 'fund_name',

      description: 'Full legal name of the fund',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 007 ---------------------------------//
    {
      displayName: 'Fund Thai Name',
      sourceSystemName: 'FUND_THAINAME',
      apiName: 'fund_thainame',

      description: 'Full legal name of the fund in Thai',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 008 ---------------------------------//
    {
      displayName: 'Fund Quote',
      sourceSystemName: 'FUND_QUOTE',
      apiName: 'fund_quote',

      description: 'Short abbreviation name (internally defined)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 009 ---------------------------------//
    {
      displayName: 'Fund Status',
      sourceSystemName: 'FUND_STATUS',
      apiName: 'fund_status',

      description:
        'Whether the fund is still operating or not or we are still looking into it',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: [true, false],
      displayValue: arrFundStatus,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 010 ---------------------------------//
    {
      displayName: 'First Investment Date',
      sourceSystemName: 'FIRST_INVESTMENT_DATE',
      apiName: 'first_investment_date',

      description: 'The date BBL made the first transfer to the fund',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 011 ---------------------------------//
    {
      displayName: 'First Closing Date',
      sourceSystemName: 'FIRST_CLOSING_DATE',
      apiName: 'first_closing_date',

      description: 'Date of the fund first closing',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 012 ---------------------------------//
    {
      displayName: 'Final Closing Date',
      sourceSystemName: 'FINAL_CLOSING_DATE',
      apiName: 'final_closing_date',

      description: 'Date of the fund final closing',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 013 ---------------------------------//
    {
      displayName: 'Vintage',
      sourceSystemName: 'FUND_VINTAGE',
      apiName: 'fund_vintage',

      description: 'Year the fund started to invest',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 014 ---------------------------------//
    {
      displayName: 'AM Company',
      sourceSystemName: 'ASSET_MANAGEMENT_COMPANY',
      apiName: 'asset_management_company',

      description: 'Name of Asset Management company responsibled for the fund',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 015 ---------------------------------//
    {
      displayName: 'Fund Type',
      sourceSystemName: 'FUND_TYPE',
      apiName: 'fund_type',

      description:
        'Broad base categories with 5 groups: Private Equity Funds, Hedge Funds, Mutual Funds, Commodity Funds, Private Credit Funds',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrFund,
      displayValue: arrFund,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 016 ---------------------------------//
    {
      displayName: 'Asset Class',
      sourceSystemName: 'ASSET_CLASS',
      apiName: 'asset_class',

      description: 'PE fund type / Mutual fund type',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrAsset,
      displayValue: arrAsset,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // !---------------------------- 016.1 ---------------------------------//
    {
      displayName: 'Registered Address',
      sourceSystemName: 'REGISTERED_ADDRESS',
      apiName: 'registered_address',

      description: 'Registered address of the fund in English',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 017 ---------------------------------//
    {
      displayName: 'HO Location',
      sourceSystemName: 'HO_COUNTRY',
      apiName: 'ho_country',

      description: 'Country of the Fund HO',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 018 ---------------------------------//
    {
      displayName: 'Country of Domicile',
      sourceSystemName: 'DOMICILE_COUNTRY',
      apiName: 'domicile_country',

      description: 'Country the fund legally registered in',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 019 ---------------------------------//
    {
      displayName: 'Investment Geography',
      sourceSystemName: 'INVESTMENT_GEOGRAPHY',
      apiName: 'investment_geography',

      description:
        'MCountries the fund intended to invest according to investment mandate as stated in PPM',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 020 ---------------------------------//
    {
      displayName: 'Invested Country',
      sourceSystemName: 'INVESTED_COUNTRY',
      apiName: 'invested_country',

      description: 'Countries the company mainly operate in.',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 021 ---------------------------------//
    {
      displayName: 'Investment Period',
      sourceSystemName: 'INVESTMENT_PERIOD',
      apiName: 'investment_period',

      description: 'How long the fund is allowed to deploy money for investment purpose',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 022 ---------------------------------//
    {
      displayName: 'Fund Term',
      sourceSystemName: 'FUND_TERM',
      apiName: 'fund_term',

      description: 'How long the fund is supposed to stay operating (Years)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 023 ---------------------------------//
    {
      displayName: 'Extension Period',
      sourceSystemName: 'EXTENSION_PERIOD',
      apiName: 'extension_period',

      description:
        'The fund could ask for extension when it almost runs out of term. This states how long the fund can ask for extension and how often',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 024 ---------------------------------//
    {
      displayName: 'Management Fee Multiplier Investment Period',
      sourceSystemName: 'INVESTMENT_PERIOD_MULTIPLIER',
      apiName: 'investment_period_multiplier',

      description: 'Multiplier of manangement fee during Investment Period',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 025 ---------------------------------//
    {
      displayName: '% of Investment Period',
      sourceSystemName: 'INVESTMENT_PERIOD_PC',
      apiName: 'investment_period_pc',

      description: '% for investment period',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 026 ---------------------------------//
    {
      displayName: 'Management Fee Multiplier Harvesting Period',
      sourceSystemName: 'HARVESTING_PERIOD_MULTIPLIER',
      apiName: 'harvesting_period_multiplier',

      description: 'Multiplier of manangement fee during Harvesting Period',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrHarvestingFactor,
      displayValue: arrHarvestingFactor,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 027 ---------------------------------//
    {
      displayName: '% of Harvesting Period',
      sourceSystemName: 'HARVESTING_PERIOD_PC',
      apiName: 'harvesting_period_pc',

      description: '% for harvesting period',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 028 ---------------------------------//
    {
      displayName: 'Distribution Method',
      sourceSystemName: 'DISTRIBUTION_METHOD',
      apiName: 'distribution_method',

      description: 'Method the fund use to allocate realized investments',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrDistributionMethod,
      displayValue: arrDistributionMethod,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 029 ---------------------------------//
    {
      displayName: '% Preferred Return',
      sourceSystemName: 'PREFERRED_RETURN',
      apiName: 'preferred_return',

      description:
        'The minimum return the fund need to achieve before it can allocate profit to GP',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 030 ---------------------------------//
    {
      displayName: '% GP Catchup',
      sourceSystemName: 'GP_CATCHUP',
      apiName: 'gp_catchup',

      description:
        'After achiving preferred return, most of subsequest return will be allocated to GP to cath them up. This number shows the catch up rate',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 031 ---------------------------------//
    {
      displayName: '% Carry Interest',
      sourceSystemName: 'CARRY_INTEREST',
      apiName: 'carry_interest',

      description: 'The percentage of profit allocate to GP',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 032 ---------------------------------//
    {
      displayName: '% Hurdle Rate',
      sourceSystemName: 'HURDLE_RATE',
      apiName: 'hurdle_rate',

      description:
        'For hedge fund, this is the minimun return it need to achieve before it can take part in the profit sharing',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 033 ---------------------------------//
    {
      displayName: 'Focused Sector 1 (Main)',
      sourceSystemName: 'FOCUSED_SECTOR_1',
      apiName: 'focused_sector_1',

      description: 'Industry classified by MSCI classification',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrSector,
      displayValue: arrSector,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 034 ---------------------------------//
    {
      displayName: 'Focused Sector 2',
      sourceSystemName: 'FOCUSED_SECTOR_2',
      apiName: 'focused_sector_2',

      description: 'Industry classified by MSCI classification',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrSector,

      displayValue: arrSector,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 035 ---------------------------------//
    {
      displayName: 'Focused Sector 3',
      sourceSystemName: 'FOCUSED_SECTOR_3',
      apiName: 'focused_sector_3',

      description: 'Industry classified by MSCI classification',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrSector,

      displayValue: arrSector,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 036 ---------------------------------//
    {
      displayName: 'Focused Sector 4',
      sourceSystemName: 'FOCUSED_SECTOR_4',
      apiName: 'focused_sector_4',

      description: 'Industry classified by MSCI classification',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrSector,

      displayValue: arrSector,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 037 ---------------------------------//
    {
      displayName: 'Focused Sector 5',
      sourceSystemName: 'FOCUSED_SECTOR_5',
      apiName: 'focused_sector_5',

      description: 'Industry classified by MSCI classification',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrSector,

      displayValue: arrSector,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 038 ---------------------------------//
    {
      displayName: 'Co-Investment Right',
      sourceSystemName: 'CO_INVESTMENT_FLAG',
      apiName: 'co_investment_flag',

      description: 'Does BBL have co-investment right from the fund',
      inputLength: '',
      inputType: 'radio',
      inputClass: 'General',
      possibleValue: arrYnValue,
      displayValue: arrYn,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 039 ---------------------------------//
    {
      displayName: 'GP Exposure',
      sourceSystemName: 'GP_EXPOSURE',
      apiName: 'gp_exposure',

      description: 'Yes If BBL has exposure in GP company of the Fund directly',
      inputLength: '',
      inputType: 'radio',
      inputClass: 'General',
      possibleValue: arrYnValue,
      displayValue: arrYn,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 040 ---------------------------------//
    {
      displayName: 'Company ID 1',
      sourceSystemName: 'COMPANY_ID_1',
      apiName: 'company_id_1',

      description:
        'Show company ID If BBL has a direct investment in any portfolio company of the fund',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromOtherSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 041 ---------------------------------//
    {
      displayName: 'Company ID 2',
      sourceSystemName: 'COMPANY_ID_2',
      apiName: 'company_id_2',

      description:
        'Show company ID If BBL has a direct investment in any portfolio company of the fund',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromOtherSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 042 ---------------------------------//
    {
      displayName: 'Company ID 3',
      sourceSystemName: 'COMPANY_ID_3',
      apiName: 'company_id_3',

      description:
        'Show company ID If BBL has a direct investment in any portfolio company of the fund',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromOtherSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 043 ---------------------------------//
    {
      displayName: 'Remark',
      sourceSystemName: 'REMARK',
      apiName: 'remark',

      description: 'Remark (If any)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 044 ---------------------------------//
    {
      displayName: 'Fund Risk Level',
      sourceSystemName: 'FUND_RISK_LEVEL',
      apiName: 'fund_risk_level',

      description: 'Identify risk level for each mutual fund',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrFundRiskLevel,
      displayValue: arrFundRiskLevel,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 045 ---------------------------------//
    {
      displayName: 'Alternative Classification',
      sourceSystemName: 'ALTERNATIVE_CLASS',
      apiName: 'alternative_class',

      description:
        'Classify if this investment is either alternative or non-alternative',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: [true, false],
      displayValue: arrAlternative,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 046 ---------------------------------//
    {
      displayName: 'Fintech Classification',
      sourceSystemName: 'FINTECH_CLASS',
      apiName: 'fintech_class',

      description:
        'Classify if this investment classifies as Fintech (followed BOT reg)',
      inputLength: '',
      inputType: 'radio',
      inputClass: 'General',
      possibleValue: arrYnValue,
      displayValue: arrYn,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 047 ---------------------------------//
    {
      displayName: 'Currency Risk Flag ',
      sourceSystemName: 'CURRENCY_RISK_FLAG',
      apiName: 'currency_risk_flag',

      description: 'Currency Risk Flag (For mutual fund)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: arrYnValue,
      displayValue: arrYn,

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'fund_type',

      isAutoGenerate: true,
      isActive: false,
    },

    // !---------------------------- 048 ---------------------------------//
    // {
    //   displayName: 'Currency Risk Level',
    //   sourceSystemName: 'CURRENCY_RISK_LEVEL',
    //   apiName: 'currency_risk_level',

    //   description:
    //     'If there is currency risk flag, need to identify currency risk level from 1 to 6 (For mutual fund)',
    //   inputLength: '',
    //   inputType: 'select',
    //   inputClass: 'General',
    //   possibleValue: arrCurrencyRiskLevel,
    //   displayValue: arrCurrencyRiskLevel,

    //   isRequire: false,

    //   isLinkValue: false,
    //   linkValueId: '',

    //   isAutoGenerate: false,
    //   isActive: true,
    // },

    // ---------------------------- 049 ---------------------------------//
    {
      displayName: 'Investor Portal',
      sourceSystemName: 'INVESTOR_PORTAL',
      apiName: 'investor_portal',

      description: 'Whether the fund has investor portal or not',
      inputLength: '',
      inputType: 'radio',
      inputClass: 'General',
      possibleValue: arrYnValue,
      displayValue: arrYn,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 050 ---------------------------------//
    {
      displayName: 'Portal Site',
      sourceSystemName: 'PORTAL_SITE',
      apiName: 'portal_site',

      description: 'URL address of the portal',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 051 ---------------------------------//
    {
      displayName: 'Portal Username',
      sourceSystemName: 'PORTAL_USERNAME',
      apiName: 'portal_username',

      description: 'Username for the portal',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 052 ---------------------------------//
    {
      displayName: 'Portal Password',
      sourceSystemName: 'PORTAL_PASSWORD',
      apiName: 'portal_password',

      description: 'Password for the portal',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 053 ---------------------------------//
    {
      displayName: 'Portal Question',
      sourceSystemName: 'PORTAL_QUESTION',
      apiName: 'portal_question',

      description: 'Security question for the portal',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 054 ---------------------------------//
    {
      displayName: 'Portal Answer',
      sourceSystemName: 'PORTAL_ANSWER',
      apiName: 'portal_answer',

      description: 'Answer of security question for the portal',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 055 ---------------------------------//
    {
      displayName: 'BBL Internal Acccount Code AC',
      sourceSystemName: 'INTERNAL_ACCTCODE',
      apiName: 'internal_acctcode',

      description:
        'BBL Internal account code for accounting purpose when there is a transaction occurred',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 056 ---------------------------------//
    {
      displayName: 'Account Description AC',
      sourceSystemName: 'INTERNAL_ACCDES',
      apiName: 'internal_accdes',

      description: 'Description of BBL Internal Account Code',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 057 ---------------------------------//
    {
      displayName: 'BBL Internal Product Code AC',
      sourceSystemName: 'INTERNAL_PROCODE',
      apiName: 'internal_procode',

      description:
        'BBL Internal product code for accounting purpose gen from Issuer Nationality, Listed Status, Product Type, Funds Type, Ownership Status',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 058 ---------------------------------//
    {
      displayName: 'Product Description AC',
      sourceSystemName: 'INTERNAL_PRODES',
      apiName: 'internal_prodes',

      description: 'Description of BBL Internal Product Code',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 059 ---------------------------------//
    {
      displayName: 'Currency AC',
      sourceSystemName: 'CURRENCY',
      apiName: 'currency',

      description: 'Denominated Currency',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrCurrency,
      displayValue: arrCurrency,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 060 ---------------------------------//
    {
      displayName: 'Valuation Method',
      sourceSystemName: 'VALUATION_METHOD',
      apiName: 'valuation_method',

      description: 'Method for BBL valuation',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 061 ---------------------------------//
    {
      displayName: 'Portfolio Classification AC',
      sourceSystemName: 'INVESTMENT_PORT',
      apiName: 'investment_port',

      description: 'BBL internal accounting record for accounting purpose',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrPort,
      displayValue: arrPort,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 062 ---------------------------------//
    {
      displayName: 'Redeemable AC',
      sourceSystemName: 'REDEEMABLE',
      apiName: 'redeemable',

      description: 'Whether the fund could be redeemable or not',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrRedeemable,
      displayValue: arrRedeemable,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 063 ---------------------------------//
    {
      displayName: 'Asso/Sub Flag',
      sourceSystemName: 'ASSO_SUB_FLAG',
      apiName: 'asso_sub_flag',

      description: 'Asso/Sub Flag',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrAssoSubFlag,
      displayValue: arrAssoSubFlag,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 064 ---------------------------------//
    {
      displayName: 'Issue Location AC',
      sourceSystemName: 'ISSUE_LOCATION',
      apiName: 'issue_location',

      description: 'Classify a nationality of issue location for accounting purpose',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrNationality,
      displayValue: arrNationality,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 065 ---------------------------------//
    {
      displayName: 'Issuer Nationality AC',
      sourceSystemName: 'ISSUER_NATIONALITY',
      apiName: 'issuer_nationality',

      description: 'Classify a nationality of the company for accounting purpose',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrNationality,
      displayValue: arrNationality,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 066 ---------------------------------//
    {
      displayName: 'Listed Status AC',
      sourceSystemName: 'LISTED_COMPANY_FLAG',
      apiName: 'listed_company_flag',

      description: 'Whether the company is listed in stock markets',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 067 ---------------------------------//
    {
      displayName: 'Product Type AC',
      sourceSystemName: 'PRODUCT_TYPE',
      apiName: 'product_type',

      description: 'Product classification for accounting purpose',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrProduct,
      displayValue: arrProduct,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 068 ---------------------------------//
    {
      displayName: 'Type of Investment AC',
      sourceSystemName: 'INVESTMENT_TYPE',
      apiName: 'investment_type',

      description:
        'Classify if the company is recorded under either TDR or non-TDR for accouting purpose',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrTdr,
      displayValue: arrTdr,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 069 ---------------------------------//
    {
      displayName: 'Intra Group Code AC',
      sourceSystemName: 'INFRA_GROUP_CODE',
      apiName: 'intra_group_code',

      description: 'Intra Group Code for subsidiary company',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },
    // !---------------------------- 069.1 ---------------------------------//
    {
      displayName: 'BOT Industrial Classification AC',
      sourceSystemName: 'ISIC_CODE',
      apiName: 'isic_code',

      description: '',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // !---------------------------- 069.2 ---------------------------------//
    {
      displayName: 'BOT Exclusion Flag AC',
      sourceSystemName: 'BOT_EXCLUSION_FLAG',
      apiName: 'bot_exclusion_flag',

      description: '',
      inputLength: '',
      inputType: 'radio',
      inputClass: 'General',
      possibleValue: arrYnValue,
      displayValue: arrYn,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 070 ---------------------------------//
    {
      displayName: 'Ownership Type AC',
      sourceSystemName: 'OWNERSHIP_TYPE',
      apiName: 'ownership_type',

      description:
        'Can be either Direct (Directly owned by BBL). or Indirect (Held by subsidiary/branch)',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrOwnership,
      displayValue: arrOwnership,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 071 ---------------------------------//
    {
      displayName: 'Trade to Subsidiary AC',
      sourceSystemName: 'TRADED_TO_SUBSIDIARY',
      apiName: 'traded_to_subsidiary',

      description: 'Whether the company is traded to subsidiary',
      inputLength: '',
      inputType: 'radio',
      inputClass: 'General',
      possibleValue: arrYnValue,
      displayValue: arrYn,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 072 ---------------------------------//
    {
      displayName: 'IFRS9 Instrument AC',
      sourceSystemName: 'IFRS9_INSTRUMENT',
      apiName: 'ifrs9_instrument',

      description: 'Define to be Equity or Debt instrument by IFRS9 method',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrIfrs9,
      displayValue: arrIfrs9,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },
    // ---------------------------- 073 ---------------------------------//
    {
      displayName: 'Currency Hedging AC',
      sourceSystemName: 'HEDGE_CURRENCY',
      apiName: 'hedge_currency',

      description: 'Hedging currency for Accounting',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrHedgeCurrency,
      displayValue: arrHedgeCurrency,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 074 ---------------------------------//
    {
      displayName: 'Fixed Rate AC',
      sourceSystemName: 'FIXED_RATE_THB_FLAG',
      apiName: 'fixed_rate_thb_flag',

      description: 'Fixed FX rate BOT or Non-BOT',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrFixedThbRate,
      displayValue: arrFixedThbRate,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ? --------------------------------- End ---------------------------------------//
  ],
};

export const invfunddefinitive = atom({
  key: 'invfunddefinitive',
  default: jsonData,
});
