import React from 'react';
import { RecoilRoot } from 'recoil';
//@ts-ignore
import ReactDOM from 'react-dom';
//@ts-ignore
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//Style
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './styles/globals.css';

//Page
import LoginPage from './pages/LoginPage';
import InvestmentPortPage from './pages/InvestmentPortPage';
import NotFoundPage from './pages/NotFoundPage';
import UnderconstructionPage from './pages/UnderconstructionPage';

//Custom
import PoweredByInnohub from './pages/components/common/logo/PoweredByInnohub';

//Other
import reportWebVitals from './reportWebVitals';

let theme = createTheme({
  palette: {
    primary: {
      light: '#8296d9',
      main: '#2140a6',
      dark: '#001b73',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff9e55',
      main: '#ff6e00',
      dark: '#aa4900',
      contrastText: '#fff',
    },

    info: {
      light: '#8296d9',
      main: '#c2cdf2',
      dark: '#f2f5ff',
      contrastText: '#2140a6',
    },

    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#1b5e20',
      contrastText: '#fff',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#2140a6',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255,255,255,0.15)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#4fc3f7',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: theme.spacing(2),
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

ReactDOM.render(
  <RecoilRoot>
    <React.StrictMode>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Router>
          <PoweredByInnohub logoClassName="PoweredByInnohub" />
          <Switch>
            {/* ------------------------ */}
            <Route exact path="/">
              <LoginPage />
            </Route>

            {/* ------------------------ */}
            <Route exact path="/investent_portfolio">
              <InvestmentPortPage />
            </Route>
            {/* ------------------------ */}
            <Route exact path="/under_construction">
              <UnderconstructionPage />
            </Route>
            {/* ------------------------ */}
            <Route>
              <NotFoundPage />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </React.StrictMode>
  </RecoilRoot>,
  document.getElementById('root'),
);

reportWebVitals();
