import { atom } from 'recoil';
import {
  companyRows,
  companyHeaders,
  fundRows,
  fundHeaders,
  InvBankAccountRows,
  InvBankAccountHeaders,
} from './mockup_table';

//----- Common ----------
import {
  createHistoryTableBodyData,
  HistoryTableHeadData,
  createSubProfileTableBodyData,
  SubProfileTableHeadData,
  createPreviouslyTableBodyData,
  PreviouslyTableHeadData,
  Order,
} from '../models/index';

//----------------------------------------------------//

export const first_page_length = 420;
export const first_page = 1;


export const tableOrderByState = atom({
  key: 'tableOrderBy',
  default: '',
});

export const tableOrder = atom<Order>({
  key: 'tableOrder',
  default: 'asc',
});

export const isLoadingTableState = atom({
  key: 'isLoadingTable',
  default: false,
});

// ****************************************************************************************
// ******************************** Main Table ********************************************
// ****************************************************************************************

//?----------------------- Company Table ------------------------

export const companyRowsState = atom({
  key: 'companyRowsState',
  default: companyRows,
});

export const companyHeadersState = atom({
  key: 'companyHeaderState',
  default: companyHeaders,
});

//?----------------------- Fund Table -------------------------

export const fundRowsState = atom({
  key: 'fundRowsState',
  default: fundRows,
});

export const fundHeadersState = atom({
  key: 'fundHeaderState',
  default: fundHeaders,
});

//?----------------------- Operation Table Example -------------------------

export const operationRowsState = atom({
  key: 'operationRowsState',
  default: InvBankAccountRows,
});

export const operationHeadersState = atom({
  key: 'operationHeadersState',
  default: InvBankAccountHeaders,
});

// ****************************************************************************************
// ******************************** Sub Table ********************************************
// ****************************************************************************************

//?----------------------------------- SubProfile Table Example ---------------------------

export const subProfileRowsMockUp = [
  createSubProfileTableBodyData(
    'approval_status',
    'approve_date',
    'approver',
    'edit_date',
    'editor',
    {},
    'sheet_id',
    'sheet_name',
    'detail',
  ),
];

const subProfileHeaders: SubProfileTableHeadData[] = [
  {
    id: 'sheet_name',
    label: 'name',
  },
  {
    id: 'edit_date',
    label: 'Last Edited Date',
  },
  {
    id: 'approval_status',
    label: 'Approval',
  },
  {
    id: 'detail',
    label: 'Detail',
  },
];

//----------------------------------------------------//

export const subProfileRowsState = atom({
  key: 'SubProfileRowsState',
  default: subProfileRowsMockUp,
});

export const subProfileHeadersState = atom({
  key: 'SubProfileHeadersState',
  default: subProfileHeaders,
});

//?----------------------------------- History Table Example ---------------------------

const historyRows = [
  createHistoryTableBodyData(
    true,
    'ASSDF',
    '05 Oct 2021 14:42',
    'Bualuang Jaidee',
    'detail button',
  ),
  createHistoryTableBodyData(
    false,
    'ASSDF',
    '04 Oct 2021 13:10',
    'Bualuang Jaidee',
    'detail button',
  ),
  createHistoryTableBodyData(
    false,
    'ASSDF',
    '03 Oct 2021 12:20',
    'Bualuang Jaidee',
    'detail button',
  ),
  createHistoryTableBodyData(
    false,
    'ASSDF',
    '02 Oct 2021 11:10',
    'Bualuang Jaidee',
    'detail button',
  ),
  createHistoryTableBodyData(
    false,
    'ASSDF',
    '01 Oct 2021 10:10',
    'Bualuang Jaidee',
    'detail button',
  ),
  createHistoryTableBodyData(
    false,
    'ASSDF',
    '29 Sep 2021 00:55',
    'Bualuang Jaidee',
    'detail button',
  ),
];

const historyHeaders: HistoryTableHeadData[] = [
  {
    id: 'current',
    label: '',
  },

  {
    id: 'last_edited_date',
    label: 'Last Edited Date',
  },
  {
    id: 'approval',
    label: 'Approval By',
  },
  {
    id: 'detail',
    label: 'Detail',
  },
];
//----------------------------------------------------//

export const historyRowsState = atom({
  key: 'historyRowsState',
  default: historyRows,
});

export const historyHeadersState = atom({
  key: 'historyHeadersState',
  default: historyHeaders,
});

//?----------------------------------- Previously Table Example ---------------------------

const previouslyRows = [
  createPreviouslyTableBodyData(
    true,
    '05 Oct 2021 14:42',
    'Bualuang Jaidee',
    'detail button',
  ),
  createPreviouslyTableBodyData(
    false,
    '05 Sep 2021 14:42',
    'Bualuang Jaidee',
    'detail button',
  ),
  createPreviouslyTableBodyData(
    false,
    '05 Aug 2021 14:42',
    'Bualuang Jaidee',
    'detail button',
  ),
  createPreviouslyTableBodyData(
    false,
    '05 Jul 2021 14:42',
    'Bualuang Jaidee',
    'detail button',
  ),
  createPreviouslyTableBodyData(
    false,
    '05 Jun 2021 14:42',
    'Bualuang Jaidee',
    'detail button',
  ),
  createPreviouslyTableBodyData(
    false,
    '05 Apr 2021 14:42',
    'Bualuang Jaidee',
    'detail button',
  ),
  createPreviouslyTableBodyData(
    false,
    '05 Mar 2021 14:42',
    'Bualuang Jaidee',
    'detail button',
  ),
];

const previouslyHeaders: PreviouslyTableHeadData[] = [
  {
    id: 'current',
    label: '',
  },
  {
    id: 'approved_date',
    label: 'Last Edited Date',
  },
  {
    id: 'approved_by',
    label: 'Approved by',
  },
  {
    id: 'detail',
    label: 'Detail',
  },
];

//----------------------------------------------------//

export const previouslyRowsState = atom({
  key: 'previouslyRowsState',
  default: previouslyRows,
});

export const previouslyHeadersState = atom({
  key: 'previouslyHeadersState',
  default: previouslyHeaders,
});
