import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';

//Chips
import Chip from '@mui/material/Chip';
import ElementStyles from '../../../../styles/Element.module.css';
import LayoutStyles from '../../../../styles/Layout.module.css';

//State
import {
  isSuccessModal,
  isCreateModal,
  isMainDetailModal,
  isDetailModal,
} from '../../../../states/index';

//Common
import { getContact, getMemo } from '../../../api/index';

import {
  requestContactMainInterface,
  requestMemoMainInterface,
} from '../../../../models/index';

//Company
import { getDetailCompany, getProduct, getFs } from '../../../api/index';
import {
  requestProductMainInterface,
  requestFsMainInterface,
  OrderBy,
} from '../../../../models/index';

//fund
import {
  getDetailFund,
  getNav,
  getPort,
  getBenchmark,
  getCommitment,
} from '../../../api/index';

import {
  requestNavMainInterface,
  requestPortMainInterface,
  requestBenchmarkMainInterface,
  requestCommitmentMainInterface,
} from '../../../../models/index';

import { requestDetailInterface } from '../../../../models/index';

import {
  tableConfigState,
  currentIsEmptyState,
  subProfileRowsState,
  currentInputJSONState,
  currentapprovalJSONState,
  currentSubSheetapprovalJSONState,
  currentRowsLengthState,
  currentSearchKeywordState,
  isCallingAPIState,
  portalStatusState,
  currentSheetNameState,
  currentIdState,
  currentFillFormStepState,
  isSubmitStepState,
  isFirstPageStepState,
} from '../../../../states/index';

//Common State
import { invboardapprovedmemo, invcontact } from '../../../../states/index';

//Company State
import { invcompdefinitive, invcompproduct, invcompfs } from '../../../../states/index';

//Fund State
import {
  invfunddefinitive,
  invfundportfolio,
  invfundnav,
  invfundbenchmark,
  invfundcommitmentandwatermark,
} from '../../../../states/index';

import CheckIcon from '../logo/CheckIcon';

function TypoHeader({ _caseName }: { _caseName: string }) {
  // console.log(_caseName);
  switch (_caseName) {
    case 'cancel':
      return <>{'Delete Successfully'}</>;
    case 'approve':
      return <>{'Approve Successfully'}</>;
    case 'create':
      return <>{'Submit Successfully'}</>;
    case 'submit':
      return <>{'Submit Successfully'}</>;
    case 'edit_form':
      return <>{'Edit Successfully'}</>;
    case 'edit':
      return <>{'Edit Successfully'}</>;
    default:
      return <div></div>;
  }
}

function TypoDescription({
  _caseName,
  _objectName,
  _sheetName,
}: {
  _caseName: string;
  _objectName: string;
  _sheetName: string;
}) {
  switch (_caseName) {
    case 'cancel':
      return <>{'"' + _sheetName + '" in "' + _objectName + '" was deleted.'}</>;
    case 'approve':
      return <>{'"' + _sheetName + '" in "' + _objectName + '" was approved.'}</>;
    case 'create':
      return (
        <>
          {'"' +
            _sheetName +
            '" was submitted. Please wait for approval to create sheet.'}
        </>
      );
    case 'submit':
      return (
        <>
          {'"' +
            _sheetName +
            '" in "' +
            _objectName +
            '" was submitted. Please wait for approval.'}
        </>
      );
    case 'edit':
      return (
        <>
          {'"' +
            _sheetName +
            '" in "' +
            _objectName +
            '" was edited. Please wait for approval.'}
        </>
      );
    case 'edit_form':
      return (
        <>
          {'"' +
            _sheetName +
            '" in "' +
            _objectName +
            '" was edited. Please wait for approval.'}
        </>
      );
    default:
      return <div></div>;
  }
}

export default function SuccessModal({
  caseName,
  sheetName,
  objectName,
  displaySheetName,
  onReloadData,
}: {
  caseName: string;
  sheetName: string;
  objectName: string;
  displaySheetName: string;
  onReloadData: (
    _currentSystemSheetName: string,
    _sheet_id: string,
    _rowsLengthTotal: number,
    _currentSearchKeyword: string,
    _currentSortBy: string,
  ) => void;
}) {
  const setCurrentIsEmpty = useSetRecoilState(currentIsEmptyState);
  const [currentInputJSON, setCurrentInputJSON] = useRecoilState(currentInputJSONState);
  const currentapprovalJSON = useRecoilValue(currentapprovalJSONState);

  const [subProfileRows, setSubProfileRows] = useRecoilState(subProfileRowsState);

  const [isUpdatedData, setIsUpdatedData] = useState(true);

  const setIsCallingAPI = useSetRecoilState(isCallingAPIState);
  const currentRowsLength = useRecoilValue(currentRowsLengthState);
  const currentSearchKeyword = useRecoilValue(currentSearchKeywordState);

  const [openSuccessModal, setOpenSuccessModal] = useRecoilState(isSuccessModal);
  const setOpenCreateModal = useSetRecoilState(isCreateModal);
  const setOpenMainDetailModal = useSetRecoilState(isMainDetailModal);
  const setOpenDetailModal = useSetRecoilState(isDetailModal);

  const currentSheetName = useRecoilValue(currentSheetNameState);
  const currentId: { [key: string]: any } = useRecoilValue(currentIdState);

  //---------------------//
  const setPage = useSetRecoilState(currentFillFormStepState);
  const setIsSubmit = useSetRecoilState(isSubmitStepState);
  const setIsFirstPage = useSetRecoilState(isFirstPageStepState);

  const portalStatus = useRecoilValue(portalStatusState);
  const [currentSubSheetapprovalJSON, setCurrentSubSheetapprovalJSON] = useRecoilState(
    currentSubSheetapprovalJSONState,
  );

  //Company Sheet
  const invcompdefinitiveData = useRecoilValue(invcompdefinitive);
  const invcompproductData = useRecoilValue(invcompproduct);
  const invcompfsData = useRecoilValue(invcompfs);

  //Fund Sheet
  const invfunddefinitiveData = useRecoilValue(invfunddefinitive);
  const invfundportfolioData = useRecoilValue(invfundportfolio);
  const invfundnavData = useRecoilValue(invfundnav);
  const invfundbenchmarkData = useRecoilValue(invfundbenchmark);
  const invfundcommitmentandwatermarkData = useRecoilValue(
    invfundcommitmentandwatermark,
  );

  //Common Sheet
  const invboardapprovedmemoData = useRecoilValue(invboardapprovedmemo);

  const invcontactData = useRecoilValue(invcontact);

  const tableConfig = useRecoilValue(tableConfigState);

  const reloadSubTable = (currentSheetName: string) => async () => {
    let _currentJSON: { [key: string]: any } = {
      inputJSON: {},
      subProfileJSON: {},
    };

    let sheet_id_str = currentId['main_sheet_id'].toString();

    let requestCompanyDetail: requestDetailInterface = {
      sheet_id: sheet_id_str,
    };

    let companyDetail: { [key: string]: any } = await getDetailCompany(
      requestCompanyDetail,
    );
    _currentJSON = {
      inputJSON: invcompdefinitiveData,
      subProfileJSON: companyDetail['data'],
    };

    switch (currentSheetName) {
      //?-------------------- Common -------------------- */

      //----------------- 01 ---------------- */
      case 'invcontact':
        let requestContact: requestContactMainInterface = {
          sheet_id: sheet_id_str,
          page: 1,
          page_length: tableConfig['defaultRowsLength'],
          sort_by: '',
          order: OrderBy.asc,
          filter: '',
        };

        let contactList: { [key: string]: any } = await getContact(requestContact);
        // console.log(contactList);

        _currentJSON = {
          inputJSON: invcontactData,
          subProfileJSON: contactList['data'],
        };

        break;

      //---------------- 02 ----------------- */
      case 'invboardapprovedmemo':
        let requestMemo: requestMemoMainInterface = {
          sheet_id: sheet_id_str,
          page: 1,
          page_length: tableConfig['defaultRowsLength'],
          sort_by: '',
          order: OrderBy.asc,
          filter: '',
        };

        let MemoList: { [key: string]: any } = await getMemo(requestMemo);

        // console.log(MemoList);
        _currentJSON = {
          inputJSON: invboardapprovedmemoData,
          subProfileJSON: MemoList['data'],
        };
        break;

      //?-------------------- Company -------------------- */
      //---------------- 01 ----------------- */
      case 'invcompdefinitive':
        let requestCompanyDetail: requestDetailInterface = {
          sheet_id: sheet_id_str,
        };

        let getCompanyDetail: { [key: string]: any } = await getDetailCompany(
          requestCompanyDetail,
        );

        _currentJSON = {
          inputJSON: invcompdefinitiveData,
          subProfileJSON: getCompanyDetail['data'],
        };
        break;
      //---------------- 02 ----------------- */
      case 'invcompproduct':
        let requestProduct: requestProductMainInterface = {
          sheet_id: sheet_id_str,
          page: 1,
          page_length: tableConfig['defaultRowsLength'],
          sort_by: '',
          order: OrderBy.asc,
          filter: '',
        };

        let ProductList: { [key: string]: any } = await getProduct(requestProduct);
        _currentJSON = {
          inputJSON: invcompproductData,
          subProfileJSON: ProductList['data'],
        };
        break;
      //------------------ 03 --------------- */
      case 'invcompfs':
        let requestFs: requestFsMainInterface = {
          sheet_id: sheet_id_str,
          page: 1,
          page_length: tableConfig['defaultRowsLength'],
          sort_by: '',
          order: OrderBy.asc,
          filter: '',
        };

        let fsList: { [key: string]: any } = await getFs(requestFs);
        // console.log(fsList);
        _currentJSON = {
          inputJSON: invcompfsData,
          subProfileJSON: fsList['data'],
        };
        break;
      //--------------------------------- */

      //?-------------------- Fund -------------------- */
      //----------------- 01 ---------------- */
      case 'invfunddefinitive':
        let requestFundDetail: requestDetailInterface = {
          sheet_id: sheet_id_str,
        };

        let getFundDetail: { [key: string]: any } = await getDetailFund(
          requestFundDetail,
        );

        _currentJSON = {
          inputJSON: invfunddefinitiveData,
          subProfileJSON: getFundDetail['data'],
        };
        break;
      //----------------- 02 ---------------- */
      case 'invfundnav':
        let requestNav: requestNavMainInterface = {
          sheet_id: sheet_id_str,
          page: 1,
          page_length: tableConfig['defaultRowsLength'],
          sort_by: '',
          order: OrderBy.asc,
          filter: '',
        };

        let navList: { [key: string]: any } = await getNav(requestNav);
        // console.log(_currentJSON);

        _currentJSON = {
          inputJSON: invfundnavData,
          subProfileJSON: navList['data'],
        };
        break;
      //----------------- 03 ---------------- */
      case 'invfundportfolio':
        let requestPort: requestPortMainInterface = {
          sheet_id: sheet_id_str,
          page: 1,
          page_length: tableConfig['defaultRowsLength'],
          sort_by: '',
          order: OrderBy.asc,
          filter: '',
        };

        let portList: { [key: string]: any } = await getPort(requestPort);

        _currentJSON = {
          inputJSON: invfundportfolioData,
          subProfileJSON: portList['data'],
        };
        break;
      //----------------- 04 ---------------- */
      case 'invfundbenchmark':
        let requestBenchmark: requestBenchmarkMainInterface = {
          sheet_id: sheet_id_str,
          page: 1,
          page_length: tableConfig['defaultRowsLength'],
          sort_by: '',
          order: OrderBy.asc,
          filter: '',
        };

        let benchmarkList: { [key: string]: any } = await getBenchmark(requestBenchmark);

        _currentJSON = {
          inputJSON: invfundbenchmarkData,
          subProfileJSON: benchmarkList['data'],
        };
        break;
      //---------------- 05 ----------------- */
      case 'invfundcommitmentandwatermark':
        let requestCommitment: requestCommitmentMainInterface = {
          sheet_id: sheet_id_str,
          page: 1,
          page_length: tableConfig['defaultRowsLength'],
          sort_by: '',
          order: OrderBy.asc,
          filter: '',
        };

        let commitmentList: { [key: string]: any } = await getCommitment(
          requestCommitment,
        );

        _currentJSON = {
          inputJSON: invfundcommitmentandwatermarkData,
          subProfileJSON: commitmentList['data'],
        };
        break;

      //************** NotFound ********************* */
      //------------------------------------//
      default:
        break;
    }
    setCurrentInputJSON(_currentJSON['inputJSON']);
    setSubProfileRows(_currentJSON['subProfileJSON']);
    setIsCallingAPI(false);
    setIsUpdatedData(false);
  };

  //!------------------------ Hot Fix: Check Button Menu Status ---------------------------

  const reloadButtonMenuStatus = (_portalStatus: string) => async () => {
    console.log('reload');
    let sheet_id_str = currentId['main_sheet_id'].toString();

    //Common Request

    let requestMemo: requestMemoMainInterface = {
      sheet_id: sheet_id_str,
      page: 1,
      page_length: tableConfig['defaultRowsLength'],
      sort_by: '',
      order: OrderBy.asc,
      filter: '',
    };

    let requestContact: requestContactMainInterface = {
      sheet_id: sheet_id_str,
      page: 1,
      page_length: tableConfig['defaultRowsLength'],
      sort_by: '',
      order: OrderBy.asc,
      filter: '',
    };

    if (_portalStatus === 'company_profile') {
      let requestCompanyDetail: requestDetailInterface = {
        sheet_id: sheet_id_str,
      };

      let requestProduct: requestProductMainInterface = {
        sheet_id: sheet_id_str,
        page: 1,
        page_length: tableConfig['defaultRowsLength'],
        sort_by: '',
        order: OrderBy.asc,
        filter: '',
      };

      let requestFs: requestFsMainInterface = {
        sheet_id: sheet_id_str,
        page: 1,
        page_length: tableConfig['defaultRowsLength'],
        sort_by: '',
        order: OrderBy.asc,
        filter: '',
      };

      const compnayButonMenuStatus: { [key: string]: any } = await Promise.all([
        getDetailCompany(requestCompanyDetail),
        getProduct(requestProduct),
        getFs(requestFs),
        getContact(requestContact),
        getMemo(requestMemo),
      ]);

      console.log(compnayButonMenuStatus);
      setIsCallingAPI(false);
    } else if (_portalStatus === 'fund_profile') {
      let requestFundDetail: requestDetailInterface = {
        sheet_id: sheet_id_str,
      };

      let requestNav: requestNavMainInterface = {
        sheet_id: sheet_id_str,
        page: 1,
        page_length: tableConfig['defaultRowsLength'],
        sort_by: '',
        order: OrderBy.asc,
        filter: '',
      };

      let requestPort: requestPortMainInterface = {
        sheet_id: sheet_id_str,
        page: 1,
        page_length: tableConfig['defaultRowsLength'],
        sort_by: '',
        order: OrderBy.asc,
        filter: '',
      };

      let requestBenchmark: requestBenchmarkMainInterface = {
        sheet_id: sheet_id_str,
        page: 1,
        page_length: tableConfig['defaultRowsLength'],
        sort_by: '',
        order: OrderBy.asc,
        filter: '',
      };

      let requestCommitment: requestCommitmentMainInterface = {
        sheet_id: sheet_id_str,
        page: 1,
        page_length: tableConfig['defaultRowsLength'],
        sort_by: '',
        order: OrderBy.asc,
        filter: '',
      };

      const fundButonMenuStatus: { [key: string]: any } = await Promise.all([
        getDetailFund(requestFundDetail),
        getNav(requestNav),
        getPort(requestPort),
        getBenchmark(requestBenchmark),
        getCommitment(requestCommitment),
        getContact(requestContact),
        getMemo(requestMemo),
      ]);

      console.log(fundButonMenuStatus);
      setIsCallingAPI(false);
    }
  };

  //!--------------------------------------------------------------------------------

  const handleClose = () => {
    setIsCallingAPI(true);

    if (caseName === 'create') {
      setCurrentIsEmpty(false);
    }

    if (currentSheetName === 'invcompdefinitive' && caseName === 'create') {
      setIsCallingAPI(false);
    } else if (currentSheetName === 'invfunddefinitive' && caseName === 'create') {
      setIsCallingAPI(false);
    } else {
      let requestDetail: requestDetailInterface = {
        sheet_id: currentId['main_sheet_id'].toString(),
      };

      //?----------------------------- Real Code ------------------------------------
      if (portalStatus === 'company_profile') {
        const getCompanyDetail = async () => {
          const response: { [key: string]: any } = await getDetailCompany(requestDetail);
          // console.log(response['data']['edition']['approval_status']);
          setCurrentSubSheetapprovalJSON(response['data']['edition']['approval_status']);
        };

        getCompanyDetail();
      } else if (portalStatus === 'fund_profile') {
        const getFundDetail = async () => {
          const response: { [key: string]: any } = await getDetailFund(requestDetail);
          // console.log(response['data']['edition']['approval_status']);
          setCurrentSubSheetapprovalJSON(response['data']['edition']['approval_status']);
        };
        getFundDetail();
      }

      //?---------------------------------------------------------------------------------

      //!----------------------------- Hotfix Code: Check Status------------------------------------

      // let sheet_id_str = currentId['main_sheet_id'].toString();

      // //Common Request

      // let requestMemo: requestMemoMainInterface = {
      //   sheet_id: sheet_id_str,
      //   page: 1,
      //   page_length: tableConfig['defaultRowsLength'],
      //   sort_by: '',
      //   order: OrderBy.asc,
      //   filter: '',
      // };

      // let requestContact: requestContactMainInterface = {
      //   sheet_id: sheet_id_str,
      //   page: 1,
      //   page_length: tableConfig['defaultRowsLength'],
      //   sort_by: '',
      //   order: OrderBy.asc,
      //   filter: '',
      // };

      // if (portalStatus === 'company_profile') {
      //   let requestProduct: requestProductMainInterface = {
      //     sheet_id: sheet_id_str,
      //     page: 1,
      //     page_length: tableConfig['defaultRowsLength'],
      //     sort_by: '',
      //     order: OrderBy.asc,
      //     filter: '',
      //   };

      //   let requestFs: requestFsMainInterface = {
      //     sheet_id: sheet_id_str,
      //     page: 1,
      //     page_length: tableConfig['defaultRowsLength'],
      //     sort_by: '',
      //     order: OrderBy.asc,
      //     filter: '',
      //   };

      //   const getCompanyButonMenuStatus = async () => {
      //     const companyButonMenuStatus: { [key: string]: any } = await Promise.all([
      //       getDetailCompany(requestDetail),
      //       getProduct(requestProduct),
      //       getFs(requestFs),
      //       getContact(requestContact),
      //       getMemo(requestMemo),
      //     ]);

      //     return companyButonMenuStatus;
      //   };

      //   getCompanyButonMenuStatus();

      //   setIsCallingAPI(false);
      // } else if (portalStatus === 'fund_profile') {
      //   let requestNav: requestNavMainInterface = {
      //     sheet_id: sheet_id_str,
      //     page: 1,
      //     page_length: tableConfig['defaultRowsLength'],
      //     sort_by: '',
      //     order: OrderBy.asc,
      //     filter: '',
      //   };

      //   let requestPort: requestPortMainInterface = {
      //     sheet_id: sheet_id_str,
      //     page: 1,
      //     page_length: tableConfig['defaultRowsLength'],
      //     sort_by: '',
      //     order: OrderBy.asc,
      //     filter: '',
      //   };

      //   let requestBenchmark: requestBenchmarkMainInterface = {
      //     sheet_id: sheet_id_str,
      //     page: 1,
      //     page_length: tableConfig['defaultRowsLength'],
      //     sort_by: '',
      //     order: OrderBy.asc,
      //     filter: '',
      //   };

      //   let requestCommitment: requestCommitmentMainInterface = {
      //     sheet_id: sheet_id_str,
      //     page: 1,
      //     page_length: tableConfig['defaultRowsLength'],
      //     sort_by: '',
      //     order: OrderBy.asc,
      //     filter: '',
      //   };
      //   const getFundButonMenuStatus = async () => {
      //     const fundButonMenuStatus: { [key: string]: any } = await Promise.all([
      //       getDetailFund(requestDetail),
      //       getNav(requestNav),
      //       getPort(requestPort),
      //       getBenchmark(requestBenchmark),
      //       getCommitment(requestCommitment),
      //       getContact(requestContact),
      //       getMemo(requestMemo),
      //     ]);
      //     console.log(fundButonMenuStatus);

      //     return fundButonMenuStatus;
      //   };

      //   getFundButonMenuStatus();
      //   setIsCallingAPI(false);
      // }

      //!---------------------------------------------------------------------------------------------
    }

    //---------------------------------------------------

    if (
      currentSheetName === 'invfunddefinitive' ||
      currentSheetName === 'invcompdefinitive'
    ) {
      setOpenMainDetailModal(false);
    } else {
      setOpenDetailModal(false);
    }
    //----------------------------------//

    onReloadData(
      currentSheetName,
      currentId['main_sheet_id'].toString(),
      currentRowsLength,
      currentSearchKeyword,
      '',
    );

    reloadSubTable(currentSheetName);

    setIsSubmit(false);
    setIsFirstPage(true);
    setPage(1);
    setOpenSuccessModal(false);
    setOpenCreateModal(false);
  };

  return (
    <div>
      <Modal open={openSuccessModal} className={LayoutStyles.ModalBackgroundFade}>
        <Box className={LayoutStyles.ModalM_Box}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            container
            className={LayoutStyles.ModalPaper}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              container
              className={LayoutStyles.ModalM_Header}
              alignItems="center"
            >
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <Typography color="primary" variant="h6" gutterBottom component="div">
                    <TypoHeader _caseName={caseName} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={LayoutStyles.ModalM_Body}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {/* -------------------------------------------- Row 2/1   ---------------------------------------*/}

              <Grid item>
                <CheckIcon />
              </Grid>

              <Grid item>
                <Typography variant="subtitle2" align="center" component="div">
                  <TypoDescription
                    _caseName={caseName}
                    _sheetName={displaySheetName}
                    _objectName={currentId['main_name']}
                  />
                </Typography>
              </Grid>

              {/* --------------------------------------------------------------------------------------------------*/}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              container
              className={LayoutStyles.ModalM_Footer}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                direction="row"
                justifyContent="center"
                alignItems="center"
                container
              >
                <Chip
                  sx={{ width: 120 }}
                  color="primary"
                  className={ElementStyles.ButtonModalCenter}
                  label="Done"
                  onClick={handleClose}
                  clickable
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
