export enum Contact {
	CompanyContact = 'COMPANY_CONTACT',
	FundContact    = 'FUND_CONTACT',
	CreditTeam     = 'CREDIT_TEAM',
	InvestmentTeam = 'INVESTMENT_TEAM',
}

interface InvContactBaseInterface {
	data_date       : string,    // date string
	contact_type    : Contact,
	contact_name    : string,
	contact_position: string,
	contact_email   : string,
	contact_phone   : string,
	contact_status  : boolean,   // Active / Inactive
}

export type InvContact = InvContactBaseInterface & {
	id  : string,   // Autogen from MASTERSHEET (Fund ID / Company ID)
	name: string,   // --- DROPDOWN from INVCOMPDEFINITIVE and INVFUNDDEFINITIVE
};
export type InvContactSubmit = InvContactBaseInterface & {
	// InvFundDefinitive.fund_id > name (fund_name)
	// InvCompDefinitive.company_id > name (company_name)
	id: string,

	//sheet id Ref
	parent_id: string,
	sheet_id: string,
};

export type InvContactAll = InvContact & InvContactSubmit;