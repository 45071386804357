import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

//Icon
import CloseIcon from '@mui/icons-material/Close';

//Chips
import LayoutStyles from '../../../../styles/Layout.module.css';

//Custom elelment
import Sheetlnformation from '../detail/Sheetlnformation';

//State
import {
  isDetailModal,
  portalStatusState,
  currentapprovalJSONState,
  currentRowsLengthState,
  currentSearchKeywordState,
  currentIdState,
} from '../../../../states/index';
import ApprovalInformation from '../detail/ApprovalInformation';
import { approvalName } from '../function/approvalName';
import { checkIsPending } from '../function/checkIsPending';

export default function DetailModal({
  caseName,
  sheetName,
  objectName,
  subObjectName,
  inputJSON,
  dataJSON,
  onReloadData,
}: {
  caseName: string;
  sheetName: string;
  objectName: string;
  subObjectName: string;
  inputJSON: object;
  dataJSON: object;
  onReloadData: (
    _currentSystemSheetName: string,
    _sheet_id: string,
    _rowsLengthTotal: number,
    _currentSearchKeyword: string,
    _currentSortBy: string,
  ) => void;
}) {
  const [openDetailModal, setOpenDetailModal] = useRecoilState(isDetailModal);
  const currentId: { [key: string]: any } = useRecoilValue(currentIdState);
  const currentRowsLength = useRecoilValue(currentRowsLengthState);
  const currentSearchKeyword = useRecoilValue(currentSearchKeywordState);

  const currentapprovalJSON: { [key: string]: any } = useRecoilValue(
    currentapprovalJSONState,
  );

  const portalStatus = useRecoilValue(portalStatusState);

  // console.log(currentapprovalJSON);
  // console.log(inputJSON);
  // console.log(dataJSON);

  let isPending = false;

  if (openDetailModal === true) {
    if (checkIsPending(sheetName, currentapprovalJSON) === 'PENDING') {
      isPending = true;
    } else {
      isPending = false;
    }
  }

  const handleClose = () => {
    onReloadData(
      sheetName,
      currentId['main_sheet_id'].toString(),
      currentRowsLength,
      currentSearchKeyword,
      '',
    );
    setOpenDetailModal(false);
  };

  return (
    <div>
      <Modal open={openDetailModal} className={LayoutStyles.ModalBackgroundFade}>
        <Box className={LayoutStyles.ModalL_Box}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            container
            className={LayoutStyles.ModalPaper}
          >
            {/* ---- */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              container
              className={LayoutStyles.ModalL_Header}
              alignItems="center"
            >
              <Grid
                item
                container
                xs={10}
                sm={10}
                md={10}
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Typography variant="h6" color="primary" gutterBottom component="div">
                    {objectName}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Grid item>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <Grid xs={12} sm={12} md={12} container item>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  gutterBottom
                  component="div"
                >
                  {subObjectName}
                </Typography>
              </Grid>

              {/* ---- */}
            </Grid>

            <Grid item xs={12} sm={12} md={12} className={LayoutStyles.ModalL_Body}>
              {/* -----------------------------------------------------------------------------*/}

              <ApprovalInformation
                approvalJSON={currentapprovalJSON}
                isHistory={false}
                approvalName={approvalName(sheetName)}
                isPending={isPending}
              />
              <br />
              <Sheetlnformation
                inputJSON={inputJSON}
                dataJSON={dataJSON}
                headerType={isPending ? 'edit' : 'view'}
                isPending={isPending}
              />

              {/* --------------------------------------------------------------------------------------------------*/}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              container
              className={LayoutStyles.ModalL_Footer}
            ></Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
