//---------------------------------------- Import React --------------------------------------
//React
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ElementStyles from '../../../../styles/Element.module.css';

export default function EmptyPage({ sheetNameDisplay }: { sheetNameDisplay: string }) {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      className={ElementStyles.ProgressLoading}
      direction="column"
      justifyContent="center"
      alignItems="center"
      container
    >
      <Grid item>
        <Typography variant="h6" color="primary" gutterBottom component="div">
          {sheetNameDisplay + ' is Empty.'}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" color="primary" gutterBottom component="div">
          {'Click "Add" button to create new sheet.'}
        </Typography>
      </Grid>
    </Grid>
  );
}
