import { useRecoilValue, useSetRecoilState } from 'recoil';

//API

//Common
import { getHistoryContact, getHistoryMemo } from '../../../api/index';

//Company
import { getHistoryCompany, getHistoryProduct, getHistoryFs } from '../../../api/index';

//Fund
import {
  getHistoryFund,
  getHistoryNav,
  getHistoryPort,
  getHistoryBenchmark,
  getHistoryCommitment,
} from '../../../api/index';

//Operation not use History
// import {
// getHistoryCertificate,
// getHistoryDirector,
//   getHistoryAccount,
//   getHistoryCapital,
//   getHistoryAdjEquity,
//   getHistoryAdjPar,
//   getHistoryBot,
//   getHistoryCompCashFlow,
//   getHistoryDividend,
//   getHistoryCompFair,
//   getHistoryWarrant,
//   getHistoryFundCashFlow,
// } from '../../../api/index';

import { requestHistoryInterface, OrderBy } from '../../../../models/index';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

//Icon
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import HistoryIcon from '@mui/icons-material/History';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

//Chips
import Chip from '@mui/material/Chip';

//@ts-ignore
import ElementStyles from '../../../../styles/Element.module.css';

import { isHistoryModal, isYesNoModal, isCreateModal } from '../../../../states/index';

import {
  currentCreateModalModeState,
  currentCaseNameState,
  currentSheetNameState,
  currentIdState,
  isUpdateInputJSONState,
} from '../../../../states/index';

import { historyRowsState } from '../../../../states/index';

import ButtonApproval from '../button/ButtonApproval';

//User
import { userProfileState, portalStatusState } from '../../../../states/index';

function DetailHeader({
  typoHeader,
  headerType,
  isPending,
}: {
  typoHeader: string;
  headerType: string;
  isPending: boolean;
}) {
  const setOpenCreateModal = useSetRecoilState(isCreateModal);
  const setOpenHistoryModal = useSetRecoilState(isHistoryModal);
  const setOpenYesNoModal = useSetRecoilState(isYesNoModal);

  const userProfile = useRecoilValue(userProfileState);
  const portalStatus = useRecoilValue(portalStatusState);

  const setHistoryRows = useSetRecoilState(historyRowsState);

  //-----------------------------------------//

  const setCurrentCaseName = useSetRecoilState(currentCaseNameState);
  const currentSheetName = useRecoilValue(currentSheetNameState);
  const currentId = useRecoilValue(currentIdState);

  const setCurrentCreateModalMode = useSetRecoilState(currentCreateModalModeState);

  const setisUpdateInputJSON = useSetRecoilState(isUpdateInputJSONState);

  let isOperation = false;

  if (portalStatus === 'operation') {
    isOperation = true;
  } else {
    isOperation = false;
  }

  const handleHistoryModalOpen = () => {
    let currentSheetIdValue = currentId['main_sheet_id'].toString();
    let currentSubSheetIdValue = currentId['sub_sheet_id'].toString();
    setisUpdateInputJSON(false);

    switch (currentSheetName) {
      //?-------------------- Common -------------------- */

      //----------------- 01 ---------------- */
      case 'invcontact':
        const getContactHistory = async () => {
          let requestContactHistory: requestHistoryInterface = {
            sheet_id: currentSubSheetIdValue,
            page: 1,
            page_length: 20,
            sort_by: '',
            order: OrderBy.asc,
            filter: '',
          };

          let historyContact: { [key: string]: any } = await getHistoryContact(
            requestContactHistory,
          );

          setHistoryRows(historyContact['data']);
          // console.log(historyContact);

          setisUpdateInputJSON(false);
        };
        getContactHistory();
        break;

      //---------------- 02 ----------------- */
      case 'invboardapprovedmemo':
        const getMemoHistory = async () => {
          let requestMemoHistory: requestHistoryInterface = {
            sheet_id: currentSubSheetIdValue,
            page: 1,
            page_length: 20,
            sort_by: '',
            order: OrderBy.asc,
            filter: '',
          };

          let historyMemo: { [key: string]: any } = await getHistoryMemo(
            requestMemoHistory,
          );
          setHistoryRows(historyMemo['data']);
          // console.log(historyMemo);
          setisUpdateInputJSON(false);
        };
        getMemoHistory();

        break;

      //?-------------------- Company -------------------- */
      //---------------- 01 ----------------- */
      case 'invcompdefinitive':
        const getCompanyHistory = async () => {
          let requestCompanyHistory: requestHistoryInterface = {
            sheet_id: currentSheetIdValue,
            page: 1,
            page_length: 20,
            sort_by: '',
            order: OrderBy.asc,
            filter: '',
          };

          let historyCompany: { [key: string]: any } = await getHistoryCompany(
            requestCompanyHistory,
          );
          setHistoryRows(historyCompany['data']);
          // console.log(historyCompany);
          setisUpdateInputJSON(false);
        };

        getCompanyHistory();

        break;
      //---------------- 02 ----------------- */
      case 'invcompproduct':
        const getProductHistory = async () => {
          let requestProductHistory: requestHistoryInterface = {
            sheet_id: currentSubSheetIdValue,
            page: 1,
            page_length: 20,
            sort_by: '',
            order: OrderBy.asc,
            filter: '',
          };

          let historyProduct: { [key: string]: any } = await getHistoryProduct(
            requestProductHistory,
          );
          setHistoryRows(historyProduct['data']);
          // console.log(historyProduct);
          setisUpdateInputJSON(false);
        };
        getProductHistory();

        break;
      //------------------ 03 --------------- */
      case 'invcompfs':
        const getFsHistory = async () => {
          let requestFsHistory: requestHistoryInterface = {
            sheet_id: currentSubSheetIdValue,
            page: 1,
            page_length: 20,
            sort_by: '',
            order: OrderBy.asc,
            filter: '',
          };

          let historyFs: { [key: string]: any } = await getHistoryFs(requestFsHistory);
          setHistoryRows(historyFs['data']);
          // console.log(historyFs);
          setisUpdateInputJSON(false);
        };

        getFsHistory();
        break;
      //--------------------------------- */

      //?-------------------- Fund -------------------- */
      //----------------- 01 ---------------- */
      case 'invfunddefinitive':
        const getFundHistory = async () => {
          let requestFundHistory: requestHistoryInterface = {
            sheet_id: currentSheetIdValue,
            page: 1,
            page_length: 20,
            sort_by: '',
            order: OrderBy.asc,
            filter: '',
          };

          let historyFund: { [key: string]: any } = await getHistoryFund(
            requestFundHistory,
          );

          setHistoryRows(historyFund['data']);
          // console.log(historyFund);
          setisUpdateInputJSON(false);
        };
        getFundHistory();
        break;
      //----------------- 02 ---------------- */
      case 'invfundnav':
        const getNavHistory = async () => {
          let requestNavHistory: requestHistoryInterface = {
            sheet_id: currentSubSheetIdValue,
            page: 1,
            page_length: 20,
            sort_by: '',
            order: OrderBy.asc,
            filter: '',
          };

          let historyNav: { [key: string]: any } = await getHistoryNav(
            requestNavHistory,
          );
          setHistoryRows(historyNav['data']);
          // console.log(historyNav);
          setisUpdateInputJSON(false);
        };
        getNavHistory();
        break;
      //----------------- 03 ---------------- */
      case 'invfundportfolio':
        const getPortHistory = async () => {
          let requestPortHistory: requestHistoryInterface = {
            sheet_id: currentSubSheetIdValue,
            page: 1,
            page_length: 20,
            sort_by: '',
            order: OrderBy.asc,
            filter: '',
          };

          let historyPort: { [key: string]: any } = await getHistoryPort(
            requestPortHistory,
          );
          setHistoryRows(historyPort['data']);
          // console.log(historyPort);
          setisUpdateInputJSON(false);
        };

        getPortHistory();

        break;
      //----------------- 04 ---------------- */
      case 'invfundbenchmark':
        const getBenchmarkHistory = async () => {
          let requestBenchmarkHistory: requestHistoryInterface = {
            sheet_id: currentSubSheetIdValue,
            page: 1,
            page_length: 20,
            sort_by: '',
            order: OrderBy.asc,
            filter: '',
          };

          let historyBenchmark: { [key: string]: any } = await getHistoryBenchmark(
            requestBenchmarkHistory,
          );

          setHistoryRows(historyBenchmark['data']);
          // console.log(historyBenchmark);
          setisUpdateInputJSON(false);
        };

        getBenchmarkHistory();
        break;
      //---------------- 05 ----------------- */
      case 'invfundcommitmentandwatermark':
        const getCommitmentHistory = async () => {
          let requestCommitmentHistory: requestHistoryInterface = {
            sheet_id: currentSubSheetIdValue,
            page: 1,
            page_length: 20,
            sort_by: '',
            order: OrderBy.asc,
            filter: '',
          };

          let historyCommitment: { [key: string]: any } = await getHistoryCommitment(
            requestCommitmentHistory,
          );

          setHistoryRows(historyCommitment['data']);
          // console.log(historyCommitment);
          setisUpdateInputJSON(false);
        };
        getCommitmentHistory();
        break;

      // //?-------------------- Operation -------------------- */
      //History is not available in operation feature.

      // //-------------- 01 ------------------ */

      // case 'invbankaccount':
      //   let requestAccountHistory: requestHistoryInterface = {
      //     sheet_id: currentSheetIdValue,
      //     page: 1,
      //     page_length: 20,
      //     sort_by: '',
      //     order: OrderBy.asc,
      //     filter: '',
      //   };

      //   let historyAccount: { [key: string]: any } = await getHistoryAccount(
      //     requestAccountHistory,
      //   );

      //   // console.log(historyDirector);
      //   break;

      // //--------------- 02 ------------------ */

      // case 'invbankcapital':
      //   let requestCapitalHistory: requestHistoryInterface = {
      //     sheet_id: currentSheetIdValue,
      //     page: 1,
      //     page_length: 20,
      //     sort_by: '',
      //     order: OrderBy.asc,
      //     filter: '',
      //   };

      //   let historyCapital: { [key: string]: any } = await getHistoryCapital(
      //     requestCapitalHistory,
      //   );

      //   // console.log(historyDirector);
      //   break;

      // //---------------- 03 ----------------- */
      // case 'invcertificate':
      //   let requestCertificateHistory: requestHistoryInterface = {
      //     sheet_id: currentSheetIdValue,
      //     page: 1,
      //     page_length: 20,
      //     sort_by: '',
      //     order: OrderBy.asc,
      //     filter: '',
      //   };

      //   let historyCertificate: { [key: string]: any } = await getHistoryCertificate(
      //     requestCertificateHistory,
      //   );

      //   break;

      // //---------------- 04 ----------------- */

      // case 'invcompadjustequity':
      //   let requestAdjEquityHistory: requestHistoryInterface = {
      //     sheet_id: currentSheetIdValue,
      //     page: 1,
      //     page_length: 20,
      //     sort_by: '',
      //     order: OrderBy.asc,
      //     filter: '',
      //   };

      //   let historyAdjEquity: { [key: string]: any } = await getHistoryAdjEquity(
      //     requestAdjEquityHistory,
      //   );
      //   break;
      // //----------------- 05 ---------------- */

      // case 'invcompadjustpar':
      //   let requestAdjParHistory: requestHistoryInterface = {
      //     sheet_id: currentSheetIdValue,
      //     page: 1,
      //     page_length: 20,
      //     sort_by: '',
      //     order: OrderBy.asc,
      //     filter: '',
      //   };

      //   let historyAdjPar: { [key: string]: any } = await getHistoryAdjPar(
      //     requestAdjParHistory,
      //   );
      //   break;

      // //---------------- 06 ----------------- */

      // case 'invcompbot':
      //   let requestBotHistory: requestHistoryInterface = {
      //     sheet_id: currentSheetIdValue,
      //     page: 1,
      //     page_length: 20,
      //     sort_by: '',
      //     order: OrderBy.asc,
      //     filter: '',
      //   };

      //   let historyBot: { [key: string]: any } = await getHistoryBot(requestBotHistory);
      //   break;

      // //--------------- 07 ------------------ */

      // case 'invcompcashflow':
      //   let requestCompCashFlowHistory: requestHistoryInterface = {
      //     sheet_id: currentSheetIdValue,
      //     page: 1,
      //     page_length: 20,
      //     sort_by: '',
      //     order: OrderBy.asc,
      //     filter: '',
      //   };

      //   let historyCompCashFlow: { [key: string]: any } = await getHistoryCompCashFlow(
      //     requestCompCashFlowHistory,
      //   );
      //   break;

      // //------------- 08 -------------------- */
      // case 'invcompdirector':
      //   let requestDirectorHistory: requestHistoryInterface = {
      //     sheet_id: currentSheetIdValue,
      //     page: 1,
      //     page_length: 20,
      //     sort_by: '',
      //     order: OrderBy.asc,
      //     filter: '',
      //   };

      //   let historyDirector: { [key: string]: any } = await getHistoryDirector(
      //     requestDirectorHistory,
      //   );

      //   // console.log(historyDirector);
      //   break;

      // //---------------- 09 ----------------- */

      // case 'invcompdividend':
      //   let requestDividendHistory: requestHistoryInterface = {
      //     sheet_id: currentSheetIdValue,
      //     page: 1,
      //     page_length: 20,
      //     sort_by: '',
      //     order: OrderBy.asc,
      //     filter: '',
      //   };

      //   let historyDividend: { [key: string]: any } = await getHistoryDividend(
      //     requestDividendHistory,
      //   );
      //   break;

      // //--------------- 10 ------------------ */

      // case 'invcompfair':
      //   let requestCompFairHistory: requestHistoryInterface = {
      //     sheet_id: currentSheetIdValue,
      //     page: 1,
      //     page_length: 20,
      //     sort_by: '',
      //     order: OrderBy.asc,
      //     filter: '',
      //   };

      //   let historyCompFair: { [key: string]: any } = await getHistoryCompFair(
      //     requestCompFairHistory,
      //   );
      //   break;

      // //---------------- 11 ----------------- */

      // case 'invcompwarrant':
      //   let requestWarrantHistory: requestHistoryInterface = {
      //     sheet_id: currentSheetIdValue,
      //     page: 1,
      //     page_length: 20,
      //     sort_by: '',
      //     order: OrderBy.asc,
      //     filter: '',
      //   };

      //   let historyWarrant: { [key: string]: any } = await getHistoryWarrant(
      //     requestWarrantHistory,
      //   );
      //   break;

      // //---------------- 12 ----------------- */

      // case 'invfundcashflow':
      //   let requestFundCashFlowHistory: requestHistoryInterface = {
      //     sheet_id: currentSheetIdValue,
      //     page: 1,
      //     page_length: 20,
      //     sort_by: '',
      //     order: OrderBy.asc,
      //     filter: '',
      //   };

      //   let historyFundCashFlow: { [key: string]: any } = await getHistoryFundCashFlow(
      //     requestFundCashFlowHistory,
      //   );
      //   break;

      //************** NotFound ********************* */
      //------------------------------------//
      default:
        setisUpdateInputJSON(false);
        break;
    }
    setOpenHistoryModal(true);
  };

  const handleCreateModalOpen = (caseName: string) => () => {
    if (caseName === 'edit') {
      setCurrentCreateModalMode(caseName);
      setCurrentCaseName(caseName);
      setOpenYesNoModal(true);
    } else if (caseName === 'create') {
      setCurrentCreateModalMode(caseName);
      setCurrentCaseName(caseName);
      setOpenCreateModal(true);
    }
  };

  const handleApproval = () => {
    setCurrentCaseName('approve');
    setOpenYesNoModal(true);
  };

  const handleCancel = () => {
    setCurrentCaseName('cancel');
    setOpenYesNoModal(true);
  };

  // ?-----------------------------------------------------------------------------------------------------------
  switch (headerType) {
    case 'general':
      return (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            container
            className={ElementStyles.GridFirstRow}
          >
            <Typography color="primary" variant="h6" gutterBottom component="div">
              {typoHeader}
            </Typography>
          </Grid>
          <Divider />
          {/* ------------------ */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            container
            className={ElementStyles.GridPadding}
          ></Grid>
        </>
      );
    //  ?-----------------------------------------------------------------------------------------------------------------------
    case 'approval':
      return (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            container
            className={ElementStyles.GridFirstRow}
          >
            {/* ---- */}
            <Grid
              item
              xs={8}
              sm={8}
              md={8}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              container
              className={ElementStyles.GridFirstRow}
            >
              <Typography color="primary" variant="h6" gutterBottom component="div">
                {typoHeader}
              </Typography>
            </Grid>

            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              justifyContent="flex-end"
              spacing={1}
              container
            >
              {userProfile.role === 'CHECKER' ||
                (userProfile.role === 'ADMIN' && (
                  <Grid item>
                    <ButtonApproval isApproved={isPending} onClick={handleApproval} />
                  </Grid>
                ))}
            </Grid>
            {/* ---- */}
          </Grid>

          <Divider />
          {/* ------------------ */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            container
            className={ElementStyles.GridPadding}
          ></Grid>
        </>
      );
    //?-----------------------------------------------------------------------------------------------------------------------
    case 'create':
      return (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            container
            className={ElementStyles.GridFirstRow}
          >
            {/* ---- */}
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              container
              className={ElementStyles.GridFirstRow}
            >
              <Typography color="primary" variant="h6" gutterBottom component="div">
                {typoHeader}
              </Typography>
            </Grid>

            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
              container
            >
              {userProfile.role === 'MAKER' ||
                (userProfile.role === 'ADMIN' && (
                  <Grid item>
                    <Chip
                      icon={<AddCircleIcon />}
                      color="primary"
                      className={ElementStyles.ButtonAdd}
                      label="New Sheet"
                      clickable
                      onClick={handleCreateModalOpen('create')}
                    />
                  </Grid>
                ))}
            </Grid>
            {/* ---- */}
          </Grid>
          <Divider />
          {/* ------------------ */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            container
            className={ElementStyles.GridPadding}
          ></Grid>
        </>
      );
    //?-----------------------------------------------------------------------------------------------------------------------
    case 'edit':
      return (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            container
            className={ElementStyles.GridFirstRow}
          >
            {/* ---- */}
            {!isPending ? (
              <>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  container
                  className={ElementStyles.GridFirstRow}
                >
                  <Typography color="primary" variant="h6" gutterBottom component="div">
                    {typoHeader}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                  container
                >
                  {userProfile.role === 'MAKER' ||
                    (userProfile.role === 'ADMIN' && (
                      <Grid item>
                        <Chip
                          icon={<ModeEditIcon />}
                          color="primary"
                          variant="outlined"
                          className={ElementStyles.ButtonAdd}
                          label="Edit Data"
                          onClick={handleCreateModalOpen('edit')}
                          clickable
                        />
                      </Grid>
                    ))}

                  {isOperation ? (
                    <div></div>
                  ) : (
                    <Grid item>
                      <Tooltip title="View History">
                        <IconButton color="primary" onClick={handleHistoryModalOpen}>
                          <HistoryIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  container
                  className={ElementStyles.GridFirstRow}
                >
                  <Typography color="primary" variant="h6" gutterBottom component="div">
                    {typoHeader}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                  container
                >
                  <Grid item>
                    <Chip
                      icon={<DeleteIcon />}
                      color="error"
                      variant="outlined"
                      className={ElementStyles.ButtonAdd}
                      label="Cancel Submit"
                      onClick={handleCancel}
                      clickable
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      icon={<ModeEditIcon />}
                      color="primary"
                      variant="outlined"
                      className={ElementStyles.ButtonAdd}
                      label="Edit Submit"
                      onClick={handleCreateModalOpen('edit')}
                      clickable
                    />
                  </Grid>
                  {isOperation ? (
                    <div></div>
                  ) : (
                    <Grid item>
                      <Tooltip title="View History">
                        <IconButton color="primary" onClick={handleHistoryModalOpen}>
                          <HistoryIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
            {/* ---- */}
          </Grid>
          <Divider />
          {/* ------------------ */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            container
            className={ElementStyles.GridPadding}
          ></Grid>
        </>
      );

    //?-----------------------------------------------------------------------------------------------------------------------
    case 'view':
      return (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            container
            className={ElementStyles.GridFirstRow}
          >
            {/* ---- */}

            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              container
              className={ElementStyles.GridFirstRow}
            >
              <Typography color="primary" variant="h6" gutterBottom component="div">
                {typoHeader}
              </Typography>
            </Grid>

            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
              container
            >
              {isOperation ? (
                <></>
              ) : (
                <Grid item>
                  <Tooltip title="View History">
                    <IconButton color="primary" onClick={handleHistoryModalOpen}>
                      <HistoryIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
            {/* ---- */}
          </Grid>
          <Divider />
          {/* ------------------ */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            container
            className={ElementStyles.GridPadding}
          ></Grid>
        </>
      );
    // --------------------------------------------------------------------------//

    default:
      return <div></div>;
  }
}

export default DetailHeader;
