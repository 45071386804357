import * as React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

//api

import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';

//Icon
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

import {
  portalStatusState,
  userProfileState,
  isLogoutModal,
} from '../../../states/index';
import { sheetNameState } from '../../../states/index';

//@ts-ignore
import LayoutStyles from '../../../styles/Layout.module.css';

function RenderIcon({ portalMode }: { portalMode: string }) {
  switch (portalMode) {
    case 'company_profile': {
      return <AccountBalanceIcon color="primary" fontSize="large" />;
    }

    case 'fund_profile': {
      return <AccountBalanceWalletIcon color="primary" fontSize="large" />;
    }

    case 'operation': {
      return <SwapHorizIcon color="primary" fontSize="large" />;
    }

    default: {
      return <p>{'NotFound PortalCard'}</p>;
    }
  }
}

export default function Topbar() {
  const userProfile = useRecoilValue(userProfileState);
  const portalMode = useRecoilValue(portalStatusState);
  const sheetName = useRecoilValue(sheetNameState);

  const setOpenLogoutModal = useSetRecoilState(isLogoutModal);
  //! -------------- API :  /logout --------------------------//
  const onClickLogout = () => {
    setOpenLogoutModal(true);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <AppBar className={LayoutStyles.mainAppBar} position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <RenderIcon portalMode={portalMode} />
            </Grid>

            <Grid item>
              <Typography color="primary" variant="h5" component="h1">
                <b>{sheetName[portalMode]['portalname']}</b>
              </Typography>
            </Grid>
            {/* -------- */}

            <Grid item xs />
            {/* -------- */}
            <Grid item></Grid>
            {/* -------- */}
            <Grid item></Grid>
            {/* -------- */}
            <Grid item>
              <Tooltip title="Profile">
                <IconButton onClick={handleProfileClick} color="inherit" sx={{ p: 0.5 }}>
                  <Avatar sx={{ bgcolor: '#2140a6', width: 40, height: 40 }}>BJ</Avatar>
                </IconButton>
              </Tooltip>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleProfileClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Paper className={LayoutStyles.mainAppPopover} square>
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                    className={LayoutStyles.mainAppGrid}
                    container
                    item
                  >
                    <Grid item>
                      <Typography
                        color="primary"
                        variant="body1"
                        gutterBottom
                        component="div"
                      >
                        {userProfile.name}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography
                        color="primary"
                        variant="caption"
                        gutterBottom
                        component="div"
                      >
                        {userProfile.role + ' ' + '(' + userProfile.access + ')'}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Chip
                        color="primary"
                        label={<b>Logout</b>}
                        variant="outlined"
                        onClick={onClickLogout}
                        clickable
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Popover>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}
