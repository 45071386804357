import { useRecoilState, useSetRecoilState } from 'recoil';
import * as InvModels from '@invport/models';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import ErrorIcon from '@mui/icons-material/Error';

//Chips
import Chip from '@mui/material/Chip';
import ElementStyles from '../../../../styles/Element.module.css';
import LayoutStyles from '../../../../styles/Layout.module.css';

//State
import { isErrorModal, isYesNoModal } from '../../../../states/index';

export default function ErrorModal() {
  const [openErrorModal, setOpenErrorModal] = useRecoilState(isErrorModal);
  const setOpenYesNoModal = useSetRecoilState(isYesNoModal);

  const handleOnButtonClick = () => {
    setOpenYesNoModal(false);
    setOpenErrorModal(false);
  };

  return (
    <div>
      <Modal open={openErrorModal} className={LayoutStyles.ModalBackgroundFade}>
        <Box className={LayoutStyles.ModalS_Box}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            container
            className={LayoutStyles.ModalPaper}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              container
              className={LayoutStyles.ModalL_Header}
              alignItems="center"
            >
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item>
                  <ErrorIcon color="error" />
                </Grid>
                <Grid item>
                  <Typography color="error" variant="h6" gutterBottom component="div">
                    {'Cannot submit data.'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={LayoutStyles.ModalS_Body}
              container
              direction="row"
              justifyContent="center"
            >
              {/* -------------------------------------------- Row 2/1   ---------------------------------------*/}

              <Grid item>
                <Typography variant="subtitle2" align="left" component="div">
                  {'Something went wrong. Please try again'}
                </Typography>
              </Grid>

              {/* --------------------------------------------------------------------------------------------------*/}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              container
              className={LayoutStyles.ModalS_Footer}
            >
              {/* --------------------------------- */}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                direction="row"
                justifyContent="center"
                alignItems="center"
                container
              >
                <Grid item>
                  <Chip
                    color="primary"
                    className={ElementStyles.ButtonModalRight}
                    sx={{ width: 60 }}
                    label={'Ok'}
                    onClick={handleOnButtonClick}
                    clickable
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
