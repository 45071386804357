import { ParAdjustmentMethod, WarrantStatus } from '../index';

//MockUp Value

//?-------------------- Common -------------------- */

// [01] invcontact ---------------- */

// [02] invboardapprovedmemo ----------------- */

//?-------------------- Company -------------------- */
// [01] invcompdefinitive ----------------- */

// [02] invcompproduct----------------- */

export const productFormula_investment_cost_thb = (
  book_value: number,
  fx_rate: number,
) => {
  /*!
//! remark: ไม่พบช่อง FX Rate --> สร้างค่า mockup ไปก่อน
*/
  const result = book_value * fx_rate;
  return result;
};

export const productFormula_allowance_impair_thb = (
  allowance_impair_ccy: number,
  fx_rate: number,
) => {
  /*!
//! remark: ไม่พบช่อง FX Rate --> สร้างค่า mockup ไปก่อน
*/
  const result = allowance_impair_ccy * fx_rate;
  return result;
};

export const productFormula_current_stake = (
  no_of_share: number,
  total_paid_up: number,
) => {
  /*!
//! data dict text: share= No. of share/ Toal Paid Up share *ถ้าไม่มีจำนวนหุ้นให้คำนวณโดยใช้ Investment cost/Total investment cost
//! remark: ไม่พบช่อง Toal Paid Up share และไม่เข้าใจ condition
*/
  const result = no_of_share * total_paid_up;
  return result;
};

export const productFormula_amortize_th = () => {
  /*!
//! data dict text: (Par - Cost)/ระยะเวลาตั้งแต่ Amortize ครั้งสุดท้าย ถึงวัน Due ของหุ้น * จำนวนวันตั้งแต่ Amortize สุดท้ายถึงวัน Value Date
//! remark: ไม่เข้าใจ condition
*/
  const result = 0;
  return result;
};

export const productFormula_interest_accrued_th = (
  principle_amout: number,
  days: number,
  interest_rate: number,
) => {
  /*!
//! data dict text:(Principle Amount * (Days/365 Days) * % Interest Rate) / 100
//! remark: ไม่พบช่อง Principle Amount --> สร้างค่า mockup ไปก่อน
//! remark: ไม่พบช่อง % Interest Rate --> สร้างค่า mockup ไปก่อน
//! remark: ไม่ได้แจ้งว่า Day จากช่องไหน --> สร้างค่า mockup ไปก่อน
*/
  const result = (principle_amout * (days / 365) * interest_rate) / 100;
  return result;
};

export const productFormula_interest_receive_th = (
  principle_amout: number,
  days: number,
  interest_rate: number,
) => {
  /*!
//! data dict text:(Principle Amount * (Days/365 Days) * % Interest Rate) / 100
//! remark: ไม่พบช่อง Principle Amount --> สร้างค่า mockup ไปก่อน
//! remark: ไม่พบช่อง % Interest Rate --> สร้างค่า mockup ไปก่อน
//! remark: ไม่ได้แจ้งว่า Day จากช่องไหน --> สร้างค่า mockup ไปก่อน
*/
  const result = (principle_amout * (days / 365) * interest_rate) / 100;
  return result;
};

export const productFormula_withholding_tax_th = () => {
  const result: number = 1 / 100;
  return result;
};

export const productFormula_net_interest_received_th = (
  interest_receive_th: number,
  withholding_tax_th: number,
) => {
  const result = interest_receive_th - withholding_tax_th;
  return result;
};

// [03] invcompfs--------------- */

export const fsFormula_book_value_per_share = (
  total_equity: number,
  paid_up_capital_no_of_share: number,
) => {
  const result = total_equity / paid_up_capital_no_of_share;
  return result;
};

export const fsFormula_earning_per_share = (
  net_income: number,
  paid_up_capital_no_of_share: number,
) => {
  const result = net_income / paid_up_capital_no_of_share;
  return result;
};

//?-------------------- Fund -------------------- */
// [01] invfunddefinitive- --------------- */

// [02] invfundnav ---------------- */

// [03] invfundportfolio ---------------- */

// [04] invfundbenchmark---------------- */

// [05] invfundcommitmentandwatermark ----------------- */

//?-------------------- Operation -------------------- */

// [01] invbankaccount ------------------ */

// [02] invbankcapital ------------------ */

// [03] invcertificate ----------------- */

// [04] invcompadjustequity ----------------- */

export const adjEquityFormula_allowance_permanent_thb = (
  investment_cost_pretransaction: number,
  allowance_impairment: number,
  allowance_permanent: number,
) => {
  const result =
    investment_cost_pretransaction - allowance_impairment + allowance_permanent;
  return result;
};

export const adjEquityFormula_adjusted_equity_pretransaction = (
  adjusted_equity_posttransaction: number,
) => {
  /*!
//! data dict text:ดึง adjusted_equity_posttransaction ก่อนหน้า
//! remark: ไม่แน่ใจจะดึงจากอะไรดี + ควรเพิ่ม api สำหรับเชคไหม
*/
  const result = adjusted_equity_posttransaction;
  return result;
};

export const adjEquityFormula_adjusted_equity_posttransaction = (
  income: string,
  adjusted_equity_posttransaction: number,
  allowance_permanent_thb: number,
  net_dividend_amount: number,
  adjusted_equity_pretransaction: number,
  total_adjusted_equity: number,
) => {
  let result = 0;

  if (income === 'Profit') {
    //get 'net_dividend_amount' from invcompdividend
    result =
      adjusted_equity_posttransaction + allowance_permanent_thb - net_dividend_amount;
  } else {
    //get 'net_dividend_amount' from invcompdividend
    result =
      adjusted_equity_pretransaction - total_adjusted_equity - net_dividend_amount;
  }

  return result;
};

export const adjEquityFormula_adjusted_cost = (
  allowance_permanent_thb: number,
  adjusted_equity_posttransaction: number,
) => {
  const result = allowance_permanent_thb + adjusted_equity_posttransaction;
  return result;
};

// [05] invcompadjustpar ---------------- */

export const adjParFormula_no_of_share_pretransaction = () => {
  /*!
//! data dict text: Before transaction date
//! remark: ไม่เข้าใจ condition
*/
  const result = 0;
  return result;
};

export const adjParFormula_investment_cost_pretransaction = () => {
  /*!
//! data dict text: Before transaction date
//! remark: ไม่เข้าใจ condition
*/
  const result = 0;
  return result;
};

export const adjParFormula_investment_cost_pretransaction_thb = () => {
  /*!
//! data dict text: Before transaction date
//! remark: ไม่เข้าใจ condition
*/
  const result = 0;
  return result;
};

export const adjParFormula_par_value_pretransaction = () => {
  /*!
//! data dict text: Before transaction date
//! remark: ไม่เข้าใจ condition
*/
  const result = 0;
  return result;
};

export const adjParFormula_no_of_share_posttransaction = (
  adjusted_par_method: ParAdjustmentMethod,
  no_of_share_pretransaction: number,
  par_value_pretransaction: number,
  par_value_posttransaction: number,
  paid_up_capital_no_of_share_posttransaction: number,
  paid_up_capital_no_of_share_pretransaction: number,
) => {
  //?remark linkID : adjusted_par_method (select)
  let result = 0;
  switch (adjusted_par_method) {
    case ParAdjustmentMethod.DecreaseAccuLoss:
      return (result = no_of_share_pretransaction);

    case ParAdjustmentMethod.AdjustParValue:
      return (result =
        no_of_share_pretransaction *
        (par_value_pretransaction / par_value_posttransaction));

    case ParAdjustmentMethod.DecreaseParValueAndReturnCash:
      return (result = no_of_share_pretransaction);

    case ParAdjustmentMethod.DecreaseNumberOfRegisteredShares:
      return (result =
        no_of_share_pretransaction *
        (paid_up_capital_no_of_share_posttransaction /
          paid_up_capital_no_of_share_pretransaction));

    case ParAdjustmentMethod.DecreaseNumberOfRegisteredSharesNonProfitNonLoss:
      //make this input isActive = true for Key-in;
      return (result = 0);

    //--------------------------------------------------//
    default:
      return result;
  }
};

export const adjParFormula_current_stake_pretransaction = (
  no_of_share_pretransaction: number,
  paid_up_capital_no_of_share_pretransaction: number,
) => {
  const result = no_of_share_pretransaction / paid_up_capital_no_of_share_pretransaction;
  return result;
};

export const adjParFormula_investment_cost_thb = (
  investment_cost: number,
  fx_rate: number,
) => {
  /*!
//! remark: ไม่พบช่อง FX Rate --> สร้างค่า mockup ไปก่อน
*/
  const result = investment_cost * fx_rate;
  return result;
};

export const adjParFormula_cashflow_amount_posttransaction_thb = (
  cashflow_amount_posttransaction: number,
  fx_rate: number,
) => {
  /*!
//! remark: ไม่พบช่อง FX Rate --> สร้างค่า mockup ไปก่อน
*/
  const result = cashflow_amount_posttransaction * fx_rate;
  return result;
};

export const adjParFormula_investment_cost = (
  adjusted_par_method: ParAdjustmentMethod,
  no_of_share_pretransaction: number,
  investment_cost_pretransaction: number,
  par_value_posttransaction: number,
  par_value_pretransaction: number,
  paid_up_capital_no_of_share_posttransaction: number,
  paid_up_capital_no_of_share_pretransaction: number,
) => {
  //?remark linkID : adjusted_par_method (select)
  let result = 0;
  switch (adjusted_par_method) {
    case ParAdjustmentMethod.DecreaseAccuLoss:
      return (result =
        investment_cost_pretransaction *
        (par_value_posttransaction / par_value_pretransaction));

    case ParAdjustmentMethod.AdjustParValue:
      return (result = investment_cost_pretransaction);

    case ParAdjustmentMethod.DecreaseParValueAndReturnCash:
      return (result =
        investment_cost_pretransaction -
        (no_of_share_pretransaction * par_value_pretransaction -
          par_value_posttransaction));

    case ParAdjustmentMethod.DecreaseNumberOfRegisteredShares:
      return (result =
        investment_cost_pretransaction *
        (paid_up_capital_no_of_share_posttransaction /
          paid_up_capital_no_of_share_pretransaction));

    case ParAdjustmentMethod.DecreaseNumberOfRegisteredSharesNonProfitNonLoss:
      //make this input isActive = true for Key-in;
      return (result = investment_cost_pretransaction);

    //--------------------------------------------------//
    default:
      return result;
  }
};

// [06] invcompbot ----------------- */

export const botFormula_current_stake = (
  no_of_share_posttransaction: number,
  paid_up_capital_no_of_share: number,
) => {
  /*!
//! data dict text: No. of Share (Autogen FORMULAR from daily report)/Paid Up Capital_No. of  Shares (from INVCOMPFS)

//! remark: ระบุไม่ชัดเจนว่าใช้ no_of_share_posttransaction หรือ no_of_share_pretransaction จึงเลือกใช้ no_of_share_posttransaction ก่อน
*/

  //get 'no_of_share_posttransaction' from invcompcashflow
  //get 'paid_up_capital_no_of_share' from invcompfs

  const result = no_of_share_posttransaction / paid_up_capital_no_of_share;
  return result;
};

// [07] invcompcashflow ------------------ */

export const compCashFlowFormula_cashflow_amount = (
  no_of_share: number,
  price_per_share: number,
) => {
  const result = no_of_share * price_per_share;
  return result;
};

export const compCashFlowFormula_net_cashflow = (
  cashflow_amount: number,
  cashflow_adjustment: number,
) => {
  const result = cashflow_amount + cashflow_adjustment;
  return result;
};

export const compCashFlowFormula_net_cashflow_thb = (
  cashflow_amount: number,
  cashflow_adjustment: number,
  exchange_rate: number,
) => {
  //exchange_rate autogen from MasterSheet
  const result = (cashflow_amount + cashflow_adjustment) * exchange_rate;
  return result;
};

export const compCashFlowFormula_commission = (
  cashflow_amount: number,
  broker_rate: number,
) => {
  /*!
//! data dict text:  Cashflow * Broker Rate (Linked to Broker Name) 

//! remark: ไม่พบช่อง Broker Rate --> สร้างค่า mockup ไปก่อน
*/

  const result = cashflow_amount * broker_rate;
  return result;
};

export const compCashFlowFormula_vat_levey_fee = (commission: number) => {
  const result = commission * (7 / 100);
  return result;
};

export const compCashFlowFormula_commission_vat = (
  commission: number,
  vat_levey_fee: number,
) => {
  const result = commission * vat_levey_fee;
  return result;
};

export const compCashFlowFormula_withholding_tax = (commission: number) => {
  const result = commission * (3 / 100);
  return result;
};

export const compCashFlowFormula_no_of_share_pretransaction = () => {
  /*!
//! data dict text: Before transaction date
//! remark: ไม่เข้าใจ condition
*/
  const result = 0;
  return result;
};

export const compCashFlowFormula_investment_cost_pretransaction_ccy = () => {
  /*!
//! data dict text: Before transaction date
//! remark: ไม่เข้าใจ condition
*/
  const result = 0;
  return result;
};

export const compCashFlowFormula_investment_cost_pretransaction_thb = () => {
  /*!
//! data dict text: Before transaction date
//! remark: ไม่เข้าใจ condition
*/
  const result = 0;
  return result;
};

export const compCashFlowFormula_current_stake_pretransaction = () => {
  /*!
//! data dict text: Before transaction date
//! remark: ไม่เข้าใจ condition
*/
  const result = 0;
  return result;
};

export const compCashFlowFormula_no_of_share_posttransaction = () => {
  /*!
//! data dict text: Autogen FORMULAR and linked to INVCOMPPRODUCT -> = Before transaction date +/- change of transaction
//! remark: ไม่เข้าใจ condition
*/
  const result = 0;
  return result;
};

export const compCashFlowFormula_investment_cost = () => {
  /*!
//! data dict text: Autogen FORMULAR and linked to INVCOMPPRODUCT -> = Before transaction date +/- change of transaction
//! remark: ไม่เข้าใจ condition
*/
  const result = 0;
  return result;
};

export const compCashFlowFormula_investment_cost_thb = () => {
  /*!
//! data dict text: Autogen FORMULAR and linked to INVCOMPPRODUCT -> = Before transaction date +/- change of transaction
//! remark: ไม่เข้าใจ condition
*/
  const result = 0;
  return result;
};

export const compCashFlowFormula_current_stake_posttransaction = () => {
  /*!
//! data dict text: Autogen FORMULAR and linked to INVCOMPPRODUCT -> = Before transaction date +/- change of transaction
//! remark: ไม่เข้าใจ condition
*/
  const result = 0;
  return result;
};

// [08] invcompdirector -------------------- */

// [09] invcompdividend ----------------- */

export const dividendFormula_dividend_amount = (
  no_of_shares: number,
  dividend: number,
  dividend_adjustment: number,
) => {
  /*!
//! data dict text: Dividend = Autogen Formular, Interest = Key In
//! remark: ขอยังไม่ให้ปรับเป็น Key-in ได้ ควรเลือกอย่างใดอย่างหนึ่ง
*/

  const result = no_of_shares * dividend + dividend_adjustment;
  return result;
};

export const dividendFormula_net_dividend_amount = (
  dividend_amount: number,
  withholding_tax: number,
) => {
  const result = dividend_amount - withholding_tax;
  return result;
};

// [10]invcompfair ------------------ */

export const compFairFormula_operating_year = (registered_date: string) => {
  /*!
//! data dict text: Today - Registered Date shown in Definitive
//! remark: ไม่เข้าใจ condition ว่าต้องการ Output คืออะไร จำนวนปี? จำนวนวัน? หรืออะไร
*/

  //get 'registered_date' from invcompdefinitive

  const result = registered_date;
  return result;
};

// [11] invcompwarrant ----------------- */

export const warrantFormula_no_of_warrant = (
  warrant_type: string,
  no_of_share_record_date: number,
  exercise_ratio: number,
) => {
  /*!
//! data dict text: *For Warrant Received* = Rounddown(No. of Recorded Shares / XXX shares from Exercise Ratio) 
//! remark: ไม่พบช่อง No. of Recorded Shares ไม่แน่ใจใช่ No. of Shares on recording date ไหม , ไม่แจ้งว่า กรณี เคสอื่นให้ทำอะไร
*/

  //?remark linkID : warrant_type (select)
  let result = 0;
  switch (warrant_type) {
    case WarrantStatus.WarrantReceived:
      return (result = Math.floor(no_of_share_record_date / exercise_ratio));

    case WarrantStatus.WarrantsExercised:
      return (result = 0);

    case WarrantStatus.WarrantsExpired:
      return (result = 0);

    //--------------------------------------------------//
    default:
      return result;
  }
};

export const warrantFormula_no_of_warrant_posttransaction = (
  warrant_type: string,
  no_of_warrant_pretransaction: number,
  no_of_warrant: number,
) => {
  //?remark linkID : warrant_type (select)
  let result = 0;
  switch (warrant_type) {
    case WarrantStatus.WarrantReceived:
      return (result = no_of_warrant_pretransaction + no_of_warrant);

    case WarrantStatus.WarrantsExercised:
      return (result = no_of_warrant_pretransaction - no_of_warrant);

    case WarrantStatus.WarrantsExpired:
      return (result = no_of_warrant_pretransaction + no_of_warrant);

    //--------------------------------------------------//
    default:
      return result;
  }
};

export const warrantFormula_no_of_share_posttransaction = (
  warrant_type: string,
  no_of_share_pretransaction: number,
  exercise_ratio: number,
  no_of_warrant: number,
) => {
  //?remark linkID : warrant_type (select)
  let result = 0;
  switch (warrant_type) {
    case WarrantStatus.WarrantReceived:
      return (result = no_of_share_pretransaction);

    case WarrantStatus.WarrantsExercised:
      return (result = no_of_share_pretransaction + exercise_ratio * no_of_warrant);

    case WarrantStatus.WarrantsExpired:
      return (result = no_of_share_pretransaction);

    //--------------------------------------------------//
    default:
      return result;
  }
};

export const warrantFormula_cashflow_ac = (
  warrant_type: string,
  no_of_exercised: number,
  strike_price: number,
) => {
  /*!
//! data dict text:

For Warrant Received = 0
For Warrant Exercised = No of exercised warrants * Strike Price
For Warrant Expired =0

//! remark: ไม่พบช่อง  No of exercised warrants --> สร้างค่า mockup ไปก่อน (no_of_exercised)
*/

  //?remark linkID : warrant_type (select)
  let result = 0;
  switch (warrant_type) {
    case WarrantStatus.WarrantReceived:
      return (result = 0);

    case WarrantStatus.WarrantsExercised:
      return (result = no_of_exercised * strike_price);

    case WarrantStatus.WarrantsExpired:
      return (result = 0);

    //--------------------------------------------------//
    default:
      return result;
  }
};

export const warrantFormula_cost_ac = (
  warrant_type: string,
  no_of_warrant: number,
  no_of_share_pretransaction: number,
  strike_price: number,
) => {
  /*!
//! data dict text:

Warrant Receive = Fixed at 1 Original Currency (ex. 1 THB, 1USD), 
Warrant Exercise =if(No. of Warrants in this transaction = No. of Warrants pre transaction,(Strike Price * No. of Warrants in this transaction)+1,(Strike Price * No. of Warrants in this transaction))
Warrant Expired = Write off both cost and expired warrants

//! remark: ไม่เข้าใจกรณี Warrant Expired ต้องการ Output อะไร
*/

  //?remark linkID : warrant_type (select)
  let result = 0;
  switch (warrant_type) {
    case WarrantStatus.WarrantReceived:
      return (result = 1);

    case WarrantStatus.WarrantsExercised:
      if (no_of_warrant === no_of_share_pretransaction) {
        return (result = strike_price * no_of_warrant + 1);
      } else {
        return (result = strike_price * no_of_warrant);
      }

    case WarrantStatus.WarrantsExpired:
      return (result = 0);

    //--------------------------------------------------//
    default:
      return result;
  }
};

export const warrantFormula_cost_ac_thb = (cost_ac: number, fx_rate: number) => {
  /*!
//! remark: ไม่พบช่อง FX Rate --> สร้างค่า mockup ไปก่อน
*/
  const result = cost_ac * fx_rate;
  return result;
};

export const warrantFormula_investment_cost_pretransaction = (
  investment_cost_pretransaction_1: number,
  investment_cost_pretransaction_2: number,
) => {
  //get 'net_dividend_amount' from slected product 1, 2
  const result = investment_cost_pretransaction_1 + investment_cost_pretransaction_2;
  return result;
};

export const warrantFormula_investment_cost_pretransaction_thb = () => {
  /*!
//! remark: ไม่ได้ให้ formula มา
*/
  const result = 0;
  return result;
};

export const warrantFormula_current_stake_pretransaction = (
  no_of_share_pretransaction: number,
  paid_up_capital_no_of_share_pretransaction: number,
) => {
  //get 'paid_up_capital_no_of_share_pretransaction' from invcompproduct

  const result = no_of_share_pretransaction / paid_up_capital_no_of_share_pretransaction;

  return result;
};

export const warrantFormula_investment_cost = (
  investment_cost_pretransaction: number,
  cost_ac: number,
) => {
  //get 'investment_cost_pretransaction'from invcompproduct
  const result = investment_cost_pretransaction + cost_ac;
  return result;
};

export const warrantFormula_investment_cost_thb = (
  investment_cost: number,
  exchange_rate: number,
) => {
  //get 'exchange_rate' invcompproduct, MasterSheet
  const result = investment_cost * exchange_rate;
  return result;
};

export const warrantFormula_current_stake_posttransaction = (
  no_of_share_pretransaction: number,
  paid_up_capital_no_of_share_posttransaction: number,
) => {
  //get 'paid_up_capital_no_of_share_posttransaction' from invcompproduct
  const result =
    no_of_share_pretransaction / paid_up_capital_no_of_share_posttransaction;
  return result;
};

// [12] invfundcashflow ----------------- */

export const fundCashFlow_current_stake = (
  cashflow_amount: number,
  total_partnership_paid_amount: number,
) => {
  const result = cashflow_amount / total_partnership_paid_amount;
  return result;
};
