import { Currency } from './inv-enums';

export enum PaymentMethod {
	Cash         = 'CASH',
	Cheque       = 'CHEQUE',
	BankTransfer = 'BANK_TRANSFER',
	BblCustodian = 'BBL_CUSTODIAN',
}
export enum DividendType {
	AccruedDividend  = 'ACCRUED_DIVIDEND',
	ReversedDividend = 'REVERSED_DIVIDEND',
	ReceivedDividend = 'RECEIVED_DIVIDEND',
	AccruedInterest  = 'ACCRUED_INTEREST',
	ReversedInterest = 'REVERSED_INTEREST',
	ReceivedInterest = 'RECEIVED_INTEREST',
}

interface InvCompDividendBaseInterface {
	data_date: string,         // date string
	data_type: DividendType,

	// Accounting Purposes
	book_closing_date   : string,          // date string, For listed stocks, auto generated from SETsmart. For non-listed stocks, fill in manually.
	dividend            : number,          // --- For listed stocks, auto generated from SETsmart. For non-listed stocks, fill in manually.
	dividend_adjustment : number,
	dividend_amount     : number,          // --- Dividend: Autogen Formular -> = (Number of Shares * Dividend (Per Share) AC) + Dividend Adjustment, Interest: Key In
	withholding_tax     : number,
	net_dividend_amount : number,          // --- Autogen FORMULAR -> = Total Cash Amount AC - Withholding Tax AC
	payment_method      : PaymentMethod,
	payment_date        : string,          // date string
	bank_name           : string,
	cheque_recorded_date: string,          // date string
	cheque_number       : number,
	cheque_received_date: string,          // date string
	details             : string,
}

export type InvCompDividend = InvCompDividendBaseInterface & {
	company_name: string,   // --- Autogen from INVCOMPPRODUCT
	product_name: string,   // --- DROPDOWN from INVCOMPPRODUCT

	// Accounting Purposes
	currency    : Currency,   // --- Autogen from INVCOMPPRODUCT
	no_of_shares: number,     // --- Autogen from INVCOMPPRODUCT
};

export type InvCompDividendSubmit = InvCompDividendBaseInterface & {
	company_id: string,   // --- Autogen from INVCOMPDEFINITIVE
	product_id: string,   // InvCompProduct.product_id > company_name, product_name, currency, no_of_shares (no_of_share)

	//sheet id Ref
	sheet_id: string,
};


export type InvCompDividendAll = InvCompDividend & InvCompDividendSubmit;