import { useRecoilValue } from 'recoil';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import ElementStyles from '../../../../styles/Element.module.css';
import {
  labelSizeState,
  labelGridState,
  inputGridState,
} from '../../../../states/index';

export default function DateComp({
  label,
  placeholder,
  helperText,
  nameId,
  parentId,
  inputClass,
  isRequire,
  isActive,
  onInputChange,
  value,
}: {
  label: string;
  placeholder: string;
  helperText: string;
  nameId: string;
  parentId: string;
  inputClass: string;
  isRequire: boolean;
  isActive: boolean;
  onInputChange: () => void;
  value: string;
}) {
  const labelFontSize = useRecoilValue(labelSizeState);
  const labelGrid = useRecoilValue(labelGridState);
  const inputGrid = useRecoilValue(inputGridState);

  switch (inputClass) {
    //--------------------------------- Custom Limit Free ------------------------- */
    case 'General': {
      return (
        <Container className={ElementStyles.FormContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            container
          >
            <Grid
              xs={labelGrid}
              sm={labelGrid}
              md={labelGrid}
              item
              container
              alignItems="center"
            >
              <Grid item>
                <Typography
                  variant={labelFontSize}
                  className={ElementStyles.FormLabelText}
                >
                  <b>{label + ':'}</b>
                </Typography>
              </Grid>
            </Grid>

            <Grid xs={inputGrid} sm={inputGrid} md={inputGrid} item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disabled={!isActive}
                  value={value}
                  className={ElementStyles.FormOutlinedInputActive}
                  onChange={onInputChange}
                  renderInput={(params) => (
                    <TextField
                      className={
                        isActive
                          ? ElementStyles.FormDateTextFieldsInputActive
                          : ElementStyles.FormDateTextFieldsInputDisable
                      }
                      id={nameId}
                      name={nameId}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Container>
      );
    }

    //******************************************************** NotFound ********************************************************** */
    //------------------------------------//
    default: {
      return <p>NotFound TextField InputClass</p>;
    }
  }
}
