import axios from './axios-instance';
import { API_SERVER_URL } from '../../config';

import {
  MtAccountCodeListRespPayload,
  MtBiIdListRespPayload,
  MtBotCalendarListRespPayload,
  MtBrokerDataListRespPayload,
  MtCompanyIdListRespPayload,
  MtCountryListRespPayload,
  MtCurrencyListRespPayload,
  MtFundIdListRespPayload,
  MtIntraGroupListRespPayload,
  MtMsciIndustryListRespPayload,
  MtPortClassificationListRespPayload,
  MtProductCodeListRespPayload,
  MtRmIdListRespPayload,
} from '@invport/models';

const ACCOUNT_CODE = `m-account-code`;
const BI_ID = `m-bi-id`;
const BOT_CALENDAR = `m-bot-calendar`;
const BROKER_DATA = `m-broker-data`;
const COMPANY_ID = `m-company-id`;
const COUNTRY = `m-country`;
const CURRENCY = `m-currency`;
const FUND_ID = `m-fund-id`;
const INTRA_GROUP = `m-intra-group`;
const MSCI_INDUSTRY = `m-msci-industry`;
const CLASSIFICATION = `m-port-classification`;
const PRODUCT_CODE = `m-product-code`;
const RM_ID = `m-rm-id`;
const LIST = `list`;


//0
export const getMtAccountCodeList = async () =>
  (
    await axios.get<MtAccountCodeListRespPayload>(
      `${API_SERVER_URL}/${ACCOUNT_CODE}/${LIST}`,
    )
  ).data;
//1
export const getMtBiIdList = async () =>
  (await axios.get<MtBiIdListRespPayload>(`${API_SERVER_URL}/${BI_ID}/${LIST}`)).data;
//2
export const getMtBotCalendarList = async () =>
  (
    await axios.get<MtBotCalendarListRespPayload>(
      `${API_SERVER_URL}/${BOT_CALENDAR}/${LIST}`,
    )
  ).data;
//3
export const getMtBrokerDataList = async () =>
  (
    await axios.get<MtBrokerDataListRespPayload>(
      `${API_SERVER_URL}/${BROKER_DATA}/${LIST}`,
    )
  ).data;
//4
export const getMtCompanyIdList = async () =>
  (
    await axios.get<MtCompanyIdListRespPayload>(
      `${API_SERVER_URL}/${COMPANY_ID}/${LIST}`,
    )
  ).data;
//5
export const getMtCountryList = async () =>
  (await axios.get<MtCountryListRespPayload>(`${API_SERVER_URL}/${COUNTRY}/${LIST}`))
    .data;
//6
export const getMtCurrencyList = async () =>
  (await axios.get<MtCurrencyListRespPayload>(`${API_SERVER_URL}/${CURRENCY}/${LIST}`))
    .data;
//7
export const getMtFundIdList = async () =>
  (await axios.get<MtFundIdListRespPayload>(`${API_SERVER_URL}/${FUND_ID}/${LIST}`))
    .data;
//8
export const getMtIntraGroupList = async () =>
  (
    await axios.get<MtIntraGroupListRespPayload>(
      `${API_SERVER_URL}/${INTRA_GROUP}/${LIST}`,
    )
  ).data;
//9
export const getMtMsciIndustryList = async () =>
  (
    await axios.get<MtMsciIndustryListRespPayload>(
      `${API_SERVER_URL}/${MSCI_INDUSTRY}/${LIST}`,
    )
  ).data;
//10
export const getMtPortClassification = async () =>
  (
    await axios.get<MtPortClassificationListRespPayload>(
      `${API_SERVER_URL}/${CLASSIFICATION}/${LIST}`,
    )
  ).data;
//11
export const getMtProductCodeList = async () =>
  (
    await axios.get<MtProductCodeListRespPayload>(
      `${API_SERVER_URL}/${PRODUCT_CODE}/${LIST}`,
    )
  ).data;
//12
export const getMtRmIdList = async () =>
  (await axios.get<MtRmIdListRespPayload>(`${API_SERVER_URL}/${RM_ID}/${LIST}`)).data;
