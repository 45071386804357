import { atom } from 'recoil';

const _sheetName = 'invfundbenchmark';

//?------- Parameter Design ---------------
/*

------- Label / ID ------------
displayName = variable for display name in label.
sourceSystemName = variable for bank system name.
apiName = variable for call api. 

-------- Input ----------------
description = input placeholder.
inputLength = custom length limit of character.
inputType = (checkbox | date | radio | select | textfield) type of input.
inputClass = Custom format masking or validation.
possibleValue = (array) Value for dropdownlist / checkbox / radio.

-------- Boolean --------------
isRequire = (true/false) require field. can't be null.

isLinkValue = (true/false) Link the value from other input (linkValueId)
linkValueId = ID of input to Link data (display when isLinkValue == true).

isAutoGenerate = (true/false) auto generate value.
generateSource = source of auto generate (display when isAutoGenerate == true).

*/
/**
 textfield
 - NoLimitFree
 - noLimitCountNumber

 date
 - General

select
 - General

 */

const jsonData: { [key: string]: any } = {
  sheetName: _sheetName,
  inputInfo: [
    // ? --------------------------------- Start ---------------------------------------//
    // ---------------------------- 001 ---------------------------------//
    {
      displayName: 'Fund ID',
      sourceSystemName: 'FUND_ID',
      apiName: 'fund_id',

      description: 'Fund ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'fund_quote',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 002 ---------------------------------//
    {
      displayName: 'Fund Quote',
      sourceSystemName: 'FUND_QUOTE',
      apiName: 'fund_quote',

      description: 'Short abbreviation name (internally defined)',
      inputLength: '',
      inputType: 'select',
      inputClass: 'Autocomplete',
      possibleValue: ['/fund/list'],
      displayValue: ['/fund/list'],

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: true,
    },

    // ---------------------------- 003 ---------------------------------//
    {
      displayName: 'Date',
      sourceSystemName: 'DATA_DATE',
      apiName: 'data_date',

      description: 'Date analysts obtain the data',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue:'',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 004 ---------------------------------//
    {
      displayName: 'Benchmark Name',
      sourceSystemName: 'BENCHMARK_NAME',
      apiName: 'benchmark_name',

      description: 'Name of each fund used as a benchmark',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue:'',


      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 005 ---------------------------------//
    {
      displayName: 'Benchmark Vintage',
      sourceSystemName: 'BENCHMARK_VINTAGE',
      apiName: 'benchmark_vintage',

      description: 'Vintage year of each fund used as a benchmark',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitCountNumber',
      possibleValue: '',
      displayValue:'',


      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 006 ---------------------------------//
    {
      displayName: 'Benchmark Size',
      sourceSystemName: 'BENCHMARK_SIZE',
      apiName: 'benchmark_size',

      description: 'Size of each fund used as a benchmark',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue:'',


      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 007 ---------------------------------//
    {
      displayName: 'Investment Geography',
      sourceSystemName: 'INVESTMENT_GEOGRAPHY',
      apiName: 'investment_geography',

      description: 'Geography of each fund used as a investment',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue:'',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 008 ---------------------------------//
    {
      displayName: 'Benchmark Performance MOIC',
      sourceSystemName: 'BENCHMARK_PERFORMANCE_MOIC',
      apiName: 'benchmark_performance_moic',

      description: 'MOIC of each fund used as a benchmark',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue:'',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 009 ---------------------------------//
    {
      displayName: 'Benchmark Performance IRR',
      sourceSystemName: 'BENCHMARK_PERFORMANCE_IRR',
      apiName: 'benchmark_performance_irr',

      description: '% IRR of each fund used as a benchmark ',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue:'',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },


    // ? --------------------------------- End ---------------------------------------//
  ],
};

export const invfundbenchmark = atom({
  key: 'invfundbenchmark',
  default: jsonData,
});
