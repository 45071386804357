/*
Table Header

A1. Company ID / company_id
A2. Company Name / company_name
-----------------------
B1. Product Name / product_name
B2. Date / data_date
B3. Currency AC / currency
B4. No. of Shares / no_of_shares
B5. Dividend Per Share /dividend
B6. Total Dividend /dividend_amount
-------------------------
C1. Approval
C2. Detail
*/

export interface InvCompDividendBodyTableInterface {
  sheet_id: string;
  company_id: string;
  company_name: string;
  product_name: string;
  data_date: string;
  currency: string;
  no_of_shares: string;
  dividend: string;
  dividend_amount: string;
  approval: object;
  detail: string;
}

export function createInvCompDividendBodyTable(
  sheet_id: string,
  company_id: string,
  company_name: string,
  product_name: string,
  data_date: string,
  currency: string,
  no_of_shares: string,
  dividend: string,
  dividend_amount: string,
  approval: object,
  detail: string,
): InvCompDividendBodyTableInterface {
  return {
    sheet_id,
    company_id,
    company_name,
    product_name,
    data_date,
    currency,
    no_of_shares,
    dividend,
    dividend_amount,
    approval,
    detail,
  };
}

//-------------------------//
export interface InvCompDividendHeaderTableInterface {
  id: keyof InvCompDividendBodyTableInterface;
  label: string;
}
