import * as React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import { visuallyHidden } from '@mui/utils';
import Grid from '@mui/material/Grid';

import LayoutStyles from '../../../../styles/Layout.module.css';
import StarIcon from '@mui/icons-material/Star';
import ButtonDetail from '../button/ButtonDetail';

import {
  HistoryTableBodyData,
  HistoryTableHeadData,
  getComparator,
  Order,
} from '../../../../models/index';

import { tableOrderByState, tableOrder } from '../../../../states/index';
import { isDetailHistoryModal } from '../../../../states/index';
import { currentHistoryDataState, currentIdState } from '../../../../states/index';

import { stableSort } from './function/stableSort';

//*********************************** Table Header **************************************//

//?----------------------------------- History Profile -------------------------
function HistoryTableHeadComp({
  order,
  orderBy,
  onRequestSort,
  rowHeadData,
}: {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof HistoryTableBodyData,
  ) => void;
  order: Order;
  orderBy: string;
  rowHeadData: any;
}) {
  const createSortHandler =
    (property: keyof HistoryTableBodyData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const rowHead: HistoryTableHeadData[] = rowHeadData;

  return (
    <TableHead>
      <TableRow>
        {rowHead.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function HistoryTableBodyComp({
  rowData,
  labelId,
  detailModalClick,
  isLastVersion,
}: {
  rowData: any;
  labelId: any;
  detailModalClick: any;
  isLastVersion: boolean;
}) {
  return (
    <TableRow hover key={labelId}>
      <TableCell align="left">{isLastVersion ? <StarIcon /> : <div></div>}</TableCell>

      {/* <TableCell component="th" id={labelId} scope="rowData">
        {rowData['id']}
      </TableCell> */}

      <TableCell align="left">{rowData['edit_date']}</TableCell>
      <TableCell align="left">{rowData['approver']}</TableCell>

      {/* <TableCell align='left'>{rowData['detail']}</TableCell> */}
      <TableCell align="left">
        <ButtonDetail onClick={detailModalClick} />
      </TableCell>
    </TableRow>
  );
}

//*********************************** Table Render **************************************//

// ?---------------------- TableHead Render ---------------------
function TableHeadRender({
  rowHeadData,
  tableMode,
}: {
  rowHeadData: any;
  tableMode: string;
}) {
  const [order, setOrder] = useRecoilState(tableOrder);
  const [orderBy, setOrderBy] = useRecoilState(tableOrderByState);

  const handleHistoryRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof HistoryTableBodyData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  switch (tableMode) {
    case 'history_table': {
      return (
        <HistoryTableHeadComp
          order={order}
          orderBy={orderBy}
          onRequestSort={handleHistoryRequestSort}
          rowHeadData={rowHeadData}
        />
      );
    }
    //------------------------------------//
    default: {
      return <div>{'NotFound TableHead'}</div>;
    }
  }
}

// ?---------------------- TableBody Render --------------------
function TableBodyRender({
  rowData,
  labelId,
  tableMode,
  detailModalClick,
  isLastVersion,
}: {
  rowData: any;
  labelId: any;
  tableMode: string;
  detailModalClick: any;
  isLastVersion: boolean;
}) {
  switch (tableMode) {
    case 'history_table': {
      return (
        <HistoryTableBodyComp
          rowData={rowData}
          labelId={labelId}
          detailModalClick={detailModalClick}
          isLastVersion={isLastVersion}
        />
      );
    }

    //------------------------------------//
    default: {
      return <div>{'NotFound TableBody'}</div>;
    }
  }
}

//Global var
const GenRowPerPage = 8;

export default function HistoryTable({
  rowBodyData,
  rowHeadData,
  tableMode,
}: {
  rowBodyData: any;
  rowHeadData: any;
  tableMode: string;
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(GenRowPerPage);
  const order = useRecoilValue(tableOrder);
  const orderBy = useRecoilValue(tableOrderByState);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  //---------------------------------------------------------------------
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, GenRowPerPage));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowBodyData.length) : 0;

  //---------------------------- MainDetailModal --------------------------

  const setOpenDetailHistoryModal = useSetRecoilState(isDetailHistoryModal);

  const setCurrentHistoryData = useSetRecoilState(currentHistoryDataState);


  const handleDetailHistoryModalOpen = (rowData: any) => () => {
    setCurrentHistoryData(rowData);
    setOpenDetailHistoryModal(true);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        {/* <Paper sx={{ width: '100%', mb: 2 }}> */}
        <Grid className={LayoutStyles.tablePortalCard}>
          {/* <TableToobarHeader /> */}
          <TableContainer>
            {/* <Table sx={{ minWidth: 500 }} aria-labelledby="tableTitle" size="small"> */}
            <Table aria-labelledby="tableTitle" size="small">
              {/* //?------------------ Head ------------------------- */}
              <TableHeadRender rowHeadData={rowHeadData} tableMode={tableMode} />
              {/* //?------------------ Head ------------------------- */}
              <TableBody>
                {stableSort(rowBodyData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `row-history-data-id-${index}`;
                    const rowData: { [key: string]: any } = row;
                    let isLastVersion = false;
                    if (index == 0) {
                      isLastVersion = true;
                    } else {
                      isLastVersion = false;
                    }
                    // ?--------------------------- Body ------------------------/

                    return (
                      <TableBodyRender
                        key={labelId}
                        rowData={rowData}
                        labelId={labelId}
                        tableMode={tableMode}
                        detailModalClick={handleDetailHistoryModalOpen(rowData)}
                        isLastVersion={isLastVersion}
                      />
                    );
                    // ?--------------------------- Body ------------------------/
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[GenRowPerPage]}
            component="div"
            count={rowBodyData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
        {/* </Paper> */}
      </Box>
    </>
  );
}
