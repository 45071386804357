import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getCompany } from '../../api/index';

//Layout
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

//Element
import MasterTable from '../common/table/MasterTable';

import LayoutStyles from '../../../styles/Layout.module.css';

import {
  companyRowsState,
  companyHeadersState,
  isLoadedMasterRowsState,
  tableConfigState,
} from '../../../states/index';
import {
  OrderBy,
  requestCompanyMainInterface,
  createCompanyTableBodyData,
  CompanyTableBodyData,
} from '../../../models/index';

export default function CompanyProfileCard({onPaginationNextClick}:{onPaginationNextClick: (
  _currentSystemSheetName: string,
  _sheet_id: string,
  _rowsLengthTotal: number,
  _currentSearchKeyword: string,
  _currentSortBy: string,
) => void;}) {
  const setIsLoadedRows = useSetRecoilState(isLoadedMasterRowsState);
  const tableConfig = useRecoilValue(tableConfigState);
  const [companyRows, setCompanyRows] = useRecoilState(companyRowsState);
  const companyHeaders = useRecoilValue(companyHeadersState);


  useEffect(() => {
    (async () => {
      let rowsData: CompanyTableBodyData[] = [];

      const requestCompanyMain: requestCompanyMainInterface = {
        page: 1,
        page_length: tableConfig['defaultRowsLength'],
        sort_by: '',
        order: OrderBy.asc,
        filter: '',
      };

      const getCompanyData: { [key: string]: any } = await getCompany(
        requestCompanyMain,
      );

      // console.log(getCompanyData);

      Object.keys(getCompanyData['data']).map((index: string) => {
        let sheet_id = getCompanyData['data'][index]['sheet_id'];
        let bi_id = getCompanyData['data'][index]['bi_id'];
        let company_id = getCompanyData['data'][index]['company_id'];
        let company_name_en = getCompanyData['data'][index]['company_name_en'];
        let company_name_th = getCompanyData['data'][index]['company_name_th'];
        let approval_status = getCompanyData['data'][index]['approval_status'];

        let member = createCompanyTableBodyData(
          sheet_id,
          bi_id,
          company_id,
          company_name_en,
          approval_status,
          'detail button',
        );

        rowsData.push(member);
      });
      setCompanyRows(rowsData);
      setIsLoadedRows(true);
    })();
  }, []);

  return (
    <Paper className={LayoutStyles.mainContentCard} square>
      <Grid item container className={LayoutStyles.mainGridCard}>
        <Grid item sm={12} md={12} lg={12}>
          {/* //------------ Master Modal -------------- */}

          <MasterTable
            rowBodyData={companyRows}
            rowHeadData={companyHeaders}
            portalMode="company_profile"
            onPaginationNextClick={onPaginationNextClick}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
