//---------------------------------------- Import React --------------------------------------
//React

import ElementStyles from '../../../../styles/Element.module.css';
//Chips
import Chip from '@mui/material/Chip';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

function ButtonApproval({ isApproved, onClick }: { isApproved: boolean; onClick:()=>void }) {
  return (
    <>
      <Chip
        icon={<ThumbUpIcon />}
        color={!isApproved ? 'info' : 'secondary'}
        className={ElementStyles.ButtonAdd}
        label={!isApproved ? 'Approved' : 'Approve'}
        onClick={onClick}
        clickable
        disabled={!isApproved}
      />
    </>
  );
}

export default ButtonApproval;
