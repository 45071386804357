import { atom } from 'recoil';
import { arrCurrency } from '../common';

const _sheetName = 'invfundnav';

//?------- Parameter Design ---------------
/*

------- Label / ID ------------
displayName = variable for display name in label.
sourceSystemName = variable for bank system name.
apiName = variable for call api. 

-------- Input ----------------
description = input placeholder.
inputLength = custom length limit of character.
inputType = (checkbox | date | radio | select | textfield) type of input.
inputClass = Custom format masking or validation.
possibleValue = (array) Value for dropdownlist / checkbox / radio.

-------- Boolean --------------
isRequire = (true/false) require field. can't be null.

isLinkValue = (true/false) Link the value from other input (linkValueId)
linkValueId = ID of input to Link data (display when isLinkValue == true).

isAutoGenerate = (true/false) auto generate value.
generateSource = source of auto generate (display when isAutoGenerate == true).

*/
/**
 textfield
 - NoLimitFree
 - noLimitCountNumber

 date
 - General

select
 - General

 */

const jsonData: { [key: string]: any } = {
  sheetName: _sheetName,
  inputInfo: [
    // ? --------------------------------- Start ---------------------------------------//
    // ---------------------------- 001 ---------------------------------//
    {
      displayName: 'Fund ID',
      sourceSystemName: 'FUND_ID',
      apiName: 'fund_id',

      description: 'Fund ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'fund_quote',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 002 ---------------------------------//
    {
      displayName: 'Fund Quote',
      sourceSystemName: 'FUND_QUOTE',
      apiName: 'fund_quote',

      description: 'Short abbreviation name (internally defined)',
      inputLength: '',
      inputType: 'select',
      inputClass: 'Autocomplete',
      possibleValue: ['/fund/list'],
      displayValue: ['/fund/list'],

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: true,
    },

    // ---------------------------- 003 ---------------------------------//
    {
      displayName: 'Date',
      sourceSystemName: 'DATA_DATE',
      apiName: 'data_date',

      description: 'Date analysts obtain the data',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 004 ---------------------------------//
    {
      displayName: 'Reporting Date',
      sourceSystemName: 'REPORTING_DATE',
      apiName: 'reporting_date',

      description: "Date as listed in fund's report",
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 005 ---------------------------------//
    {
      displayName: 'Accounting Period (Year)',
      sourceSystemName: 'ACCOUNTING_PERIOD_YEAR',
      apiName: 'accounting_period_year',

      description: 'Accouting period, (quarterly)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 006 ---------------------------------//
    {
      displayName: 'Accounting Period (Quarter)',
      sourceSystemName: 'ACCOUNTING_PERIOD_QUARTER',
      apiName: 'accounting_period_quarter',

      description: 'Accouting period, (quarterly)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 007 ---------------------------------//
    {
      displayName: 'Total Assets',
      sourceSystemName: 'TOTAL_ASSETS',
      apiName: 'total_assets',

      description: "Total assets as reported in funds' statements",
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 008 ---------------------------------//
    {
      displayName: 'Total Liabilities',
      sourceSystemName: 'TOTAL_LIABILITIES',
      apiName: 'total_liabilities',

      description: "Total liabilities as reported in funds' statements",
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 009 ---------------------------------//
    {
      displayName: 'Total Equity',
      sourceSystemName: 'TOTAL_EQUITY',
      apiName: 'total_equity',

      description: "Total equities as reported in funds' statements",
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 010 ---------------------------------//
    {
      displayName: 'Total Income',
      sourceSystemName: 'TOTAL_INCOME',
      apiName: 'total_income',

      description: "Total income as reported in funds' statements",
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 011 ---------------------------------//
    {
      displayName: 'Total Expenses',
      sourceSystemName: 'TOTAL_EXPENSES',
      apiName: 'total_expenses',

      description: "Total expenses as reported in funds' statements",
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 012 ---------------------------------//
    {
      displayName: 'Currency AC',
      sourceSystemName: 'CURRENCY',
      apiName: 'currency',

      description: 'Denominated Currency',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: arrCurrency,
      displayValue: arrCurrency,

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'fund_quote',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 013 ---------------------------------//
    {
      displayName: 'Reported NAV AC',
      sourceSystemName: 'REPORTED_NAV_AMOUNT',
      apiName: 'reported_nav_amount',

      description: "Reported value of BBL's stake in the fund",
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 014 ---------------------------------//
    {
      displayName: 'Official NAV AC',
      sourceSystemName: 'OFFICIAL_NAV',
      apiName: 'official_nav',

      description: 'Official NAV for accounting purpose (BBL Accounting Report)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ? --------------------------------- End ---------------------------------------//
  ],
};

export const invfundnav = atom({
  key: 'invfundnav',
  default: jsonData,
});
