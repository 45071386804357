// ? ------------------------------ Operation Table ---------------------------------------

/*
Table Header

A1. Company ID / company_id
-----------------------

B1. Company Name / company_name
B2. Date / data_date
B3. WACC / wacc
B4. Terminal Growth Rate / terminal_growth
B5. Fair Value AC / fair_value

-------------------------
C1. Approval
C2. Detail
*/

export interface InvCompFairBodyTableInterface {
  sheet_id: string;
  company_id: string;
  company_name: string;
  data_date: string;
  wacc: string;
  terminal_growth: string;
  fair_value: string;
  approval: object;
  detail: string;
}

export function createInvCompFairBodyTable(
  sheet_id: string,
  company_id: string,
  company_name: string,
  data_date: string,
  wacc: string,
  terminal_growth: string,
  fair_value: string,
  approval: object,
  detail: string,
): InvCompFairBodyTableInterface {
  return {
    sheet_id,
    company_id,
    company_name,
    data_date,
    wacc,
    terminal_growth,
    fair_value,
    approval,
    detail,
  };
}

//-------------------------//
export interface InvCompFairHeaderTableInterface {
  id: keyof InvCompFairBodyTableInterface;
  label: string;
}
