import axios from './axios-instance';
import {
  API_SERVER_URL,
  API_COMPANY_URL,
  API_FUND_URL,
  API_PRODUCT_URL,
  API_FS_URL,
  API_COMPCASHFLOW_URL,
  API_FUNDCASHFLOW_URL,
  API_PORT_URL,
} from '../../config';

const API_TEST_URL = `foo`;
const API_LIST_URL = `list`;

export const getCompanyList = async () =>
  (await axios.get(`${API_SERVER_URL}/${API_COMPANY_URL}/${API_LIST_URL}`)).data;

export const getFundList = async () =>
  (await axios.get(`${API_SERVER_URL}/${API_FUND_URL}/${API_LIST_URL}`)).data;

export const getProductList = async () =>
  (await axios.get(`${API_SERVER_URL}/${API_PRODUCT_URL}/${API_LIST_URL}`)).data;

export const getFsList = async () =>
  (await axios.get(`${API_SERVER_URL}/${API_FS_URL}/${API_LIST_URL}`)).data;

export const getCompCashFlowList = async () =>
  (await axios.get(`${API_SERVER_URL}/${API_COMPCASHFLOW_URL}/${API_LIST_URL}`)).data;

export const getFundCashFlowList = async () =>
  (await axios.get(`${API_SERVER_URL}/${API_FUNDCASHFLOW_URL}/${API_LIST_URL}`)).data;

export const getPortList = async () =>
  (await axios.get(`${API_SERVER_URL}/${API_PORT_URL}/${API_LIST_URL}`)).data;

export const getTestList = async () =>
  (await axios.get(`${API_SERVER_URL}/${API_TEST_URL}/${API_LIST_URL}`)).data;
