import { atom } from 'recoil';
import * as InvModels from '@invport/models';
import { newDate, newText, newNumber } from '../defaultvalue/defaultvalue';
import {
  MtAccountCode,
  MtBiId,
  MtBotCalendar,
  MtBrokerData,
  MtCompanyId,
  MtCountry,
  MtCurrency,
  MtFundId,
  MtIntraGroup,
  MtMsciIndustry,
  MtPortClassification,
  MtProductCode,
  MtRmId,
} from '@invport/models';

//------------------------------- Master --------------------------------------

const arr: any[] = [];
export const currentMasterSheetState = atom({
  key: 'currentMasterSheet',
  default: arr,
});

const bi_id: MtBiId = {
  id: newNumber, // integer
  product_name: newText,
  data_type: newText,
  public_private: newText,
  tdr_nontdr: newText,
  invested_year: newNumber, // integer
  running_number: newText,
  running_number_4: newText,
};

export const currentSelectedBIidState = atom({
  key: 'currentSelectedBIid',
  default: bi_id,
});

const company_id: MtCompanyId = {
  id: newNumber, // integer
  company_name: newText,
  company_symbol: newText,
  first_investment_day: newText, // date newText
  data_type: newText,
  public_private: newText,
  invested_year: newNumber, // integer
  running_number: newText,
  running_number_4: newText,
};

export const currentSelectedCompanyIdState = atom({
  key: 'currentSelectedCompanyId',
  default: company_id,
});

const fund_id: MtFundId = {
  id: newNumber, // integer
  fund_name: newText,
  fund_type: newText,
  first_investment_date: newText, // date newText
  data_type: newText,
  public_private: newText,
  invested_year: newNumber, // integer
  running_number: newText,
  running_number_4: newText,
};



export const currentSelectedFundIDState = atom({
  key: 'currentSelectedFundID',
  default: fund_id,
});

const rm_id: MtRmId = {
  id: newNumber, // integer
  company_name: newText,
};


export const currentSelectedRmIdState = atom({
  key: 'currentSelectedRmId',
  default: rm_id,
});
//------------------------------- Autogen --------------------------------------

export const currentAutogen = atom({
  key: 'currentAutogen',
  default: {},
});

export const currentSelectedNameState = atom({
  key: 'currentSelectedName',
  default: {},
});

export const currentSelectedProductNameState = atom<InvModels.productListDataInterface>({
  key: 'currentSelectedProductName',
  default: {
    product_id: newText,
    allowance_impair_ccy: newNumber,
    allowance_permanent: newNumber,
    book_value: newNumber,
    company_name: newText,
    currency: InvModels.Currency.Thb,
    current_stake: newNumber,
    first_investment_date: newText,
    investment_cost_thb: newNumber,
    investment_instrument: InvModels.Holding.CommonStock,
    investment_port: InvModels.Port.Amc,
    no_of_share: newNumber,
    product_name: newText,
    strike_price: newNumber,
  },
});

export const currentSelectedProductName2State = atom<InvModels.productListDataInterface>(
  {
    key: 'currentSelectedProductName2',
    default: {
      product_id: newText,
      allowance_impair_ccy: newNumber,
      allowance_permanent: newNumber,
      book_value: newNumber,
      company_name: newText,
      currency: InvModels.Currency.Thb,
      current_stake: newNumber,
      first_investment_date: newText,
      investment_cost_thb: newNumber,
      investment_instrument: InvModels.Holding.CommonStock,
      investment_port: InvModels.Port.Amc,
      no_of_share: newNumber,
      product_name: newText,
      strike_price: newNumber,
    },
  },
);

export const currentSelectedCompanyNameState = atom<InvModels.companyListDataInterface>({
  key: 'currentSelectedCompanyName',
  default: {
    company_id: newText,
    bi_id: newText,
    company_name_en: newText,
    company_name_th: newText,
    rm_id: newText,
  },
});

export const currentSelectedFundQuoteState = atom<InvModels.fundListDataInterface>({
  key: 'currentSelectedFundQuote',
  default: {
    fund_id: newText,
    currency: InvModels.Currency.Thb,
    fund_name: newText,
    fund_quote: newText,
    fund_thainame: newText,
  },
});

export const currentSelectedFsState = atom<InvModels.fsListDataInterface>({
  key: 'currentSelectedFs',
  default: {
    fs_id: newText,
    company_id: newText,
    paid_up_capital_no_of_share: newNumber,
    par_value: newNumber,
  },
});

export const currentSelectedCompCashFlowState =
  atom<InvModels.compCashFlowListDataInterface>({
    key: 'currentSelectedCompCashFlow',
    default: {
      txn_id: newText,
      net_cashflow: newNumber,
      investment_cost: newNumber,
      investment_cost_thb: newNumber,
    },
  });

export const currentSelectedFundCashFlowState = atom({
  key: 'currentSelectedFundCashFlow',
  default: {},
});

export const currentSelectedPortState = atom({
  key: 'currentSelectedPort',
  default: {},
});

export const currentSelectedTXNIdState = atom({
  key: 'currentSelectedTXNId',
  default: {},
});

//--------------------------invbankaccount--------------------------------//

export const currentFillInvBankAccountState = atom<InvModels.InvBankAccount>({
  key: 'currentFillInvBankAccount',
  default: {
    id: newText, // --- Autogen from MASTERSHEET
    name: newText, // --- DROPDOWN from INVCOMPDEFINITIVE and INVFUNDDEFINITIVE
    date: newDate, // date string
    account_no: newText,
    account_name: newText,
    account_bank_name: newText,
    bank_address: newText,
    bank_swift_code: newText,
    aba_no: newNumber,
    corr_name: newText,
    corr_swift_code: newNumber,
  },
});

//----------------------------------------------------------//
export const currentFillInvBankCapitalState = atom<InvModels.InvBankCapital>({
  key: 'currentFillInvBankCapital',
  default: {
    data_date: newDate, // date string
    reporting_date: newDate,
    status: false, // Before, After
    tier_1_capital: newNumber,
    tier_2_capital: newNumber,
  },
});

//---------------------------------------------------------//

export const currentFillInvBankDiscountFxState = atom<InvModels.InvBankDiscountFx>({
  key: 'currentFillInvBankDiscountFx',
  default: {
    currency: newText,
    date: newDate, // date string
    fx_rate: newNumber, // --- Autogen from Treasury Notepad
  },
});

//---------------------------------------------------------//

export const currentFillInvBankFxState = atom<InvModels.InvBankFx>({
  key: 'currentFillInvBankFx',
  default: {
    currency: newText,
    date: newDate, // date string
    time: newNumber, // --- Autogen
    buying_rate: newNumber, // --- Autogen from BBL Website
    selling_rate: newNumber, // --- Autogen from BBL Website
  },
});

//-------------------------invboardapprovedmemo--------------------------------//

export const currentFillInvBoardApprovedMemoState = atom<InvModels.InvBoardApprovedMemo>(
  {
    key: 'currentFillInvBoardApprovedMemo',
    default: {
      id: newText, // --- Autogen from MASTERSHEET
      name: newText, // --- DROPDOWN from INVCOMPDEFINITIVE and INVFUNDDEFINITIVE
      data_date: newDate, // date string
      txn_id: newText, // --- Linked to INVFUNDCASHFLOW and INVCOMPCASHFLOW
      bid_memo_no: newText,
      bid_memo_date: newDate, // date string
      memo_request: newText,
      memo_description: newText,
      exec_board_approval_no: newText,
      exec_board_approval_date: newDate, // date string
      board_approval_no: newText,
      board_approval_date: newDate, // date string
      approval_budget_currency: InvModels.Currency.Thb,
      approval_budget_amount: newNumber,
      bbl_commitment_amt: newNumber,
      fund_commitment_amt: newNumber,
      investment_thesis: newText,
      expected_irr: newNumber,
      remark: newText,
    },
  },
);

//---------------------------------------------------------//
{
  /* <Pick<InvModels.InvBualuangFundNav,'fund_id'|'fund_thainame'>> เอา*/
}
{
  /* <Omit<InvModels.InvBualuangFundNav,'fund_id'|'fund_thainame'>> ไม่เอา*/
}

export const currentFillInvBualuangFundNavState = atom<InvModels.InvBualuangFundNav>({
  key: 'currentFillInvBualuangFundNav',
  default: {
    date: newDate, // date string, Autogenerated
    fund_id: newText, // --- Autogenerated from INVFUNDDEFINITIVE
    fund_name: newText, // --- Matched with fund name from INVFUNDDEFINITIVE
    fund_thainame: newText, // --- Autogenerated from INVFUNDDEFINITIVE
    unit_price: newNumber, // --- Autogenerated from BCAP and BBLAM
    offer_price: newNumber, // --- Autogenerated from BCAP and BBLAM
    redeem_price: newNumber, // --- Autogenerated from BCAP and BBLAM
    nav: newNumber, // --- Autogenerated from BCAP and BBLAM
  },
});

//-------------------------invcertificate--------------------------------//

export const currentFillInvCertificateState = atom<InvModels.InvCertificate>({
  key: 'currentFillInvCertificate',
  default: {
    id: newText, // --- Autogen from MASTERSHEET
    name: newText, // --- DROPDOWN from INVCOMPDEFINITIVE and INVFUNDDEFINITIVE
    data_date: newDate, // date string
    certificate_no: newText, // --- xxxx (can be alphabet/figure), Acceptance (for custodian)
    registered_date: newDate, // date string
    registered_id: newNumber, // integer
    book_value: newNumber, // --- Autogen from INVCOMPPRODUCT
    no_of_share: newNumber,
    par_per_share: newNumber, // --- Autogen from INVCOMPFS
    total_share: newNumber, // --- Autogen from INVCOMPPRODUCT
    currency: InvModels.Currency.Thb, // --- Autogen from INVCOMPPRODUCT
    custodian: false, // Yes, No
    custodian_name: newText,
    remark: newText,
  },
});

//---------------------------------------------------------//

export const currentFillInvCompAdjustEquityState = atom<InvModels.InvCompAdjustEquity>({
  key: 'currentFillInvCompAdjustEquity',
  default: {
    bi_id: newText, // --- Autogen from INVCOMPDEFINITIVE
    rm_id: newText, // --- Autogen from INVCOMPDEFINITIVE
    company_id: newText, // --- Autogen from INVCOMPDEFINITIVE
    company_name: newText, // --- Autogen from INVCOMPPRODUCT
    product_name: newText, // --- DROPDOWN from INVCOMPPRODUCT
    infra_group_code: newText, // --- Autogen from INTRA GROUP Sheet
    txn_id: newText, // --- Autogen
    reporting_date: newDate, // date newText
    txn_date: newDate, // date newText
    remark: newText,

    // Accounting Purposes
    investment_instrument: InvModels.Holding.CommonStock, // --- Autogen from INVCOMPPRODUCT
    investment_port: InvModels.Port.Amc, // --- Autogen from INVCOMPPRODUCT
    currency: InvModels.Currency.Thb, // --- Autogen from INVCOMPPRODUCT
    no_of_share: newNumber, // integer
    investment_cost_pretransaction: newNumber, // --- Autogen from invcompproduct (Pre Transaction end of month)
    investment_cost_pretransaction_thb: newNumber, // --- Autogen from invcompproduct (Pre Transaction end of month)
    allowance_impairment: newNumber, // --- Autogen from invcompproduct (Pre Transaction end of month)
    allowance_permanent: newNumber, // --- Autogen from invcompproduct (Pre Transaction end of month)
    allowance_permanent_thb: newNumber, // --- Autogen FORMULAR -> = Investment Cost (Orginal CCY) AC - Alowance Impairment (Orginal CCY) AC + Allowance Permanent (Orginal CCY) AC
    adjusted_equity_pretransaction: newNumber, // --- ดึง Adjusted Equity Amount AC (THB) (Post Transaction) AC (การ record ก่อนหน้า)
    income: InvModels.IncomeType.Profit,
    total_adjusted_equity: newNumber,
    adjusted_equity_posttransaction: newNumber, // --- Autogen FORMULAR
    adjusted_cost: newNumber, // --- Autogen FORMULAR
  },
});

//---------------------------------------------------------//

export const currentFillInvCompAdjustParState = atom<InvModels.InvCompAdjustPar>({
  key: 'currentFillInvCompAdjustPar',
  default: {
    bi_id: newText, // --- Autogen from INVCOMPDEFINITIVE
    rm_id: newText, // --- Autogen from INVCOMPDEFINITIVE
    company_id: newText, // --- Autogen from INVCOMPDEFINITIVE
    company_name: newText, // --- Autogen from INVCOMPPRODUCT
    product_name: newText, // --- DROPDOWN from INVCOMPPRODUCT
    txn_id: newText, // --- Autogen
    txn_date: newDate, // date string
    remark: newText,

    // Accounting Purposes
    investment_instrument: InvModels.Holding.CommonStock,
    investment_port: InvModels.Port.Amc, // --- Autogen from Mastersheet AC Questionare
    currency: InvModels.Currency.Thb, // --- Autogen from INVCOMPDEFINITIVE
    no_of_share_pretransaction: newNumber, // --- Autogen FORMULAR ->
    investment_cost_pretransaction: newNumber, // --- Autogen FORMULAR ->
    investment_cost_pretransaction_thb: newNumber, // --- Autogen FORMULAR ->
    par_value_pretransaction: newNumber, // --- Autogen from INVCOMPFS
    current_stake_pretransaction: newNumber, // --- Autogen FORMULAR -> = No. of Share (from INVCOMPCASHFLOW)/Paid Up Capital_No. of  Shares (from INVCOMPFS)
    adjusted_par_method: InvModels.ParAdjustmentMethod.AdjustParValue,
    paid_up_capital_no_of_share_pretransaction: newNumber,
    no_of_share_posttransaction: newNumber, // --- Autogen FORMULAR ->
    investment_cost: newNumber, // --- Autogen FORMULAR and linked to INVCOMPPRODUCT ->
    investment_cost_thb: newNumber, // --- Autogen FORMULAR and linked to INVCOMPPRODUCT ->
    par_value_posttransaction: newNumber, // --- Autogen FORMULAR ->
    current_stake_posttransaction: newNumber, // --- Autogen FORMULAR and linked to INVCOMPPRODUCT ->
    cashflow_amount_posttransaction: newNumber,
    cashflow_amount_posttransaction_thb: newNumber, // --- Autogen FORMULAR and linked to INVCOMPPRODUCT
    paid_up_capital_no_of_share_posttransaction: newNumber,
  },
});

//---------------------------------------------------------//

export const currentFillInvCompBotState = atom<InvModels.InvCompBot>({
  key: 'currentFillInvCompBot',
  default: {
    bi_id: newText, // --- Autogen from INVCOMPDEFINITIVE
    rm_id: newText, // --- Autogen from INVCOMPDEFINITIVE
    company_id: newText, // --- Autogen from INVCOMPDEFINITIVE
    company_name: newText, // --- DROPDOWN
    recording_date: newDate, // date string
    document_no: newText,
    request_type: newText,
    request_detail: newText, // Remark: Possible values in the workbook sheet
    document_start_date: newDate, // date string
    document_end_date: newDate, // date string
    current_stake: newNumber, // --- Autogen FORMULAR -> = No. of Share (from INVCOMPCASHFLOW)/Paid Up Capital_No. of  Shares (from INVCOMPFS)
  },
});

//---------------------------------------------------------//

// const [state, setState] = rev(currentFillInvCompCashFlowState)
// if (state.currency === 'THB') return ,div>THB Baht
// setState({...state, currency: 'USD', txn_id: '123'})
// const testArr = [{key: 1, v: 1},{key: 2, v: 2}]
// const arr2 = [...testArry,{key: 1, v: 99}] // ===> [] x 3
// const arr2 = [...testArry.filter(item => item.key === 1),{key: 1, v: 99}] // ===> [] x 2
// export const currentFillInvCompCashFlowStatessss = atom<Partial<InvModels.InvCompCashFlow>>({key: 'fdgdfg', default: {}})

export const currentFillInvCompCashFlowState = atom<InvModels.InvCompCashFlow>({
  key: 'currentFillInvCompCashFlow',
  default: {
    bi_id: newText, // --- Autogen from MASTERSHEET
    rm_id: newText, // --- Autogen from MASTERSHEET
    company_name: newText, // --- Autogen from INVCOMPPRODUCT
    product_name: newText, // --- DROPDOWN from INVCOMPPRODUCT
    txn_id: newText, // --- Autogen
    first_investment_date: newDate, // date string
    txn_date: newDate, // date string
    investment_instrument: InvModels.Holding.CommonStock,
    remark: newText,

    // Accounting Purposes
    investment_port: InvModels.Port.Amc, // --- Autogen from INVCOMPPRODUCT
    currency: InvModels.Currency.Thb, // --- Autogen from INVCOMPDEFINITIVE
    txn_type: InvModels.CompTransaction.Buy,
    cashflow_direction: InvModels.CashflowDirection.Inflow, // --- Autogen from transaction type
    exchange_rate: newText, // --- Autogen from MASTERSHEET
    no_of_share: newNumber, // integer
    price_per_share: newNumber,
    cashflow_amount: newNumber, // --- Autogen FORMULAR -> = No. of Share * Price per share
    cashflow_adjustment: newNumber,
    net_cashflow: newNumber, // --- Autogen FORMULAR -> = Cashflow AC + Cashflow Adjustment
    net_cashflow_thb: newNumber, // --- Autogen FORMULAR -> = (Cashflow AC + Cashflow Adjustment)*Exchange Rate
    brokerage_name: InvModels.Broker.Asp003, // --- Autogen from MASTERSHEET
    commission: newNumber, // --- Autogen FORMULAR -> = Cashflow * Broker Rate (Linked to Broker Name)
    vat_levey_fee: newNumber, // --- Autogen FORMULAR -> = Commission * 7%
    commission_vat: newNumber, // --- Autogen FORMULAR -> = Commission + VAT&Levey Fee
    withholding_tax: newNumber, // --- Autogen FORMULAR -> = Commission * 3%
    custodian_fee: newNumber,
    advisory_fee_kc: newNumber,
    miscellaneous_exp: newNumber,
    allowance_impairment: newNumber,
    allowance_permanent: newNumber,
    no_of_share_pretransaction: newNumber, // --- Autogen FORMULAR -> Before transaction date
    investment_cost_pretransaction_ccy: newNumber, // --- Autogen FORMULAR -> Before transaction date
    investment_cost_pretransaction_thb: newNumber, // --- Autogen FORMULAR -> Before transaction date
    current_stake_pretransaction: newNumber, // --- Autogen FORMULAR -> Before transaction date
    no_of_share_posttransaction: newNumber, // --- Autogen FORMULAR and linked to INVCOMPPRODUCT -> = Before transaction date +/- change of transaction
    investment_cost: newNumber, // --- Autogen FORMULAR and linked to INVCOMPPRODUCT -> = Before transaction date +/- change of transaction
    investment_cost_thb: newNumber, // --- Autogen FORMULAR and linked to INVCOMPPRODUCT -> = Before transaction date +/- change of transaction
    current_stake_posttransaction: newNumber, // --- Autogen FORMULAR and linked to INVCOMPPRODUCT -> = Before transaction date +/- change of transaction
  },
});

//------------------------- invcompdefinitive --------------------------------//

export const currentFillInvCompDefinitiveState = atom<InvModels.InvCompDefinitive>({
  key: 'currentFillInvCompDefinitive',
  default: {
    // bi_id: newText, // --- Autogen from Mastersheet
    rm_id: newText, // --- Autogen from Mastersheet
    data_date: newDate, // date string
    company_id: newText, // --- Autogen from Mastersheet
    registered_id: newText,
    registered_date: newDate, // date string
    company_name_en: newText,
    company_name_th: newText,
    company_symbol: newText,
    equity_short_name: newText,
    company_group: newText,
    company_stage: InvModels.CompStage.Accelerator,
    strategic_investment: false, // Yes / No
    exit_flag: false, // Yes / No
    business_type_en: newText,
    business_type_th: newText,
    msci_industry: InvModels.Sector.CommunicationServices,
    main_product_1: newText,
    main_product_2: newText,
    main_product_3: newText,
    main_product_4: newText,
    main_product_5: newText,
    domicile_country: newText,
    invested_country: newText,
    fund_id: newNumber, // integer
    address_en: newText,
    address_th: newText,
    remark: newText,

    // Risk Management Purposes
    alternative_class: false, // Alternative / Non-Alternative
    fintech_class: false, // Yes / No

    // Accounting Purposes
    isic_code: newText,
    bot_exclusion_flag: false, // Yes / No
    consolidated_flag: InvModels.Consolidation.NonSoloConsolidated,
    ownership_type: InvModels.Ownership.Branch,
  },
});

//----------------------- invcompdirector ----------------------------------//

export const currentFillInvCompDirectorState = atom<InvModels.InvCompDirector>({
  key: 'currentFillInvCompDirector',
  default: {
    bi_id: newText, // --- Autogen from INVCOMPDEFINITIVE
    company_id: newText, // --- Autogen from INVCOMPDEFINITIVE
    company_name: newText, // --- DROPDOWN from INVCOMPDEFINITIVE
    committee_name: newText,
    committee_position: newText,
    appointment_date: newDate, // date string
    date_of_completion: newDate, // date string
    contact_person: newText,
    contact_phone: newText,
    signing_authority: false, // Yes / No
    bbl_representative: false, // Yes / No
    bbl_representative_internalposition: newText,
  },
});

//---------------------------------------------------------//

export const currentFillInvCompDividendState = atom<InvModels.InvCompDividend>({
  key: 'currentFillInvCompDividend',
  default: {
    company_name: newText,
    product_name: newText, // --- DROPDOWN from INVCOMPPRODUCT
    data_date: newDate, // date string
    data_type: InvModels.DividendType.AccruedDividend,

    // Accounting Purposes
    book_closing_date: newDate, // date string, For listed stocks, auto generated from SETsmart. For non-listed stocks, fill in manually.
    currency: InvModels.Currency.Thb, // --- Autogen from INVCOMPDEFINITIVE
    no_of_shares: newNumber, // --- Autogen from INVCOMPPRODUCT
    dividend: newNumber, // --- For listed stocks, auto generated from SETsmart. For non-listed stocks, fill in manually.
    dividend_adjustment: newNumber,
    dividend_amount: newNumber, // --- Autogen Formular -> = (Number of Shares*Dividend (Per Share) AC)+Dividend Adjustment
    withholding_tax: newNumber,
    net_dividend_amount: newNumber, // --- Autogen Formular -> = Total Dividend Amount AC-Withholding Tax AC
    payment_method: InvModels.PaymentMethod.BankTransfer,
    payment_date: newDate, // date string
    bank_name: newText,
    cheque_recorded_date: newDate, // date string
    cheque_number: newNumber,
    cheque_received_date: newDate, // date string
    details: newText,
  },
});

//------------------------- invcompfair --------------------------------//

export const currentFillInvCompFairState = atom<InvModels.InvCompFair>({
  key: 'currentFillInvCompFair',
  default: {
    company_id: newText, // --- Autogen from INVCOMPDEFINITIVE
    company_name: newText, // --- DROPDOWN from INVCOMPDEFINITIVE
    data_date: newDate, // date string
    reporting_date: newDate, // date string
    wacc: newNumber,
    terminal_growth: newNumber,
    avg_income_growth_3_y: newNumber,
    operating_year: newNumber, // --- Autogen FORMULAR -> = Today - Registered Date shown in Definitive

    // Accounting Purposes
    fair_value: newNumber,
    fair_value_official: newNumber, // --- = Fair Value *50% (As agreed with the president)
  },
});

//------------------------- invcompfs --------------------------------//

export const currentFillInvCompFsState = atom<InvModels.InvCompFs>({
  key: 'currentFillInvCompFs',
  default: {
    fs_id: newText,
    company_id: newText, // --- Autogen from INVCOMPDEFINITIVE
    company_name: newText, // --- DROPDOWN from INVCOMPDEFINITIVE
    reporting_date: newDate, // date string, Remark: Autogen from ENLITE
    fs_year: newNumber, // integer, Remark: Autogen from ENLITE
    fs_quarter: newNumber, // integer, Remark: Autogen from ENLITE
    value_period: InvModels.EvaluationPeriod.Annual,
    value_country: newText, // Remark: Autogen from ENLITE/SET/Bloomberg
    currency: InvModels.Currency.Thb, // --- Autogen from INVCOMPDEFINITIVE
    revenue: newNumber, // Remark: Autogen from ENLITE/SET/Bloomberg
    cost_of_good_sold: newNumber, // Remark: Autogen from ENLITE/SET/Bloomberg
    gross_profit: newNumber, // Remark: Autogen from ENLITE/SET/Bloomberg
    ebitda: newNumber, // Remark: Autogen from ENLITE/SET/Bloomberg
    ebit: newNumber, // Remark: Autogen from ENLITE/SET/Bloomberg
    net_income: newNumber, // Remark: Autogen from ENLITE/SET/Bloomberg
    total_cash: newNumber, // Remark: Autogen from ENLITE/SET/Bloomberg
    total_current_asset: newNumber, // Remark: Autogen from ENLITE/SET/Bloomberg
    total_asset: newNumber, // Remark: Autogen from ENLITE/SET/Bloomberg
    total_current_liability: newNumber, // Remark: Autogen from ENLITE/SET/Bloomberg
    total_liability: newNumber, // Remark: Autogen from ENLITE/SET/Bloomberg
    total_equity: newNumber, // Remark: Autogen from ENLITE/SET/Bloomberg
    interest_bearing_debt: newNumber, // Remark: Autogen from ENLITE/SET/Bloomberg
    registered_capital_no_of_share: newNumber, // Remark: Autogen from ENLITE/SET/Bloomberg
    registered_capital_value: newNumber, // Remark: Autogen from ENLITE/SET/Bloomberg
    paid_up_capital_no_of_share: newNumber, // Remark: Autogen from ENLITE/SET/Bloomberg
    paid_up_capital_value: newNumber, // Remark: Autogen from ENLITE/SET/Bloomberg

    // Accounting Purposes
    par_value: newNumber,
    book_value_per_share: newNumber, // --- Autogen FORMULAR -> = Total Equtiy/No of paid-up shares(shown in INVCOMPFS)
    earning_per_share: newNumber, // --- Autogen FORMULAR -> = Net Income/No of paid-up shares(shown in INVCOMPFS)
  },
});

//-------------------------- invcompproduct --------------------------------//
export const currentFillInvCompProductState = atom<InvModels.InvCompProduct>({
  key: 'currentFillInvCompProduct',
  default: {
    product_id: newText,
    bi_id: newText, // --- Autogen from INVCOMPDEFINITIVE
    rm_id: newText, // --- Autogen from INVCOMPDEFINITIVE
    company_id: newText, // --- Autogen from Mastersheet
    company_name: newText, // --- DROPDOWN from INVCOMPDEFINITIVE
    product_name: newText, // --- Autogen FORMULAR
    equity_short_name: newText, // --- Autogen from Invcompdefinitive
    data_date: newDate, // date string
    first_investment_date: newDate, // date string
    market_listed: newText,
    remark: newText,

    // Product Features
    coupon_rate: newNumber,
    conversion_price: newNumber,
    issue_date: newDate, // date string
    maturity_date: newDate, // date string
    conversion_ratio: newNumber,
    underlying: newText,
    conversion_period: newDate, // date string
    strike_price: newNumber,

    // Accounting Purposes
    internal_acctcode: newText, // --- Autogen from MASTERSHEET
    internal_accdes: newText, // --- Autogen from MASTERSHEET
    internal_procode: newNumber, // --- Autogen from MASTERSHEET
    internal_prodes: newText, // --- Autogen from MASTERSHEET
    impair_account_code: newText, // --- Autogen from MASTERSHEET
    interest_impair_account_code: newText, // --- Autogen from MASTERSHEET
    infra_group_code: newText, // --- Autogen from Mastersheet
    currency: InvModels.Currency.Thb,
    valuation_method: InvModels.Evaluation.BookValue,
    investment_port: InvModels.Port.Amc, // --- Autogen from Mastersheet AC Questionare
    redeemable: false, // Redeemable / Non-Redeemable, --- Autogen from Mastersheet AC Questionare
    asso_sub_flag: InvModels.AssoSubFlag.Asso, // --- Autogen from Mastersheet AC Questionare
    investment_instrument: InvModels.Holding.CommonStock,
    issue_location: InvModels.Nationality.Thai, // --- Autogen from Mastersheet AC Questionare
    issuer_nationality: InvModels.Nationality.Thai, // --- Autogen from Mastersheet AC Questionare
    listed_company_flag: false, // Fixed: 'NO' (false), --- Autogen from Mastersheet AC Questionare
    ifrs9_instrument: InvModels.Ifrs9.Debt, // --- Autogen from Mastersheet AC Questionare
    investment_type: false, // Tdr / Non-Tdr, --- Autogen from Mastersheet AC Questionare
    marketable_security: InvModels.MarketableSecurity.Listed, // --- Autogen from Mastersheet AC Questionare
    hedge_currency: false, // Hedge / Non-Hedge, --- Autogen from Mastersheet AC Questionare
    fixed_rate_thb_flag: InvModels.FixedThbRate.FixedBot, // --- Autogen from Mastersheet AC Questionare (Linked from Currency Hedging AC)
    product_type: InvModels.Product.DebtConvertibleBond, // --- Autogen from Mastersheet AC Questionare
    ownership_type: InvModels.Ownership.Branch, // --- Autogen from Mastersheet AC Questionare
    traded_to_subsidiary: false, // Yes / No, --- Autogen from Mastersheet AC Questionare
    no_of_share: newNumber, // integer, Autogen from INVCOMPCASHFLOW
    book_value: newNumber, // --- Autogen from INVCOMPCASHFLOW
    allowance_impair_ccy: newNumber, // --- Autogen from INVCOMPCASHFLOW
    net_cost_ccy: newNumber, // --- Autogen from INVCOMPCASHFLOW
    investment_cost_thb: newNumber, // --- Autogen from INVCOMPCASHFLOW
    allowance_impair_thb: newNumber, // --- Autogen from INVCOMPCASHFLOW
    allowance_permanent: newNumber,
    net_cost_thb: newNumber, // --- Autogen from INVCOMPCASHFLOW
    current_stake: newNumber, // --- Autogen FORMULAR

    // Thai Convertible Bond Calculation (Amortization, Accured and Receive) - For Thai Convertible Bond - Only for BH #1 and #2, Rest = Key-In
    amortize_th: newNumber, // --- Autogen FORMULAR -> = (Par - Cost)/ระยะเวลาตั้งแต่ Amortize ครั้งสุดท้าย ถึงวัน Due ของหุ้น * จำนวนวันตั้งแต่ Amortize สุดท้ายถึงวัน Value Date
    interest_accrued_th: newNumber, // --- Autogen FORMULAR -> = (Principle Amount * (Days/365 Days) * % Interest Rate) / 100
    interest_receive_th: newNumber, // --- Autogen FORMULAR -> = (Principle Amount * (Days/365 Days) * % Interest Rate) / 100
    withholding_tax_th: newNumber, // --- Autogen FORMULAR -> 1%
    net_interest_received_th: newNumber, // --- Autogen FORMULAR -> = Interest Receive - Withholding Tax

    // Foreign Convertible Bond Calculation (Amortization, Accured and Receive) -  For Foreign Convertible Bond
    amortize: newNumber,
    interest_accrued: newNumber,
    interest_receive: newNumber,
    withholding_tax: newNumber,
    net_interest_received: newNumber,
  },
});

//---------------------------------------------------------//

export const currentFillInvCompWarrantState = atom<InvModels.InvCompWarrant>({
  key: 'currentFillInvCompWarrant',
  default: {
    bi_id: newText, // --- Autogen from INVCOMPDEFINITIVE
    rm_id: newText, // --- Autogen from INVCOMPDEFINITIVE
    company_id: newText, // --- Autogen from INVCOMPDEFINITIVE
    company_name: newText, // --- Autogen from INVCOMPPRODUCT
    product_name_2: newText, // --- DROPDOWN from INVCOMPPRODUCT
    product_name: newText, // --- DROPDOWN from INVCOMPPRODUCT
    txn_date: newDate, // date string
    record_date: newDate, // date string
    investment_instrument: InvModels.Holding.CommonStock, // --- Autogen from INVCOMPPRODUCT
    warrant_name: newText, // --- Link to Warrant from INVCOMPPRODUCT
    warrant_type: InvModels.WarrantStatus.WarrantReceived,
    no_of_share_pretransaction: newNumber, // integer, Linked from output - daily report on pre transaction date
    no_of_share_record_date: newNumber, // integer, Linked from output (daily report on Recording date (XW)); for others - keep blank
    no_of_warrant_pretransaction: newNumber, // integer, Linked from output - daily report
    exercise_ratio: newNumber, // integer
    no_of_warrant: newNumber, // integer, Autogen FORMULAR -> (see the workbook sheet)
    no_of_warrant_posttransaction: newNumber, // integer, Autogen FORMULAR -> (see the workbook sheet)
    no_of_share_posttransaction: newNumber, // integer, Autogen FORMULAR -> (see the workbook sheet)
    strike_price: newNumber, // --- For Warrant Exercised - Autogen from INVCOMPPRODUCT
    remark: newText,

    // Accounting Purposes
    investment_port: newText, // --- Autogen from INVCOMPPRODUCT
    currency: InvModels.Currency.Thb, // --- Autogen from INVCOMPDEFINITIVE
    exchange_rate: newNumber, // --- Autogen from MASTERSHEET
    cashflow_ac: newNumber, // --- Autogen FORMULAR linked to INVCOMPCASHFLOW -> (see the workbook sheet)
    cost_ac: newNumber, // --- Autogen FORMULAR linked to INVCOMPCASHFLOW -> (see the workbook sheet)
    cost_ac_thb: newNumber, // --- Autogen FORMULAR linked to INVCOMPCASHFLOW -> = Cost (Original CCY) AC * Exchange Rate
    investment_cost_pretransaction: newNumber, // --- Autogen FORMULAR from daily report on date before transaction ->
    investment_cost_pretransaction_thb: newNumber, // --- Autogen FORMULAR ->
    current_stake_pretransaction: newNumber, // --- Autogen FORMULAR -> = Total No. of Share (Pre Transaction) /Paid Up Capital_No. of  Shares (Pre Transaction)
    investment_cost: newNumber, // --- Autogen FORMULAR and linked to INVCOMPPRODUCT -> = Investment Cost (Orginal CCY) (Pre Transaction) AC + Cost (Original CCY) AC
    investment_cost_thb: newNumber, // --- Autogen FORMULAR and linked to INVCOMPPRODUCT -> = Investment Cost (Orginal CCY) (Post Transaction) AC * Exchange Rate
    current_stake_posttransaction: newNumber, // --- Autogen FORMULAR and linked to INVCOMPPRODUCT -> = Total No. of Share (Post Transaction) /Paid Up Capital_No. of  Shares (Post Transaction)
  },
});

//---------------------------invcontact------------------------------//

export const currentFillInvContactState = atom<InvModels.InvContact>({
  key: 'currentFillInvContact',
  default: {
    id: newText,
    name: newText,
    data_date: newDate, // date string
    contact_type: InvModels.Contact.CompanyContact,
    contact_name: newText,
    contact_position: newText,
    contact_email: newText,
    contact_phone: newText,
    contact_status: false, // Active, Inactive
  },
});

//---------------------------------------------------------//

export const currentFillInvDataBloombergState = atom<InvModels.InvDataBloomberg>({
  key: 'currentFillInvDataBloomberg',
  default: {
    date: newDate, // date string
    equity_short_name: newText,
    company_symbol: newText, // --- Autogen from Bloomberg
    closing_price: newNumber, // --- Autogen from Bloomberg
    bid_price: newNumber, // --- Autogen from Bloomberg
    offer_price: newNumber, // --- Autogen from Bloomberg
    share_outstanding: newNumber, // --- Autogen from Bloomberg
    trading_volume: newNumber, // --- Autogen from Bloomberg
    event_flag: newText, // --- Autogen from Bloomberg
    pe_ratio: newNumber, // --- Autogen from Bloomberg
    pbv_ratio: newNumber, // --- Autogen from Bloomberg
    isin_code_foreign: newNumber, // --- Autogen from Bloomberg
  },
});

//---------------------------------------------------------//

export const currentFillInvDataPortBloombergState = atom<InvModels.InvDataPortBloomberg>(
  {
    key: 'currentFillInvDataPortBloomberg',
    default: {
      date: newDate, // date string
      port_name: newText,
      var_percent_99pc_1y: newNumber,
      var_thb_99pc_1y: newNumber,
      var_percent_99pc_3y: newNumber,
      var_thb_99pc_3y: newNumber,
      var_percent_95pc_1y: newNumber,
      var_thb_95pc_1y: newNumber,
      var_percent_95pc_3y: newNumber,
      var_thb_95pc_3y: newNumber,
      var_of_set_index_99pc_1m: newNumber,
    },
  },
);

//---------------------------------------------------------//

export const currentFillInvDataSetMarketState = atom<InvModels.InvDataSetMarket>({
  key: 'currentFillInvDataSetMarket',
  default: {
    date: newDate, // date string
    company_symbol: newText,
    set100_flag: newText, // --- Autogen from SETSMART
    set50_flag: newText, // --- Autogen from SETSMART
    mai_flag: newText, // --- Autogen from SETSMART
    event_flag: newText, // --- Autogen from SETSMART
    isin_code_domestic: newNumber, // --- Autogen
  },
});

//---------------------------------------------------------//

export const currentFillInvDataSetState = atom<InvModels.InvDataSet>({
  key: 'currentFillInvDataSet',
  default: {
    date: newDate, // date string
    set_total_return_index: newNumber, // --- Autogen from Bloomberg
    set50_total_return_index: newNumber, // --- Autogen from Bloomberg
    set100_total_return_index: newNumber, // --- Autogen from Bloomberg
    mai_total_return_index: newNumber, // --- Autogen from Bloomberg
    set_index: newNumber, // --- Autogen from Bloomberg
    set_value: newNumber, // --- Autogen from Bloomberg
    set_volume: newNumber, // --- Autogen from Bloomberg
    set50_index: newNumber, // --- Autogen from Bloomberg
    set50_value: newNumber, // --- Autogen from Bloomberg
    set50_volume: newNumber, // --- Autogen from Bloomberg
    set100_index: newNumber, // --- Autogen from Bloomberg
    set100_value: newNumber, // --- Autogen from Bloomberg
    set100_volume: newNumber, // --- Autogen from Bloomberg
    mai_index: newNumber, // --- Autogen from Bloomberg
    mai_value: newNumber, // --- Autogen from Bloomberg
    mai_volume: newNumber, // --- Autogen from Bloomberg
  },
});

//---------------------------------------------------------//

export const currentFillInvFundBenchmarkState = atom<InvModels.InvFundBenchmark>({
  key: 'currentFillInvFundBenchmark',
  default: {
    fund_id: newText, // --- Autogen from INVFUNDDEFINITIVE
    fund_quote: newText, // --- DROPDOWN from INVFUNDDEFINITIVE
    data_date: newDate, // date string
    benchmark_name: newText,
    benchmark_vintage: newNumber, // integer
    benchmark_size: newNumber,
    investment_geography: newText,
    benchmark_performance_moic: newNumber,
    benchmark_performance_irr: newNumber,
  },
});

//---------------------------------------------------------//

export const currentFillInvFundCashflowState = atom<InvModels.InvFundCashflow>({
  key: 'currentFillInvFundCashflow',
  default: {
    fund_id: newText, // --- Autogen from INVFUNDDEFINITIVE
    fund_quote: newText, // --- DROPDOWN from INVFUNDDEFINITIVE
    txn_date: newDate, // date string
    txn_id: newText, // --- Autogen from MASTERSHEET
    txn_type: InvModels.FundTransaction.Dividend,
    recyclable_flag: false, // Yes / No

    // Accounting Purposes
    cashflow_type: false, // Inflow / Outflow
    currency: InvModels.Currency.Thb, // --- Autogen from INVFUNDDEFINITIVE
    cashflow_amount: newNumber,
    total_partnership_paid_amount: newNumber,
    current_stake: newNumber, // --- Autogen FORMULAR -> = Cashflow AC / Total Partnership Paid
    unit_price: newNumber,
    unit_no: newNumber,

    // Accounting Purposes (Post Transaction Process)
    brokerage_name: InvModels.Broker.Asp003, // --- Autogen from MASTERSHEET
    commission: newNumber,
    vat_levey_fee: newNumber,
    commission_vat: newNumber,
    withholding_tax: newNumber,
    custodian_fee: newNumber,
    miscellaneous_exp: newNumber,
    fund_transfer_fee: newNumber,
    underwriting_fee: newNumber,
  },
});

//---------------------------------------------------------//

export const currentFillInvFundCommitmentAndWatermarkState =
  atom<InvModels.InvFundCommitmentAndWatermark>({
    key: 'currentFillInvFundCommitmentAndWatermark',
    default: {
      fund_id: newText, // --- Autogen from INVFUNDDEFINITIVE
      fund_quote: newText, // --- DROPDOWN from INVFUNDDEFINITIVE
      data_date: newDate, // date string
      type: InvModels.Commitment.Commitment,
      currency: InvModels.Currency.Thb, // --- Autogen from INVFUNDDEFINITIVE
      investor_name: newText,
      commitment_amount: newNumber,
    },
  });

//----------------------------- invfunddefinitive ----------------------------//

export const currentFillInvFundDefinitiveState = atom<InvModels.InvFundDefinitive>({
  key: 'currentFillInvFundDefinitive',
  default: {
    bi_id: newText, // --- Autogen from Mastersheet
    rm_id: newText,
    data_date: newDate, // date string
    fund_id: newText, // --- Autogen from Mastersheet
    registered_date: newDate, // date newText
    registered_id: newNumber, // integer
    fund_name: newText,
    fund_thainame: newText,
    fund_quote: newText,
    fund_status: InvModels.FundStatus.Active,
    first_investment_date: newDate, // date string
    first_closing_date: newDate, // date string
    final_closing_date: newDate, // date string
    fund_vintage: newNumber, // integer
    asset_management_company: newText,
    fund_type: InvModels.Fund.CommodityFunds,
    asset_class: InvModels.Asset.AlternativeFund,
    registered_address: newText,
    ho_country: newText, // --- Autogen from Country Mastersheet
    domicile_country: newText, // --- Autogen from Country Mastersheet
    investment_geography: newText, // --- Autogen from Country Mastersheet
    invested_country: newText, // --- Autogen from Country Mastersheet
    investment_period: newNumber, // integer
    fund_term: newNumber, // integer
    extension_period: newText,
    investment_period_multiplier: newText,
    investment_period_pc: newNumber,
    harvesting_period_multiplier: InvModels.HarvestingFactor.CapitalInvestmentPercentage,
    harvesting_period_pc: newNumber,
    distribution_method: InvModels.DistributionMethod.AmericanWaterfall,
    preferred_return: newNumber,
    gp_catchup: newNumber,
    carry_interest: newNumber,
    hurdle_rate: newNumber,
    focused_sector_1: InvModels.Sector.CommunicationServices,
    focused_sector_2: InvModels.Sector.CommunicationServices,
    focused_sector_3: InvModels.Sector.CommunicationServices,
    focused_sector_4: InvModels.Sector.CommunicationServices,
    focused_sector_5: InvModels.Sector.CommunicationServices,
    co_investment_flag: false, // Yes / No
    gp_exposure: false, // Yes / No
    company_id_1: newText, // --- Autogen from INVFUNDPORTFOLIO
    company_id_2: newText, // --- Autogen from INVFUNDPORTFOLIO
    company_id_3: newText, // --- Autogen from INVFUNDPORTFOLIO
    remark: newText,

    // Risk Management Purposes
    fund_risk_level: newNumber, // integer, 1-8
    alternative_class: false, // Alternative / Non-Alternative
    fintech_class: false, // Yes / No
    currency_risk_flag: false, // Yes / No
    // currency_risk_level: newNumber, // integer, 1-6

    // Portal Access
    investor_portal: false, // Yes / No
    portal_site: newText,
    portal_username: newText,
    portal_password: newText,
    portal_question: newText,
    portal_answer: newText,

    // Accounting Purposes
    internal_acctcode: newText, // --- Autogen from Mastersheet AC Questionare
    internal_accdes: newText, // --- Autogen from Mastersheet AC Questionare
    internal_procode: newText, // --- Autogen from Mastersheet AC Questionare
    internal_prodes: newText, // --- Autogen from Mastersheet AC Questionare
    currency: InvModels.Currency.Thb, // --- Linked from Approval Budget Currency in Row 25
    valuation_method: newNumber, // integer, --- Fixed: 'NAV' (unknown enum ?)
    investment_port: InvModels.Port.Amc, // --- Autogen from Mastersheet AC Questionare
    redeemable: false, // Redeemable / Non-Redeemable, --- Autogen from Mastersheet AC Questionare
    asso_sub_flag: InvModels.AssoSubFlag.Asso, // --- Autogen from Mastersheet AC Questionare
    issue_location: InvModels.Nationality.Thai, // --- Autogen from Mastersheet AC Questionare
    issuer_nationality: InvModels.Nationality.Thai, // --- Autogen from Mastersheet AC Questionare
    listed_company_flag: false, // Fixed: 'NO' (false)
    product_type: InvModels.Product.DebtConvertibleBond, // --- Autogen from Mastersheet AC Questionare
    investment_type: false, // Tdr / Non-Tdr, --- Autogen from Mastersheet AC Questionare
    intra_group_code: newText, // --- Autogen from Mastersheet
    isic_code: newText,
    bot_exclusion_flag: false,
    ownership_type: InvModels.Ownership.Branch, // --- Autogen from Mastersheet AC Questionare
    traded_to_subsidiary: false, // Yes / No, --- Autogen from Mastersheet AC Questionare
    ifrs9_instrument: InvModels.Ifrs9.Debt, // --- Autogen from Mastersheet AC Questionare
    hedge_currency: false, // Hedge / Non-Hedge, --- Autogen from Mastersheet AC Questionare
    fixed_rate_thb_flag: InvModels.FixedThbRate.FixedBot, // --- Autogen from Mastersheet AC Questionare (Linked from Currency Hedging AC)
  },
});

//---------------------------------------------------------//

export const currentFillInvFundNavState = atom<InvModels.InvFundNav>({
  key: 'currentFillInvFundNav',
  default: {
    fund_id: newText, // --- Autogen from INVFUNDDEFINITIVE
    fund_quote: newText, // --- DROPDOWN from INVFUNDDEFINITIVE
    data_date: newDate, // date string
    reporting_date: newDate, // date string
    accounting_period_year: newNumber, // integer, Generated formula -> = IF(REPORTING DATE=""," ",YEAR(REPORTING DATE))
    accounting_period_quarter: newNumber, // integer, Generated formula -> = IF(REPORTING DATE=""," ",ROUNDUP(MONTH(REPORTING DATE)/3,newNumber))
    total_assets: newNumber,
    total_liabilities: newNumber,
    total_equity: newNumber,
    total_income: newNumber,
    total_expenses: newNumber,

    // Accounting Purposes
    currency: InvModels.Currency.Thb, // --- Autogen from INVFUNDDEFINITIVE
    reported_nav_amount: newNumber,
    official_nav: newNumber,
  },
});

//---------------------------------------------------------//

export const currentFillInvFundPortfolioState = atom<InvModels.InvFundPortfolio>({
  key: 'currentFillInvFundPortfolio',
  default: {
    fund_id: newText, // --- Autogen from INVFUNDDEFINITIVE
    fund_quote: InvModels.Currency.Thb, // --- DROPDOWN from INVFUNDDEFINITIVE
    data_date: newDate, // date string
    company_name: newText,
    company_id: newText, // --- Autogen from INVCOMPDEFINITIVE
    currency: InvModels.Currency.Thb,
    investment_cost: newNumber,
    fair_value: newNumber,
  },
});
