//---------------------------------------- Import React --------------------------------------
//React

import Chip from '@mui/material/Chip';

export default function ApprovalChip({ sheetStauts }: { sheetStauts: string }) {
  switch (sheetStauts) {
    case 'APPROVED':
      return (
        <Chip sx={{ backgroundColor: '#c8e6c9', color: '#33691e' }} label="Approved" />
      );
    case 'PENDING':
      return (
        <Chip
          sx={{ backgroundColor: '#ffe0b2', color: '#e65100' }}
          color="secondary"
          label="Pending"
        />
      );
    case 'EMPTY':
      return <Chip color="primary" label="Empty" disabled={true} />;
    default:
      return <div>{sheetStauts}</div>;
  }
}
