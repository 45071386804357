import { useRecoilValue } from 'recoil';

import DetailHeader from './DetailHeader';

import { historyRowsState, historyHeadersState } from '../../../../states/index';

import HistoryTable from '../table/HistoryTable';

function HistoryInformation() {
  const historyRows = useRecoilValue(historyRowsState);
  const historyHeaders = useRecoilValue(historyHeadersState);

  return (
    <>
      <DetailHeader typoHeader="History" headerType="general" isPending={false} />
      {/* -------- Row 1 ---------- */}

      <HistoryTable
        rowBodyData={historyRows}
        rowHeadData={historyHeaders}
        tableMode="history_table"
      />
    </>
  );
}

export default HistoryInformation;
