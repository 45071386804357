import { useRecoilValue } from 'recoil';

import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ElementStyles from '../../../../styles/Element.module.css';
import {
  labelSizeState,
  labelGridState,
  inputGridState,
} from '../../../../states/index';

//----------- Free Style ------------------
// [] CustomLimitFree
// [x] NoLimitFree

//------------ Only Text ----------------
// [] CustomLimitText
// [] NoLimitText

//----------- Only Number --------------------
// [] CustomLimitCountNumber
// [x] NoLimitCountNumber
// [x] NoLimitFloat
// [] CustomPosDecimalsNumber
// [] NoPosDecimalsNumber
// [] TwoPosDecimalsNumber
// [] ThreePosDecimalsNumber

//----------- Specific for Address/Contact  ---------------
// [] BranchCode
// [] PhoneNumber
// [] PostalCode
// [] Email

//----------- Specific for Information ---------------
// [] IDCardNumber
// [] JuristicNumber
// [] PassportNumber
// [] SavingAccountNumber
// [] CreditAccountNumber

//------------ Specific for Unique ID ----------------------
// [] BI_ID
// [] FundID
// [] CompanyID

export default function TextFieldComp({
  label,
  placeholder,
  helperText,
  nameId,
  parentValue,
  inputClass,
  isRequire,
  isActive,
  onInputChange,
  value,
}: {
  label: string;
  placeholder: string;
  helperText: string;
  nameId: string;
  parentValue: string;
  inputClass: string;
  isRequire: boolean;
  isActive: boolean;
  onInputChange: () => void;
  value: string | number;
}) {
  const labelFontSize = useRecoilValue(labelSizeState);
  const labelGrid = useRecoilValue(labelGridState);
  const inputGrid = useRecoilValue(inputGridState);

  switch (inputClass) {
    //******************************************************** Free Style ********************************************************** */
    //--------------------------------- Custom Limit Free ------------------------- */
    case 'CustomLimitFree': {
      return <TextField label="Outlined" variant="outlined" />;
    }

    //--------------------------------- Autogen From Select----------------------------- */

    case 'AutogenFormSelect': {
      return (
        <Container className={ElementStyles.FormContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            container
          >
            <Grid
              xs={labelGrid}
              sm={labelGrid}
              md={labelGrid}
              item
              container
              alignItems="center"
            >
              <Grid item>
                <Typography
                  variant={labelFontSize}
                  className={ElementStyles.FormLabelText}
                >
                  <b>{label + ':'}</b>
                </Typography>
              </Grid>
            </Grid>

            <Grid xs={inputGrid} sm={inputGrid} md={inputGrid} item>
              <FormControl className={ElementStyles.FormFormControl}>
                <Typography
                  variant={labelFontSize}
                  className={ElementStyles.FormAutogenText}
                >
                  {'Auto generate in next page.'}
                </Typography>

                {/* <OutlinedInput
                  key={nameId}
                  id={nameId}
                  name={nameId}
                  placeholder={placeholder}
                  className={ElementStyles.FormOutlinedInputDisable}
                  disabled={true}
                  onChange={onInputChange}
                  value={parentValue}
                /> */}
              </FormControl>
            </Grid>
          </Grid>
        </Container>
      );
    }

    //--------------------------------- Autogen Formula---------------------------- */

    case 'AutogenFromFormula': {
      return (
        <Container className={ElementStyles.FormContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            container
          >
            <Grid
              xs={labelGrid}
              sm={labelGrid}
              md={labelGrid}
              item
              container
              alignItems="center"
            >
              <Grid item>
                <Typography
                  variant={labelFontSize}
                  className={ElementStyles.FormLabelText}
                >
                  <b>{label + ':'}</b>
                </Typography>
              </Grid>
            </Grid>

            <Grid xs={inputGrid} sm={inputGrid} md={inputGrid} item>
              <FormControl className={ElementStyles.FormFormControl}>
                <Typography
                  variant={labelFontSize}
                  className={ElementStyles.FormAutogenText}
                >
                  {'Auto generate with formula in next page.'}
                </Typography>

                {/* <OutlinedInput
                  key={nameId}
                  id={nameId}
                  name={nameId}
                  placeholder={placeholder}
                  className={ElementStyles.FormOutlinedInputDisable}
                  disabled={true}
                  onChange={onInputChange}
                  value={parentValue}
                /> */}
              </FormControl>
            </Grid>
          </Grid>
        </Container>
      );
    }

    //--------------------------------- Autogen From MainSheet--------------------------- */

    case 'AutogenFromDefinitive': {
      return (
        <Container className={ElementStyles.FormContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            container
          >
            <Grid
              xs={labelGrid}
              sm={labelGrid}
              md={labelGrid}
              item
              container
              alignItems="center"
            >
              <Grid item>
                <Typography
                  variant={labelFontSize}
                  className={ElementStyles.FormLabelText}
                >
                  <b>{label + ':'}</b>
                </Typography>
              </Grid>
            </Grid>

            <Grid xs={inputGrid} sm={inputGrid} md={inputGrid} item>
              <FormControl className={ElementStyles.FormFormControl}>
                <Typography
                  variant={labelFontSize}
                  className={ElementStyles.FormAutogenText}
                >
                  {'Auto generate from current definitive. Display value in next page.'}
                </Typography>

                {/* <OutlinedInput
                  key={nameId}
                  id={nameId}
                  name={nameId}
                  placeholder={placeholder}
                  className={ElementStyles.FormOutlinedInputDisable}
                  disabled={true}
                  onChange={onInputChange}
                  value={parentValue}
                /> */}
              </FormControl>
            </Grid>
          </Grid>
        </Container>
      );
    }

        //--------------------------------- Autogen From MainSheet--------------------------- */

        case 'AutogenFromOtherSheet': {
          return (
            <Container className={ElementStyles.FormContainer}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                container
              >
                <Grid
                  xs={labelGrid}
                  sm={labelGrid}
                  md={labelGrid}
                  item
                  container
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      variant={labelFontSize}
                      className={ElementStyles.FormLabelText}
                    >
                      <b>{label + ':'}</b>
                    </Typography>
                  </Grid>
                </Grid>
    
                <Grid xs={inputGrid} sm={inputGrid} md={inputGrid} item>
                  <FormControl className={ElementStyles.FormFormControl}>
                    <Typography
                      variant={labelFontSize}
                      className={ElementStyles.FormAutogenText}
                    >
                      {'Auto generate from other sheet. Display value in next page.'}
                    </Typography>
    
                    {/* <OutlinedInput
                      key={nameId}
                      id={nameId}
                      name={nameId}
                      placeholder={placeholder}
                      className={ElementStyles.FormOutlinedInputDisable}
                      disabled={true}
                      onChange={onInputChange}
                      value={parentValue}
                    /> */}
                  </FormControl>
                </Grid>
              </Grid>
            </Container>
          );
        }

    //--------------------------------- Autogen From Other--------------------------- */

    case 'AutogenFromExternal': {
      return (
        <Container className={ElementStyles.FormContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            container
          >
            <Grid
              xs={labelGrid}
              sm={labelGrid}
              md={labelGrid}
              item
              container
              alignItems="center"
            >
              <Grid item>
                <Typography
                  variant={labelFontSize}
                  className={ElementStyles.FormLabelText}
                >
                  <b>{label + ':'}</b>
                </Typography>
              </Grid>
            </Grid>

            <Grid xs={inputGrid} sm={inputGrid} md={inputGrid} item>
              <FormControl className={ElementStyles.FormFormControl}>
                <Typography
                  variant={labelFontSize}
                  className={ElementStyles.FormAutogenText}
                >
                  {'Auto generate from ENLITE/SET/Bloomberg . Display value in next page.'}
                </Typography>

                {/* <OutlinedInput
                    key={nameId}
                    id={nameId}
                    name={nameId}
                    placeholder={placeholder}
                    className={ElementStyles.FormOutlinedInputDisable}
                    disabled={true}
                    onChange={onInputChange}
                    value={parentValue}
                  /> */}
              </FormControl>
            </Grid>
          </Grid>
        </Container>
      );
    }

        //--------------------------------- Autogen From Server--------------------------- */

        case 'AutogenFromServer': {
          return (
            <Container className={ElementStyles.FormContainer}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                container
              >
                <Grid
                  xs={labelGrid}
                  sm={labelGrid}
                  md={labelGrid}
                  item
                  container
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      variant={labelFontSize}
                      className={ElementStyles.FormLabelText}
                    >
                      <b>{label + ':'}</b>
                    </Typography>
                  </Grid>
                </Grid>
    
                <Grid xs={inputGrid} sm={inputGrid} md={inputGrid} item>
                  <FormControl className={ElementStyles.FormFormControl}>
                    <Typography
                      variant={labelFontSize}
                      className={ElementStyles.FormAutogenText}
                    >
                      {'Auto generate from Server . Display value in next page.'}
                    </Typography>
    
                    {/* <OutlinedInput
                        key={nameId}
                        id={nameId}
                        name={nameId}
                        placeholder={placeholder}
                        className={ElementStyles.FormOutlinedInputDisable}
                        disabled={true}
                        onChange={onInputChange}
                        value={parentValue}
                      /> */}
                  </FormControl>
                </Grid>
              </Grid>
            </Container>
          );
        }

          //--------------------------------- Autogen From MaterSheet--------------------------- */

          case 'AutogenFromMaterSheet': {
            return (
              <Container className={ElementStyles.FormContainer}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  container
                >
                  <Grid
                    xs={labelGrid}
                    sm={labelGrid}
                    md={labelGrid}
                    item
                    container
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography
                        variant={labelFontSize}
                        className={ElementStyles.FormLabelText}
                      >
                        <b>{label + ':'}</b>
                      </Typography>
                    </Grid>
                  </Grid>
      
                  <Grid xs={inputGrid} sm={inputGrid} md={inputGrid} item>
                    <FormControl className={ElementStyles.FormFormControl}>
                      <Typography
                        variant={labelFontSize}
                        className={ElementStyles.FormAutogenText}
                      >
                        {'Auto generate from MaterSheet . Display value in next page.'}
                      </Typography>
      
                      {/* <OutlinedInput
                          key={nameId}
                          id={nameId}
                          name={nameId}
                          placeholder={placeholder}
                          className={ElementStyles.FormOutlinedInputDisable}
                          disabled={true}
                          onChange={onInputChange}
                          value={parentValue}
                        /> */}
                    </FormControl>
                  </Grid>
                </Grid>
              </Container>
            );
          }
    

    //--------------------------------- No Limit Free ----------------------------- */

    case 'NoLimitFree': {
      return (
        <Container className={ElementStyles.FormContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            container
          >
            <Grid
              xs={labelGrid}
              sm={labelGrid}
              md={labelGrid}
              item
              container
              alignItems="center"
            >
              <Grid item>
                <Typography
                  variant={labelFontSize}
                  className={ElementStyles.FormLabelText}
                >
                  <b>{label + ':'}</b>
                </Typography>
              </Grid>
            </Grid>

            <Grid xs={inputGrid} sm={inputGrid} md={inputGrid} item>
              <FormControl className={ElementStyles.FormFormControl}>
                <OutlinedInput
                  key={nameId}
                  id={nameId}
                  name={nameId}
                  placeholder={placeholder}
                  className={
                    isActive
                      ? ElementStyles.FormOutlinedInputActive
                      : ElementStyles.FormOutlinedInputDisable
                  }
                  disabled={!isActive}
                  onChange={onInputChange}
                  value={value}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Container>
      );
    }

    //******************************************************** Only Text ********************************************************** */
    //-------------------------------- Custom Limit Text ---------------------------- */
    case 'CustomLimitText': {
      return <TextField label="Outlined" variant="outlined" />;
    }

    //-------------------------------- No Limit Text --------------------------------- */
    case 'NoLimitText': {
      return <TextField label="Outlined" variant="outlined" />;
    }

    //******************************************************** Only Number ********************************************************** */
    //?----------------------------------------------------- Count Number -------------------------------------------------------* */
    //------------------------------- Custom Limit CountNumber ------------------------ */
    case 'CustomLimitCountNumber': {
      return <TextField label="Outlined" variant="outlined" />;
    }

    //------------------------------- No Limit CountNumber --------------------------- */
    case 'NoLimitCountNumber': {
      return (
        <Container className={ElementStyles.FormContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            container
          >
            <Grid
              xs={labelGrid}
              sm={labelGrid}
              md={labelGrid}
              item
              container
              alignItems="center"
            >
              <Grid item>
                <Typography
                  variant={labelFontSize}
                  className={ElementStyles.FormLabelText}
                >
                  <b>{label + ':'}</b>
                </Typography>
              </Grid>
            </Grid>

            <Grid xs={inputGrid} sm={inputGrid} md={inputGrid} item>
              <FormControl className={ElementStyles.FormFormControl}>
                <OutlinedInput
                  key={nameId}
                  id={nameId}
                  name={nameId}
                  placeholder={placeholder}
                  inputProps={{ step: 1 }}
                  type="number"
                  className={
                    isActive
                      ? ElementStyles.FormOutlinedInputActive
                      : ElementStyles.FormOutlinedInputDisable
                  }
                  disabled={!isActive}
                  onChange={onInputChange}
                  value={value}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Container>
      );
    }

    //?----------------------------------------------------- DecimalsNumber -------------------------------------------------------* */
    //------------------------------- No Limit Float  --------------------------- */
    case 'NoLimitFloat': {
      return (
        <Container className={ElementStyles.FormContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            container
          >
            <Grid
              xs={labelGrid}
              sm={labelGrid}
              md={labelGrid}
              item
              container
              alignItems="center"
            >
              <Grid item>
                <Typography
                  variant={labelFontSize}
                  className={ElementStyles.FormLabelText}
                >
                  <b>{label + ':'}</b>
                </Typography>
              </Grid>
            </Grid>

            <Grid xs={inputGrid} sm={inputGrid} md={inputGrid} item>
              <FormControl className={ElementStyles.FormFormControl}>
                <OutlinedInput
                  key={nameId}
                  id={nameId}
                  name={nameId}
                  placeholder={placeholder}
                  type="number"
                  className={
                    isActive
                      ? ElementStyles.FormOutlinedInputActive
                      : ElementStyles.FormOutlinedInputDisable
                  }
                  disabled={!isActive}
                  onChange={onInputChange}
                  value={value}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Container>
      );
    }

    //------------------------------- Custom Pos DecimalsNumber ------------------------ */
    case 'CustomPosDecimalsNumber': {
      return <TextField label="Outlined" variant="outlined" />;
    }

    //------------------------------- No Pos DecimalsNumber ---------------------------- */
    case 'NoPosDecimalsNumber': {
      return <TextField label="Outlined" variant="outlined" />;
    }

    //------------------------------- Two Pos DecimalsNumber ---------------------------- */
    case 'TwoPosDecimalsNumber': {
      return <TextField label="Outlined" variant="outlined" />;
    }

    //------------------------------- Three Pos DecimalsNumber --------------------------- */
    case 'ThreePosDecimalsNumber': {
      return <TextField label="Outlined" variant="outlined" />;
    }
    //******************************************************** Specific ********************************************************** */
    //?----------------------------------------------------- Address -------------------------------------------------------* */
    //-------------------------------------- Branch Code---------------------------------- */
    case 'BranchCode': {
      return <TextField label="Outlined" variant="outlined" />;
    }

    //------------------------------------ Postal Code ------------------------------------ */
    case 'PostalCode': {
      return <TextField label="Outlined" variant="outlined" />;
    }
    //?----------------------------------------------------- Contact -------------------------------------------------------* */

    //------------------------------------- Phone Number ---------------------------------- */
    case 'PhoneNumber': {
      return <TextField label="Outlined" variant="outlined" />;
    }

    //--------------------------------------- Email ----------------------------------------- */
    case 'Email': {
      return <TextField label="Outlined" variant="outlined" />;
    }

    //?----------------------------------------------------- Information -------------------------------------------------------* */
    //--------------------------------------- ID Card Number ----------------------------------------- */
    case 'IDCardNumber': {
      return <TextField label="Outlined" variant="outlined" />;
    }

    //--------------------------------------- Juristic Number ----------------------------------------- */
    case 'JuristicNumber': {
      return <TextField label="Outlined" variant="outlined" />;
    }

    //--------------------------------------- Passport Number ----------------------------------------- */
    case 'PassportNumber': {
      return <TextField label="Outlined" variant="outlined" />;
    }

    //--------------------------------------- Saving Account Number ----------------------------------------- */
    case 'SavingAccountNumber': {
      return <TextField label="Outlined" variant="outlined" />;
    }

    //--------------------------------------- Credit Account Number ----------------------------------------- */
    case 'CreditAccountNumber': {
      return <TextField label="Outlined" variant="outlined" />;
    }

    //?-----------------------------------------------------  Unique ID  -------------------------------------------------------* */

    //--------------------------------------- BI_ID ----------------------------------------- */
    case 'BI_ID': {
      return <TextField label="Outlined" variant="outlined" />;
    }

    //--------------------------------------- FundID ----------------------------------------- */
    case 'FundID': {
      return <TextField label="Outlined" variant="outlined" />;
    }

    //--------------------------------------- CompanyID ----------------------------------------- */
    case 'CompanyID': {
      return <TextField label="Outlined" variant="outlined" />;
    }

    //******************************************************** NotFound ********************************************************** */
    //------------------------------------//
    default: {
      return <p>NotFound TextField InputClass</p>;
    }
  }
}
