/*
Table Header

A1. BI_ID / bi_id
A2. Company ID / company_id
-----------------------
B1. Company Name / company_name
B2. Transaction Date / txn_date
B3. Transaction Type / txn_type
B4. Currency (ex USD, THB) Currency AC /currency
B5. No. of Share /  no_of_share
B6. Price per Share / price_per_share
B7. Net Cashflow (Original CCY) /net_cashflow
-------------------------
C1. Approval
C2. Detail

*/

export interface InvCompCashFlowBodyTableInterface {
  sheet_id: string;
  bi_id: string;
  company_id: string;
  company_name: string;
  txn_date: string;
  txn_type: string;
  currency: string;
  no_of_share: string;
  price_per_share: string;
  net_cashflow: string;
  approval: object;
  detail: string;
}

export function createInvCompCashFlowBodyTable(
  sheet_id: string,
  bi_id: string,
  company_id: string,
  company_name: string,
  txn_date: string,
  txn_type: string,
  currency: string,
  no_of_share: string,
  price_per_share: string,
  net_cashflow: string,
  approval: object,
  detail: string,
): InvCompCashFlowBodyTableInterface {
  return {
    sheet_id,
    bi_id,
    company_id,
    company_name,
    txn_date,
    txn_type,
    currency,
    no_of_share,
    price_per_share,
    net_cashflow,
    approval,
    detail,
  };
}

//-------------------------//
export interface InvCompCashFlowHeaderTableInterface {
  id: keyof InvCompCashFlowBodyTableInterface;
  label: string;
}
