import { atom } from 'recoil'
import {arrContact,arrActive,arrYnValue  } from '../common';

const _sheetName = 'invcontact';

//?------- Parameter Design ---------------
/*

------- Label / ID ------------
displayName = variable for display name in label.
sourceSystemName = variable for bank system name.
apiName = variable for call api. 

-------- Input ----------------
description = input placeholder.
inputLength = custom length limit of character.
inputType = (checkbox | date | radio | select | textfield) type of input.
inputClass = Custom format masking or validation.
possibleValue = (array) Value for dropdownlist / checkbox / radio.

-------- Boolean --------------
isRequire = (true/false) require field. can't be null.

isLinkValue = (true/false) Link the value from other input (linkValueId)
linkValueId = ID of input to Link data (display when isLinkValue == true).

isAutoGenerate = (true/false) auto generate value.
generateSource = source of auto generate (display when isAutoGenerate == true).

*/
/**
 textfield
 - NoLimitFree
 - noLimitCountNumber

 date
 - General

select
 - General

 */

const jsonData: { [key: string]: any } = {
  sheetName: _sheetName,
  inputInfo: [
    // ? --------------------------------- Start ---------------------------------------//
    // ---------------------------- 001 ---------------------------------//
    {
      displayName: 'ID',
      sourceSystemName: 'ID',
      apiName: 'id',

      description: 'Fund ID/Company ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromDefinitive',
      possibleValue: '',
      displayValue:'',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 002 ---------------------------------//
    {
      displayName: 'Name',
      sourceSystemName: 'NAME',
      apiName: 'name',

      description: 'Full legal name of the fund/company',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromDefinitive',
      possibleValue: '',
      displayValue:'',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 003 ---------------------------------//
    {
      displayName: 'Date',
      sourceSystemName: 'DATA_DATE',
      apiName: 'data_date',

      description: 'Date',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue:'',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 004 ---------------------------------//
    {
      displayName: 'Type',
      sourceSystemName: 'CONTACT_TYPE',
      apiName: 'contact_type',

      description: 'Type of contact ',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrContact,
      displayValue: arrContact,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 005 ---------------------------------//
    {
      displayName: 'Name',
      sourceSystemName: 'CONTACT_NAME',
      apiName: 'contact_name',

      description: "Contact's name (Format: Firstname Lastname)",
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue:'',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 006 ---------------------------------//
    {
      displayName: 'Position',
      sourceSystemName: 'CONTACT_POSITION',
      apiName: 'contact_position',

      description: "Contact's position",
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue:'',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 007 ---------------------------------//
    {
      displayName: 'Email',
      sourceSystemName: 'CONTACT_EMAIL',
      apiName: 'contact_email',

      description: "Contact's email",
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue:'',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 008 ---------------------------------//
    {
      displayName: 'Phone',
      sourceSystemName: 'CONTACT_PHONE',
      apiName: 'contact_phone',

      description: "Contact's phone number (Format: Country Code-PhoneNo)",
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue:'',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 009 ---------------------------------//
    {
      displayName: 'Status',
      sourceSystemName: 'CONTACT_STATUS',
      apiName: 'contact_status',

      description: "Contact's status (active, inactive)",
      inputLength: '',
      inputType: 'radio',
      inputClass: 'General',
      possibleValue: arrYnValue,
      displayValue: arrActive,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ? --------------------------------- End ---------------------------------------//
  ],
};

export const invcontact = atom({
  key: 'invcontact',
  default: jsonData,
});
