import { useRecoilState } from 'recoil';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';

//api
import { getLogout } from '../../../api/index';

//Icon
import { isLogoutModal, isLoggedInState } from '../../../../states/index';

//Chips
import Chip from '@mui/material/Chip';
import ElementStyles from '../../../../styles/Element.module.css';
import LayoutStyles from '../../../../styles/Layout.module.css';

export default function LogoutModal() {
  const [openLogoutModal, setOpenLogoutModal] = useRecoilState(isLogoutModal);
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);

  const handleClose = () => {
    setOpenLogoutModal(false);
  };

  const onClickLogout = () => {
    (async () => {
      const logout = await getLogout();
      // console.log(logout.result.success);
      setIsLoggedIn(!logout.result.success);
    })();
    window.location.replace(`/`);
  };

  if (isLoggedIn === false) {
    window.location.replace(`/`);
  }


  return (
    <div>
      <Modal open={openLogoutModal} className={LayoutStyles.ModalBackgroundFade}>
        <Box className={LayoutStyles.ModalS_Box}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            container
            className={LayoutStyles.ModalPaper}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              container
              className={LayoutStyles.ModalL_Header}
              alignItems="center"
            >
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item>
                  <Typography color="primary" variant="h6" gutterBottom component="div">
                    <b>Logout</b>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={LayoutStyles.ModalS_Body}
              container
              direction="row"
              justifyContent="center"
             
            >
              {/* -------------------------------------------- Row 2/1   ---------------------------------------*/}

              <Grid item>
                <Typography variant="subtitle2" align="center" component="div">
                  {'Are you sure you want to logout?'}
                </Typography>
              </Grid>

              {/* --------------------------------------------------------------------------------------------------*/}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              container
              className={LayoutStyles.ModalS_Footer}
            >
              {/* --------------------------------- */}
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                container
              >
                <Grid item>
                  <Chip
                    color="primary"
                
                    variant="outlined"
                    className={ElementStyles.ButtonModalLeft}
                    label="Cancel"
                    onClick={handleClose}
                    clickable
                  />
                </Grid>
              </Grid>
              {/* --------------------------------- */}
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                container
              >
                <Grid item>
                  <Chip
                   
                  color='error'
                    className={ElementStyles.ButtonModalLeft}
                    onClick={onClickLogout}
                    label="Yes, Logout!"
                    clickable
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
