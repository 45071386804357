export enum Broker { // from Mastersheet Broker Data
	Cns001   = '001 CNS',
	Bls002   = '002 BLS',
	Asp003   = '003 ASP',
	Kgi004   = '004 KGI',
	Tisco005 = '005 TISCO',
	Phatr006 = '006 PHATR',
	Bls007   = '007 BLS',
	Bls008   = '008 BLS',
	Ktbs009  = '009 KTBS',
	Bls010   = '010 BLS',
}
export enum Currency { // from Mastersheet Currency
   Aed = 'AED',
   Aud = 'AUD',
   Bhd = 'BHD',
   Bnd = 'BND',
   Cad = 'CAD',
   Chf = 'CHF',
   Cny = 'CNY',
   Dkk = 'DKK',
   Eur = 'EUR',
   Gbp = 'GBP',
   Hkd = 'HKD',
   Idr = 'IDR',
   Idx = 'IDX',
   Inr = 'INR',
   Inx = 'INX',
   Iqd = 'IQD',
   Jod = 'JOD',
   Jpy = 'JPY',
   Khr = 'KHR',
   Krw = 'KRW',
   Krx = 'KRX',
   Kwd = 'KWD',
   Lak = 'LAK',
   Mad = 'MAD',
   Mmk = 'MMK',
   Myr = 'MYR',
   Myx = 'MYX',
   Nok = 'NOK',
   Nzd = 'NZD',
   Omr = 'OMR',
   Php = 'PHP',
   Phx = 'PHX',
   Pkr = 'PKR',
   Qar = 'QAR',
   Rub = 'RUB',
   Sar = 'SAR',
   Sek = 'SEK',
   Sgd = 'SGD',
   Thb = 'THB',
   Twd = 'TWD',
   Twx = 'TWX',
   Usd = 'USD',
   Vnd = 'VND',
   Vnx = 'VNX',
   Zar = 'ZAR',
}
export enum Sector { // from Mastersheet MSCI Industry
	InformationTechnology = 'INFORMATION_TECHNOLOGY',
	Financials            = 'FINANCIALS',
	HealthCare            = 'HEALTH_CARE',
	ConsumerDiscretionary = 'CONSUMER_DISCRETIONARY',
	Industrials           = 'INDUSTRIALS',
	CommunicationServices = 'COMMUNICATION_SERVICES',
	ConsumerStaples       = 'CONSUMER_STAPLES',
	Materials             = 'MATERIALS',
	Energy                = 'ENERGY',
	Utilities             = 'UTILITIES',
	RealEstate            = 'REAL_ESTATE',
}
export enum AssoSubFlag {
	None = 'NONE',
	Asso = 'ASSO',
	Sub  = 'SUB',
}
export enum FixedThbRate {
	FixedBot    = 'FIXED_BOT',
	FixedNonBot = 'FIXED_NON_BOT',
	NotFixed    = 'NOT_FIXED',
}
export enum Fund {
	PrivateEquityFunds = 'PRIVATE_EQUITY_FUNDS',
	HedgeFunds         = 'HEDGE_FUNDS',
	MutualFunds        = 'MUTUAL_FUNDS',
	CommodityFunds     = 'COMMODITY_FUNDS',
	PrivateCreditFunds = 'PRIVATE_CREDIT_FUNDS',
}
export enum Holding {
	CommonStock        = 'COMMON_STOCK',
	PreferredStock     = 'PREFERRED_STOCK',
	ConvertibleBond    = 'CONVERTIBLE_BOND',
	PerpetualBond      = 'PERPETUAL_BOND',
	Warrant            = 'WARRANT',
	Etf                = 'ETF',
	InfrastructureFund = 'INFRASTRUCTURE_FUND',
	PropertyFund       = 'PROPERTY_FUND',
}
export enum Ifrs9 {
	Debt           = 'DEBT',
	Debt32FailSppi = 'DEBT32_FAIL_SPPI',
	Debt32PassSppi = 'DEBT32_PASS_SPPI',
	DebtFailSppi   = 'DEBT_FAIL_SPPI',
	Derivative     = 'DERIVATIVE',
	Equity         = 'EQUITY',
	Equity32       = 'EQUITY32',
}
export enum Nationality {
	Thai    = 'THAI',
	Foreign = 'FOREIGN',
}
export enum Ownership {
	Direct   = 'DIRECT',
	Related  = 'RELATED',
	Branch   = 'BRANCH',
	Treasury = 'TREASURY',
}
export enum Port {
	Fvoci       = 'FVOCI',
	FvplBanking = 'FVPL_BANKING',
	FvplTrading = 'FVPL_TRADING',
	Subsidiary  = 'SUBSIDIARY',
	Associated  = 'ASSOCIATED',
	Amc         = 'AMC',
}
export enum Product {
	DebtConvertibleBond  = 'DEBT_CONVERTIBLE_BOND',
	DebtPerpetualBond    = 'DEBT_PERPETUAL_BOND',
	DebtCorporateBond    = 'DEBT_CORPORATE_BOND',
	EquityCommonShare    = 'EQUITY_COMMON_SHARE',
	EquityPreferredShare = 'EQUITY_PREFERRED_SHARE',
	EquityFunds          = 'EQUITY_FUNDS',
	EquityPerpetualBond  = 'EQUITY_PERPETUAL_BOND',
	Warrants             = 'WARRANTS',
}

// Binary enum(s) below are to be mapped to booleans (1st: true, 2nd: false)
export enum HedgeCurrency {
	Hedge    = 'HEDGE',
	NonHedge = 'NON_HEDGE',
}
export enum Redeemable {
	Redeemable    = 'REDEEMABLE',
	NonRedeemable = 'NON_REDEEMABLE',
}
export enum Tdr {
	Tdr    = 'TDR',
	NonTdr = 'NON_TDR',
}
export enum Yn {
	Yes = 'YES',
	No  = 'NO',
}