import { useRecoilValue } from 'recoil';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import ElementStyles from '../../../../styles/Element.module.css';
import {
  labelSizeState,
  labelGridState,
  inputGridState,
} from '../../../../states/index';

export default function CheckboxComp({
  label,
  placeholder,
  helperText,
  nameId,
  parentId,
  inputClass,
  isRequire,
  isActive,
}: {
  label: string;
  placeholder: string;
  helperText: string;
  nameId: string;
  parentId: string;
  inputClass: string;
  isRequire: boolean;
  isActive: boolean;
}) {
  const labelFontSize = useRecoilValue(labelSizeState);
  const labelGrid = useRecoilValue(labelGridState);
  const inputGrid = useRecoilValue(inputGridState);

  switch (inputClass) {
    //--------------------------------- Custom Limit Free ------------------------- */
    case 'Checkbox': {
      return (
        <Container className={ElementStyles.FormContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            container
          >
            <Grid
              xs={labelGrid}
              sm={labelGrid}
              md={labelGrid}
              item
              container
              alignItems="center"
            >
              <Grid item>
                <Typography
                  variant={labelFontSize}
                  className={ElementStyles.FormLabelText}
                >
                  <b>{label + ':'}</b>
                </Typography>
              </Grid>
            </Grid>

            <Grid xs={inputGrid} sm={inputGrid} md={inputGrid} item>
              <FormGroup row>
                <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />

                <FormControlLabel disabled control={<Checkbox />} label="Disabled" />
              </FormGroup>
            </Grid>
          </Grid>
        </Container>
      );
    }

    //******************************************************** NotFound ********************************************************** */
    //------------------------------------//
    default: {
      return <p>NotFound TextField InputClass</p>;
    }
  }
}
