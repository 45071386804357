import { Broker, Currency } from "./inv-enums";

export enum FundTransaction {
	Investment                         = 'INVESTMENT',
	ManagementFee                      = 'MANAGEMENT_FEE',
	OrganizationAndPartnershipExpenses = 'ORGANIZATION_AND_PARTNERSHIP_EXPENSES',
	OtherExpenses                      = 'OTHER_EXPENSES',
	InterestPaid                       = 'INTEREST_PAID',
	RecallableCapitalDistribution      = 'RECALLABLE_CAPITAL_DISTRIBUTION',
	RecallableOtherDistribution        = 'RECALLABLE_OTHER_DISTRIBUTION',
	NonRecallableCapitalDistribution   = 'NON_RECALLABLE_CAPITAL_DISTRIBUTION',
	NonRecallableOtherDistribution     = 'NON_RECALLABLE_OTHER_DISTRIBUTION',
	Dividend                           = 'DIVIDEND',
}

interface InvFundCashflowBaseInterface {
	txn_date       : string,            // date string
	txn_id         : string,            // --- Autogen from MASTERSHEET
	txn_type       : FundTransaction,
	recyclable_flag: boolean,           // Yes / No

	// Accounting Purposes
	cashflow_type                : boolean,   // Inflow / Outflow
	cashflow_amount              : number,
	total_partnership_paid_amount: number,
	current_stake                : number,    // --- Autogen FORMULAR -> = Cashflow AC / Total Partnership Paid
	unit_price                   : number,
	unit_no                      : number,

	// Accounting Purposes (Post Transaction Process)
	brokerage_name   : Broker,   // --- Autogen from MASTERSHEET (Broker Data)
	commission       : number,
	vat_levey_fee    : number,
	commission_vat   : number,
	withholding_tax  : number,
	custodian_fee    : number,
	miscellaneous_exp: number,
	fund_transfer_fee: number,
	underwriting_fee : number,
}

export type InvFundCashflow = InvFundCashflowBaseInterface & {
	fund_id   : string,   // --- Autogen from INVFUNDDEFINITIVE
	fund_quote: string,   // --- DROPDOWN from INVFUNDDEFINITIVE

	// Accounting Purposes
	currency: Currency,   // --- Autogen from INVFUNDDEFINITIVE
};
export type InvFundCashflowSubmit = InvFundCashflowBaseInterface & {
	fund_id: string,   // InvFundDefinitive.fund_id > fund_quote, currency

	//sheet id Ref
	sheet_id: string,
};


export type InvFundCashflowAll = InvFundCashflow & InvFundCashflowSubmit;