//---------------------------------------- Import React --------------------------------------
//React
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ElementStyles from '../../../../styles/Element.module.css';

export default function ProgressLoading({ typoLoading }: { typoLoading: string }) {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      className={ElementStyles.ProgressLoading}
      direction="row"
      justifyContent="center"
      alignItems="center"
      container
      spacing={2}
    >
      <Grid item>
        <CircularProgress color="primary" />
      </Grid>

      <Grid item>
        <Typography variant="h6" color="primary" gutterBottom component="div">
          {typoLoading}
        </Typography>
      </Grid>
    </Grid>
  );
}
