import { useRecoilValue } from 'recoil';
import DetailHeader from './DetailHeader';
import SubTable from '../table/SubTable';
import { subProfileHeadersState } from '../../../../states/index';
import { SubProfileTableBodyData } from '../../../../models/index';

function SubProfileInformation({
  typoHeader,
  subProfileJSON,
}: {
  typoHeader: string;
  subProfileJSON: SubProfileTableBodyData[];
}) {
  const subProfileHeaders = useRecoilValue(subProfileHeadersState);
  // console.log(subProfileJSON);
  return (
    <>
      <DetailHeader typoHeader={typoHeader + ' List'} headerType="create" isPending={false} />
      {/* -------- Row 1 ---------- */}
      <br />
      <SubTable
        rowBodyData={subProfileJSON}
        rowHeadData={subProfileHeaders}
        tableMode="sub_profile_table"
      />
    </>
  );
}

export default SubProfileInformation;
