import { atom } from 'recoil';
import { ProfileInterface } from '../models/index';

//----- portalStatusl ---------------
// keyword
// [1] company_profile

// [2] fund_profile
// [3] operation




export const isCallingAPIState = atom({
  key: 'isCallingAPI',
  default: false,
});

export const isLoggedInState = atom({
  key: 'isLoggedIn',
  default: false,
});

export const isFirstLoadState = atom({
  key: 'isFirstLoad',
  default: false,
});

export const portalStatusState = atom({
  key: 'portalStatus',
  default: 'company_profile',
});

const mockupProfile: ProfileInterface = {
  id: '1235',
  name: 'Bualung Jaidee',
  team: 'ADMIN',
  access: 'ADMIN',
  role: 'ADMIN',
};

export const userProfileState = atom({
  key: 'userType',
  default: mockupProfile,
});
