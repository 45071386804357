import { Currency } from "./inv-enums";

export enum EvaluationPeriod {
	Quarter = 'QUARTER',
	Annual  = 'ANNUAL'
}

interface InvCompFsBaseInterface {
	fs_id                         : string,				// Autogen
	reporting_date                : string,             // date string, Remark: Autogen from ENLITE
	fs_year                       : number,             // integer, Remark: Autogen from ENLITE
	fs_quarter                    : number,             // integer, Remark: Autogen from ENLITE
	value_period                  : EvaluationPeriod,
	value_country                 : string,             // Remark: Autogen from ENLITE/SET/Bloomberg
	currency                      : Currency,           // --- Autogen from INVCOMPDEFINITIVE (?)
	revenue                       : number,             // Remark: Autogen from ENLITE/SET/Bloomberg
	cost_of_good_sold             : number,             // Remark: Autogen from ENLITE/SET/Bloomberg
	gross_profit                  : number,             // Remark: Autogen from ENLITE/SET/Bloomberg
	ebitda                        : number,             // Remark: Autogen from ENLITE/SET/Bloomberg
	ebit                          : number,             // Remark: Autogen from ENLITE/SET/Bloomberg
	net_income                    : number,             // Remark: Autogen from ENLITE/SET/Bloomberg
	total_cash                    : number,             // Remark: Autogen from ENLITE/SET/Bloomberg
	total_current_asset           : number,             // Remark: Autogen from ENLITE/SET/Bloomberg
	total_asset                   : number,             // Remark: Autogen from ENLITE/SET/Bloomberg
	total_current_liability       : number,             // Remark: Autogen from ENLITE/SET/Bloomberg
	total_liability               : number,             // Remark: Autogen from ENLITE/SET/Bloomberg
	total_equity                  : number,             // Remark: Autogen from ENLITE/SET/Bloomberg
	interest_bearing_debt         : number,             // Remark: Autogen from ENLITE/SET/Bloomberg
	registered_capital_no_of_share: number,             // Remark: Autogen from ENLITE/SET/Bloomberg
	registered_capital_value      : number,             // Remark: Autogen from ENLITE/SET/Bloomberg
	paid_up_capital_no_of_share   : number,             // Remark: Autogen from ENLITE/SET/Bloomberg
	paid_up_capital_value         : number,             // Remark: Autogen from ENLITE/SET/Bloomberg

	// Accounting Purposes
	par_value           : number,
	book_value_per_share: number,   // --- Autogen FORMULAR -> = Total Equtiy/No of paid-up shares(shown in INVCOMPFS)
	earning_per_share   : number,   // --- Autogen FORMULAR -> = Net Income/No of paid-up shares(shown in INVCOMPFS)
}

export type InvCompFs = InvCompFsBaseInterface & {
	company_id  : string,     // --- Autogen from INVCOMPDEFINITIVE
	company_name: string,     // --- DROPDOWN from INVCOMPDEFINITIVE
	// currency : Currency,   // --- Autogen from INVCOMPDEFINITIVE (?)
};
export type InvCompFsSubmit = InvCompFsBaseInterface & {
	// InvCompDefinitive.company_id >
	//     company_id,
	//     company_name,
	//     currency (?)
	company_id: string,

	//sheet id Ref
	parent_id: string,
	sheet_id: string,
};
export type InvCompFsEntry = Pick<
	InvCompFs,
	'fs_id' |
	'company_id' |
	'paid_up_capital_no_of_share' |
	'par_value'
>[];

export type InvCompFsAll = InvCompFs & InvCompFsSubmit;