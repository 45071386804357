import { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import * as InvModels from '@invport/models';

//api

import {
  requestApproveInterface,
  requestCancelInterface,
} from '../../../../models/index';

//Common
import {
  approveContact,
  cancelContact,
  submitContact,
  approveMemo,
  cancelMemo,
  submitMemo,
} from '../../../api/index';
//Company
import {
  approveCompany,
  cancelCompany,
  submitCompany,
  approveProduct,
  cancelProduct,
  submitProduct,
  approveFs,
  cancelFs,
  submitFs,
} from '../../../api/index';

//Fund
import {
  approveFund,
  cancelFund,
  submitFund,
  approveNav,
  cancelNav,
  submitNav,
  approvePort,
  cancelPort,
  submitPort,
  approveBenchmark,
  cancelBenchmark,
  submitBenchmark,
  approveCommitment,
  cancelCommitment,
  submitCommitment,
} from '../../../api/index';

//Operation
import {
  approveAccount,
  cancelAccount,
  submitAccount,
  approveCapital,
  cancelCapital,
  submitCapital,
  approveCertificate,
  cancelCertificate,
  submitCertificate,
  approveAdjEquity,
  cancelAdjEquity,
  submitAdjEquity,
  approveAdjPar,
  cancelAdjPar,
  submitAdjPar,
  approveBot,
  cancelBot,
  submitBot,
  approveCompCashFlow,
  cancelCompCashFlow,
  submitCompCashFlow,
  approveDirector,
  cancelDirector,
  submitDirector,
  approveDividend,
  cancelDividend,
  submitDividend,
  approveCompFair,
  cancelCompFair,
  submitCompFair,
  approveWarrant,
  cancelWarrant,
  submitWarrant,
  approveFundCashFlow,
  cancelFundCashFlow,
  submitFundCashFlow,
} from '../../../api/index';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

//Icon

import CloseIcon from '@mui/icons-material/Close';

//Current Fill Data
import {
  currentFillInvBankAccountState,
  currentFillInvBankCapitalState,
  currentFillInvBoardApprovedMemoState,
  currentFillInvCertificateState,
  currentFillInvCompAdjustEquityState,
  currentFillInvCompAdjustParState,
  currentFillInvCompBotState,
  currentFillInvCompCashFlowState,
  currentFillInvCompDefinitiveState,
  currentFillInvCompDirectorState,
  currentFillInvCompDividendState,
  currentFillInvCompFairState,
  currentFillInvCompFsState,
  currentFillInvCompProductState,
  currentFillInvCompWarrantState,
  currentFillInvContactState,
  currentFillInvFundBenchmarkState,
  currentFillInvFundCashflowState,
  currentFillInvFundCommitmentAndWatermarkState,
  currentFillInvFundDefinitiveState,
  currentFillInvFundNavState,
  currentFillInvFundPortfolioState,
} from '../../../../states/index';

//Chips
import Chip from '@mui/material/Chip';
import ElementStyles from '../../../../styles/Element.module.css';
import LayoutStyles from '../../../../styles/Layout.module.css';

//State
import {
  isYesNoModal,
  isSuccessModal,
  isCreateModal,
  isCallingAPIState,
  isErrorModal,
} from '../../../../states/index';

//Current
import {
  portalStatusState,
  currentSubSheetapprovalJSONState,
  currentSheetNameState,
  currentIdState,
  currentCaseNameState,
  previousCaseNameState,
  currentFillFormStepState,
  isSubmitStepState,
  isFirstPageStepState,
} from '../../../../states/index';

function TypoButton({ _caseName }: { _caseName: string }) {
  switch (_caseName) {
    case 'create':
      return <>{'Yes, create sheet.'}</>;
    case 'edit':
      return <>{'Yes, edit this sheet.'}</>;
    case 'edit_form':
      return <>{'Yes, update this edited sheet.'}</>;
    case 'approve':
      return <>{'Yes, approve this sheet.'}</>;
    case 'close':
      return <>{'Yes, close this activity.'}</>;
    case 'cancel':
      return <>{'Yes, cancel this submitted'}</>;

    default:
      return <div></div>;
  }
}

function TypoHeader({ _caseName }: { _caseName: string }) {
  switch (_caseName) {
    case 'create':
      return <>{'Create Sheet'}</>;
    case 'edit':
      return <>{'Edit Sheet'}</>;
    case 'edit_form':
      return <>{'Update Edited Sheet'}</>;
    case 'approve':
      return <>{'Approve Sheet'}</>;
    case 'close':
      return <>{'Close this activity'}</>;
    case 'cancel':
      return <>{'Cancel this submitted?'}</>;

    default:
      return <div></div>;
  }
}

function TypoDescription({
  _caseName,
  _objectName,
  _sheetName,
}: {
  _caseName: string;
  _objectName: string;
  _sheetName: string;
}) {
  switch (_caseName) {
    case 'create':
      return <>{'Do you want to create "' + _sheetName + '" ?'}</>;
    case 'edit':
      return (
        <>
          {'Do you want to edit "' + _sheetName + '" sheet in "' + _objectName + ': ?'}
        </>
      );

    case 'edit_form':
      return (
        <>
          {'Do you want to edit "' + _sheetName + '" sheet in "' + _objectName + ': ?'}
        </>
      );

    case 'approve':
      return (
        <>
          {'Do you want to approve "' +
            _sheetName +
            '" sheet in "' +
            _objectName +
            '" ?'}
        </>
      );
    case 'close':
      return (
        <>
          {
            'Your data that you filling out will be lost if you close. Do you want to continue ?'
          }
        </>
      );
    case 'cancel':
      return (
        <>{'System will permanently delete this sheet. Do you want to continue ?'}</>
      );

    default:
      return <div></div>;
  }
}

export default function YesNoModal({
  caseName,
  sheetName,
  objectName,
  displaySheetName,
}: {
  caseName: string;
  sheetName: string;
  objectName: string;
  displaySheetName: string;
}) {
  const setIsCallingAPI = useSetRecoilState(isCallingAPIState);
  const [isSuccess, setIsSuccess] = useState(false);

  const [isFinishedSentRequest, setIsFinishedSentRequest] = useState(false);

  const [isUpdatingSheetStatus, setIsUpdatingSheetStatus] = useState(false);
  const portalStatus = useRecoilValue(portalStatusState);
  const [currentSubSheetapprovalJSON, setCurrentSubSheetapprovalJSON] = useRecoilState(
    currentSubSheetapprovalJSONState,
  );

  const [openErrorModal, setOpenErrorModal] = useRecoilState(isErrorModal);
  //-------------------------//
  const [openYesNoModal, setOpenYesNoModal] = useRecoilState(isYesNoModal);
  const setOpenSuccessModal = useSetRecoilState(isSuccessModal);
  const setOpenCreateModal = useSetRecoilState(isCreateModal);

  //Current
  const setCurrentCaseName = useSetRecoilState(currentCaseNameState);
  const currentId: { [key: string]: any } = useRecoilValue(currentIdState);
  const previousCaseName = useRecoilValue(previousCaseNameState);
  const currentSheetName = useRecoilValue(currentSheetNameState);

  //Current Fill Form
  const currentFillInvBankAccount = useRecoilValue(currentFillInvBankAccountState);
  const currentFillInvBankCapital = useRecoilValue(currentFillInvBankCapitalState);
  const currentFillInvBoardApprovedMemo = useRecoilValue(
    currentFillInvBoardApprovedMemoState,
  );
  const currentFillInvCertificate = useRecoilValue(currentFillInvCertificateState);
  const currentFillInvCompAdjustEquity = useRecoilValue(
    currentFillInvCompAdjustEquityState,
  );
  const currentFillInvCompAdjustPar = useRecoilValue(currentFillInvCompAdjustParState);
  const currentFillInvCompBot = useRecoilValue(currentFillInvCompBotState);
  const currentFillInvCompCashFlow = useRecoilValue(currentFillInvCompCashFlowState);
  const currentFillInvCompDefinitive = useRecoilValue(currentFillInvCompDefinitiveState);
  const currentFillInvCompDirector = useRecoilValue(currentFillInvCompDirectorState);
  const currentFillInvCompDividend = useRecoilValue(currentFillInvCompDividendState);
  const currentFillInvCompFair = useRecoilValue(currentFillInvCompFairState);
  const currentFillInvCompFs = useRecoilValue(currentFillInvCompFsState);
  const currentFillInvCompProduct = useRecoilValue(currentFillInvCompProductState);
  const currentFillInvCompWarrant = useRecoilValue(currentFillInvCompWarrantState);
  const currentFillInvContact = useRecoilValue(currentFillInvContactState);
  const currentFillInvFundBenchmark = useRecoilValue(currentFillInvFundBenchmarkState);
  const currentFillInvFundCashflow = useRecoilValue(currentFillInvFundCashflowState);
  const currentFillInvFundCommitmentAndWatermark = useRecoilValue(
    currentFillInvFundCommitmentAndWatermarkState,
  );
  const currentFillInvFundDefinitive = useRecoilValue(currentFillInvFundDefinitiveState);
  const currentFillInvFundNav = useRecoilValue(currentFillInvFundNavState);
  const currentFillInvFundPortfolio = useRecoilValue(currentFillInvFundPortfolioState);

  // console.log(currentId['id_value']);

  const setPage = useSetRecoilState(currentFillFormStepState);
  const setIsSubmit = useSetRecoilState(isSubmitStepState);
  const setIsFirstPage = useSetRecoilState(isFirstPageStepState);

  const setFalse = () => {
    setIsSuccess(false);
    setIsFinishedSentRequest(true);
  };

  //----------------------------------------------

  if (isSuccess === true && isFinishedSentRequest === true) {
    if (caseName === 'create' || caseName === 'edit_form') {
      setPage(1);
      setIsSubmit(false);
      setIsFirstPage(true);
      setOpenCreateModal(false);
      setIsUpdatingSheetStatus(true);
      setOpenSuccessModal(true);
    } else if (caseName === 'cancel') {
      setIsUpdatingSheetStatus(true);
      setOpenSuccessModal(true);
    } else if (caseName === 'approve') {
      setIsUpdatingSheetStatus(true);
      setOpenSuccessModal(true);
    }
    setIsFinishedSentRequest(false);
    setIsSuccess(false);
  } else if (isSuccess === false && isFinishedSentRequest === true) {
    setOpenErrorModal(true);
    setIsFinishedSentRequest(false);
    setIsSuccess(false);
  }

  //-----------------------------

  const handleClose = () => {
    if (caseName === 'close') {
      setCurrentCaseName(previousCaseName);
    } else {
    }

    setOpenYesNoModal(false);
  };

  const handleSubmit = () => {
    let isNewSheet = false;
    let empty: string = '';

    if (caseName === 'create') {
      isNewSheet = true;
    } else {
      isNewSheet = false;
    }

    if (caseName === 'create' || caseName === 'edit_form') {
      // console.log(currentId);
      switch (currentSheetName) {
        //?-------------------- Common -------------------- */

        //----------------- 01 ---------------- */
        case 'invcontact':
          const sendSubmitContact = async () => {
            const submitObj: InvModels.InvContactAll = {
              ...currentFillInvContact,
              parent_id: currentId['main_sheet_id'],
              sheet_id: isNewSheet ? empty : currentId['sub_sheet_id'],
            };

            const submitInvContact: InvModels.InvContactSubmit = submitObj;
            try {
              const response: { [key: string]: any } = await submitContact(
                submitInvContact,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };

          sendSubmitContact();
          break;

        //---------------- 02 ----------------- */
        case 'invboardapprovedmemo':
          const sendSubmitMemo = async () => {
            const submitObj: InvModels.InvBoardApprovedMemoAll = {
              ...currentFillInvBoardApprovedMemo,
              parent_id: currentId['main_sheet_id'],
              sheet_id: isNewSheet ? empty : currentId['sub_sheet_id'],
            };

            try {
              const submitInvMemo: InvModels.InvBoardApprovedMemoSubmit = submitObj;

              const response: { [key: string]: any } = await submitMemo(submitInvMemo);
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };

          sendSubmitMemo();
          break;

        //?-------------------- Company -------------------- */
        //---------------- 01 ----------------- */
        case 'invcompdefinitive':
          const sendSubmitCompany = async () => {
            const submitObj: InvModels.InvCompDefinitiveAll = {
              ...currentFillInvCompDefinitive,
              sheet_id: isNewSheet ? empty : currentId['main_sheet_id'],
            };

            try {
              const submitInvCompDefinitive: InvModels.InvCompDefinitiveSubmit =
                submitObj;

              const response: { [key: string]: any } = await submitCompany(
                submitInvCompDefinitive,
              );
              // console.log(response['result']['success']);
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };

          sendSubmitCompany();
          break;
        //---------------- 02 ----------------- */
        case 'invcompproduct':
          const sendSubmitProduct = async () => {
            const submitObj: InvModels.InvCompProductAll = {
              ...currentFillInvCompProduct,
              parent_id: currentId['main_sheet_id'],
              sheet_id: isNewSheet ? empty : currentId['sub_sheet_id'],
            };
            try {
              const submitInvCompProduct: InvModels.InvCompProductSubmit = submitObj;

              const response: { [key: string]: any } = await submitProduct(
                submitInvCompProduct,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendSubmitProduct();

          break;
        //------------------ 03 --------------- */
        case 'invcompfs':
          const sendSubmitFs = async () => {
            const submitObj: InvModels.InvCompFsAll = {
              ...currentFillInvCompFs,
              parent_id: currentId['main_sheet_id'],
              sheet_id: isNewSheet ? empty : currentId['sub_sheet_id'],
            };
            try {
              const submitInvCompFs: InvModels.InvCompFsSubmit = submitObj;
              const response: { [key: string]: any } = await submitFs(submitInvCompFs);
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };

          sendSubmitFs();
          break;
        //--------------------------------- */

        //?-------------------- Fund -------------------- */
        //----------------- 01 ---------------- */
        case 'invfunddefinitive':
          const sendSubmitFund = async () => {
            const submitObj: InvModels.InvFundDefinitiveAll = {
              ...currentFillInvFundDefinitive,
              sheet_id: isNewSheet ? empty : currentId['main_sheet_id'],
            };
            try {
              const submitInvFundDefinitive: InvModels.InvFundDefinitiveSubmit =
                submitObj;
              const response: { [key: string]: any } = await submitFund(
                submitInvFundDefinitive,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };

          sendSubmitFund();

          break;
        //----------------- 02 ---------------- */
        case 'invfundnav':
          const sendSubmitNav = async () => {
            const submitObj: InvModels.InvFundNavAll = {
              ...currentFillInvFundNav,
              parent_id: currentId['main_sheet_id'],
              sheet_id: isNewSheet ? empty : currentId['sub_sheet_id'],
            };
            try {
              const submitInvFundNav: InvModels.InvFundNavSubmit = submitObj;
              const response: { [key: string]: any } = await submitNav(submitInvFundNav);
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };

          sendSubmitNav();
          break;
        //----------------- 03 ---------------- */
        case 'invfundportfolio':
          const sendSubmitPort = async () => {
            const submitObj: InvModels.InvFundPortfolioAll = {
              ...currentFillInvFundPortfolio,
              parent_id: currentId['main_sheet_id'],
              sheet_id: isNewSheet ? empty : currentId['sub_sheet_id'],
            };
            try {
              const submitInvFundPortfolio: InvModels.InvFundPortfolioSubmit = submitObj;

              const response: { [key: string]: any } = await submitPort(
                submitInvFundPortfolio,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };

          sendSubmitPort();
          break;
        //----------------- 04 ---------------- */
        case 'invfundbenchmark':
          const sendSubmitBenchmark = async () => {
            const submitObj: InvModels.InvFundBenchmarkAll = {
              ...currentFillInvFundBenchmark,
              parent_id: currentId['main_sheet_id'],
              sheet_id: isNewSheet ? empty : currentId['sub_sheet_id'],
            };
            try {
              const submitInvFundBenchmark: InvModels.InvFundBenchmarkSubmit = submitObj;
              const response: { [key: string]: any } = await submitBenchmark(
                submitInvFundBenchmark,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };

          sendSubmitBenchmark();
          break;
        //---------------- 05 ----------------- */
        case 'invfundcommitmentandwatermark':
          const sendSubmitCommitment = async () => {
            const submitObj: InvModels.InvFundCommitmentAndWatermarkAll = {
              ...currentFillInvFundCommitmentAndWatermark,
              parent_id: currentId['main_sheet_id'],
              sheet_id: isNewSheet ? empty : currentId['sub_sheet_id'],
            };

            const submitInvFundCommitmentAndWatermark: InvModels.InvFundCommitmentAndWatermarkSubmit =
              submitObj;

            try {
              const response: { [key: string]: any } = await submitCommitment(
                submitInvFundCommitmentAndWatermark,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);

              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };

          sendSubmitCommitment();
          break;

        //?-------------------- Operation -------------------- */

        //-------------- 01 ------------------ */

        case 'invbankaccount':
          const sendSubmitAccount = async () => {
            const submitObj: InvModels.InvBankAccountAll = {
              ...currentFillInvBankAccount,
              sheet_id: isNewSheet ? empty : currentId['main_sheet_id'],
            };

            const submitInvBankAccount: InvModels.InvBankAccountSubmit = submitObj;

            try {
              const response: { [key: string]: any } = await submitAccount(
                submitInvBankAccount,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendSubmitAccount();
          break;

        //--------------- 02 ------------------ */

        case 'invbankcapital':
          const sendSubmitCapital = async () => {
            const submitObj: InvModels.InvBankCapitalAll = {
              ...currentFillInvBankCapital,
              sheet_id: isNewSheet ? empty : currentId['main_sheet_id'],
            };
            try {
              const submitInvBankCapital: InvModels.InvBankCapitalSubmit = submitObj;

              const response: { [key: string]: any } = await submitCapital(
                submitInvBankCapital,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);

              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendSubmitCapital();
          break;

        //---------------- 03 ----------------- */
        case 'invcertificate':
          const sendSubmitCertificate = async () => {
            const submitObj: InvModels.InvCertificateAll = {
              ...currentFillInvCertificate,
              sheet_id: isNewSheet ? empty : currentId['main_sheet_id'],
              product_id: '',
              company_id: '',
            };
            try {
              const submitInvCertificate: InvModels.InvCertificateSubmit = submitObj;

              const response: { [key: string]: any } = await submitCertificate(
                submitInvCertificate,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendSubmitCertificate();
          break;

        //---------------- 04 ----------------- */

        case 'invcompadjustequity':
          const sendSubmitAdjEquity = async () => {
            const submitObj: InvModels.InvCompAdjustEquityAll = {
              ...currentFillInvCompAdjustEquity,
              sheet_id: isNewSheet ? empty : currentId['main_sheet_id'],
              product_id: '',
              company_id: '',
            };
            try {
              const submitInvCompAdjustEquity: InvModels.InvCompAdjustEquitySubmit =
                submitObj;

              const response: { [key: string]: any } = await submitAdjEquity(
                submitInvCompAdjustEquity,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendSubmitAdjEquity();
          break;
        //----------------- 05 ---------------- */

        case 'invcompadjustpar':
          const sendSubmitAdjPar = async () => {
            const submitObj: InvModels.InvCompAdjustParAll = {
              ...currentFillInvCompAdjustPar,
              sheet_id: isNewSheet ? empty : currentId['main_sheet_id'],
              product_id: '',
              company_id: '',
              fs_id: '',
            };
            try {
              const submitInvCompAdjustPar: InvModels.InvCompAdjustParSubmit = submitObj;

              const response: { [key: string]: any } = await submitAdjPar(
                submitInvCompAdjustPar,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendSubmitAdjPar();
          break;

        //---------------- 06 ----------------- */

        case 'invcompbot':
          const sendSubmitBot = async () => {
            const submitObj: InvModels.InvCompBotAll = {
              ...currentFillInvCompBot,
              sheet_id: isNewSheet ? empty : currentId['main_sheet_id'],
            };
            try {
              const submitInvCompBot: InvModels.InvCompBotSubmit = submitObj;

              const response: { [key: string]: any } = await submitBot(submitInvCompBot);
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendSubmitBot();
          break;

        //--------------- 07 ------------------ */

        case 'invcompcashflow':
          const sendSubmitCompCashFlow = async () => {
            const submitObj: InvModels.InvCompCashFlowAll = {
              ...currentFillInvCompCashFlow,
              sheet_id: isNewSheet ? empty : currentId['main_sheet_id'],
              product_id: isNewSheet ? empty : currentId['sub_id_value'],
              company_id: '',
            };
            try {
              const submitInvCompCashFlow: InvModels.InvCompCashFlowSubmit = submitObj;

              const response: { [key: string]: any } = await submitCompCashFlow(
                submitInvCompCashFlow,
              );

              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendSubmitCompCashFlow();
          break;

        //------------- 08 -------------------- */
        case 'invcompdirector':
          const sendSubmitDirector = async () => {
            const submitObj: InvModels.InvCompDirectorAll = {
              ...currentFillInvCompDirector,
              sheet_id: isNewSheet ? empty : currentId['main_sheet_id'],
            };
            try {
              const submitInvCompDirector: InvModels.InvCompDirectorSubmit = submitObj;
              const response: { [key: string]: any } = await submitDirector(
                submitInvCompDirector,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };

          sendSubmitDirector();
          break;

        //---------------- 09 ----------------- */

        case 'invcompdividend':
          const sendSubmitDividend = async () => {
            const submitObj: InvModels.InvCompDividendAll = {
              ...currentFillInvCompDividend,
              sheet_id: isNewSheet ? empty : currentId['main_sheet_id'],
              product_id: currentId['sub_id_value'],
              company_id: '',
            };
            try {
              const submitInvCompDividend: InvModels.InvCompDividendSubmit = submitObj;

              const response: { [key: string]: any } = await submitDividend(
                submitInvCompDividend,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };

          sendSubmitDividend();
          break;

        //--------------- 10 ------------------ */

        case 'invcompfair':
          const sendSubmitCompFair = async () => {
            const submitObj: InvModels.InvCompFairAll = {
              ...currentFillInvCompFair,
              sheet_id: isNewSheet ? empty : currentId['main_sheet_id'],
            };
            try {
              const submitInvCompFair: InvModels.InvCompFairSubmit = submitObj;
              const response: { [key: string]: any } = await submitCompFair(
                submitInvCompFair,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };

          sendSubmitCompFair();
          break;

        //---------------- 11 ----------------- */

        case 'invcompwarrant':
          const sendSubmitWarrant = async () => {
            const submitObj: InvModels.InvCompWarrantAll = {
              ...currentFillInvCompWarrant,
              sheet_id: isNewSheet ? empty : currentId['main_sheet_id'],
              company_id: '',
              product_id: '',
              product_id_2: '',
              txn_id: '',
            };
            try {
              const submitInvCompWarrant: InvModels.InvCompWarrantSubmit = submitObj;
              const response: { [key: string]: any } = await submitWarrant(
                submitInvCompWarrant,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };

          sendSubmitWarrant();
          break;

        //---------------- 12 ----------------- */

        case 'invfundcashflow':
          const sendSubmitFundCashflow = async () => {
            const submitObj: InvModels.InvFundCashflowAll = {
              ...currentFillInvFundCashflow,
              sheet_id: isNewSheet ? empty : currentId['main_sheet_id'],
            };

            try {
              const submitInvFundCashflow: InvModels.InvFundCashflowSubmit = submitObj;

              const response: { [key: string]: any } = await submitFundCashFlow(
                submitInvFundCashflow,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };

          sendSubmitFundCashflow();
          break;

        //************** NotFound ********************* */
        //------------------------------------//
        default:
          break;
      }

      //?------------------------------------------------------------------//
    } else if (caseName === 'edit') {
      setCurrentCaseName('edit_form');
      setOpenCreateModal(true);
      setPage(1);
      setIsSubmit(false);
      setIsFirstPage(true);
      //?------------------------------------------------------------------//
      // } else if (caseName === 'edit_form') {
      //   setOpenCreateModal(false);
      //   setOpenSuccessModal(true);
      //?------------------------------------------------------------------//
    } else if (caseName === 'approve') {
      let requestApprove: requestApproveInterface = {
        id: currentId['main_sheet_id'],
      };

      let requestSubApprove: requestApproveInterface = {
        id: currentId['sub_sheet_id'],
      };

      // console.log(currentId);

      switch (currentSheetName) {
        //?-------------------- Common -------------------- */

        //----------------- 01 ---------------- */
        case 'invcontact':
          const sendApproveContact = async () => {
            try {
              const response: { [key: string]: any } = await approveContact(
                requestSubApprove,
              );

              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendApproveContact();
          break;

        //---------------- 02 ----------------- */
        case 'invboardapprovedmemo':
          const sendApproveMemo = async () => {
            try {
              const response: { [key: string]: any } = await approveMemo(
                requestSubApprove,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendApproveMemo();
          break;

        //?-------------------- Company -------------------- */
        //---------------- 01 ----------------- */
        case 'invcompdefinitive':
          const sendApproveCompany = async () => {
            try {
              const response: { [key: string]: any } = await approveCompany(
                requestApprove,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendApproveCompany();
          break;
        //---------------- 02 ----------------- */
        case 'invcompproduct':
          const sendApproveProduct = async () => {
            try {
              const response: { [key: string]: any } = await approveProduct(
                requestSubApprove,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendApproveProduct();
          break;
        //------------------ 03 --------------- */
        case 'invcompfs':
          const sendApproveFs = async () => {
            try {
              const response: { [key: string]: any } = await approveFs(
                requestSubApprove,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendApproveFs();
          break;
        //--------------------------------- */

        //?-------------------- Fund -------------------- */
        //----------------- 01 ---------------- */
        case 'invfunddefinitive':
          const sendApproveFund = async () => {
            try {
              const response: { [key: string]: any } = await approveFund(requestApprove);
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendApproveFund();
          break;
        //----------------- 02 ---------------- */
        case 'invfundnav':
          const sendApproveNav = async () => {
            try {
              const response: { [key: string]: any } = await approveNav(
                requestSubApprove,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendApproveNav();
          break;
        //----------------- 03 ---------------- */
        case 'invfundportfolio':
          const sendApprovePort = async () => {
            try {
              const response: { [key: string]: any } = await approvePort(
                requestSubApprove,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendApprovePort();
          break;
        //----------------- 04 ---------------- */
        case 'invfundbenchmark':
          const sendApproveBenchmark = async () => {
            try {
              const response: { [key: string]: any } = await approveBenchmark(
                requestSubApprove,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendApproveBenchmark();
          break;
        //---------------- 05 ----------------- */
        case 'invfundcommitmentandwatermark':
          const sendApproveCommitment = async () => {
            try {
              const response: { [key: string]: any } = await approveCommitment(
                requestSubApprove,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendApproveCommitment();
          break;

        //?-------------------- Operation -------------------- */

        //-------------- 01 ------------------ */

        case 'invbankaccount':
          const sendApproveAccount = async () => {
            try {
              const response: { [key: string]: any } = await approveAccount(
                requestApprove,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendApproveAccount();
          break;

        //--------------- 02 ------------------ */

        case 'invbankcapital':
          const sendApproveCapital = async () => {
            try {
              const response: { [key: string]: any } = await approveCapital(
                requestApprove,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendApproveCapital();
          break;

        //---------------- 03 ----------------- */
        case 'invcertificate':
          const sendApproveCertificate = async () => {
            try {
              const response: { [key: string]: any } = await approveCertificate(
                requestApprove,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendApproveCertificate();
          break;

        //---------------- 04 ----------------- */

        case 'invcompadjustequity':
          const sendApproveAdjEquity = async () => {
            try {
              const response: { [key: string]: any } = await approveAdjEquity(
                requestApprove,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendApproveAdjEquity();
          break;
        //----------------- 05 ---------------- */

        case 'invcompadjustpar':
          const sendApproveAdjPar = async () => {
            try {
              const response: { [key: string]: any } = await approveAdjPar(
                requestApprove,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendApproveAdjPar();
          break;

        //---------------- 06 ----------------- */

        case 'invcompbot':
          const sendApproveBot = async () => {
            try {
              const response: { [key: string]: any } = await approveBot(requestApprove);
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendApproveBot();
          break;

        //--------------- 07 ------------------ */

        case 'invcompcashflow':
          const sendApproveCompCashFlow = async () => {
            try {
              const response: { [key: string]: any } = await approveCompCashFlow(
                requestApprove,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendApproveCompCashFlow();
          break;

        //------------- 08 -------------------- */
        case 'invcompdirector':
          const sendApproveDirector = async () => {
            try {
              const response: { [key: string]: any } = await approveDirector(
                requestApprove,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendApproveDirector();
          break;

        //---------------- 09 ----------------- */

        case 'invcompdividend':
          const sendApproveDividend = async () => {
            try {
              const response: { [key: string]: any } = await approveDividend(
                requestApprove,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendApproveDividend();
          break;

        //--------------- 10 ------------------ */

        case 'invcompfair':
          const sendApproveCompFair = async () => {
            try {
              const response: { [key: string]: any } = await approveCompFair(
                requestApprove,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendApproveCompFair();
          break;

        //---------------- 11 ----------------- */

        case 'invcompwarrant':
          const sendApproveWarrant = async () => {
            try {
              const response: { [key: string]: any } = await approveWarrant(
                requestApprove,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendApproveWarrant();
          break;

        //---------------- 12 ----------------- */

        case 'invfundcashflow':
          const sendApproveFundCashFlow = async () => {
            try {
              const response: { [key: string]: any } = await approveFundCashFlow(
                requestApprove,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendApproveFundCashFlow();
          break;

        //************** NotFound ********************* */
        //------------------------------------//
        default:
          break;
      }

      //?------------------------------------------------------------------//
    } else if (caseName === 'cancel') {
      let requestCancel: requestCancelInterface = {
        id: currentId['main_sheet_id'],
      };

      let requestSubCancel: requestCancelInterface = {
        id: currentId['sub_sheet_id'],
      };

      // console.log(requestCancel);
      //    console.log(requestSubCancel);

      switch (currentSheetName) {
        //?-------------------- Common -------------------- */

        //----------------- 01 ---------------- */
        case 'invcontact':
          const sendCancelContact = async () => {
            try {
              const response: { [key: string]: any } = await cancelContact(
                requestSubCancel,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendCancelContact();
          break;

        //---------------- 02 ----------------- */
        case 'invboardapprovedmemo':
          const sendCancelMemo = async () => {
            try {
              const response: { [key: string]: any } = await cancelMemo(
                requestSubCancel,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendCancelMemo();
          break;

        //?-------------------- Company -------------------- */
        //---------------- 01 ----------------- */
        case 'invcompdefinitive':
          const sendCancelCompany = async () => {
            try {
              const response: { [key: string]: any } = await cancelCompany(
                requestCancel,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendCancelCompany();
          break;
        //---------------- 02 ----------------- */
        case 'invcompproduct':
          const sendCancelProduct = async () => {
            try {
              const response: { [key: string]: any } = await cancelProduct(
                requestSubCancel,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendCancelProduct();
          break;
        //------------------ 03 --------------- */
        case 'invcompfs':
          const sendCancelFs = async () => {
            try {
              const response: { [key: string]: any } = await cancelFs(requestSubCancel);
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendCancelFs();
          break;
        //--------------------------------- */

        //?-------------------- Fund -------------------- */
        //----------------- 01 ---------------- */
        case 'invfunddefinitive':
          const sendCancelFund = async () => {
            try {
              const response: { [key: string]: any } = await cancelFund(requestCancel);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendCancelFund();
          break;
        //----------------- 02 ---------------- */
        case 'invfundnav':
          const sendCancelNav = async () => {
            try {
              const response: { [key: string]: any } = await cancelNav(requestSubCancel);
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendCancelNav();
          break;
        //----------------- 03 ---------------- */
        case 'invfundportfolio':
          const sendCancelPort = async () => {
            try {
              const response: { [key: string]: any } = await cancelPort(
                requestSubCancel,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendCancelPort();
          break;
        //----------------- 04 ---------------- */
        case 'invfundbenchmark':
          const sendCancelBenchmark = async () => {
            try {
              const response: { [key: string]: any } = await cancelBenchmark(
                requestSubCancel,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendCancelBenchmark();
          break;
        //---------------- 05 ----------------- */
        case 'invfundcommitmentandwatermark':
          const sendCancelCommitment = async () => {
            try {
              const response: { [key: string]: any } = await cancelCommitment(
                requestSubCancel,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendCancelCommitment();
          break;

        //?-------------------- Operation -------------------- */

        //-------------- 01 ------------------ */

        case 'invbankaccount':
          const sendCancelAccount = async () => {
            try {
              const response: { [key: string]: any } = await cancelAccount(
                requestCancel,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };

          sendCancelAccount();
          break;

        //--------------- 02 ------------------ */

        case 'invbankcapital':
          const sendCancelCapital = async () => {
            try {
              const response: { [key: string]: any } = await cancelCapital(
                requestCancel,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };

          sendCancelCapital();
          break;

        //---------------- 03 ----------------- */
        case 'invcertificate':
          const sendCancelCertificate = async () => {
            try {
              const response: { [key: string]: any } = await cancelCertificate(
                requestCancel,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendCancelCertificate();
          break;

        //---------------- 04 ----------------- */

        case 'invcompadjustequity':
          const sendCancelAdjEquity = async () => {
            try {
              const response: { [key: string]: any } = await cancelAdjEquity(
                requestCancel,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendCancelAdjEquity();
          break;
        //----------------- 05 ---------------- */

        case 'invcompadjustpar':
          const sendCancelAdjPar = async () => {
            try {
              const response: { [key: string]: any } = await cancelAdjPar(requestCancel);
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };

          sendCancelAdjPar();
          break;

        //---------------- 06 ----------------- */

        case 'invcompbot':
          const sendCancelBot = async () => {
            try {
              const response: { [key: string]: any } = await cancelBot(requestCancel);
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendCancelBot();
          break;

        //--------------- 07 ------------------ */

        case 'invcompcashflow':
          const sendCancelCompCashFlow = async () => {
            try {
              const response: { [key: string]: any } = await cancelCompCashFlow(
                requestCancel,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendCancelCompCashFlow();
          break;

        //------------- 08 -------------------- */
        case 'invcompdirector':
          const sendCancelDirector = async () => {
            try {
              const response: { [key: string]: any } = await cancelDirector(
                requestCancel,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendCancelDirector();
          break;

        //---------------- 09 ----------------- */

        case 'invcompdividend':
          const sendCancelDividend = async () => {
            try {
              const response: { [key: string]: any } = await cancelDividend(
                requestCancel,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendCancelDividend();
          break;

        //--------------- 10 ------------------ */

        case 'invcompfair':
          const sendCancelCompFair = async () => {
            try {
              const response: { [key: string]: any } = await cancelCompFair(
                requestCancel,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendCancelCompFair();
          break;

        //---------------- 11 ----------------- */

        case 'invcompwarrant':
          const sendCancelWarrant = async () => {
            try {
              const response: { [key: string]: any } = await cancelWarrant(
                requestCancel,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendCancelWarrant();
          break;

        //---------------- 12 ----------------- */

        case 'invfundcashflow':
          const sendCancelFundCashFlow = async () => {
            try {
              const response: { [key: string]: any } = await cancelFundCashFlow(
                requestCancel,
              );
              setIsSuccess(response['result']['success']);
              setIsFinishedSentRequest(true);
              return response;
            } catch (error) {
              setIsSuccess(false);
              setIsFinishedSentRequest(true);
            }
          };
          sendCancelFundCashFlow();
          break;

        //************** NotFound ********************* */
        //------------------------------------//
        default:
          break;
      }

      //?------------------------------------------------------------------//
    } else if (caseName === 'close') {
      if (
        previousCaseName === 'create' ||
        previousCaseName === 'edit' ||
        previousCaseName === 'edit_form'
      ) {
        setPage(1);
        setIsSubmit(false);
        setIsFirstPage(true);
        setIsUpdatingSheetStatus(true);
        setOpenCreateModal(false);
      }

      //?------------------------------------------------------------------//
    } else {
    }
    setIsUpdatingSheetStatus(true);
    setOpenYesNoModal(false);
  };

  return (
    <div>
      <Modal open={openYesNoModal} className={LayoutStyles.ModalBackgroundFade}>
        <Box className={LayoutStyles.ModalS_Box}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            container
            className={LayoutStyles.ModalPaper}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              container
              className={LayoutStyles.ModalL_Header}
              alignItems="center"
            >
              <Grid
                item
                container
                xs={10}
                sm={10}
                md={10}
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={1}
              >
                <Grid item>
                  <div></div>
                </Grid>
                <Grid item>
                  <Typography color="primary" variant="h6" gutterBottom component="div">
                    <TypoHeader _caseName={caseName} />
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Grid item>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={LayoutStyles.ModalS_Body}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="left"
            >
              {/* -------------------------------------------- Row 2/1   ---------------------------------------*/}

              <Grid item>
                <Typography variant="subtitle2" align="left" component="div">
                  <TypoDescription
                    _caseName={caseName}
                    _sheetName={displaySheetName}
                    _objectName={currentId['main_name']}
                  />
                </Typography>
              </Grid>

              {/* --------------------------------------------------------------------------------------------------*/}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              container
              className={LayoutStyles.ModalS_Footer}
            >
              {/* --------------------------------- */}
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                direction="row"
                justifyContent="flex-start"
                alignItems="left"
                container
              >
                <Grid item>
                  <Chip
                    color="primary"
                    variant="outlined"
                    className={ElementStyles.ButtonModalLeft}
                    label="Cancel"
                    onClick={handleClose}
                    clickable
                  />
                </Grid>
              </Grid>
              {/* --------------------------------- */}
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                direction="row"
                justifyContent="flex-end"
                alignItems="right"
                container
              >
                <Grid item>
                  <Chip
                    color="primary"
                    className={ElementStyles.ButtonModalRight}
                    label={<TypoButton _caseName={caseName} />}
                    onClick={handleSubmit}
                    clickable
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
