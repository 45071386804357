import { atom } from 'recoil';
import { arrCurrency } from '../common';

const _sheetName = 'invfundportfolio';

//?------- Parameter Design ---------------
/*

------- Label / ID ------------
displayName = variable for display name in label.
sourceSystemName = variable for bank system name.
apiName = variable for call api. 

-------- Input ----------------
description = input placeholder.
inputLength = custom length limit of character.
inputType = (checkbox | date | radio | select | textfield) type of input.
inputClass = Custom format masking or validation.
possibleValue = (array) Value for dropdownlist / checkbox / radio.

-------- Boolean --------------
isRequire = (true/false) require field. can't be null.

isLinkValue = (true/false) Link the value from other input (linkValueId)
linkValueId = ID of input to Link data (display when isLinkValue == true).

isAutoGenerate = (true/false) auto generate value.
generateSource = source of auto generate (display when isAutoGenerate == true).

*/
/**
 textfield
 - NoLimitFree
 - noLimitCountNumber

 date
 - General

select
 - General

 */

const jsonData: { [key: string]: any } = {
  sheetName: _sheetName,
  inputInfo: [
    // ? --------------------------------- Start ---------------------------------------//
    // ---------------------------- 001 ---------------------------------//
    {
      displayName: 'Fund ID',
      sourceSystemName: 'FUND_ID',
      apiName: 'fund_id',

      description: 'Fund ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'fund_quote',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 002 ---------------------------------//
    {
      displayName: 'Fund Quote',
      sourceSystemName: 'FUND_QUOTE',
      apiName: 'fund_quote',

      description: 'Fund Quote',
      inputLength: '',
      inputType: 'select',
      inputClass: 'Autocomplete',
      possibleValue: ['/fund/list'],
      displayValue: ['/fund/list'],

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: true,
    },

    // ---------------------------- 003 ---------------------------------//
    {
      displayName: 'Date',
      sourceSystemName: 'DATA_DATE',
      apiName: 'data_date',

      description: 'Date',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 004 ---------------------------------//
    {
      displayName: 'Company Name',
      sourceSystemName: 'COMPANY_NAME',
      apiName: 'company_name',

      description: 'Company Name',
      inputLength: '',
      inputType: 'select',
      inputClass: 'Autocomplete',
      possibleValue: ['/company/list'],
      displayValue: ['/company/list'],

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: true,
    },

    // ---------------------------- 005 ---------------------------------//
    {
      displayName: 'Company ID',
      sourceSystemName: 'COMPANY_ID',
      apiName: 'company_id',

      description: 'Company ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'company_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 006 ---------------------------------//
    {
      displayName: 'Currency',
      sourceSystemName: 'CURRENCY',
      apiName: 'currency',

      description: 'Currency',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrCurrency,
      displayValue: arrCurrency,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 007 ---------------------------------//
    {
      displayName: 'Investment Cost',
      sourceSystemName: 'INVESTMENT_COST',
      apiName: 'investment_cost',

      description: 'Investment Cost ',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 008 ---------------------------------//
    {
      displayName: 'Fair Value',
      sourceSystemName: 'FAIR_VALUE',
      apiName: 'fair_value',

      description: 'Fair Value',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },


    // ? --------------------------------- End ---------------------------------------//
  ],
};

export const invfundportfolio = atom({
  key: 'invfundportfolio',
  default: jsonData,
});
