//---------------------------------------- Import React --------------------------------------
//React
import * as React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SearchIcon from '@mui/icons-material/Search';

//api
import { getCompany, getFund } from '../../../api/index';

import ElementStyles from '../../../../styles/Element.module.css';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import OutlinedInput from '@mui/material/OutlinedInput';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import {
  currentSearchKeywordState,
  currentOperationTableState,
  currentSheetNameState,
} from '../../../../states/index';

import {
  labelSizeState,
  labelGridState,
  inputGridState,
  isLoadingTableState,
  isLoadedMasterRowsState,

} from '../../../../states/index';

import { sheetNameState } from '../../../../states/index';

function SearchInput({
  nameId,
  handleSearchInputChange,
  handleSearchClick,
  isActive,
}: {
  nameId: string;
  handleSearchInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchClick: () => void;
  isActive: boolean;
}) {
  return (
    <Grid
    item
      xs={12}
      sm={12}
      md={12}
      spacing={1}
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      container
    >
      <Grid xs={8} sm={8} md={8} item container>
        <Grid xs={12} sm={12} md={12} item>
          <FormControl className={ElementStyles.FormFormControl}>
            <OutlinedInput
              key={nameId}
              id={nameId}
              name={nameId}
              onChange={handleSearchInputChange}
              placeholder="Search"
              className={
                isActive
                  ? ElementStyles.FormOutlinedInputActive
                  : ElementStyles.FormOutlinedInputDisable
              }
              disabled={!isActive}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* ------------------------ */}

      <Grid xs={4} sm={4} md={4} item container justifyContent="flex-start">
        <Grid item>
          <Chip
            variant="outlined"
            icon={<SearchIcon />}
            color="primary"
            className={ElementStyles.ButtonAdd}
            label={'Search'}
            onClick={handleSearchClick}
            clickable
            disabled={!isActive}
          />
        </Grid>
      </Grid>

      {/* ------------------------ */}
    </Grid>
  );
}
//************************************************ Default Export ***************************************************** */
export default function SearchBar({
  portalMode,
  onSelectedDropDownList,
  onSearchClick,
}: {
  portalMode: string;
  onSelectedDropDownList: (fieldVal: string) => void;
  onSearchClick: () => void;
}) {
  //-------------------------------------------------------------------------------//

  //-------------------------------------------------------------------------------//

  const [currentOperationTable, setCurrentOperationTable] = useRecoilState(
    currentOperationTableState,
  );

  const setCurrentSheetName = useSetRecoilState(currentSheetNameState);
  const setCurrentSearchKeyword = useSetRecoilState(currentSearchKeywordState);
  const setIsLoadingTable = useSetRecoilState(isLoadingTableState);

  

  const [isLoadedRows, setIsLoadedRows] = useRecoilState(isLoadedMasterRowsState);

  const handleChange = (event: SelectChangeEvent) => {
    let fieldVal = event.target.value as string;
    setCurrentSheetName(fieldVal);
    setCurrentOperationTable(fieldVal);
    onSelectedDropDownList(fieldVal);
    setIsLoadingTable(true);
    setIsLoadedRows(false);
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fieldVal = (event.target as HTMLInputElement).value;
    setCurrentSearchKeyword(fieldVal);
  };

  const labelFontSize = useRecoilValue(labelSizeState);
  const labelGrid = useRecoilValue(labelGridState);
  const inputGrid = useRecoilValue(inputGridState);
  const sheetName = useRecoilValue(sheetNameState);
  //-------------------------------------------------------------------------------//

  let nameId = 'hello';

  //-------------------------------------------------------------------------------//

  switch (portalMode) {
    case 'company_profile': {
      return (
        <div className={ElementStyles.SearchBar}>
          <Grid item xs={12} sm={12} md={12} container>
            <Grid item xs={8} sm={8} md={8}>
              <SearchInput
                nameId={nameId}
                isActive={isLoadedRows}
                handleSearchInputChange={handleSearchInputChange}
                handleSearchClick={onSearchClick}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}></Grid>
          </Grid>
        </div>
      );
    }
    //------------------------------------//
    case 'fund_profile': {
      return (
        <div className={ElementStyles.SearchBar}>
          <Grid item xs={12} sm={12} md={12} container>
            <Grid item xs={8} sm={8} md={8}>
              <SearchInput
                nameId={nameId}
                isActive={isLoadedRows}
                handleSearchInputChange={handleSearchInputChange}
                handleSearchClick={onSearchClick}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}></Grid>
          </Grid>
        </div>
      );
    }

    //------------------------------------//
    case 'operation': {
      return (
        <div className={ElementStyles.SearchBar}>
          {/* ----------------------- */}

          <Grid
            xs={12}
            sm={12}
            md={12}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            container
            spacing={1}
          >
            {/* ------------------------ */}
            <Grid item xs={8} sm={8} md={8} lg={6} container>
              <Grid xs={3} sm={2} md={2} item container alignItems="center">
                <Grid item>
                  <Typography
                    variant={labelFontSize}
                    className={ElementStyles.FormLabelText}
                  >
                    <b>Sheet:</b>
                  </Typography>
                </Grid>
              </Grid>

              <Grid xs={9} sm={10} md={10} item>
                <FormControl fullWidth>
                  <Select
                    disabled={!isLoadedRows}
                    value={currentOperationTable}
                    onChange={handleChange}
                    className={
                      isLoadedRows
                        ? ElementStyles.FormOutlinedInputActive
                        : ElementStyles.FormOutlinedInputDisable
                    }
                    input={<OutlinedInput key={nameId} id={nameId} name={nameId} />}
                  >
                    {/* <MenuItem value='' >{placeholder}</MenuItem> */}

                    {Object.keys(sheetName['operation']['subsheet']).map(
                      (index: any) => {
                        return (
                          <MenuItem
                            value={
                              sheetName['operation']['subsheet'][index]['systemName']
                            }
                          >
                            {sheetName['operation']['subsheet'][index]['displayName']}
                          </MenuItem>
                        );
                      },
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {/* ------------------------ */}

            <Grid xs={4} sm={4} md={4} lg={6} item container>
              <SearchInput
                nameId={nameId}
                isActive={isLoadedRows}
                handleSearchInputChange={handleSearchInputChange}
                handleSearchClick={onSearchClick}
              />

              {/* ------------------------ */}
            </Grid>
          </Grid>

          {/* ---------------------------------- */}
        </div>
      );
    }

    //------------------------------------//
    default: {
      return <div>{'NotFound Create Button'}</div>;
    }
  }
}
