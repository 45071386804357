import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
//---- API
import { getCompany, getFund, getAccount } from '../../api/index';
import {
  requestCompanyMainInterface,
  requestFundMainInterface,
  requestAccountMainInterface,
  OrderBy,
} from '../../../models/index';

//---- Table
import {
  createCompanyTableBodyData,
  createFundTableBodyData,
  createInvBankAccountBodyTable,
} from '../../../models/index';

import {
  companyRowsState,
  fundRowsState,
  operationRowsState,
  operationHeadersState,
  InvBankAccountHeaders,
} from '../../../states/index';

//----
import Divider from '@mui/material/Divider';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Grid from '@mui/material/Grid';

import { sheetNameState } from '../../../states/index';
import { tableOrderByState } from '../../../states/index';

//Current
import {
  currentTablePageState,
  currentInputJSONState,
  currentSheetNameState,
  currentOperationTableState,
} from '../../../states/index';

//User
import {
  tableConfigState,
  portalStatusState,
  userProfileState,
  isLoggedInState,
  isLoadedMasterRowsState,
} from '../../../states/index';

//Style
import ElementStyles from '../../../styles/Element.module.css';
import LayoutStyles from '../../../styles/Layout.module.css';

//Custom
import BBLLogoWhite from '../common/logo/BBLLogoWhite';

const sideBarMenu = [
  {
    id: '',
    children: [
      {
        id: 'Company Profile',
        icon: <AccountBalanceIcon />,
        active: false,
        setPortal: 'company_profile',
      },
      {
        id: 'Fund Profile',
        icon: <AccountBalanceWalletIcon />,
        active: false,
        setPortal: 'fund_profile',
      },
      {
        id: 'Operation',
        icon: <SwapHorizIcon />,
        active: false,
        setPortal: 'operation',
      },
    ],
  },
];

const SideBarHeader = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255)',
};

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: 'none',
  py: 1.5,
  px: 3,
};

export default function SideBar({
  drawerWidth,
  onMenuClick,
}: {
  drawerWidth: number;
  onMenuClick: (
    _currentSystemSheetName: string,
    _sheet_id: string,
    _rowsLengthTotal: number,
    _currentSearchKeyword: string,
    _currentSortBy: string,
  ) => void;
}) {
  const setInputJSON = useSetRecoilState(currentInputJSONState);
  const setCurrentSheetName = useSetRecoilState(currentSheetNameState);
  const [userProfile, setUserProfile] = useRecoilState(userProfileState);
  const isLoggedIn = useRecoilValue(isLoggedInState);
  const setCurrentOperationTable = useSetRecoilState(currentOperationTableState);
  const setCompanyRows = useSetRecoilState(companyRowsState);
  const setFundRows = useSetRecoilState(fundRowsState);
  const setOperationRows = useSetRecoilState(operationRowsState);
  const setOperationHeaders = useSetRecoilState(operationHeadersState);

  const tableConfig = useRecoilValue(tableConfigState);

  const [currentTablePage, setCurrentTablePage] = useRecoilState(currentTablePageState);

  //!------------------------- Temporary Test -----------------------------------//
  {
    /* // TODO: ------- [For Dev Only] Remove brfore deploy-------- */
  }

  const onLogin_Admin = () => {
    // console.log('ADMIN');
    setUserProfile({ ...userProfile, team: 'ADMIN', access: 'ADMIN', role: 'ADMIN' });
  };

  const onLogin_Checker_COM_PUBLIC = () => {
    // console.log('CHECKER COM [PUBLIC]');
    setUserProfile({
      ...userProfile,
      team: 'COM_FUND',
      access: 'PUBLIC',
      role: 'CHECKER',
    });
  };
  const onLogin_Checker_OP_PUBLIC = () => {
    // console.log('CHECKER OP [PUBLIC]');
    setUserProfile({
      ...userProfile,
      team: 'OPERATION',
      access: 'PUBLIC',
      role: 'CHECKER',
    });
  };

  const onLogin_Maker_COM_PUBLIC = () => {
    // console.log('MAKER COM [PUBLIC]');
    setUserProfile({
      ...userProfile,
      team: 'COM_FUND',
      access: 'PUBLIC',
      role: 'MAKER',
    });
  };
  const onLogin_Maker_OP_PUBLIC = () => {
    // console.log('MAKER OP [PUBLIC]');
    setUserProfile({
      ...userProfile,
      team: 'OPERATION',
      access: 'PUBLIC',
      role: 'MAKER',
    });
  };

  const onLogin_Checker_COM_PRIVATE = () => {
    // console.log('CHECKER COM [PRIVATE]');
    setUserProfile({
      ...userProfile,
      team: 'COM_FUND',
      access: 'PRIVATE',
      role: 'CHECKER',
    });
  };
  const onLogin_Checker_OP_PRIVATE = () => {
    // console.log('CHECKER OP [PRIVATE]');
    setUserProfile({
      ...userProfile,
      team: 'OPERATION',
      access: 'PRIVATE',
      role: 'CHECKER',
    });
  };

  const onLogin_Maker_COM_PRIVATE = () => {
    // console.log('MAKER COM [PRIVATE]');
    setUserProfile({
      ...userProfile,
      team: 'COM_FUND',
      access: 'PRIVATE',
      role: 'MAKER',
    });
  };
  const onLogin_Maker_OP_PRIVATE = () => {
    // console.log('MAKER OP [PRIVATE]');
    setUserProfile({
      ...userProfile,
      team: 'OPERATION',
      access: 'PRIVATE',
      role: 'MAKER',
    });
  };
  const onLogin_Viewer = () => {
    // console.log('VIEWER');
    setUserProfile({ ...userProfile, team: 'VIEWER', access: 'VIEWER', role: 'VIEWER' });
  };

  const onLoginCheckStatus = () => {
    // console.log(userProfile);
    // console.log(isLoggedIn);
  };

  const setPortalStatus = useSetRecoilState(portalStatusState);
  const setTableOrderBy = useSetRecoilState(tableOrderByState);

  const setIsLoadedRows = useSetRecoilState(isLoadedMasterRowsState);

  const handleClick = (_setPortal: string) => async () => {
    setIsLoadedRows(false);
    setPortalStatus(_setPortal);
    setCurrentTablePage(0);

    if (_setPortal === 'company_profile') {
      setTableOrderBy('company_id');
      setCurrentSheetName('non');
      setInputJSON({});
      onMenuClick('invcompdefinitive', '1', tableConfig['defaultRowsLength'], '', '');

      //-----------------------------------------------------------//
    } else if (_setPortal === 'fund_profile') {
      setTableOrderBy('fund_id');
      setCurrentSheetName('non');
      setInputJSON({});
      onMenuClick('invfunddefinitive', '1', tableConfig['defaultRowsLength'], '', '');

    } else if (_setPortal === 'operation') {
      setTableOrderBy('id');
      setCurrentSheetName('invbankaccount');
      setCurrentOperationTable('invbankaccount');
      setInputJSON({});
      onMenuClick('invbankaccount', '1', tableConfig['defaultRowsLength'], '', '');
    }
  };

  return (
    <Drawer
      variant="permanent"
     className={LayoutStyles.SideBar}
    >
      <List disablePadding className={LayoutStyles.SideBar}>
        <ListItem className={LayoutStyles.SideBar}>
          <Grid
            xs={12}
            sm={12}
            md={12}
            direction="row"
            justifyContent="center"
            alignItems="center"
            container
            item
          >
            <Grid item>
              <BBLLogoWhite logoClassName={ElementStyles.LogoHeader} />
            </Grid>
          </Grid>
        </ListItem>

        <ListItem sx={{ ...SideBarHeader, ...itemCategory }}>
          <Grid
            xs={12}
            sm={12}
            md={12}
            direction="row"
            justifyContent="center"
            alignItems="center"
            container
            item
          >
            <Grid item>
              <ListItemText>Investment Portfolio</ListItemText>
            </Grid>
          </Grid>
        </ListItem>

        {/*//-------------------------------------------  */}

        {sideBarMenu.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: '#2140a6' }}>
            {/* <Divider sx={{ mt: 2 }} /> */}
            {/* <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: "#fff" }}>{id}</ListItemText>
            </ListItem> */}

            <ListItem sx={{ py: 1, px: 3 }}></ListItem>

            {/* -------------------company_profile-------------------- */}

            {isLoggedIn &&
              (userProfile.team === 'COM_FUND' ||
                userProfile.team === 'VIEWER' ||
                userProfile.team === 'ADMIN') && (
                <ListItem disablePadding key="feature_menu_01">
                  <ListItemButton
                    selected={false}
                    sx={item}
                    onClick={handleClick('company_profile')}
                  >
                    <ListItemIcon>
                      <AccountBalanceIcon />
                    </ListItemIcon>
                    <ListItemText>{'Company Profile'}</ListItemText>
                  </ListItemButton>
                </ListItem>
              )}
            {/* ------------------fund_profile--------------------- */}

            {isLoggedIn &&
              (userProfile.team === 'COM_FUND' ||
                userProfile.team === 'VIEWER' ||
                userProfile.team === 'ADMIN') && (
                <ListItem disablePadding key="feature_menu_02">
                  <ListItemButton
                    selected={false}
                    sx={item}
                    onClick={handleClick('fund_profile')}
                  >
                    <ListItemIcon>
                      <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText>{'Fund Profile'}</ListItemText>
                  </ListItemButton>
                </ListItem>
              )}

            {/* -------------------operation-------------------- */}

            {isLoggedIn &&
              (userProfile.team === 'OPERATION' ||
                userProfile.team === 'VIEWER' ||
                userProfile.team === 'ADMIN') && (
                <ListItem disablePadding key="feature_menu_03">
                  <ListItemButton
                    selected={false}
                    sx={item}
                    onClick={handleClick('operation')}
                  >
                    <ListItemIcon>
                      <SwapHorizIcon />
                    </ListItemIcon>
                    <ListItemText>{'Operation'}</ListItemText>
                  </ListItemButton>
                </ListItem>
              )}

            {/* ------------------ */}

            <Divider sx={{ mt: 2 }} />
            <br />

            {/* // TODO: ------- [For Dev Only] Remove brfore deploy (Start)-------- */}
            {/* <Grid>
              <ListItemText sx={{ color: '#ffffff', py: '2px', px: 3 }}>
                Access Control (For Dev. Only)
              </ListItemText>
              
              <ListItem disablePadding>
                <ListItemButton onClick={onLogin_Admin} sx={item}>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText sx={{ color: '#a74fff' }}>ADMIN</ListItemText>
                </ListItemButton>
              </ListItem>

             

              <ListItem disablePadding>
                <ListItemButton onClick={onLogin_Checker_COM_PUBLIC} sx={item}>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText sx={{ color: '#ff2d55' }}>
                    CHECKER - COM [PUBLIC]
                  </ListItemText>
                </ListItemButton>
              </ListItem>

            

              <ListItem disablePadding>
                <ListItemButton onClick={onLogin_Checker_OP_PUBLIC} sx={item}>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText sx={{ color: '#ff4f4f' }}>
                    CHECKER - OP [PUBLIC]
                  </ListItemText>
                </ListItemButton>
              </ListItem>

           

              <ListItem disablePadding>
                <ListItemButton onClick={onLogin_Maker_COM_PUBLIC} sx={item}>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText sx={{ color: '#00ff00' }}>
                    MAKER - COM [PUBLIC]
                  </ListItemText>
                </ListItemButton>
              </ListItem>

            

              <ListItem disablePadding>
                <ListItemButton onClick={onLogin_Maker_OP_PUBLIC} sx={item}>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText sx={{ color: '#80ff80' }}>
                    MAKER - OP [PUBLIC]
                  </ListItemText>
                </ListItemButton>
              </ListItem>

              

              <ListItem disablePadding>
                <ListItemButton onClick={onLogin_Checker_COM_PRIVATE} sx={item}>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText sx={{ color: '#ff00ff' }}>
                    CHECKER - COM [PRIVATE]
                  </ListItemText>
                </ListItemButton>
              </ListItem>

             

              <ListItem disablePadding>
                <ListItemButton onClick={onLogin_Checker_OP_PRIVATE} sx={item}>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText sx={{ color: '#ff80ff' }}>
                    CHECKER - OP [PRIVATE]
                  </ListItemText>
                </ListItemButton>
              </ListItem>

            

              <ListItem disablePadding>
                <ListItemButton onClick={onLogin_Maker_COM_PRIVATE} sx={item}>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText sx={{ color: '#10ebba' }}>
                    MAKER - COM [PRIVATE]
                  </ListItemText>
                </ListItemButton>
              </ListItem>

             

              <ListItem disablePadding>
                <ListItemButton onClick={onLogin_Maker_OP_PRIVATE} sx={item}>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText sx={{ color: '#7decd3' }}>
                    MAKER - OP [PRIVATE]
                  </ListItemText>
                </ListItemButton>
              </ListItem>

             

              <ListItem disablePadding>
                <ListItemButton onClick={onLogin_Viewer} sx={item}>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText sx={{ color: '#f5c00a' }}>VIEWER</ListItemText>
                </ListItemButton>
              </ListItem>

              
              <ListItem disablePadding>
                <ListItemButton onClick={onLoginCheckStatus} sx={item}>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText sx={{ color: '#ffffff' }}>Check Status</ListItemText>
                </ListItemButton>
              </ListItem>
            </Grid> */}

            {/* // TODO: ------- [For Dev Only] Remove brfore deploy (End) -------- */}
          </Box>
        ))}
      </List>
    </Drawer>
  );
}
