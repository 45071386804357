import { atom } from 'recoil';

//----- Login Modal ------------
export const isLogoutModal = atom({
  key: 'isLogoutModal',
  default: false,
});

export const isLoginModal = atom({
  key: 'isLoginModal',
  default: false,
});

//----- Create Modal ------------
export const isCreateModal = atom({
  key: 'isCreateModal',
  default: false,
});

//----- Success Modal ------------
export const isSummaryModal = atom({
  key: 'isSummaryModal',
  default: false,
});

//----- Confirmation Modal ------------
//Confirmation: Delete, Approve, Close, Submit,Edit,
export const isConfirmationModal = atom({
  key: 'isConfirmationModal',
  default: false,
});

//----- Success Modal ------------
export const isSuccessModal = atom({
  key: 'isSuccessModal',
  default: false,
});

//----- History Modal ------------
export const isHistoryModal = atom({
  key: 'isHistoryModal',
  default: false,
});

//----- Detail History Modal ------------
export const isDetailHistoryModal = atom({
  key: 'isDetailHistoryModal',
  default: false,
});

//----- Detail Modal ------------
export const isDetailModal = atom({
  key: 'isDetailModal',
  default: false,
});

//----- Main Detail Modal ------------
export const isMainDetailModal = atom({
  key: 'isMainSheetModal',
  default: false,
});

//----- YesNo Modal ------------
export const isYesNoModal = atom({
  key: 'isYesNoModal',
  default: false,
});


//----- Error Modal ------------
export const isErrorModal = atom({
  key: 'isErrorModal',
  default: false,
});