import axios from '../axios-instance';
import {
  API_SERVER_URL,
  API_DIVIDEND_URL,
  API_SUBMIT_URL,
  API_APPROVE_URL,
  API_CANCEL_URL,
  API_DETAIL_URL,
  API_HISTORY_URL,
} from '../../../config';

import { InvCompDividendSubmit, requestDividendMainInterface } from '@invport/models';

import {
  requestApproveInterface,
  requestCancelInterface,
  requestHistoryInterface,
  requestDetailInterface,
} from '@invport/models';

import {
  responseApproveInterface,
  responseCancelInterface,
  responseHistoryInterface,
  responseDetailInterface,
  responseSubmitInterface,
} from '@invport/models';



export const getDividend = async (
  requestMainBody: requestDividendMainInterface,
): Promise<requestDividendMainInterface> =>
  (
    await axios.post<requestDividendMainInterface>(
      `${API_SERVER_URL}/${API_DIVIDEND_URL}`,
      requestMainBody,
    )
  ).data;

//submit
export const submitDividend = async (
  requestSubmitBody: InvCompDividendSubmit,
): Promise<responseSubmitInterface> =>
  (
    await axios.post<responseSubmitInterface>(
      `${API_SERVER_URL}/${API_DIVIDEND_URL}/${API_SUBMIT_URL}`,
      requestSubmitBody,
    )
  ).data;

//approve
export const approveDividend = async (
  requestApprov: requestApproveInterface,
): Promise<responseApproveInterface> =>
  (
    await axios.post<responseApproveInterface>(
      `${API_SERVER_URL}/${API_DIVIDEND_URL}/${API_APPROVE_URL}`,
      requestApprov,
    )
  ).data;

//cancel
export const cancelDividend = async (
  requestCancel: requestCancelInterface,
): Promise<responseCancelInterface> =>
  (
    await axios.post<responseCancelInterface>(
      `${API_SERVER_URL}/${API_DIVIDEND_URL}/${API_CANCEL_URL}`,
      requestCancel,
    )
  ).data;

//detail
export const getDetailDividend = async (
  requestDetailBody: requestDetailInterface,
): Promise<responseDetailInterface> =>
  (
    await axios.post<responseDetailInterface>(
      `${API_SERVER_URL}/${API_DIVIDEND_URL}/${API_DETAIL_URL}`,
      requestDetailBody,
    )
  ).data;

//histroy
export const getHistoryDividend = async (
  requestHistoryBody: requestHistoryInterface,
): Promise<responseHistoryInterface> =>
  (
    await axios.post<responseHistoryInterface>(
      `${API_SERVER_URL}/${API_DIVIDEND_URL}/${API_HISTORY_URL}`,
      requestHistoryBody,
    )
  ).data;
