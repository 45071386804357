import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Grid from '@mui/material/Grid';
import FieldDisplay from './FieldDisplay';
import DetailHeader from './DetailHeader';


import {
  isGenerateCompleteState,
  isCallingAPIState,
  isUpdateInputJSONState,
} from '../../../../states/index';

import { groupBy } from '../function/groupBy';

export default function Sheetlnformation({
  headerType,
  dataJSON,
  inputJSON,
  isPending,
}: {
  headerType: string;
  dataJSON: object;
  inputJSON: object;
  isPending: boolean;
}) {
  // console.log(inputJSON);

  const currentData: { [key: string]: any } = dataJSON;
  const inputData: { [key: string]: any } = inputJSON;

  let inputData_ByApiName = groupBy(inputData['inputInfo'], 'apiName');

  //for check fields count
  // console.log(Object.keys(dataJSON));
  // console.log(Object.keys(inputData_ByApiName));

  const setIsUpdateInputJSON = useSetRecoilState(isUpdateInputJSONState);

  const setGenerateComplete = useSetRecoilState(isGenerateCompleteState);

  const isCallingAPI = useRecoilValue(isCallingAPIState);

  if (isCallingAPI === false) {
    if (Object.keys(dataJSON).length === 0 || Object.keys(inputJSON).length === 0) {
      return <div></div>;
    } else {
      // console.log(inputData_ByApiName);

      return (
        <>
          <DetailHeader
            typoHeader="Data"
            headerType={headerType}
            isPending={isPending}
          />
          {/* -------- Row 1 ---------- */}

          {Object.keys(Object.values(dataJSON)).map((index: string) => {
            let apiName: { [key: string]: any } = Object.keys(dataJSON);
            let fieldLabel = inputData_ByApiName[apiName[index]][0]['displayName'];
            let fieldData = currentData[apiName[index]];
            let displayValue = inputData_ByApiName[apiName[index]][0]['displayValue'];
            let inputType = inputData_ByApiName[apiName[index]][0]['inputType'];

            const last = Object.keys(Object.values(currentData)).length - 1;
            if (index === last.toString()) {
              setGenerateComplete(true);
              setIsUpdateInputJSON(false);
            }

       

            // console.log(index + ':'+apiName[index]+' : ' + fieldLabel + ' : ' + fieldData+' : ' +inputType);
            // console.log(index + ' : ' + fieldData);

            return (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                container
                key={fieldLabel}
              >
                <FieldDisplay
                  key={fieldLabel}
                  fieldLabel={fieldLabel}
                  fieldData={fieldData}
                  inputType={inputType}
                  displayValueArr={displayValue}
                />
              </Grid>
            );
          })}
        </>
      );
    }
  } else {
    return <div></div>;
  }
}
