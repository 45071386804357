import {
  AssoSubFlag,
  Broker,
  Currency,
  FixedThbRate,
  Fund,
  HedgeCurrency,
  Holding,
  Ifrs9,
  Nationality,
  Ownership,
  Port,
  Product,
  Redeemable,
  Sector,
  Tdr,
  Yn,
  CashflowDirection,
  CompStage,
  Consolidation,
  PaymentMethod,
  Evaluation,
  MarketableSecurity,
  WarrantStatus,
  Contact,
  Commitment,
  Asset,
  HarvestingFactor,
  DistributionMethod,
  FundStatus,
  Alternative,
  ParAdjustmentMethod,
  CompTransaction,
  FundTransaction,
  DividendType,
} from '@invport/models';

//Boolean
export const arrYnValue = [true, false];

//Common
export const arrAssoSubFlag = Object.values(AssoSubFlag);
export const arrBroker = Object.values(Broker);
export const arrCurrency = Object.values(Currency);
export const arrFixedThbRate = Object.values(FixedThbRate);
export const arrFund = Object.values(Fund);
export const arrHedgeCurrency = Object.values(HedgeCurrency);
export const arrHolding = Object.values(Holding);
export const arrIfrs9 = Object.values(Ifrs9);
export const arrNationality = Object.values(Nationality);
export const arrOwnership = Object.values(Ownership);
export const arrPort = Object.values(Port);
export const arrProduct = Object.values(Product);
export const arrRedeemable = Object.values(Redeemable);
export const arrSector = Object.values(Sector);
export const arrTdr = Object.values(Tdr);
export const arrYn = Object.values(Yn);

//InvBankCapitalInterface
export const arrStatus = ['BEFORE', 'AFTER'];

//InvCompAdjustEquityInterface
export const arrIncome = ['PROFIT', 'LOSS'];

//InvCompAdjustParInterface
export const arrParAdjustmentMethod = Object.values(ParAdjustmentMethod);

//InvCompCashFlowInterface
export const arrCompTransaction = Object.values(CompTransaction);
export const arrCashflowDirection = Object.values(CashflowDirection);

//InvCompDefinitiveInterface
export const arrCompStage = Object.values(CompStage);
export const arrConsolidation = Object.values(Consolidation);

//InvCompFsInterface
export const arrValuePeriod = ['QUARTER', 'ANNUAL'];

//InvCompDividendInterface
export const arrPaymentMethod = Object.values(PaymentMethod);
export const arrDividendType = Object.values(DividendType);

//InvCompProductInterface
export const arrEvaluation = Object.values(Evaluation);
export const arrMarketableSecurity = Object.values(MarketableSecurity);

//InvCompWarrantInterface
export const arrWarrantStatus = Object.values(WarrantStatus);

//InvContactInterface
export const arrContact = Object.values(Contact);
export const arrActive = ['ACTIVE', 'INACTIVE'];

//InvFundCashflowInterface
export const arrFundTransaction = Object.values(FundTransaction);
export const arrCashflowType = ['INFLOW', 'OUTFLOW'];

//InvFundCommitmentWatermarkInterface
export const arrCommitment = Object.values(Commitment);

//InvFundDefinitiveInterface
export const arrAsset = Object.values(Asset);
export const arrHarvestingFactor = Object.values(HarvestingFactor);
export const arrDistributionMethod = Object.values(DistributionMethod);
export const arrFundStatus = Object.values(FundStatus);
export const arrAlternative = Object.values(Alternative);
export const arrFundRiskLevel = ['1', '2', '3', '4', '5', '6', '7', '8'];
export const arrCurrencyRiskLevel = ['1', '2', '3', '4', '5', '6'];
