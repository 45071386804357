import { atom } from 'recoil';
import { arrParAdjustmentMethod, arrCurrency, arrHolding, arrPort } from '../common';

const _sheetName = 'invcompadjustpar';

//?------- Parameter Design ---------------
/*

------- Label / ID ------------
displayName = variable for display name in label.
sourceSystemName = variable for bank system name.
apiName = variable for call api. 

-------- Input ----------------
description = input placeholder.
inputLength = custom length limit of character.
inputType = (checkbox | date | radio | select | textfield) type of input.
inputClass = Custom format masking or validation.
possibleValue = (array) Value for dropdownlist / checkbox / radio.

-------- Boolean --------------
isRequire = (true/false) require field. can't be null.

isLinkValue = (true/false) Link the value from other input (linkValueId)
linkValueId = ID of input to Link data (display when isLinkValue == true).

isAutoGenerate = (true/false) auto generate value.
generateSource = source of auto generate (display when isAutoGenerate == true).

*/
/**
 textfield
 - NoLimitFree
 - NoLimitCountNumber
 - NoLimitFloat

 date
 - General

select
 - General

 */

const jsonData: { [key: string]: any } = {
  sheetName: _sheetName,
  inputInfo: [
    // ? --------------------------------- Start ---------------------------------------//
    // ! ---------------------------- 000.1 ---------------------------------//
    {
      displayName: 'Product id',
      sourceSystemName: 'PRODUCT_ID',
      apiName: 'product_id',

      description: 'Product id',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ! ---------------------------- 000.2 ---------------------------------//
    {
      displayName: 'Fs id',
      sourceSystemName: 'FS_ID',
      apiName: 'fs_id',

      description: 'Fs id',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 001 ---------------------------------//
    {
      displayName: 'BI_ID',
      sourceSystemName: 'BI_ID',
      apiName: 'bi_id',

      description: 'Business ID from Investment',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 002 ---------------------------------//
    {
      displayName: 'RM ID',
      sourceSystemName: 'RM_ID',
      apiName: 'rm_id',

      description: 'ID generated from bank',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 003 ---------------------------------//
    {
      displayName: 'Company ID',
      sourceSystemName: 'COMPANY_ID',
      apiName: 'company_id',

      description: 'Company ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 004 ---------------------------------//
    {
      displayName: 'Company Name',
      sourceSystemName: 'COMPANY_NAME',
      apiName: 'company_name',

      description: 'Full legal name of the company',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 005 ---------------------------------//
    {
      displayName: 'Product Name',
      sourceSystemName: 'PRODUCT_NAME',
      apiName: 'product_name',

      description: 'Company name classified by type of products',
      inputLength: '',
      inputType: 'select',
      inputClass: 'Autocomplete',
      possibleValue: ['/product/list'],
      displayValue: ['/product/list'],

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: true,
    },

    // ---------------------------- 006 ---------------------------------//
    {
      displayName: 'Transaction ID',
      sourceSystemName: 'TXN_ID',
      apiName: 'txn_id',

      description: 'Transaction ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromServer',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 007 ---------------------------------//
    {
      displayName: 'Transaction Date',
      sourceSystemName: 'TXN_DATE',
      apiName: 'txn_date',

      description: 'Transaction Date',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 008 ---------------------------------//
    {
      displayName: 'Remark',
      sourceSystemName: 'REMARK',
      apiName: 'remark',

      description: 'Remark (If any)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 009 ---------------------------------//
    {
      displayName: 'Investment Instrument AC',
      sourceSystemName: 'INVESTMENT_INSTRUMENT',
      apiName: 'investment_instrument',

      description: 'Type of securities BBL hold in the company',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrHolding,
      displayValue: arrHolding,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 010 ---------------------------------//
    {
      displayName: 'Portfolio Classification AC',
      sourceSystemName: 'INVESTMENT_PORT',
      apiName: 'investment_port',

      description: 'BBL internal accounting record for accounting purpose',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: arrPort,
      displayValue: arrPort,

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 011 ---------------------------------//
    {
      displayName: 'Currency AC',
      sourceSystemName: 'CURRENCY',
      apiName: 'currency',

      description: 'Denominated Currency',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: arrCurrency,

      displayValue: arrCurrency,

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 012 ---------------------------------//
    {
      displayName: 'Total No. of Share (Pre Transaction)',
      sourceSystemName: 'NO_OF_SHARE_PRETRANSACTION',
      apiName: 'no_of_share_pretransaction',

      description: 'Total number of share - Pre Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 013 ---------------------------------//
    {
      displayName: 'Investment Cost (Orginal CCY) (Pre Transaction) AC',
      sourceSystemName: 'INVESTMENT_COST_PRETRANSACTION',
      apiName: 'investment_cost_pretransaction',

      description: 'Investment Cost (Orginal CCY) - Pre Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 014 ---------------------------------//
    {
      displayName: 'Investment Cost (THB) (Pre Transaction) AC',
      sourceSystemName: 'INVESTMENT_COST_PRETRANSACTION_THB',
      apiName: 'investment_cost_pretransaction_thb',

      description: 'Investment Cost (THB) - Pre Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 015 ---------------------------------//
    {
      displayName: 'Par (Pre Transaction)',
      sourceSystemName: 'PAR_VALUE_PRETRANSACTION',
      apiName: 'par_value_pretransaction',

      description: 'Par (Pre Transaction)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 016 ---------------------------------//
    {
      displayName: '% Hold (Pre Transaction)',
      sourceSystemName: 'CURRENT_STAKE_PRETRANSACTION',
      apiName: 'current_stake_pretransaction',

      description: '% holding in the firm - Pre transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 017 ---------------------------------//
    {
      displayName: 'Adjusted Par Method AC',
      sourceSystemName: 'ADJUSTED_PAR_METHOD',
      apiName: 'adjusted_par_method',

      description: 'Adjusted Par Method',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrParAdjustmentMethod,

      displayValue: arrParAdjustmentMethod,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    //! ---------------------------- 017_1 ---------------------------------//
    {
      displayName: 'Paid Up Capital - No. of Shares (Pre Transaction) AC',
      sourceSystemName: 'PAID_UP_CAPITAL_NO_OF_SHARE_PRETRANSACTION',
      apiName: 'paid_up_capital_no_of_share_pretransaction',

      description: 'Paid Up Capital - No. of Shares (Pre Transaction) ',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',

      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 018 ---------------------------------//
    {
      displayName: 'Total No. of Share (Post Transaction)',
      sourceSystemName: 'NO_OF_SHARE_POSTTRANSACTION',
      apiName: 'no_of_share_posttransaction',

      description: 'Total number of share - Post Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 019 ---------------------------------//
    {
      displayName: 'Investment Cost (Orginal CCY) (Post Transaction) AC',
      sourceSystemName: 'INVESTMENT_COST',
      apiName: 'investment_cost',

      description: 'Investment Cost (Orginal CCY)  - Post Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 020 ---------------------------------//
    {
      displayName: 'Investment Cost (THB) (Post Transaction) AC',
      sourceSystemName: 'INVESTMENT_COST_THB',
      apiName: 'investment_cost_thb',

      description: 'Investment Cost (THB)  - Post Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 021 ---------------------------------//
    {
      displayName: 'Par (Post Transaction) ',
      sourceSystemName: 'PAR_VALUE_POSTTRANSACTION',
      apiName: 'par_value_posttransaction',

      description: 'Par - Post Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 022 ---------------------------------//
    {
      displayName: '% Hold (Post Transaction)',
      sourceSystemName: 'CURRENT_STAKE_POSTTRANSACTION',
      apiName: 'current_stake_posttransaction',

      description: '% holding in the firm - Post Transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'product_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // !---------------------------- 023 ---------------------------------//
    {
      displayName: 'Cashflow (Orginal CCY) (Post Transaction) AC',
      sourceSystemName: 'CASHFLOW_AMOUNT_POSTTRANSACTION',
      apiName: 'cashflow_amount_posttransaction',

      description: 'Cashflow (Orginal CCY) - Post Transaction ',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // !---------------------------- 024 ---------------------------------//
    {
      displayName: 'Cashflow (THB) (Post Transaction) AC',
      sourceSystemName: 'CASHFLOW_AMOUNT_POSTTRANSACTION_THB',
      apiName: 'cashflow_amount_posttransaction_thb',

      description: 'Cashflow (THB) - Post Transaction for Decrease Par Value',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // !---------------------------- 024 ---------------------------------//
    {
      displayName: 'Paid Up Capital - No. of Shares (Post Transaction) AC',
      sourceSystemName: 'PAID_UP_CAPITAL_NO_OF_SHARE_POSTTRANSACTION',
      apiName: 'paid_up_capital_no_of_share_posttransaction',

      description:
        'Paid Up Capital - No. of Shares (Post Transaction) for Decrease Number of Registered Shares',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ? --------------------------------- End ---------------------------------------//
  ],
};

export const invcompadjustpar = atom({
  key: 'invcompadjustpar',
  default: jsonData,
});
