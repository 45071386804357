//Button
import Button from '@mui/material/Button';

export default function RenderNextButton({
  _page,
  _isLoadedAutogen,
  onNext,
  onSubmitToAPI,
  onSubmitForm,
}: {
  _page: number;
  _isLoadedAutogen: boolean;
  onNext: () => void;
  onSubmitToAPI: () => void;
  onSubmitForm: any;
}) {
  switch (_page) {
    case 1:
      return (
        <Button
          color="primary"
          variant="contained"
          onClick={onNext}
          disabled={!_isLoadedAutogen}
        >
          Next
        </Button>
      );

    case 2:
      return (
        <Button
          color="primary"
          variant="contained"
          type="submit"
          onClick={onNext}
          disabled={!_isLoadedAutogen}
        >
          Next
        </Button>
      );

    case 3:
      return (
        <Button color="secondary" variant="contained" onClick={onSubmitToAPI}>
          Submit
        </Button>
      );

    default:
      return <p>Not Found Button</p>;
  }
}
