import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

//Icon

import CloseIcon from '@mui/icons-material/Close';

//Chips

import LayoutStyles from '../../../../styles/Layout.module.css';

//Custom elelment
import ApprovalInformation from '../detail/ApprovalInformation';
import Sheetlnformation from '../detail/Sheetlnformation';

//State
import {
  isDetailHistoryModal,
  currentHistoryDataState,
  currentIdState,
  currentSheetNameState,
  sheetNameState,
  portalStatusState,
} from '../../../../states/index';

import { groupBy } from '../function/groupBy';
import { approvalName } from '../function/approvalName';
import { checkIsPending } from '../function/checkIsPending';

export default function DetailHistoryModal({
  caseName,
  sheetName,
  objectName,
  subObjectName,
  inputJSON,
  dataJSON,
}: {
  caseName: string;
  sheetName: string;
  objectName: string;
  subObjectName: string;
  inputJSON: object;
  dataJSON: object;
}) {
  const [openDetailHistoryModal, setOpenDetailHistoryModal] =
    useRecoilState(isDetailHistoryModal);
  const currentHistoryData: { [key: string]: any } = useRecoilValue(
    currentHistoryDataState,
  );

  const currentId: { [key: string]: any } = useRecoilValue(currentIdState);
  const currentSheetName = useRecoilValue(currentSheetNameState);
  const sheetNameList = useRecoilValue(sheetNameState);
  const portalStatus = useRecoilValue(portalStatusState);

  let sheetNameList_byName: { [key: string]: any } = {};
  let displayName01 = '';
  let displayName02 = '';

  if (currentSheetName === 'non') {
    displayName01 = 'non';
  } else {
    if (Object.keys(currentHistoryData).length === 0) {
      displayName01 = 'non';
    } else {
      sheetNameList_byName = groupBy(
        sheetNameList[portalStatus]['subsheet'],
        'systemName',
      );
      displayName01 =
        currentId['main_id_type'] +
        ' : ' +
        currentId['main_id_value'] +
        ' / ' +
        sheetNameList_byName[currentSheetName][0]['displayName'] +
        ' History' +
        ' / ';

      displayName02 = currentHistoryData['edit_date'];
    }
  }

  let isPending = false;

  if (openDetailHistoryModal === true) {
    if (checkIsPending(sheetName, currentHistoryData) === 'PENDING') {
      isPending = true;
    } else {
      isPending = false;
    }
  }

  const handleClose = () => setOpenDetailHistoryModal(false);

  return (
    <div>
      <Modal open={openDetailHistoryModal} className={LayoutStyles.ModalBackgroundFade}>
        <Box className={LayoutStyles.ModalL_Box}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            container
            className={LayoutStyles.ModalPaper}
          >
            {/* ---- */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              container
              className={LayoutStyles.ModalL_Header}
              alignItems="center"
            >
              <Grid
                item
                container
                xs={10}
                sm={10}
                md={10}
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Typography color="primary" variant="h6" gutterBottom component="div">
                    {objectName}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Grid item>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <Grid xs={12} sm={12} md={12} container item>
                <Typography
                  color="primary"
                  variant="subtitle2"
                  gutterBottom
                  component="div"
                >
                  {displayName01}
                  <b>{displayName02}</b>
                </Typography>
              </Grid>

              {/* ---- */}
            </Grid>

            <Grid item xs={12} sm={12} md={12} className={LayoutStyles.ModalL_Body}>
              {/* -----------------------------------------------------------------------------*/}

              <ApprovalInformation
                approvalJSON={currentHistoryData}
                isHistory={true}
                approvalName={approvalName(sheetName)}
                isPending={isPending}
              />
              <br />
              <Sheetlnformation
                dataJSON={currentHistoryData['sheet_data']}
                inputJSON={inputJSON}
                headerType="general"
                isPending={isPending}
              />

              {/* --------------------------------------------------------------------------------------------------*/}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              container
              className={LayoutStyles.ModalL_Footer}
            ></Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
