import { Currency, Holding, Port } from "./inv-enums";

export enum IncomeType {
	Profit = 'PROFIT',
	Loss   = 'LOSS',
}

interface InvCompAdjustEquityBaseInterface {
	infra_group_code: string,   // --- Autogen from MASTERSHEET (Intra Group)
	txn_id          : string,   // --- Autogen
	reporting_date  : string,   // date string
	txn_date        : string,   // date string
	remark          : string,

	// Accounting Purposes
	no_of_share                    : number,       // integer
	allowance_permanent_thb        : number,       // --- Autogen FORMULAR -> = Investment Cost (Orginal CCY) AC - Alowance Impairment (Orginal CCY) AC + Allowance Permanent (Orginal CCY) AC
	adjusted_equity_pretransaction : number,       // --- Autogen FORMULAR -> ดึง Adjusted Equity Amount AC (THB) (Post Transaction) AC (การ record ก่อนหน้า)
	income                         : IncomeType,
	total_adjusted_equity          : number,
	adjusted_equity_posttransaction: number,       // --- Autogen FORMULAR
	adjusted_cost                  : number,       // --- Autogen FORMULAR
}

export type InvCompAdjustEquity = InvCompAdjustEquityBaseInterface & {
	bi_id       : string,   // --- Autogen from INVCOMPDEFINITIVE
	rm_id       : string,   // --- Autogen from INVCOMPDEFINITIVE
	company_id  : string,   // --- Autogen from INVCOMPDEFINITIVE
	company_name: string,   // --- Autogen from INVCOMPPRODUCT
	product_name: string,   // --- DROPDOWN from INVCOMPPRODUCT

	// Accounting Purposes
	investment_instrument             : Holding,    // --- Autogen from INVCOMPPRODUCT
	investment_port                   : Port,       // --- Autogen from INVCOMPPRODUCT
	currency                          : Currency,   // --- Autogen from INVCOMPPRODUCT
	investment_cost_pretransaction    : number,     // --- Autogen from invcompproduct (Pre Transaction end of month)
	investment_cost_pretransaction_thb: number,     // --- Autogen from invcompproduct (Pre Transaction end of month)
	allowance_impairment              : number,     // --- Autogen from invcompproduct (Pre Transaction end of month)
	allowance_permanent               : number,     // --- Autogen from invcompproduct (Pre Transaction end of month)
};
export type InvCompAdjustEquitySubmit = InvCompAdjustEquityBaseInterface & {
	company_id: string,   // InvCompDefinitive.company_id > bi_id, rm_id
	// InvCompProduct.product_id > 
	//     company_name,
	//     product_name,
	//     investment_instrument,
	//     investment_port,
	//     currency,
	//     investment_cost_pretransaction (book_value, with additional calculations),
	//     investment_cost_pretransaction_thb (investment_cost_thb, with additional calculations),
	//     allowance_impairment (allowance_impair_ccy, with additional calculations),
	//     allowance_permanent (allowance_permanent, with additional calculations)
	product_id: string,

	//sheet id Ref
	sheet_id: string,
};


export type InvCompAdjustEquityAll = InvCompAdjustEquity & InvCompAdjustEquitySubmit;