import { Currency, Holding, Port } from "./inv-enums";

export enum ParAdjustmentMethod {
	DecreaseAccuLoss                                 = 'DECREASE_ACCU_LOSS',
	AdjustParValue                                   = 'ADJUST_PAR_VALUE',
	DecreaseParValueAndReturnCash                    = 'DECREASE_PAR_VALUE_AND_RETURN_CASH',
	DecreaseNumberOfRegisteredShares                 = 'DECREASE_NUMBER_OF_REGISTERED_SHARES',
	DecreaseNumberOfRegisteredSharesNonProfitNonLoss = 'DECREASE_NUMBER_OF_REGISTERED_SHARES_NON_PROFIT_NON_LOSS',
}

interface InvCompAdjustParBaseInterface {
	txn_id  : string,   // --- Autogen
	txn_date: string,   // date string
	remark  : string,
	
	// Accounting Purposes
	investment_instrument                      : Holding,
	no_of_share_pretransaction                 : number,                // --- Autogen FORMULAR
	investment_cost_pretransaction             : number,                // --- Autogen FORMULAR
	investment_cost_pretransaction_thb         : number,                // --- Autogen FORMULAR
	par_value_pretransaction                   : number,                // --- Autogen FORMULAR
	current_stake_pretransaction               : number,                // --- Autogen FORMULAR
	adjusted_par_method                        : ParAdjustmentMethod,
	no_of_share_posttransaction                : number,                // --- Autogen FORMULAR
	investment_cost_thb                        : number,                // --- Autogen FORMULAR
	par_value_posttransaction                  : number,
	cashflow_amount_posttransaction            : number,
	cashflow_amount_posttransaction_thb        : number,                // --- Autogen FORMULAR and linked to INVCOMPPRODUCT (?)
	paid_up_capital_no_of_share_posttransaction: number,
}

export type InvCompAdjustPar = InvCompAdjustParBaseInterface & {
	bi_id       : string,   // --- Autogen from INVCOMPDEFINITIVE
	rm_id       : string,   // --- Autogen from INVCOMPDEFINITIVE
	company_id  : string,   // --- Autogen from INVCOMPDEFINITIVE
	company_name: string,   // --- Autogen from INVCOMPPRODUCT
	product_name: string,   // --- DROPDOWN from INVCOMPPRODUCT

	// Accounting Purposes
	investment_port                           : Port,       // --- Autogen from INVCOMPPRODUCT
	currency                                  : Currency,   // --- Autogen from INVCOMPPRODUCT
	paid_up_capital_no_of_share_pretransaction: number,     // --- Autogen from INVCOMPFS
	investment_cost                           : number,     // --- Autogen FORMULAR and linked to INVCOMPPRODUCT
	current_stake_posttransaction             : number,     // --- Autogen FORMULAR and linked to INVCOMPPRODUCT
	// cashflow_amount_posttransaction_thb    : number,     // --- Autogen FORMULAR and linked to INVCOMPPRODUCT (?)
};
export type InvCompAdjustParSubmit = InvCompAdjustParBaseInterface & {
	company_id: string,   // InvCompDefinitive.company_id > bi_id, rm_id
	// InvCompProduct.product_id > 
	//     company_name,
	//     product_name,
	//     investment_port,
	//     currency,
	//     investment_cost (book_value, with additional calculations),
	//     current_stake_posttransaction (current_stake, with additional calculations),
	//     cashflow_amount_posttransaction_thb (?)
	product_id: string,
	fs_id     : string,   // InvCompFs.fs_id > paid_up_capital_no_of_share_pretransaction (paid_up_capital_no_of_share)

	//sheet id Ref
	sheet_id: string,
};

export type InvCompAdjustParAll = InvCompAdjustPar & InvCompAdjustParSubmit;