import { atom } from 'recoil';
import {
  arrBroker,
  arrCashflowType,
  arrCurrency,
  arrFundTransaction,
  arrYn,
  arrYnValue,
} from '../common';

const _sheetName = 'invfundcashflow';

//?------- Parameter Design ---------------
/*

------- Label / ID ------------
displayName = variable for display name in label.
sourceSystemName = variable for bank system name.
apiName = variable for call api. 

-------- Input ----------------
description = input placeholder.
inputLength = custom length limit of character.
inputType = (checkbox | date | radio | select | textfield) type of input.
inputClass = Custom format masking or validation.
possibleValue = (array) Value for dropdownlist / checkbox / radio.

-------- Boolean --------------
isRequire = (true/false) require field. can't be null.

isLinkValue = (true/false) Link the value from other input (linkValueId)
linkValueId = ID of input to Link data (display when isLinkValue == true).

isAutoGenerate = (true/false) auto generate value.
generateSource = source of auto generate (display when isAutoGenerate == true).

*/
/**
 textfield
 - NoLimitFree
 - noLimitCountNumber

 date
 - General

select
 - General

 */

const jsonData: { [key: string]: any } = {
  sheetName: _sheetName,
  inputInfo: [
    // ? --------------------------------- Start ---------------------------------------//
    // ---------------------------- 001 ---------------------------------//
    {
      displayName: 'Fund ID',
      sourceSystemName: 'FUND_ID',
      apiName: 'fund_id',

      description: 'Fund ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'fund_quote',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 002 ---------------------------------//
    {
      displayName: 'Fund Quote',
      sourceSystemName: 'FUND_QUOTE',
      apiName: 'fund_quote',

      description: 'Short abbreviation name (internally defined)',
      inputLength: '',
      inputType: 'select',
      inputClass: 'Autocomplete',
      possibleValue: ['/fund/list'],
      displayValue: ['/fund/list'],

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: true,
    },

    // ---------------------------- 003 ---------------------------------//
    {
      displayName: 'Date',
      sourceSystemName: 'TXN_DATE',
      apiName: 'txn_date',

      description: 'Date of transaction',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 004 ---------------------------------//
    {
      displayName: 'Transaction ID',
      sourceSystemName: 'TXN_ID',
      apiName: 'txn_id',

      description: 'Transaction ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 005 ---------------------------------//
    {
      displayName: 'Transaction Detail',
      sourceSystemName: 'TXN_TYPE',
      apiName: 'txn_type',

      description:
        'Use of money or source of distribution, for example, management fee, investment, dividend from ....',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrFundTransaction,
      displayValue: arrFundTransaction,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 006 ---------------------------------//
    {
      displayName: 'Recyclable',
      sourceSystemName: 'RECYCLABLE_FLAG',
      apiName: 'recyclable_flag',

      description:
        'Flag Y or N, this is applicable only to cash inflow. The flag would indicate whether the fund could later recall that amount without reduction in our remaining commitment',
      inputLength: '',
      inputType: 'radio',
      inputClass: 'General',
      possibleValue: arrYnValue,
      displayValue: arrYn,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 007 ---------------------------------//
    // {
    //   displayName: 'Total Partnership Paid',
    //   sourceSystemName: 'TOTAL_PARTNERSHIP_PAID_AMOUNT',
    //   apiName: 'total_partnership_paid_amount',

    //   description: 'Amount contributed / returned to all parties',
    //   inputLength: '',
    //   inputType: 'textfield',
    //   inputClass: 'NoLimitFloat',
    //   possibleValue: '',
    //   displayValue: '',

    //   isRequire: false,

    //   isLinkValue: false,
    //   linkValueId: '',

    //   isAutoGenerate: false,
    //   isActive: true,
    // },

    // ---------------------------- 008 ---------------------------------//
    {
      displayName: 'Cashflow Direction AC',
      sourceSystemName: 'CASHFLOW_TYPE',
      apiName: 'cashflow_type',

      description: 'Can be either inflow or outflow',
      inputLength: '',
      inputType: 'radio',
      inputClass: 'General',
      possibleValue: arrCashflowType,
      displayValue: arrCashflowType,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 009 ---------------------------------//
    {
      displayName: 'Currency AC',
      sourceSystemName: 'CURRENCY',
      apiName: 'currency',

      description: 'Denominated Currency',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: arrCurrency,
      displayValue: arrCurrency,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 010 ---------------------------------//
    {
      displayName: 'Cashflow AC',
      sourceSystemName: 'CASHFLOW_AMOUNT',
      apiName: 'cashflow_amount',

      description: 'Amount of money transferred',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 011 ---------------------------------//
    {
      displayName: 'Total Partnership Paid',
      sourceSystemName: 'TOTAL_PARTNERSHIP_PAID_AMOUNT',
      apiName: 'total_partnership_paid_amount',

      description: 'Amount contributed / returned to all parties',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 012 ---------------------------------//
    {
      displayName: '% of BBL Contribution',
      sourceSystemName: 'CURRENT_STAKE',
      apiName: 'current_stake',

      description: '% of BBL contribution in each transaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 013 ---------------------------------//
    {
      displayName: 'Unit Price AC',
      sourceSystemName: 'UNIT_PRICE',
      apiName: 'unit_price',

      description: 'Price of each unit of the assets',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 014 ---------------------------------//
    {
      displayName: 'No. of Unit AC',
      sourceSystemName: 'UNIT_NO',
      apiName: 'unit_no',

      description: 'No. of units bought/sold',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 015 ---------------------------------//
    {
      displayName: 'Broker Name AC',
      sourceSystemName: 'BROKERAGE_NAME',
      apiName: 'brokerage_name',

      description: 'Name of Brokers used to execute the trasaction',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: arrBroker,
      displayValue: arrBroker,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 016 ---------------------------------//
    {
      displayName: 'Commission AC',
      sourceSystemName: 'COMMISSION',
      apiName: 'commission',

      description: 'Commission',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 017 ---------------------------------//
    {
      displayName: 'VAT & Levey Fee AC',
      sourceSystemName: 'VAT_LEVEY_FEE',
      apiName: 'vat_levey_fee',

      description: 'VAT & Levey Fee',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 018 ---------------------------------//
    {
      displayName: 'Commission (Include VAT) AC',
      sourceSystemName: 'COMMISSION_VAT',
      apiName: 'commission_vat',

      description: 'Commission (Include VAT)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 019 ---------------------------------//
    {
      displayName: 'Withholding Tax AC',
      sourceSystemName: 'WITHHOLDING_TAX',
      apiName: 'withholding_tax',

      description: 'Withholding Tax',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 020 ---------------------------------//
    {
      displayName: 'Custodian Fee AC',
      sourceSystemName: 'CUSTODIAN_FEE',
      apiName: 'custodian_fee',

      description: 'Custodian Fee',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 021 ---------------------------------//
    {
      displayName: 'Miscellaneous Expenses AC',
      sourceSystemName: 'MISCELLANEOUS_EXP',
      apiName: 'miscellaneous_exp',

      description: 'Miscellaneous Expenses',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 022 ---------------------------------//
    {
      displayName: 'Fund Transafer Fee AC',
      sourceSystemName: 'FUND_TRANSAFER_FEE',
      apiName: 'fund_transfer_fee',

      description: 'Fund Transafer Fee',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 023 ---------------------------------//
    {
      displayName: 'Underwriting Fee AC',
      sourceSystemName: 'UNDERWRITING_FEE',
      apiName: 'underwriting_fee',

      description: 'Underwriting Fee',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ? --------------------------------- End ---------------------------------------//
  ],
};

export const invfundcashflow = atom({
  key: 'invfundcashflow',
  default: jsonData,
});
