//MockUp
export const API_SERVER_URL =
  process.env.REACT_APP_API_SERVER_URL ?? 'http://localhost:4010';



//Sub Sheet (Common)
export const API_DIRECTOR_URL = `director`;
export const API_CERTIFICATE_URL = `certificate`;
export const API_CONTACT_URL = `contact`;
export const API_MEMO_URL = `memo`;

//Sub Sheet (Company)
export const API_COMPANY_URL = `company`;
export const API_PRODUCT_URL = `product`;
export const API_FS_URL = `fs`;

//Sub Sheet (Fund)
export const API_FUND_URL = `fund`;
export const API_NAV_URL = `nav`;
export const API_PORT_URL = `port`;
export const API_BENCHMARK_URL = `benchmark`;
export const API_COMMITMENT_URL = `commitment`;

//Sub Sheet (Operation)
export const API_ACCOUNT_URL = `account`;
export const API_CAPITAL_URL = `capital`;
export const API_ADJEQUITY_URL = `adjEquity`;
export const API_ADJPAR_URL = `adjPar`;
export const API_BOT_URL = `bot`;
export const API_COMPCASHFLOW_URL = `compCashFlow`;
export const API_DIVIDEND_URL = `dividend`;
export const API_COMPFAIR_URL = `compFair`;
export const API_WARRANT_URL = `warrant`;
export const API_FUNDCASHFLOW_URL = `fundCashFlow`;

//Action
export const API_SUBMIT_URL = `submit`;
export const API_APPROVE_URL = `approve`;
export const API_CANCEL_URL = `cancel`;
export const API_DETAIL_URL = `detail`;
export const API_HISTORY_URL = `history`;
