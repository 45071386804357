import * as React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import { visuallyHidden } from '@mui/utils';
import Grid from '@mui/material/Grid';

import LayoutStyles from '../../../../styles/Layout.module.css';

import StarIcon from '@mui/icons-material/Star';

import ButtonDetail from '../button/ButtonDetail';

import ApprovalChip from '../element/ApprovalChip';

import {
  PreviouslyTableBodyData,
  PreviouslyTableHeadData,
  SubProfileTableBodyData,
  SubProfileTableHeadData,
  getComparator,
  Order,
} from '../../../../models/index';

//Current Data
import {
  currentInvBoardApprovedMemoState,
  currentInvCompDefinitiveState,
  currentInvCompFsState,
  currentInvCompProductState,
  currentInvContactState,
  currentInvFundBenchmarkState,
  currentInvFundCommitmentAndWatermarkState,
  currentInvFundDefinitiveState,
  currentInvFundNavState,
  currentInvFundPortfolioState,
} from '../../../../states/index';

import { tableOrderByState, tableOrder } from '../../../../states/index';
import {
  isDetailModal,
  currentIdState,
  currentapprovalJSONState,
  currentSheetNameState,
} from '../../../../states/index';

import { stableSort } from './function/stableSort';
import { approvalName } from '../function/approvalName';
//*********************************** Table Header **************************************//

//?----------------------------------- SubProfile Profile -------------------------
function SubProfileTableHeadComp({
  order,
  orderBy,
  onRequestSort,
  rowHeadData,
}: {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof SubProfileTableBodyData,
  ) => void;
  order: Order;
  orderBy: string;
  rowHeadData: SubProfileTableHeadData[];
}) {
  const createSortHandler =
    (property: keyof SubProfileTableBodyData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const rowHead: SubProfileTableHeadData[] = rowHeadData;

  return (
    <TableHead>
      <TableRow>
        {rowHead.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function SubProfileTableBodyComp({
  rowData,
  labelId,
  detailModalClick,
  sheetName,
}: {
  rowData: object;
  labelId: string;
  detailModalClick: () => void;
  sheetName: string;
}) {
  let currentRowData: { [key: string]: any } = rowData;

  return (
    <TableRow hover key={labelId}>
      <TableCell component="th" id={labelId} scope="currentRowData">
        {currentRowData['sheet_name']}
      </TableCell>
      <TableCell align="left">{currentRowData['edit_date']}</TableCell>
      <TableCell align="left">
        <ApprovalChip sheetStauts={currentRowData['approval_status'][sheetName]} />
      </TableCell>
      {/* <TableCell align='left'>{currentRowData['detail']}</TableCell> */}
      <TableCell align="left">
        <ButtonDetail onClick={detailModalClick} />
      </TableCell>
    </TableRow>
  );
}

//?----------------------------------- Previously Profile -------------------------
function PreviouslyTableHeadComp({
  order,
  orderBy,
  onRequestSort,
  rowHeadData,
}: {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof PreviouslyTableBodyData,
  ) => void;
  order: Order;
  orderBy: string;
  rowHeadData: PreviouslyTableHeadData[];
}) {
  const createSortHandler =
    (property: keyof PreviouslyTableBodyData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const rowHead: PreviouslyTableHeadData[] = rowHeadData;

  return (
    <TableHead>
      <TableRow>
        {rowHead.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function PreviouslyTableBodyComp({
  rowData,
  labelId,
  detailModalClick,
}: {
  rowData: object;
  labelId: string;
  detailModalClick: () => void;
}) {
  let currentRowData: { [key: string]: any } = rowData;
  return (
    <TableRow hover key={labelId}>
      <TableCell align="right">
        {currentRowData['current'] ? <StarIcon /> : <></>}
      </TableCell>
      <TableCell component="th" id={labelId} scope="currentRowData">
        {currentRowData['approved_date']}
      </TableCell>
      <TableCell align="left">{currentRowData['approved_by']}</TableCell>

      <TableCell align="left">
        <ButtonDetail onClick={detailModalClick} />
      </TableCell>
    </TableRow>
  );
}

//*********************************** Table Render **************************************//

// ?---------------------- TableHead Render ---------------------
function TableHeadRender({
  rowHeadData,
  tableMode,
}: {
  rowHeadData: any;
  tableMode: string;
}) {
  const [order, setOrder] = useRecoilState(tableOrder);
  const [orderBy, setOrderBy] = useRecoilState(tableOrderByState);

  const handleSubProfileRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SubProfileTableBodyData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handlePreviouslyRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof PreviouslyTableBodyData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  switch (tableMode) {
    case 'sub_profile_table': {
      return (
        <SubProfileTableHeadComp
          order={order}
          orderBy={orderBy}
          onRequestSort={handleSubProfileRequestSort}
          rowHeadData={rowHeadData}
        />
      );
    }
    //------------------------------------//
    case 'previously_table': {
      return (
        <PreviouslyTableHeadComp
          order={order}
          orderBy={orderBy}
          onRequestSort={handlePreviouslyRequestSort}
          rowHeadData={rowHeadData}
        />
      );
    }

    //------------------------------------//
    default: {
      return <div>{'NotFound TableHead'}</div>;
    }
  }
}

// ?---------------------- TableBody Render --------------------
function TableBodyRender({
  rowData,
  labelId,
  tableMode,
  detailModalClick,
  sheetName,
}: {
  rowData: object;
  labelId: string;
  tableMode: string;
  detailModalClick: () => void;
  sheetName: string;
}) {
  switch (tableMode) {
    case 'sub_profile_table': {
      return (
        <SubProfileTableBodyComp
          rowData={rowData}
          labelId={labelId}
          detailModalClick={detailModalClick}
          sheetName={approvalName(sheetName)}
        />
      );
    }
    //------------------------------------//
    case 'previously_table': {
      return (
        <PreviouslyTableBodyComp
          rowData={rowData}
          labelId={labelId}
          detailModalClick={detailModalClick}
        />
      );
    }

    //------------------------------------//
    default: {
      return <div>{'NotFound TableBody'}</div>;
    }
  }
}

//Global var
const GenRowPerPage = 8;

export default function SubTable({
  rowBodyData,
  rowHeadData,
  tableMode,
}: {
  rowBodyData: SubProfileTableBodyData[];
  rowHeadData: SubProfileTableHeadData[];
  tableMode: string;
}) {
  //TODO find way to fix "any"
  const currentRowBodyData: any[] = rowBodyData;
  const currentRowHeadData: SubProfileTableHeadData[] = rowHeadData;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(GenRowPerPage);
  const order = useRecoilValue(tableOrder);
  const orderBy = useRecoilValue(tableOrderByState);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  //---------------------------------------------------------------------
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, GenRowPerPage));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - currentRowBodyData.length) : 0;

  //---------------------------- MainDetailModal --------------------------

  const [currentId, setcurrentId] = useRecoilState(currentIdState);

  const setOpenDetailModal = useSetRecoilState(isDetailModal);

  const setCurrentapprovalJSON = useSetRecoilState(currentapprovalJSONState);

  const currentSheetName = useRecoilValue(currentSheetNameState);

  //--------------------------------Data ----------------------------------//

  const setCurrentInvBoardApprovedMemo = useSetRecoilState(
    currentInvBoardApprovedMemoState,
  );

  const setCurrentInvCompDefinitive = useSetRecoilState(currentInvCompDefinitiveState);

  const setCurrentInvCompFs = useSetRecoilState(currentInvCompFsState);
  const setCurrentInvCompProduct = useSetRecoilState(currentInvCompProductState);

  const setCurrentInvContact = useSetRecoilState(currentInvContactState);

  const setCurrentInvFundBenchmark = useSetRecoilState(currentInvFundBenchmarkState);

  const setCurrentInvFundCommitmentWatermark = useSetRecoilState(
    currentInvFundCommitmentAndWatermarkState,
  );

  const setCurrentInvFundDefinitive = useSetRecoilState(currentInvFundDefinitiveState);
  const setCurrentInvFundNav = useSetRecoilState(currentInvFundNavState);

  const setCurrentInvFundPortfolio = useSetRecoilState(currentInvFundPortfolioState);

  const handleDetailModalOpen = (rowData: any) => () => {
    // console.log(rowData);
    // console.log(currentId);

    let id = '';
    let sheet_id = rowData['sheet_id'].toString();

    switch (currentSheetName) {
      //?-------------------- Common -------------------- */

      //----------------- 01 ---------------- */
      case 'invcontact':
        // console.log(rowData['sheet_data']);
        id = rowData['sheet_data']['id'];
        setCurrentInvContact(rowData['sheet_data']);

        break;

      //---------------- 02 ----------------- */
      case 'invboardapprovedmemo':
        id = rowData['sheet_data']['id'];
        setCurrentInvBoardApprovedMemo(rowData['sheet_data']);
        break;

      //?-------------------- Company -------------------- */
      //---------------- 01 ----------------- */
      case 'invcompdefinitive':
        id = rowData['sheet_data']['company_id'];
        setCurrentInvCompDefinitive(rowData['sheet_data']);
        break;
      //---------------- 02 ----------------- */
      case 'invcompproduct':
        id = rowData['sheet_data']['company_id'];
        setCurrentInvCompProduct(rowData['sheet_data']);
        break;
      //------------------ 03 --------------- */
      case 'invcompfs':
    
        id = rowData['sheet_data']['company_id'];
        setCurrentInvCompFs(rowData['sheet_data']);
        break;
      //--------------------------------- */

      //?-------------------- Fund -------------------- */
      //----------------- 01 ---------------- */
      case 'invfunddefinitive':
        id = rowData['sheet_data']['company_fund'];
        setCurrentInvFundDefinitive(rowData['sheet_data']);
        break;
      //----------------- 02 ---------------- */
      case 'invfundnav':
        id = rowData['sheet_data']['company_fund'];
        setCurrentInvFundNav(rowData['sheet_data']);
        break;
      //----------------- 03 ---------------- */
      case 'invfundportfolio':
        id = rowData['sheet_data']['company_fund'];
        setCurrentInvFundPortfolio(rowData['sheet_data']);
        break;
      //----------------- 04 ---------------- */
      case 'invfundbenchmark':
        id = rowData['sheet_data']['company_fund'];
        setCurrentInvFundBenchmark(rowData['sheet_data']);
        break;
      //---------------- 05 ----------------- */
      case 'invfundcommitmentandwatermark':
        id = rowData['sheet_data']['company_fund'];
        setCurrentInvFundCommitmentWatermark(rowData['sheet_data']);
        break;

      //************** NotFound ********************* */
      //------------------------------------//
      default:
        break;
    }

    setcurrentId({
      ...currentId,
      sub_name: currentSheetName,
      sub_id_type:'',
      sub_id_value: id,
      sub_sheet_id: sheet_id,
    });
    setCurrentapprovalJSON(rowData);
    setOpenDetailModal(true);
  };

  if (Object.keys(currentRowBodyData).length === 0) {
    return <div></div>;
  } else {
    return (
      <>
        <Box sx={{ width: '100%' }}>
          {/* <Paper sx={{ width: '100%', mb: 2 }}> */}
          <Grid className={LayoutStyles.tablePortalCard}>
            {/* <TableToobarHeader /> */}
            <TableContainer>
              {/* <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="small"> */}
              <Table aria-labelledby="tableTitle" size="small">
                {/* //?------------------ Head ------------------------- */}
                <TableHeadRender
                  rowHeadData={currentRowHeadData}
                  tableMode={tableMode}
                />
                {/* //?------------------ Head ------------------------- */}
                <TableBody>
                  {stableSort(currentRowBodyData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `row-data-subtable-id-${index}`;
                      const rowData: { [key: string]: any } = row;
                      // ?--------------------------- Body ------------------------/

                      return (
                        <TableBodyRender
                          key={labelId}
                          rowData={rowData}
                          labelId={labelId}
                          tableMode={tableMode}
                          detailModalClick={handleDetailModalOpen(rowData)}
                          sheetName={currentSheetName}
                        />
                      );
                      // ?--------------------------- Body ------------------------/
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[GenRowPerPage]}
              component="div"
              count={currentRowBodyData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
          {/* </Paper> */}
        </Box>
      </>
    );
  }
}
