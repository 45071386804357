import { atom } from 'recoil';
import { arrValuePeriod, arrCurrency } from '../common';

const _sheetName = 'invcompfs';

//?------- Parameter Design ---------------
/*

------- Label / ID ------------
displayName = variable for display name in label.
sourceSystemName = variable for bank system name.
apiName = variable for call api. 

-------- Input ----------------
description = input placeholder.
inputLength = custom length limit of character.
inputType = (checkbox | date | radio | select | textfield) type of input.
inputClass = Custom format masking or validation.
possibleValue = (array) Value for dropdownlist / checkbox / radio.

-------- Boolean --------------
isRequire = (true/false) require field. can't be null.

isLinkValue = (true/false) Link the value from other input (linkValueId)
linkValueId = ID of input to Link data (display when isLinkValue == true).

isAutoGenerate = (true/false) auto generate value.
generateSource = source of auto generate (display when isAutoGenerate == true).

*/
/**
 textfield
 - NoLimitFree
 - NoLimitCountNumber
 - NoLimitFloat

 date
 - General

select
 - General

 */

const jsonData: { [key: string]: any } = {
  sheetName: _sheetName,
  inputInfo: [
    // ? --------------------------------- Start ---------------------------------------//
    // !---------------------------- 000 ---------------------------------//
    {
      displayName: 'FS ID',
      sourceSystemName: 'FS_ID',
      apiName: 'fs_id',

      description: 'FS ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromServer',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 001 ---------------------------------//
    {
      displayName: 'Company ID',
      sourceSystemName: 'COMPANY_ID',
      apiName: 'company_id',

      description: 'Company ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromDefinitive',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'company_name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 002 ---------------------------------//
    {
      displayName: 'Company Name',
      sourceSystemName: 'COMPANY_NAME',
      apiName: 'company_name',

      description: 'Full legal name of the company',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromDefinitive',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 003 ---------------------------------//
    {
      displayName: 'Reporting Date',
      sourceSystemName: 'REPORTING_DATE',
      apiName: 'reporting_date',

      description: "Date as listed in company's report",
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromExternal',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 004 ---------------------------------//
    {
      displayName: 'FS Year',
      sourceSystemName: 'FS_YEAR',
      apiName: 'fs_year',

      description: 'Year of the financial statements',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromExternal',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 005 ---------------------------------//
    {
      displayName: 'FS Quarter',
      sourceSystemName: 'FS_QUARTER',
      apiName: 'fs_quarter',

      description: 'Quarter of the financial statements',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromExternal',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 006 ---------------------------------//
    {
      displayName: 'Value Period',
      sourceSystemName: 'VALUE_PERIOD',
      apiName: 'value_period',

      description: 'Quarter or Annual',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrValuePeriod,
      displayValue: arrValuePeriod,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 007 ---------------------------------//
    {
      displayName: 'Value Country',
      sourceSystemName: 'VALUE_COUNTRY',
      apiName: 'value_country',

      description: 'Reported country',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromExternal',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 008 ---------------------------------//
    {
      displayName: 'Currency AC',
      sourceSystemName: 'CURRENCY',
      apiName: 'currency',

      description: 'Denominated Currency',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromDefinitive',
      possibleValue: arrCurrency,
      displayValue: arrCurrency,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 009 ---------------------------------//
    {
      displayName: 'Revenue',
      sourceSystemName: 'REVENUE',
      apiName: 'revenue',

      description: 'Revenue during the period',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromExternal',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 010 ---------------------------------//
    {
      displayName: 'Cost of Good Sold ',
      sourceSystemName: 'COST_OF_GOOD_SOLD',
      apiName: 'cost_of_good_sold',

      description: 'COGS during the period',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromExternal',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 011 ---------------------------------//
    {
      displayName: 'Gross Profit',
      sourceSystemName: 'GROSS_PROFIT',
      apiName: 'gross_profit',

      description: 'Gross profit during the period',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromExternal',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 012 ---------------------------------//
    {
      displayName: 'EBITDA',
      sourceSystemName: 'EBITDA',
      apiName: 'ebitda',

      description: 'Earnings Before Depreciation and Amortization',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromExternal',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 013 ---------------------------------//
    {
      displayName: 'EBIT',
      sourceSystemName: 'EBIT',
      apiName: 'ebit',

      description: 'Earnings Before Interest and Tax',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromExternal',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 014 ---------------------------------//
    {
      displayName: 'Net Income',
      sourceSystemName: 'NET_INCOME',
      apiName: 'net_income',

      description: 'Net Income during the period',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromExternal',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 015 ---------------------------------//
    {
      displayName: 'Total Cash and Cash Equivalent',
      sourceSystemName: 'TOTAL_CASH',
      apiName: 'total_cash',

      description: 'Cash and Cash Equivalent',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromExternal',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 016 ---------------------------------//
    {
      displayName: 'Total Current Assets',
      sourceSystemName: 'TOTAL_CURRENT_ASSET',
      apiName: 'total_current_asset',

      description: 'Current Assets',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromExternal',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 017 ---------------------------------//
    {
      displayName: 'Total Assets',
      sourceSystemName: 'TOTAL_ASSET',
      apiName: 'total_asset',

      description: 'Total Assets',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromExternal',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 018 ---------------------------------//
    {
      displayName: 'Total Current Liability',
      sourceSystemName: 'TOTAL_CURRENT_LIABILITY',
      apiName: 'total_current_liability',

      description: 'Total Current Liability',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromExternal',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,

    },

    // ---------------------------- 019 ---------------------------------//
    {
      displayName: 'Total Liability',
      sourceSystemName: 'TOTAL_LIABILITY',
      apiName: 'total_liability',

      description: 'Total Liability',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromExternal',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,

    },

    // ---------------------------- 020 ---------------------------------//
    {
      displayName: 'Total Equity',
      sourceSystemName: 'TOTAL_EQUITY',
      apiName: 'total_equity',

      description: 'Total Equity',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromExternal',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 021 ---------------------------------//
    {
      displayName: 'Interest-Bearing Debt',
      sourceSystemName: 'INTEREST_BEARING_DEBT',
      apiName: 'interest_bearing_debt',

      description: 'Interest-Bearing Debt',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromExternal',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 022 ---------------------------------//
    {
      displayName: 'Registered Capital - No. of Shares',
      sourceSystemName: 'REGISTERED_CAPITAL_NO_OF_SHARE',
      apiName: 'registered_capital_no_of_share',

      description: 'Registered Capital (Shares)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromExternal',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 023 ---------------------------------//
    {
      displayName: 'Registered Capital - Value',
      sourceSystemName: 'REGISTERED_CAPITAL_VALUE',
      apiName: 'registered_capital_value',

      description: 'Registered Capital (Value)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromExternal',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,

    },

    // ---------------------------- 024 ---------------------------------//
    {
      displayName: 'Paid Up Capital - No. of  Shares',
      sourceSystemName: 'PAID_UP_CAPITAL_NO_OF_SHARE',
      apiName: 'paid_up_capital_no_of_share',

      description: 'Paid-up Capital (Shares)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromExternal',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 025 ---------------------------------//
    {
      displayName: 'Paid Up Capital - Value',
      sourceSystemName: 'PAID_UP_CAPITAL_VALUE',
      apiName: 'paid_up_capital_value',

      description: 'Paid-up Capital (Value)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromExternal',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,

    },

    // ---------------------------- 026 ---------------------------------//
    {
      displayName: 'Par AC',
      sourceSystemName: 'PAR_VALUE',
      apiName: 'par_value',

      description: 'Par Value',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 027 ---------------------------------//
    {
      displayName: 'Book value per share',
      sourceSystemName: 'BOOK_VALUE_PER_SHARE',
      apiName: 'book_value_per_share',

      description: 'Book value per share',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 028 ---------------------------------//
    {
      displayName: 'Earning per share',
      sourceSystemName: 'EARNING_PER_SHARE',
      apiName: 'earning_per_share',

      description: 'Earning per share',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ? --------------------------------- End ---------------------------------------//
  ],
};

export const invcompfs = atom({
  key: 'invcompfs',
  default: jsonData,
});
