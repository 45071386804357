import { Controller, Control } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import GenerateInputField from '../../form/function/GenerateInputField';
import FieldDisplay from '../../detail/FieldDisplay';
import RenderPrePage from './RenderPrePage';
import ProgressLoading from '../../element/ProgressLoading';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import Container from '@mui/material/Container';
import ElementStyles from '../../../../../styles/Element.module.css';

export default function RenderPage({
  _inputJSON,
  _fieldData,
  _currentSystemSheetName,
  _typoHeaderDisplay,
  _hadleSetGenerateCompleteState,
  _handleResetForm,
  _control,
  _page,
  _caseName,
  _previousCaseName,
  _autogenList,
  _isLoadedAutogen,
  _isUpdateInputJSON,
  setCurrentList,
}: {
  _inputJSON: object;
  _fieldData: object;
  _currentSystemSheetName: string;
  _typoHeaderDisplay: string;
  _hadleSetGenerateCompleteState: () => void;
  _handleResetForm: () => void;
  _control: Control;
  _page: number;
  _caseName: string;
  _previousCaseName: string;
  _autogenList: any[];
  _isLoadedAutogen: boolean;
  _isUpdateInputJSON: boolean;
  setCurrentList: (selectedValue: any, nameId: string) => void;
}) {
  // console.log(_autogenList);
  const currentInputData: { [key: string]: any } = _inputJSON;
  const currentFieldData: { [key: string]: any } = _fieldData;

  switch (_page) {
    case 1:
      return (
        <RenderPrePage
          _caseName={_caseName}
          _previousCaseName={_previousCaseName}
          _typoHeaderDisplay={_typoHeaderDisplay}
        />
      );
    // ----------------------------------------------------------------------------------------------

    case 2:
      return (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          container
        >
          {_isLoadedAutogen ? (
            <>
              {Object.keys(currentInputData['inputInfo']).map((index: string) => {
                const last = Object.keys(currentInputData['inputInfo']).length - 1;

                if (index === last.toString()) {
                  _hadleSetGenerateCompleteState();
                  // setGenerateCompleteState(true);
                }

                const label =
                  '(' +
                  (parseInt(index) + 1) +
                  ')' +
                  ' ' +
                  currentInputData['inputInfo'][index]['displayName'];
                const placeholder = currentInputData['inputInfo'][index]['description'];
                const helperText = currentInputData['inputInfo'][index]['description'];
                const nameId = currentInputData['inputInfo'][index]['apiName'];
                const parentId = currentInputData['inputInfo'][index]['linkValueId'];
                const inputType = currentInputData['inputInfo'][index]['inputType'];
                const inputClass = currentInputData['inputInfo'][index]['inputClass'];
                const isRequire = currentInputData['inputInfo'][index]['isRequire'];
                const isActive = currentInputData['inputInfo'][index]['isActive'];
                const isAutoGenerate =
                  currentInputData['inputInfo'][index]['isAutoGenerate'];

                const possibleValue =
                  currentInputData['inputInfo'][index]['possibleValue'];
                const displayValue =
                  currentInputData['inputInfo'][index]['displayValue'];

                return (
                  <Controller
                    key={nameId}
                    name={nameId}
                    control={_control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <GenerateInputField
                        label={label}
                        placeholder={placeholder}
                        helperText={helperText}
                        nameId={nameId}
                        parentId={parentId}
                        inputType={inputType}
                        inputClass={inputClass}
                        isRequire={isRequire}
                        isActive={isActive}
                        isAutoGenerate={isAutoGenerate}
                        possibleValue={possibleValue}
                        displayValue={displayValue}
                        currentValue={value}
                        onInputChange={onChange}
                        currentSheetName={_currentSystemSheetName}
                        setCurrentList={setCurrentList}
                      />
                    )}
                    rules={{ required: '*required' }}
                  />
                );
              })}
            </>
          ) : (
            <ProgressLoading typoLoading="Generating Form" />
          )}

          {/* --------------------------------------- */}

          {/* --------------------------------------- */}
        </Grid>
      );

    //-----------------------------------------------------------------------------------------------
    case 3:
      console.log(currentFieldData);

      return (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          container
        >
          {_isUpdateInputJSON ? (
            <ProgressLoading typoLoading="Generating" />
          ) : (
            <>
              {Object.keys(currentInputData['inputInfo']).map((index: string) => {
                const last = Object.keys(currentInputData['inputInfo']).length - 1;

                if (index === last.toString()) {
                  _hadleSetGenerateCompleteState();
                  // setGenerateCompleteState(true);
                }

                return (
                  <FieldDisplay
                    key={currentInputData['inputInfo'][index]['apiName']}
                    fieldLabel={
                      '(' +
                      (parseInt(index) + 1) +
                      ')' +
                      ' ' +
                      currentInputData['inputInfo'][index]['displayName']
                    }
                    fieldData={
                      currentFieldData['current'][
                        currentInputData['inputInfo'][index]['apiName']
                      ]
                    }
                    inputType={currentInputData['inputInfo'][index]['inputType']}
                    displayValueArr={
                      currentInputData['inputInfo'][index]['displayValue']
                    }
                  />
                );
              })}
            </>
          )}

          {/* --------------------------------------- */}
        </Grid>
      );
    //-----------------------------------------------------------------------------------------------

    default:
      return <div></div>;
  }
}
