//----- Operation ----------
import {
  createInvCertificateBodyTable,
  createInvCompAdjustEquityBodyTable,
  createInvCompCashFlowBodyTable,
  createInvCompDividendBodyTable,
  createInvBankAccountBodyTable,
  createInvBankCapitalBodyTable,
  createInvCompAdjustParBodyTable,
  createInvCompBotBodyTable,
  createInvCompDirectorBodyTable,
  createInvCompFairBodyTable,
  createInvCompWarrantBodyTable,
  InvCertificateHeaderTableInterface,
  InvCompAdjustEquityHeaderTableInterface,
  InvCompCashFlowHeaderTableInterface,
  InvCompDividendHeaderTableInterface,
  InvBankAccountHeaderTableInterface,
  InvBankCapitalHeaderTableInterface,
  InvCompAdjustParHeaderTableInterface,
  InvCompBotHeaderTableInterface,
  InvCompDirectorHeaderTableInterface,
  InvCompFairHeaderTableInterface,
  InvCompWarrantHeaderTableInterface,
  InvFundCashflowHeaderTableInterface,
  createInvFundCashflowBodyTable,
} from '../models/index';

//----- Company Profile ----------
import { createCompanyTableBodyData, CompanyTableHeadData } from '../models/index';

//----- Fund Profile -------------
import { createFundTableBodyData, FundTableHeadData } from '../models/index';

//********************************************************************************************************** */
//************************************************* Company Table ***********************************
//********************************************************************************************************* */
export const companyRows = [
  createCompanyTableBodyData(
    '4554',
    '558',
    'EASLGM',
    'Company A',
    ['Approved'],
    'detail button',
  ),
];

export const companyHeaders: CompanyTableHeadData[] = [

  {
    id: 'company_id',
    label: 'Company ID',
  },
  {
    id: 'company_name',
    label: 'Company Name',
  },
  {
    id: 'approval',
    label: 'Approval',
  },
  {
    id: 'detail',
    label: 'Detail',
  },
];

//********************************************************************************************************* */
//****************************************************** Fund Table *********************************************
//********************************************************************************************************* */

export const fundRows = [
  createFundTableBodyData(
    '5685',
    '558',
    'EASLGM',
    'Fund A',
    ['Approved'],
    'detail button',
  ),
];

export const fundHeaders: FundTableHeadData[] = [
  {
    id: 'bi_id',
    label: 'BI ID',
  },
  {
    id: 'fund_id',
    label: 'Fund ID',
  },
  {
    id: 'fund_name',
    label: 'Fund Name',
  },
  {
    id: 'approval',
    label: 'Approval',
  },
  {
    id: 'detail',
    label: 'Detail',
  },
];

//********************************************************************************************************* */
//********************************************* Operation Table *********************************************
//********************************************************************************************************* */

//------------------------------- incertificate ------------------------------------------//
export const InvCertificateRows = [
  createInvCertificateBodyTable(
    'sheet_id',
    '1253',
    'name',
    'certificate_no',
    'currency',
    'no_of_share',
    'book_value',
    'custodian',
    ['Approved'],
    'detail',
  ),
];
export const InvCertificateHeaders: InvCertificateHeaderTableInterface[] = [
  {
    id: 'id',
    label: 'ID',
  },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'certificate_no',
    label: 'Certificate No.',
  },
  {
    id: 'currency',
    label: 'Currency',
  },
  {
    id: 'no_of_share',
    label: 'No.of Share',
  },

  {
    id: 'book_value',
    label: 'Investment Cost (Orginal CCY) AC ',
  },
  {
    id: 'custodian',
    label: 'Custodian Name',
  },
  {
    id: 'approval',
    label: 'Approval',
  },
  {
    id: 'detail',
    label: 'Detail',
  },
];

//--------------------------- incompadjustequity ------------------------------------------//
export const InvCompAdjustEquityRows = [
  createInvCompAdjustEquityBodyTable(
    'sheet_id',
    'bi_id',
    'company_id',
    'product_name',
    'txn_date',
    'investment_port',
    'no_of_share',
    'allowance_permanent_thb',
    'total_adjusted_equity',
    'adjusted_equity_posttransaction',
    ['Approved'],
    'detail',
  ),
];
export const InvCompAdjustEquityHeaders: InvCompAdjustEquityHeaderTableInterface[] = [
  {
    id: 'company_id',
    label: 'Company ID',
  },

  {
    id: 'product_name',
    label: 'Product Name ',
  },

  {
    id: 'txn_date',
    label: 'Recording Date',
  },

  {
    id: 'investment_port',
    label: 'Portfolio Classifications',
  },

  {
    id: 'no_of_share',
    label: 'No. of Share',
  },

  {
    id: 'allowance_permanent_thb',
    label: 'Cost After Impair',
  },

  {
    id: 'total_adjusted_equity',
    label: 'Adjust Equity Amount Ac (THB)',
  },

  {
    id: 'adjusted_equity_posttransaction',
    label: 'Adjust Equity Amount AC (THB) (Post transaction)',
  },

  {
    id: 'approval',
    label: 'Approval',
  },
  {
    id: 'detail',
    label: 'Detail',
  },
];

//--------------------------- incompcashflow ------------------------------------------//
export const InvCompCashFlowRows = [
  createInvCompCashFlowBodyTable(
    'sheet_id',
    'bi_id',
    'company_id',
    'company_name',
    'txn_date',
    'txn_type',
    'currency',
    'no_of_share',
    'price_per_share',
    'net_cashflow',
    ['Approved'],
    'detail',
  ),
];
export const InvCompCashFlowHeaders: InvCompCashFlowHeaderTableInterface[] = [
  {
    id: 'company_id',
    label: 'Company ID',
  },

  {
    id: 'company_name',
    label: 'Company Name ',
  },

  {
    id: 'txn_date',
    label: 'Transaction Date',
  },

  {
    id: 'txn_type',
    label: 'Transaction Type',
  },

  {
    id: 'currency',
    label: 'Currency',
  },

  {
    id: 'no_of_share',
    label: 'No. of Share',
  },

  {
    id: 'price_per_share',
    label: 'Price per Share',
  },

  {
    id: 'net_cashflow',
    label: 'Net Cashflow (Original CCY)',
  },

  {
    id: 'approval',
    label: 'Approval',
  },
  {
    id: 'detail',
    label: 'Detail',
  },
];

//--------------------------- incompdividend ------------------------------------------//
export const InvCompDividendRows = [
  createInvCompDividendBodyTable(
    'sheet_id',
    'company_id',
    'company_name',
    'product_name',
    'data_date',
    'currency',
    'no_of_shares',
    'dividend',
    'dividend_amount',
    ['Approved'],
    'detail',
  ),
];
export const InvCompDividendHeaders: InvCompDividendHeaderTableInterface[] = [
  {
    id: 'company_id',
    label: 'Company ID',
  },

  {
    id: 'product_name',
    label: 'Product Name',
  },

  {
    id: 'data_date',
    label: 'Date',
  },

  {
    id: 'currency',
    label: 'Currency',
  },

  {
    id: 'no_of_shares',
    label: 'No. of Shares',
  },

  {
    id: 'dividend',
    label: 'Dividend Per Share',
  },

  {
    id: 'dividend_amount',
    label: 'Total Dividend',
  },

  {
    id: 'approval',
    label: 'Approval',
  },
  {
    id: 'detail',
    label: 'Detail',
  },
];
//--------------------------- invbankaccount ------------------------------------------//
export const InvBankAccountRows = [
  createInvBankAccountBodyTable(
    'sheet_id',
    'id',
    'name',
    'date',
    'account_no',
    'account_name',
    'account_bank_name',
    ['Approved'],
    'detail',
  ),
];
export const InvBankAccountHeaders: InvBankAccountHeaderTableInterface[] = [
  {
    id: 'id',
    label: 'ID',
  },

  {
    id: 'name',
    label: 'Name',
  },

  {
    id: 'date',
    label: 'Date',
  },

  {
    id: 'account_no',
    label: 'Beneficiary Account Number',
  },

  {
    id: 'account_name',
    label: 'Beneficiary Account Name',
  },

  {
    id: 'account_bank_name',
    label: 'Beneficiary Bank Name',
  },

  {
    id: 'approval',
    label: 'Approval',
  },
  {
    id: 'detail',
    label: 'Detail',
  },
];
//--------------------------- invbankcapital ------------------------------------------//
export const InvBankCapitalRows = [
  createInvBankCapitalBodyTable(
    'sheet_id',
    'data_date',
    'status',
    'tier_1_capital',
    'tier_2_capital',
    'total',
    ['Approved'],
    'detail',
  ),
];
export const InvBankCapitalHeaders: InvBankCapitalHeaderTableInterface[] = [
  {
    id: 'data_date',
    label: 'Date',
  },

  {
    id: 'status',
    label: 'Status',
  },

  {
    id: 'tier_1_capital',
    label: 'Tier 1',
  },

  {
    id: 'tier_2_capital',
    label: 'Tier 2',
  },

  {
    id: 'total',
    label: 'Total',
  },

  {
    id: 'approval',
    label: 'Approval',
  },
  {
    id: 'detail',
    label: 'Detail',
  },
];

//--------------------------- invcomadjustpar ------------------------------------------//
export const InvCompAdjustParRows = [
  createInvCompAdjustParBodyTable(
    'sheet_id',
    'bi_id',
    'company_id',
    'product_name',
    'txn_date',
    'adjusted_par_method',
    'no_of_share_posttransaction',
    'investment_cost',
    'par_value_posttransaction',
    ['Approved'],
    'detail',
  ),
];
export const InvCompAdjustParHeaders: InvCompAdjustParHeaderTableInterface[] = [
  {
    id: 'company_id',
    label: 'Company ID',
  },

  {
    id: 'product_name',
    label: 'Product Name',
  },

  {
    id: 'txn_date',
    label: 'Transaction Date',
  },

  {
    id: 'adjusted_par_method',
    label: 'Adjusted Par Method AC',
  },

  {
    id: 'no_of_share_posttransaction',
    label: 'No. of Shares on post-transaction ',
  },

  {
    id: 'investment_cost',
    label: 'Investment cost post transaction',
  },

  {
    id: 'par_value_posttransaction',
    label: 'Par (post)',
  },

  {
    id: 'approval',
    label: 'Approval',
  },
  {
    id: 'detail',
    label: 'Detail',
  },
];

//--------------------------- invcompbot ------------------------------------------//
export const InvCompBotRows = [
  createInvCompBotBodyTable(
    'sheet_id',
    'company_id',
    'bi_id',
    'recording_date',
    'document_no',
    'request_type',
    'document_start_date',
    'document_end_date',
    ['Approved'],
    'detail',
  ),
];
export const InvCompBotHeaders: InvCompBotHeaderTableInterface[] = [
  {
    id: 'company_id',
    label: 'Company ID',
  },

  {
    id: 'recording_date',
    label: 'Recording Date',
  },

  {
    id: 'document_no',
    label: 'Document No.',
  },

  {
    id: 'request_type',
    label: 'Requested Type',
  },

  {
    id: 'document_start_date',
    label: 'Document Start Date ',
  },

  {
    id: 'document_end_date',
    label: 'Document End Date',
  },

  {
    id: 'approval',
    label: 'Approval',
  },
  {
    id: 'detail',
    label: 'Detail',
  },
];
//--------------------------- invcompdirector ------------------------------------------//
export const InvCompDirectorRows = [
  createInvCompDirectorBodyTable(
    'sheet_id',
    'bi_id',
    'company_id',
    'committee_name',
    'bbl_representative',
    'bbl_representative_internalposition',
    ['Approved'],
    'detail',
  ),
];
export const InvCompDirectorHeaders: InvCompDirectorHeaderTableInterface[] = [
  {
    id: 'company_id',
    label: 'Company ID',
  },

  {
    id: 'committee_name',
    label: 'Committee Name',
  },

  {
    id: 'bbl_representative',
    label: "BBL's representative",
  },

  {
    id: 'bbl_representative_internalposition',
    label: "BBL's representative internal position",
  },

  {
    id: 'approval',
    label: 'Approval',
  },
  {
    id: 'detail',
    label: 'Detail',
  },
];
//--------------------------- invcompfair ------------------------------------------//
export const InvCompFairRows = [
  createInvCompFairBodyTable(
    'sheet_id',
    'company_id',
    'company_name',
    'data_date',
    'wacc',
    'terminal_growth',
    'fair_value',
    ['Approved'],
    'detail',
  ),
];
export const InvCompFairHeaders: InvCompFairHeaderTableInterface[] = [
  {
    id: 'company_id',
    label: 'Company ID',
  },

  {
    id: 'company_name',
    label: 'Company Name',
  },

  {
    id: 'data_date',
    label: 'Date',
  },
  {
    id: 'wacc',
    label: 'WACC',
  },
  {
    id: 'terminal_growth',
    label: 'Terminal Growth Rate',
  },
  {
    id: 'fair_value',
    label: 'Fair Value',
  },
  {
    id: 'approval',
    label: 'Approval',
  },
  {
    id: 'detail',
    label: 'Detail',
  },
];
//--------------------------- invcompwarrant ------------------------------------------//
export const InvCompWarrantRows = [
  createInvCompWarrantBodyTable(
    'sheet_id',
    'bi_id',
    'company_id',
    'product_name',
    'txn_date',
    'warrant_name',
    'warrant_type',
    'no_of_share_pretransaction',
    ['Approved'],
    'detail',
  ),
];
export const InvCompWarrantHeaders: InvCompWarrantHeaderTableInterface[] = [
  {
    id: 'company_id',
    label: 'Company ID',
  },

  {
    id: 'product_name',
    label: 'Product Name',
  },

  {
    id: 'txn_date',
    label: 'Transaction Date',
  },

  {
    id: 'warrant_name',
    label: 'Warrant Name',
  },

  {
    id: 'warrant_type',
    label: 'Transaction Type of Warrant',
  },

  {
    id: 'no_of_share_pretransaction',
    label: 'No. of Warrant in this transaction',
  },

  {
    id: 'approval',
    label: 'Approval',
  },
  {
    id: 'detail',
    label: 'Detail',
  },
];
//--------------------------- invfundcashflow ------------------------------------------//
export const InvFundCashflowRows = [
  createInvFundCashflowBodyTable(
    'sheet_id',
    'fund_id',
    'fund_name',
    'txn_date',
    'cashflow_type',
    'currency',
    'net_cashflow',
    'current_stake',
    ['Approved'],
    'detail',
  ),
];
export const InvFundCashflowHeaders: InvFundCashflowHeaderTableInterface[] = [
  {
    id: 'fund_id',
    label: 'Fund ID',
  },

  {
    id: 'fund_name',
    label: 'Fund Name',
  },

  {
    id: 'cashflow_type',
    label: 'Cashflow Direction',
  },

  {
    id: 'currency',
    label: 'Currency',
  },

  {
    id: 'cashflow_amount',
    label: 'Cashflow AC',
  },

  {
    id: 'current_stake',
    label: '% of BBL Contribution',
  },

  {
    id: 'approval',
    label: 'Approval',
  },
  {
    id: 'detail',
    label: 'Detail',
  },
];
