import axios from '../axios-instance';
import {
  API_SERVER_URL,
  API_PORT_URL,
  API_SUBMIT_URL,
  API_APPROVE_URL,
  API_CANCEL_URL,
  API_DETAIL_URL,
  API_HISTORY_URL,
} from '../../../config';

import {
  InvFundPortfolioSubmit,
  requestPortMainInterface,
} from '@invport/models';

import {
  requestApproveInterface,
  requestCancelInterface,
  requestHistoryInterface,
  requestDetailInterface,
} from '@invport/models'

import {
  responseApproveInterface,
  responseCancelInterface,
  responseHistoryInterface,
  responseDetailInterface,
  responseSubmitInterface,
} from '@invport/models';



export const getPort = async (
  requestMainBody: requestPortMainInterface,
): Promise<requestPortMainInterface> =>
  (
    await axios.post<requestPortMainInterface>(
      `${API_SERVER_URL}/${API_PORT_URL}`,
      requestMainBody,
    )
  ).data;

//submit
export const submitPort = async (
  requestSubmitBody: InvFundPortfolioSubmit,
): Promise<responseSubmitInterface> =>
  (
    await axios.post<responseSubmitInterface>(
      `${API_SERVER_URL}/${API_PORT_URL}/${API_SUBMIT_URL}`,
      requestSubmitBody,
    )
  ).data;

//approve
export const approvePort = async (
  requestApprov: requestApproveInterface
): Promise<responseApproveInterface> =>
  (
    await axios.post<responseApproveInterface>(
      `${API_SERVER_URL}/${API_PORT_URL}/${API_APPROVE_URL}`,
      requestApprov,
    )
  ).data;

//cancel
export const cancelPort = async (
  requestCancel: requestCancelInterface
): Promise<responseCancelInterface> =>
  (
    await axios.post<responseCancelInterface>(
      `${API_SERVER_URL}/${API_PORT_URL}/${API_CANCEL_URL}`,
      requestCancel,
    )
  ).data;

//detail
export const getDetailPort = async (
  requestDetailBody: requestDetailInterface,
): Promise<responseDetailInterface> =>
  (
    await axios.post<responseDetailInterface>(
      `${API_SERVER_URL}/${API_PORT_URL}/${API_DETAIL_URL}`,
      requestDetailBody,
    )
  ).data;

//histroy
export const getHistoryPort = async (
  requestHistoryBody: requestHistoryInterface,
): Promise<responseHistoryInterface> =>
  (
    await axios.post<responseHistoryInterface>(
      `${API_SERVER_URL}/${API_PORT_URL}/${API_HISTORY_URL}`,
      requestHistoryBody,
    )
  ).data;
