import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getFund } from '../../api/index';

//Text

//Layout
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

//Element
import MasterTable from '../common/table/MasterTable';
import LayoutStyles from '../../../styles/Layout.module.css';

import {
  fundRowsState,
  fundHeadersState,
  isLoadedMasterRowsState,
  tableConfigState,
} from '../../../states/index';
import {
  requestFundMainInterface,
  OrderBy,
  createFundTableBodyData,
  FundTableBodyData,
} from '../../../models/index';

export default function FundProfileCard({
  onPaginationNextClick,
}: {
  onPaginationNextClick: (
    _currentSystemSheetName: string,
    _sheet_id: string,
    _rowsLengthTotal: number,
    _currentSearchKeyword: string,
    _currentSortBy: string,
  ) => void;
}) {
  const setIsLoadedRows = useSetRecoilState(isLoadedMasterRowsState);
  const tableConfig = useRecoilValue(tableConfigState);
  const [fundRows, setFundRows] = useRecoilState(fundRowsState);
  const fundHeaders = useRecoilValue(fundHeadersState);

  useEffect(() => {
    (async () => {
      let rowsData: FundTableBodyData[] = [];

      const requestFundMain: requestFundMainInterface = {
        page: 1,
        page_length: tableConfig['defaultRowsLength'],
        sort_by: '',
        order: OrderBy.asc,
        filter: '',
      };

      const getFundData: { [key: string]: any } = await getFund(requestFundMain);

      // console.log(getFundData);
      Object.keys(getFundData['data']).map((index: string) => {
        let sheet_id = getFundData['data'][index]['sheet_id'];
        let bi_id = getFundData['data'][index]['bi_id'];
        let fund_id = getFundData['data'][index]['fund_id'];
        let fund_name_en = getFundData['data'][index]['fund_name'];
        let fund_name_th = getFundData['data'][index]['fund_thainame'];
        let approval_status = getFundData['data'][index]['approval_status'];

        let member = createFundTableBodyData(
          sheet_id,
          bi_id,
          fund_id,
          fund_name_en,
          approval_status,
          'detail button',
        );

        rowsData.push(member);
      });
      setFundRows(rowsData);
      setIsLoadedRows(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper className={LayoutStyles.mainContentCard} square>
      <Grid container className={LayoutStyles.mainGridCard}>
        <Grid item sm={12} md={12} lg={12}>
          {/* //------------ Master Modal -------------- */}
          <MasterTable
            rowBodyData={fundRows}
            rowHeadData={fundHeaders}
            portalMode="fund_profile"
            onPaginationNextClick={onPaginationNextClick}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
