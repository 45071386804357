import { Ownership, Sector } from "./inv-enums";

export enum CompStage {
	Accelerator = 'ACCELERATOR',   // typo
	Seed        = 'SEED',
	SeriesA     = 'SERIES_A',
	SeriesB     = 'SERIES_B',
	LaterStage  = 'LATER_STAGE',
	Corporate   = 'CORPORATE',
	Ipo         = 'IPO',
	Others      = 'OTHERS',
}
export enum Consolidation {
	SoloConsolidated    = 'SOLO_CONSOLIDATED',
	NonSoloConsolidated = 'NON_SOLO_CONSOLIDATED',
	None                = 'NONE',
}

interface InvCompDefinitiveBaseInterface {
	rm_id               : string,      // --- Autogen from Mastersheet
	data_date           : string,      // date string
	company_id          : string,      // --- Autogen from Mastersheet
	registered_id       : string,
	registered_date     : string,      // date string
	company_name_en     : string,
	company_name_th     : string,
	company_symbol      : string,
	equity_short_name   : string,
	company_group       : string,
	company_stage       : CompStage,
	strategic_investment: boolean,     // Yes / No
	exit_flag           : boolean,     // Yes / No
	business_type_en    : string,
	business_type_th    : string,
	msci_industry       : Sector,
	main_product_1      : string,
	main_product_2      : string,
	main_product_3      : string,
	main_product_4      : string,
	main_product_5      : string,
	domicile_country    : string,
	invested_country    : string,
	fund_id             : number,      // integer
	address_en          : string,
	address_th          : string,
	remark              : string,

	// Risk Management Purposes
	alternative_class: boolean,   // Alternative / Non-Alternative
	fintech_class    : boolean,   // Yes / No

	// Accounting Purposes
	isic_code         : string,
	bot_exclusion_flag: boolean,         // Yes / No
	consolidated_flag : Consolidation,
	ownership_type    : Ownership,

	
}

export type InvCompDefinitive       = InvCompDefinitiveBaseInterface;
export type InvCompDefinitiveSubmit = InvCompDefinitiveBaseInterface & {
	//sheet id Ref
	sheet_id: string,
};
export type InvCompDefinitiveEntry = Pick<
	InvCompDefinitive,
	'company_id' |
	'company_name_en' |
	'company_name_th' |
	'rm_id'
>[];


export type InvCompDefinitiveAll = InvCompDefinitive & InvCompDefinitiveSubmit;
