//---------------------------------------- Import React --------------------------------------
import Tooltip from '@mui/material/Tooltip';
import DescriptionIcon from '@mui/icons-material/Description';
import IconButton from '@mui/material/IconButton';

function ButtonDetail({ onClick }: { onClick: () => void }) {
  return (
    <>
      <Tooltip title="View Detail">
        <IconButton color="primary" onClick={onClick}>
          <DescriptionIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default ButtonDetail;
