import { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

//API

//Company
import { getCompany } from '../../../api/index';

import {
  requestFundMainInterface,
  requestCompanyMainInterface,
  OrderBy,
} from '../../../../models/index';

//Fund
import { getFund } from '../../../api/index';

//Operation
import {
  getAccount,
  getCapital,
  getCertificate,
  getAdjEquity,
  getAdjPar,
  getBot,
  getCompCashFlow,
  getDirector,
  getDividend,
  getCompFair,
  getWarrant,
  getFundCashFlow,
} from '../../../api/index';

import {
  requestAccountMainInterface,
  requestCapitalMainInterface,
  requestCertificateMainInterface,
  requestAdjEquityMainInterface,
  requestAdjParMainInterface,
  requestBotMainInterface,
  requestCompCashFlowMainInterface,
  requestDirectorMainInterface,
  requestDividendMainInterface,
  requestCompFairMainInterface,
  requestWarrantMainInterface,
  requestFundCashFlowMainInterface,
} from '../../../../models/index';

//API MainTable Detail
import { requestDetailInterface } from '../../../../models/index';
import {
  getDetailCompany,
  getDetailFund,
  getDetailAccount,
  getDetailCapital,
  getDetailCertificate,
  getDetailAdjEquity,
  getDetailAdjPar,
  getDetailBot,
  getDetailCompCashFlow,
  getDetailDirector,
  getDetailDividend,
  getDetailCompFair,
  getDetailWarrant,
  getDetailFundCashFlow,
} from '../../../api/index';

//Mui
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';

//Style
import LayoutStyles from '../../../../styles/Layout.module.css';

//Custom
import SearchBar from '../search/SearchBar';
import ProgressLoading from '../element/ProgressLoading';
//Table
import { stableSort } from './function/stableSort';
import { getComparator } from '../../../../models/index';
import {
  TableHeadRender,
  TableBodyRender,
  CreateButtonRender,
} from './RenderMasterTable';
import { tableOrderByState, tableOrder } from '../../../../states/index';
import {
  createInvBankAccountBodyTable,
  createInvBankCapitalBodyTable,
  createInvCertificateBodyTable,
  createInvCompAdjustEquityBodyTable,
  createInvCompAdjustParBodyTable,
  createInvCompBotBodyTable,
  createInvCompCashFlowBodyTable,
  createInvCompDirectorBodyTable,
  createInvCompDividendBodyTable,
  createInvCompFairBodyTable,
  createInvCompWarrantBodyTable,
  createInvFundCashflowBodyTable,
} from '../../../../models/index';

//User
import { userProfileState, isLoggedInState } from '../../../../states/index';

//Modal
import {
  isMainDetailModal,
  isDetailModal,
  isCreateModal,
  isYesNoModal,
  isFirstResetState,
} from '../../../../states/index';

//Current Data Status
import {
  currentSubSheetapprovalJSONState,
  currentSearchKeywordState,
  currentSheetTypeState,
  currentIsApprovedState,
  currentCaseNameState,
  currentModalHeaderState,
  currentIdState,
  currentSheetNameState,
  currentInputJSONState,
  isUpdateInputJSONState,
  isGenerateCompleteState,
  currentapprovalJSONState,
  currentCreateModalModeState,
  currentIsMainApprovedState,
} from '../../../../states/index';

//Current Data
import {
  currentInvBankAccountState,
  currentInvBankCapitalState,
  currentInvCertificateState,
  currentInvCompAdjustEquityState,
  currentInvCompAdjustParState,
  currentInvCompBotState,
  currentInvCompCashFlowState,
  currentInvCompDefinitiveState,
  currentInvCompDirectorState,
  currentInvCompDividendState,
  currentInvCompFairState,
  currentInvCompWarrantState,
  currentInvFundCashflowState,
  currentInvFundDefinitiveState,
} from '../../../../states/index';

//Table
import {
  operationHeadersState,
  operationRowsState,
  currentTablePageState,
  tableConfigState,
  currentRowsLengthState,
  companyRowsState,
  fundRowsState,
  currentOperationTableState,
} from '../../../../states/index';

import {
  createFundTableBodyData,
  createCompanyTableBodyData,
} from '../../../../models/index';
import { isLoadedMasterRowsState } from '../../../../states/index';

//Main form State
import { invcompdefinitive, invfunddefinitive } from '../../../../states/index';

//Operation form State
import {
  invcertificate,
  invcompdirector,
  invfundcashflow,
  invbankaccount,
  invbankcapital,
  invcompcashflow,
  invcompdividend,
  invcompfair,
  invcompadjustpar,
  invcompadjustequity,
  invcompwarrant,
  invcompbot,
} from '../../../../states/index';

//------------------

//Global var

//****************************** MainTable *********************************//
export default function MasterTable({
  rowBodyData,
  rowHeadData,
  portalMode,
  onPaginationNextClick,
}: {
  rowBodyData: any[];
  rowHeadData: any[];
  portalMode: string;
  onPaginationNextClick: (
    _currentSystemSheetName: string,
    _sheet_id: string,
    _rowsLengthTotal: number,
    _currentSearchKeyword: string,
    _currentSortBy: string,
  ) => void;
}) {




  //------------------------------------------------------------------------//
  //use any[] for Dynamic Table
  const currentRowBodyData: any[] = rowBodyData;
  const currentRowHeadData: any[] = rowHeadData;

  //?------------------------------ User ----------------------------------//

  const userProfile = useRecoilValue(userProfileState);
  const isLoggedIn = useRecoilValue(isLoggedInState);

  //?------------------------------ Table ----------------------------------//
  const GenRowPerPage = 8;
  const [page, setPage] = useRecoilState(currentTablePageState);
  const [rowsPerPage, setRowsPerPage] = useState(GenRowPerPage);

  const tableConfig = useRecoilValue(tableConfigState);
  const [currentRowsLength, setCurrentRowsLength] =
    useRecoilState(currentRowsLengthState);

  const order = useRecoilValue(tableOrder);
  const orderBy = useRecoilValue(tableOrderByState);

  //-----------------------------------------------------------------//

  //filter number every n th
  const every_nth = (arr: number[], nth: number) =>
    arr.filter((e: any, i: any) => i % nth === nth - 1);

  const handleChangePage = (event: unknown, newPage: number) => {
    let pageArr = [];
    let pageTotal = Math.floor(currentRowBodyData.length / 8);

    for (let index = 0; index < pageTotal; index++) {
      let pageNumber = index + 1;

      if (pageNumber > 20) {
        pageArr.push(pageNumber);
      }
    }

    let callApiPageArr = every_nth(pageArr, 10);
    let pageNumber = newPage - 1;
    let isLoadMoreRows = callApiPageArr.includes(pageNumber);

    if (isLoadMoreRows === true) {
      let indexOfPage = callApiPageArr.indexOf(pageNumber);
      let lengthPerLoad = tableConfig['lengthPerLoad'];
      let rowsLengthTotal = (indexOfPage + 1) * lengthPerLoad;

      onPaginationNextClick(
        currentSheetName,
        currentId['main_sheet_id'].toString(),
        rowsLengthTotal,
        currentSearchKeyword,
        '',
      );

      setCurrentRowsLength(rowsLengthTotal);

      // console.log(
      //   pageNumber +
      //     ' : ' +
      //     isLoadMoreRows +
      //     ' : ' +
      //     indexOfPage +
      //     ' : ' +
      //     rowsLengthTotal,
      // );
    }

    // else {
    //   console.log(pageNumber + ' : ' + isLoadMoreRows);
    // }

    setPage(newPage);
  };
  //---------------------------------------------------------------------
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - currentRowBodyData.length) : 0;

  //---------------------------------current table----------------------------------------//
  const currentOperationTable = useRecoilValue(currentOperationTableState);
  const [isLoadedRows, setIsLoadedRows] = useRecoilState(isLoadedMasterRowsState);
  const [operationRows, setOperationRows] = useRecoilState(operationRowsState);
  const [operationHeaders, setOperationHeaders] = useRecoilState(operationHeadersState);



  //?------------------------------ Modal ----------------------------------//

  const [openMainDetailModal, setOpenMainDetailModal] =
    useRecoilState(isMainDetailModal);
  const setOpenDetailModal = useSetRecoilState(isDetailModal);
  const setOpenYesNoModal = useSetRecoilState(isYesNoModal);
  const setOpenCreateModal = useSetRecoilState(isCreateModal);

  //?----------------------------------Current -----------------------------------//
  //---------------------------------Search Bar State ---------------------------------------------//
  const currentSearchKeyword = useRecoilValue(currentSearchKeywordState);

  const setCurrentSubSheetapprovalJSON = useSetRecoilState(
    currentSubSheetapprovalJSONState,
  );

  const setFundRows = useSetRecoilState(fundRowsState);
  const setCompanyRows = useSetRecoilState(companyRowsState);
  //----------------------------------------------------------------------------------//
  const setCurrentModalHeader = useSetRecoilState(currentModalHeaderState);
  const [currentId, setCurrentId] = useRecoilState(currentIdState);
  const setCurrentapprovalJSON = useSetRecoilState(currentapprovalJSONState);

  const setCurrentCaseName = useSetRecoilState(currentCaseNameState);
  const [currentSheetName, setCurrentSheetName] = useRecoilState(currentSheetNameState);
  const setCurrentInputJSON = useSetRecoilState(currentInputJSONState);
  const [isUpdateInputJSON, setIsUpdateInputJSON] =
    useRecoilState(isUpdateInputJSONState);

  const setCurrentCreateModalMode = useSetRecoilState(currentCreateModalModeState);

  const setCurrentSheetType = useSetRecoilState(currentSheetTypeState);

  const setCurrentIsMainApproved = useSetRecoilState(currentIsMainApprovedState);

  //--------------------------------Data ----------------------------------//

  // Company --------------
  const setCurrentInvCompDefinitive = useSetRecoilState(currentInvCompDefinitiveState);
  //Fund --------------
  const setCurrentInvFundDefinitive = useSetRecoilState(currentInvFundDefinitiveState);
  //Operation ----------------
  const setCurrentInvBankAccount = useSetRecoilState(currentInvBankAccountState);
  const setCurrentInvBankCapital = useSetRecoilState(currentInvBankCapitalState);
  const setCurrentInvCertificate = useSetRecoilState(currentInvCertificateState);
  const setCurrentInvCompAdjustEquity = useSetRecoilState(
    currentInvCompAdjustEquityState,
  );
  const setCurrentInvCompAdjustPar = useSetRecoilState(currentInvCompAdjustParState);
  const setCurrentInvCompBot = useSetRecoilState(currentInvCompBotState);
  const setCurrentInvCompCashFlow = useSetRecoilState(currentInvCompCashFlowState);
  const setCurrentInvCompDirector = useSetRecoilState(currentInvCompDirectorState);
  const setCurrentInvCompDividend = useSetRecoilState(currentInvCompDividendState);
  const setCurrentInvCompFair = useSetRecoilState(currentInvCompFairState);
  const setCurrentInvCompWarrant = useSetRecoilState(currentInvCompWarrantState);
  const setCurrentInvFundCashflow = useSetRecoilState(currentInvFundCashflowState);

  //?---------------------------- Boolean ---------------------------------------//
  const setGenerateComplete = useSetRecoilState(isGenerateCompleteState);
  const setIsFirstReset = useSetRecoilState(isFirstResetState);

  //?------------------------------Input JSON ----------------------------------//
  //Mian Comp InputJSON
  const invcompdefinitiveData = useRecoilValue(invcompdefinitive);
  //Main Fund InputJSON
  const invfunddefinitiveData = useRecoilValue(invfunddefinitive);
  //Operation InputJSON
  const invfundcashflowData = useRecoilValue(invfundcashflow);
  const invbankaccountData = useRecoilValue(invbankaccount);
  const invbankcapitalData = useRecoilValue(invbankcapital);
  const invcompcashflowData = useRecoilValue(invcompcashflow);
  const invcompdividendData = useRecoilValue(invcompdividend);
  const invcompfairData = useRecoilValue(invcompfair);
  const invcompadjustparData = useRecoilValue(invcompadjustpar);
  const invcompadjustequityData = useRecoilValue(invcompadjustequity);
  const invcompwarrantData = useRecoilValue(invcompwarrant);
  const invcompbotData = useRecoilValue(invcompbot);
  const invcertificateData = useRecoilValue(invcertificate);
  const invcompdirectorData = useRecoilValue(invcompdirector);

  //------------------------------------------------------------------------//

  let currentValue = (_currentSheetName: string) => {
    // let currentTableData = {
    //   _currentInputJSON: {},
    //   currentHeaders: {},
    // };

    let _currentInputJSON = {};
    switch (_currentSheetName) {
      //--------------------------------- */
      case 'invcompdefinitive':
        return (_currentInputJSON = invcompdefinitiveData);

      //--------------------------------- */
      case 'invfunddefinitive':
        return (_currentInputJSON = invfunddefinitiveData);

      //--------------------------------- */
      case 'invfundcashflow':
        return (_currentInputJSON = invfundcashflowData);

      //--------------------------------- */
      case 'invbankaccount':
        return (_currentInputJSON = invbankaccountData);

      //--------------------------------- */
      case 'invbankcapital':
        return (_currentInputJSON = invbankcapitalData);

      //--------------------------------- */
      case 'invcompcashflow':
        return (_currentInputJSON = invcompcashflowData);

      //--------------------------------- */
      case 'invcompdividend':
        return (_currentInputJSON = invcompdividendData);

      //--------------------------------- */
      case 'invcompfair':
        return (_currentInputJSON = invcompfairData);

      //--------------------------------- */
      case 'invcompadjustpar':
        return (_currentInputJSON = invcompadjustparData);

      //--------------------------------- */
      case 'invcompadjustequity':
        return (_currentInputJSON = invcompadjustequityData);

      //--------------------------------- */
      case 'invcompwarrant':
        return (_currentInputJSON = invcompwarrantData);

      //--------------------------------- */
      case 'invcompbot':
        return (_currentInputJSON = invcompbotData);

      //--------------------------------- */
      case 'invcertificate':
        return (_currentInputJSON = invcertificateData);

      //--------------------------------- */
      case 'invcompdirector':
        return (_currentInputJSON = invcompdirectorData);

      //************** NotFound ********************* */
      //------------------------------------//
      default:
        return (_currentInputJSON = {});
    }
  };

  if (isUpdateInputJSON === true && openMainDetailModal === false) {
    let _currentInputJSON = currentValue(currentSheetName);
    setCurrentInputJSON(_currentInputJSON);
    setIsUpdateInputJSON(false);
  }

  const [currentIsApproved, setCurrentIsApproved] =
    useRecoilState(currentIsApprovedState);

  //--------------------------------------------------------------------//
 

  //------------------------------------------------------------//
  //!-------------------- API:  handle  Detail Modal Open------------------------------------
  const handleMainDetailModalOpen =
    (id: string, sheet_id: string, name: string, approvalStatusArr: object) => () => {
      let requestDetail: requestDetailInterface = {
        sheet_id: sheet_id.toString(),
      };

      const approvalArr: { [key: string]: any } = approvalStatusArr;

      if (portalMode === 'company_profile') {
        // -----------------------------------------------------------------------

        const getCompanyDetail = async () => {
          const response: { [key: string]: any } = await getDetailCompany(requestDetail);

          // console.log(response);

          const approval_status =
            response['data']['edition']['approval_status']['company'];

            // console.log(response['data']['edition']['approval_status']);

          //TODO ----------- Merge Later-------------------------//

          if (approval_status === 'PENDING') {
            setCurrentIsApproved(false);
          } else if (approval_status === 'APPROVED') {
            setCurrentIsApproved(true);
          }

          if (approval_status === 'PENDING') {
            setCurrentIsMainApproved(false);
          } else if (approval_status === 'APPROVED') {
            setCurrentIsMainApproved(true);
          }

          //TODO ----------- Merge Later-------------------------//

          setCurrentapprovalJSON(response['data']['edition']);

          setCurrentModalHeader(name);
          setCurrentId({
            main_name: response['data']['sheet_data']['company_name_en'],
            main_id_type: 'company_id',
            main_id_value: id,
            main_sheet_id: sheet_id.toString(),
            sub_name: '',
            sub_id_type: '',
            sub_id_value: '',
            sub_sheet_id: '',
          });

          let _currentInputJSON = currentValue('invcompdefinitive');

          setCurrentSheetName('invcompdefinitive');
          setCurrentSheetType('primary');
          setCurrentInputJSON(_currentInputJSON);
          setCurrentInvCompDefinitive(response['data']['sheet_data']);

          setCurrentSubSheetapprovalJSON(response['data']['edition']['approval_status']);

          setOpenMainDetailModal(true);
        };

        getCompanyDetail();

        // -----------------------------------------------------------------------
      } else if (portalMode === 'fund_profile') {
        // -----------------------------------------------------------------------

        const getFundDetail = async () => {
          const response: { [key: string]: any } = await getDetailFund(requestDetail);

          const approval_status = response['data']['edition']['approval_status']['fund'];

          //TODO ----------- Merge Later-------------------------//
          if (approval_status === 'PENDING') {
            setCurrentIsApproved(false);
          } else if (approval_status === 'APPROVED') {
            setCurrentIsApproved(true);
          }

          if (approval_status === 'PENDING') {
            setCurrentIsMainApproved(false);
          } else if (approval_status === 'APPROVED') {
            setCurrentIsMainApproved(true);
          }
          //TODO ----------- Merge Later-------------------------//

          setCurrentapprovalJSON(response['data']['edition']);
          setCurrentModalHeader(name);
          setCurrentId({
            main_name: response['data']['sheet_data']['fund_quote'],
            main_id_type: 'fund_id',
            main_id_value: id,
            main_sheet_id: sheet_id.toString(),
            sub_name: '',
            sub_id_type: '',
            sub_id_value: '',
            sub_sheet_id: '',
          });

          let _currentInputJSON = currentValue('invfunddefinitive');
          setCurrentSheetName('invfunddefinitive');
          setCurrentSheetType('primary');
          setCurrentInputJSON(_currentInputJSON);
          setCurrentInvFundDefinitive(response['data']['sheet_data']);
          setCurrentSubSheetapprovalJSON(response['data']['edition']['approval_status']);
          setOpenMainDetailModal(true);
        };

        getFundDetail();

        // -----------------------------------------------------------------------
      } else if (portalMode === 'operation') {
        // -----------------------------------------------------------------------
        const getOperationDetail = async () => {
          // console.log(sheet_id);
          let response: { [key: string]: any } = {};

          switch (currentSheetName) {
            //?-------------------- Operation -------------------- */

            //-------------- 01 ------------------ */

            case 'invbankaccount':
              response = await getDetailAccount(requestDetail);

              // console.log(response['data']);

              setCurrentapprovalJSON(response['data']['edition']);
              setCurrentModalHeader(name);
              setCurrentId({
                main_name: '',
                main_id_type: 'txn_id',
                main_id_value: id,
                main_sheet_id: sheet_id.toString(),
                sub_name: '',
                sub_id_type: '',
                sub_id_value: '',
                sub_sheet_id: '',
              });
              setCurrentInvBankAccount(response['data']['sheet_data']);

              break;

            //--------------- 02 ------------------ */

            case 'invbankcapital':
              response = await getDetailCapital(requestDetail);

              setCurrentapprovalJSON(response['data']['edition']);
              setCurrentModalHeader(name);
              setCurrentId({
                main_name: '',
                main_id_type: 'txn_id',
                main_id_value: id,
                main_sheet_id: sheet_id.toString(),
                sub_name: '',
                sub_id_type: '',
                sub_id_value: '',
                sub_sheet_id: '',
              });
              setCurrentInvBankCapital(response['data']['sheet_data']);

              break;

            //---------------- 03 ----------------- */
            case 'invcertificate':
              response = await getDetailCertificate(requestDetail);

              setCurrentapprovalJSON(response['data']['edition']);
              setCurrentModalHeader(name);
              setCurrentId({
                main_name: '',
                main_id_type: 'txn_id',
                main_id_value: id,
                main_sheet_id: sheet_id.toString(),
                sub_name: '',
                sub_id_type: '',
                sub_id_value: '',
                sub_sheet_id: '',
              });
              setCurrentInvCertificate(response['data']['sheet_data']);
              break;

            //---------------- 04 ----------------- */

            case 'invcompadjustequity':
              response = await getDetailAdjEquity(requestDetail);

              setCurrentapprovalJSON(response['data']['edition']);
              setCurrentModalHeader(name);
              setCurrentId({
                main_name: '',
                main_id_type: 'txn_id',
                main_id_value: id,
                main_sheet_id: sheet_id.toString(),
                sub_name: '',
                sub_id_type: '',
                sub_id_value: '',
                sub_sheet_id: '',
              });
              setCurrentInvCompAdjustEquity(response['data']['sheet_data']);
              break;
            //----------------- 05 ---------------- */

            case 'invcompadjustpar':
              response = await getDetailAdjPar(requestDetail);

              setCurrentapprovalJSON(response['data']['edition']);
              setCurrentModalHeader(name);
              setCurrentId({
                main_name: '',
                main_id_type: 'txn_id',
                main_id_value: id,
                main_sheet_id: sheet_id.toString(),
                sub_name: '',
                sub_id_type: '',
                sub_id_value: '',
                sub_sheet_id: '',
              });
              setCurrentInvCompAdjustPar(response['data']['sheet_data']);
              break;

            //---------------- 06 ----------------- */

            case 'invcompbot':
              response = await getDetailBot(requestDetail);

              setCurrentapprovalJSON(response['data']['edition']);
              setCurrentModalHeader(name);
              setCurrentId({
                main_name: '',
                main_id_type: 'txn_id',
                main_id_value: id,
                main_sheet_id: sheet_id.toString(),
                sub_name: '',
                sub_id_type: '',
                sub_id_value: '',
                sub_sheet_id: '',
              });
              setCurrentInvCompBot(response['data']['sheet_data']);
              break;

            //--------------- 07 ------------------ */

            case 'invcompcashflow':
              response = await getDetailCompCashFlow(requestDetail);

              setCurrentapprovalJSON(response['data']['edition']);
              setCurrentModalHeader(name);
              setCurrentId({
                main_name: '',
                main_id_type: 'txn_id',
                main_id_value: id,
                main_sheet_id: sheet_id.toString(),
                sub_name: '',
                sub_id_type: '',
                sub_id_value: '',
                sub_sheet_id: '',
              });
              setCurrentInvCompCashFlow(response['data']['sheet_data']);
              break;

            //------------- 08 -------------------- */
            case 'invcompdirector':
              response = await getDetailDirector(requestDetail);

              setCurrentapprovalJSON(response['data']['edition']);
              setCurrentModalHeader(name);
              setCurrentId({
                main_name: '',
                main_id_type: 'txn_id',
                main_id_value: id,
                main_sheet_id: sheet_id.toString(),
                sub_name: '',
                sub_id_type: '',
                sub_id_value: '',
                sub_sheet_id: '',
              });
              setCurrentInvCompDirector(response['data']['sheet_data']);
              break;

            //---------------- 09 ----------------- */

            case 'invcompdividend':
              response = await getDetailDividend(requestDetail);

              setCurrentapprovalJSON(response['data']['edition']);
              setCurrentModalHeader(name);
              setCurrentId({
                main_name: '',
                main_id_type: 'txn_id',
                main_id_value: id,
                main_sheet_id: sheet_id.toString(),
                sub_name: '',
                sub_id_type: '',
                sub_id_value: '',
                sub_sheet_id: '',
              });
              setCurrentInvCompDividend(response['data']['sheet_data']);
              break;

            //--------------- 10 ------------------ */

            case 'invcompfair':
              response = await getDetailCompFair(requestDetail);

              setCurrentapprovalJSON(response['data']['edition']);
              setCurrentModalHeader(name);
              setCurrentId({
                main_name: '',
                main_id_type: 'txn_id',
                main_id_value: id,
                main_sheet_id: sheet_id.toString(),
                sub_name: '',
                sub_id_type: '',
                sub_id_value: '',
                sub_sheet_id: '',
              });
              setCurrentInvCompFair(response['data']['sheet_data']);
              break;

            //---------------- 11 ----------------- */

            case 'invcompwarrant':
              response = await getDetailWarrant(requestDetail);

              setCurrentapprovalJSON(response['data']['edition']);
              setCurrentModalHeader(name);
              setCurrentId({
                main_name: '',
                main_id_type: 'txn_id',
                main_id_value: id,
                main_sheet_id: sheet_id.toString(),
                sub_name: '',
                sub_id_type: '',
                sub_id_value: '',
                sub_sheet_id: '',
              });
              setCurrentInvCompWarrant(response['data']['sheet_data']);
              break;

            //---------------- 12 ----------------- */

            case 'invfundcashflow':
              response = await getDetailFundCashFlow(requestDetail);

              setCurrentapprovalJSON(response['data']['edition']);
              setCurrentModalHeader(name);
              setCurrentId({
                main_name: '',
                main_id_type: 'txn_id',
                main_id_value: id,
                main_sheet_id: sheet_id.toString(),
                sub_name: '',
                sub_id_type: '',
                sub_id_value: '',
                sub_sheet_id: '',
              });
              setCurrentInvFundCashflow(response['data']['sheet_data']);
              break;

            //************** NotFound ********************* */
            //------------------------------------//
            default:
              break;
          }

          //---------------------------------------------------------//
          let _currentInputJSON = currentValue(currentSheetName);
          setCurrentSheetName(currentSheetName);
          setCurrentInputJSON(_currentInputJSON);
          setCurrentSheetType('transaction');
          setOpenDetailModal(true);
          // -----------------------------------------------------------------------
        };
        getOperationDetail();
      }
    };

  //---------------------------------------------------------
  const handleApproval = () => {
    setCurrentCaseName('approve');
    setOpenYesNoModal(true);
  };

  //---------------------------------------------------

  const handleCreateModalOpen = () => {
    setCurrentCreateModalMode('create');
    setGenerateComplete(false);
    if (portalMode === 'company_profile') {
      setCurrentSheetName('invcompdefinitive');
    } else if (portalMode === 'fund_profile') {
      setCurrentSheetName('invfunddefinitive');
    } else if (portalMode === 'operation') {
      setCurrentSheetName(currentOperationTable);
    } else {
    }
    setCurrentCaseName('create');
    setIsUpdateInputJSON(true);
    setIsFirstReset(true);
    setOpenCreateModal(true);
  };

  //--------------------------------------------------------

  const handleSelectedDropDownList = (fieldVal: string) => {
    //-------------- For Operation Only ------------//
    let rowsData: any[] = [];

    switch (fieldVal) {
      //?-------------------- Common -------------------- */

      //----------------- 01 ---------------- */
      // case 'invcontact':
      //   break;
      //---------------- 02 ----------------- */
      // case 'invboardapprovedmemo':
      //   break;

      //?-------------------- Company -------------------- */
      //---------------- 01 ----------------- */
      // case 'invcompdefinitive':
      //   break;
      //---------------- 02 ----------------- */
      // case 'invcompproduct':
      //   break;
      //------------------ 03 --------------- */
      // case 'invcompfs':
      //   break;
      //--------------------------------- */

      //?-------------------- Fund -------------------- */
      //----------------- 01 ---------------- */
      // case 'invfunddefinitive':
      //   break;
      //----------------- 02 ---------------- */
      // case 'invfundnav':
      //   break;
      //----------------- 03 ---------------- */
      // case 'invfundportfolio':
      //   break;
      //----------------- 04 ---------------- */
      // case 'invfundbenchmark':
      //   break;
      //---------------- 05 ----------------- */
      // case 'invfundcommitmentandwatermark':
      //   break;

      //?-------------------- Operation -------------------- */

      //-------------- 01 ------------------ */

      case 'invbankaccount':
        const getAccountRows = async () => {
          const requestAccountMain: requestAccountMainInterface = {
            page: 1,
            page_length: tableConfig['defaultRowsLength'],
            sort_by: '',
            order: OrderBy.asc,
            filter: '',
          };

          const getAccountData: { [key: string]: any } = await getAccount(
            requestAccountMain,
          );

          Object.keys(getAccountData['data']).map((index: string) => {
            let arr = getAccountData['data'][index];

            let sheet_id = arr['sheet_id'];
            // let bi_id = 'non';

            let id = arr['sheet_data']['id'];
            let name = arr['sheet_data']['name'];
            let date = arr['sheet_data']['date'];
            let account_no = arr['sheet_data']['account_no'];
            let account_name = arr['sheet_data']['account_name'];
            let account_bank_name = arr['sheet_data']['account_bank_name'];
            let approval = arr['approval_status'];

            let member = createInvBankAccountBodyTable(
              sheet_id,
              id,
              name,
              date,
              account_no,
              account_name,
              account_bank_name,
              approval,

              'detail button',
            );
            rowsData.push(member);
          });

          // console.log(getAccountData);
          setOperationRows(rowsData);
          setIsLoadedRows(true);
        };

        getAccountRows();

        break;

      //--------------- 02 ------------------ */

      case 'invbankcapital':
        const getCapitalRows = async () => {
          const requestCapitalMain: requestCapitalMainInterface = {
            page: 1,
            page_length: tableConfig['defaultRowsLength'],
            sort_by: '',
            order: OrderBy.asc,
            filter: '',
          };

          const getCapitalData: { [key: string]: any } = await getCapital(
            requestCapitalMain,
          );

          Object.keys(getCapitalData['data']).map((index: string) => {
            let arr = getCapitalData['data'][index];

            let sheet_id = arr['sheet_id'];
            // let bi_id = 'non';
            // let id = arr['id'];

            let data_date = arr['data_date'];
            let cap_status = arr['status'];
            let tier_1_capital = arr['tier_1_capital'];
            let tier_2_capital = arr['tier_2_capital'];
            //TODO Change to Number to Calcurate
            let total = tier_1_capital + tier_2_capital;
            let approval = arr['approval_status'];

            let member = createInvBankCapitalBodyTable(
              sheet_id,
              data_date,
              cap_status,
              tier_1_capital,
              tier_2_capital,
              total,
              approval,
              'detail button',
            );
            rowsData.push(member);
          });

          // console.log(getCapitalData);
          setOperationRows(rowsData);
          setIsLoadedRows(true);
        };
        getCapitalRows();

        break;

      //---------------- 03 ----------------- */
      case 'invcertificate':
        const getCertificateRows = async () => {
          const requestCertificateMain: requestCertificateMainInterface = {
            page: 1,
            page_length: tableConfig['defaultRowsLength'],
            sort_by: '',
            order: OrderBy.asc,
            filter: '',
          };

          const getCertificateData: { [key: string]: any } = await getCertificate(
            requestCertificateMain,
          );

          Object.keys(getCertificateData['data']).map((index: string) => {
            let arr = getCertificateData['data'][index];

            let sheet_id = arr['sheet_id'];
            // let bi_id = 'non';
            let id = arr['id'];

            let name = arr['name'];
            let certificate_no = arr['certificate_no'];
            let currency = arr['currency'];
            let no_of_share = arr['no_of_share'];
            let book_value = arr['book_value'];
            let custodian = arr['custodian'];
            let approval = arr['approval_status'];

            let member = createInvCertificateBodyTable(
              sheet_id,
              id,
              name,
              certificate_no,
              currency,
              no_of_share,
              book_value,
              custodian,
              approval,

              'detail button',
            );
            rowsData.push(member);
          });

          // console.log(getCertificateData);
          setOperationRows(rowsData);
          setIsLoadedRows(true);
        };
        getCertificateRows();

        break;

      //---------------- 04 ----------------- */

      case 'invcompadjustequity':
        const getAdjEquityRows = async () => {
          const requestAdjEquityMain: requestAdjEquityMainInterface = {
            page: 1,
            page_length: tableConfig['defaultRowsLength'],
            sort_by: '',
            order: OrderBy.asc,
            filter: '',
          };

          const getAdjEquityData: { [key: string]: any } = await getAdjEquity(
            requestAdjEquityMain,
          );
          Object.keys(getAdjEquityData['data']).map((index: string) => {
            let arr = getAdjEquityData['data'][index];

            let sheet_id = arr['sheet_id'];
            let bi_id = 'non';
            let company_id = arr['company_id'];
            let product_name = arr['product_name'];
            let txn_date = arr['txn_date'];
            let investment_port = arr['investment_port'];
            let no_of_share = arr['no_of_share'];
            let allowance_permanent_thb = arr['allowance_permanent_thb'];
            let total_adjusted_equity = arr['total_adjusted_equity'];
            let adjusted_equity_posttransaction = arr['adjusted_equity_posttransaction'];
            let approval = arr['approval_status'];
            

            let member = createInvCompAdjustEquityBodyTable(
              sheet_id,
              bi_id,
              company_id,
              product_name,
              txn_date,
              investment_port,
              no_of_share,
              allowance_permanent_thb,
              total_adjusted_equity,
              adjusted_equity_posttransaction,
              approval,
              'detail button',
            );
            rowsData.push(member);
          });

          // console.log(getAdjEquityData);
          setOperationRows(rowsData);
          setIsLoadedRows(true);
        };
        getAdjEquityRows();

        break;
      //----------------- 05 ---------------- */

      case 'invcompadjustpar':
        const getAdjParRows = async () => {
          const requestAdjParMain: requestAdjParMainInterface = {
            page: 1,
            page_length: tableConfig['defaultRowsLength'],
            sort_by: '',
            order: OrderBy.asc,
            filter: '',
          };

          const getAdjParData: { [key: string]: any } = await getAdjPar(
            requestAdjParMain,
          );

          Object.keys(getAdjParData['data']).map((index: string) => {
            let arr = getAdjParData['data'][index];

            let sheet_id = arr['sheet_id'];
            let bi_id = 'non';
            let company_id = arr['company_id'];
            let product_name = arr['product_name'];
            let txn_date = arr['txn_date'];
            let adjusted_par_method = arr['adjusted_par_method'];
            let no_of_share_posttransaction = arr['no_of_share_posttransaction'];
            let investment_cost = arr['investment_cost'];
            let par_value_posttransaction = arr['par_value_posttransaction'];
            let approval = arr['approval_status'];

            let member = createInvCompAdjustParBodyTable(
              sheet_id,
              bi_id,
              company_id,
              product_name,
              txn_date,
              adjusted_par_method,
              no_of_share_posttransaction,
              investment_cost,
              par_value_posttransaction,
              approval,
              'detail button',
            );
            rowsData.push(member);
          });

          // console.log(getAdjParData);
          setOperationRows(rowsData);
          setIsLoadedRows(true);
        };
        getAdjParRows();

        break;

      //---------------- 06 ----------------- */

      case 'invcompbot':
        const getBotRows = async () => {
          const requestBotMain: requestBotMainInterface = {
            page: 1,
            page_length: tableConfig['defaultRowsLength'],
            sort_by: '',
            order: OrderBy.asc,
            filter: '',
          };

          const getBotData: { [key: string]: any } = await getBot(requestBotMain);

          Object.keys(getBotData['data']).map((index: string) => {
            let arr = getBotData['data'][index];

            let sheet_id = arr['sheet_id'];
            let bi_id = 'non';
            let company_id = arr['company_id'];
            let recording_date = arr['recording_date'];
            let document_no = arr['document_no'];
            let request_type = arr['request_type'];
            let document_start_date = arr['document_start_date'];
            let document_end_date = arr['document_end_date'];
            let approval = arr['approval_status'];

            let member = createInvCompBotBodyTable(
              sheet_id,
              company_id,
              bi_id,
              recording_date,
              document_no,
              request_type,
              document_start_date,
              document_end_date,
              approval,
              'detail button',
            );
            rowsData.push(member);
          });

          // console.log(getBotData);
          setOperationRows(rowsData);
          setIsLoadedRows(true);
        };
        getBotRows();

        break;

      //--------------- 07 ------------------ */

      case 'invcompcashflow':
        const getCompCashFlowRows = async () => {
          const requestCompCashFlowMain: requestCompCashFlowMainInterface = {
            page: 1,
            page_length: tableConfig['defaultRowsLength'],
            sort_by: '',
            order: OrderBy.asc,
            filter: '',
          };

          const getCompCashFlowData: { [key: string]: any } = await getCompCashFlow(
            requestCompCashFlowMain,
          );

          Object.keys(getCompCashFlowData['data']).map((index: string) => {
            let arr = getCompCashFlowData['data'][index];

            let sheet_id = arr['sheet_id'];
            let bi_id = 'non';

            let company_id = arr['company_id'];
            let company_name = arr['company_name'];
            let txn_date = arr['txn_date'];
            let txn_type = arr['txn_type'];
            let currency = arr['currency'];
            let no_of_share = arr['no_of_share'];
            let price_per_share = arr['price_per_share'];
            let net_cashflow = arr['net_cashflow'];
            let approval = arr['approval_status'];

            let member = createInvCompCashFlowBodyTable(
              sheet_id,
              bi_id,
              company_id,
              company_name,
              txn_date,
              txn_type,
              currency,
              no_of_share,
              price_per_share,
              net_cashflow,
              approval,
              'detail button',
            );
            rowsData.push(member);
          });

          // console.log(getCompCashFlowData);
          setOperationRows(rowsData);
          setIsLoadedRows(true);
        };
        getCompCashFlowRows();

        break;

      //------------- 08 -------------------- */
      case 'invcompdirector':
        const getDirectorRows = async () => {
          const requestDirectorMain: requestDirectorMainInterface = {
            page: 1,
            page_length: tableConfig['defaultRowsLength'],
            sort_by: '',
            order: OrderBy.asc,
            filter: '',
          };

          const getDirectorData: { [key: string]: any } = await getDirector(
            requestDirectorMain,
          );

          Object.keys(getDirectorData['data']).map((index: string) => {
            let arr = getDirectorData['data'][index];

            let sheet_id = arr['sheet_id'];
            let bi_id = 'non';

            let company_id = arr['sheet_data']['company_id'];
            let committee_name = arr['sheet_data']['committee_name'];
            let bbl_representative = arr['sheet_data']['bbl_representative'];
            let bbl_representative_internalposition =
              arr['sheet_data']['bbl_representative_internalposition'];
            let approval = arr['approval_status'];

            let bbl_representative_text = '';

            if (bbl_representative === true) {
              bbl_representative_text = 'Yes';
            } else {
              bbl_representative_text = 'No';
            }

            let member = createInvCompDirectorBodyTable(
              sheet_id,
              bi_id,
              company_id,
              committee_name,
              bbl_representative_text,
              bbl_representative_internalposition,
              approval,
              'detail button',
            );
            rowsData.push(member);
          });

          // console.log(getDirectorData);
          setOperationRows(rowsData);
          setIsLoadedRows(true);
        };
        getDirectorRows();

        break;

      //---------------- 09 ----------------- */

      case 'invcompdividend':
        const getDividendRows = async () => {
          const requestDividendMain: requestDividendMainInterface = {
            page: 1,
            page_length: tableConfig['defaultRowsLength'],
            sort_by: '',
            order: OrderBy.asc,
            filter: '',
          };

          const getDividendData: { [key: string]: any } = await getDividend(
            requestDividendMain,
          );

          Object.keys(getDividendData['data']).map((index: string) => {
            let arr = getDividendData['data'][index];

            let sheet_id = arr['sheet_id'];
            // let bi_id = 'non';
            let company_id = arr['company_id'];
            let company_name = arr['company_name'];
            let product_name = arr['product_name'];
            let data_date = arr['data_date'];
            let currency = arr['currency'];
            let no_of_shares = arr['no_of_shares'];
            let dividend = arr['dividend'];
            let dividend_amount = arr['dividend_amount'];
            let approval = arr['approval_status'];

            let member = createInvCompDividendBodyTable(
              sheet_id,
              company_id,
              company_name,
              product_name,
              data_date,
              currency,
              no_of_shares,
              dividend,
              dividend_amount,
              approval,
              'detail button',
            );
            rowsData.push(member);
          });

          // console.log(getDividendData);
          setOperationRows(rowsData);
          setIsLoadedRows(true);
        };
        getDividendRows();

        break;

      //--------------- 10 ------------------ */

      case 'invcompfair':
        const getCompFairRows = async () => {
          const requestCompFairMain: requestCompFairMainInterface = {
            page: 1,
            page_length: tableConfig['defaultRowsLength'],
            sort_by: '',
            order: OrderBy.asc,
            filter: '',
          };

          const getCompFairData: { [key: string]: any } = await getCompFair(
            requestCompFairMain,
          );

          Object.keys(getCompFairData['data']).map((index: string) => {
            let arr = getCompFairData['data'][index];

            let sheet_id = arr['sheet_id'];
            // let bi_id = 'non';
            let company_id = arr['company_id'];
            let company_name = arr['company_name'];
            let data_date = arr['data_date'];
            let wacc = arr['wacc'];
            let terminal_growth = arr['terminal_growth'];
            let fair_value = arr['fair_value'];
            let approval = arr['approval_status'];

            let member = createInvCompFairBodyTable(
              sheet_id,
              company_id,
              company_name,
              data_date,
              wacc,
              terminal_growth,
              fair_value,
              approval,

              'detail button',
            );
            rowsData.push(member);
          });

          // console.log(getCompFairData);
          setOperationRows(rowsData);
          setIsLoadedRows(true);
        };
        getCompFairRows();

        break;

      //---------------- 11 ----------------- */

      case 'invcompwarrant':
        const getWarrantRows = async () => {
          const requestWarrantMain: requestWarrantMainInterface = {
            page: 1,
            page_length: tableConfig['defaultRowsLength'],
            sort_by: '',
            order: OrderBy.asc,
            filter: '',
          };

          const getWarrantData: { [key: string]: any } = await getWarrant(
            requestWarrantMain,
          );

          Object.keys(getWarrantData['data']).map((index: string) => {
            let arr = getWarrantData['data'][index];

            let sheet_id = arr['sheet_id'];
            let bi_id = 'non';
            let company_id = arr['company_id'];
            let product_name = arr['product_name'];
            let txn_date = arr['txn_date'];
            let warrant_name = arr['warrant_name'];
            let warrant_type = arr['warrant_type'];
            let no_of_share_pretransaction = arr['no_of_share_pretransaction'];
            let approval = arr['approval_status'];

            let member = createInvCompWarrantBodyTable(
              sheet_id,
              bi_id,
              company_id,
              product_name,
              txn_date,
              warrant_name,
              warrant_type,
              no_of_share_pretransaction,
              approval,
              'detail button',
            );

            rowsData.push(member);
          });

          // console.log(getWarrantData);
          setOperationRows(rowsData);
          setIsLoadedRows(true);
        };
        getWarrantRows();

        break;

      //---------------- 12 ----------------- */

      case 'invfundcashflow':
        const getFundCashFlowRows = async () => {
          const requestFundCashFlowMain: requestFundCashFlowMainInterface = {
            page: 1,
            page_length: tableConfig['defaultRowsLength'],
            sort_by: '',
            order: OrderBy.asc,
            filter: '',
          };

          const getFundCashFlowData: { [key: string]: any } = await getFundCashFlow(
            requestFundCashFlowMain,
          );

          Object.keys(getFundCashFlowData['data']).map((index: string) => {
            let arr = getFundCashFlowData['data'][index];

            let sheet_id = arr['sheet_id'];
            // let bi_id = 'non';

            let fund_id = arr['fund_id'];
            let fund_name = arr['fund_name'];
            let txn_date = arr['txn_date'];
            let cashflow_type = arr['cashflow_type'];
            let currency = arr['currency'];
            let cashflow_amount = arr['cashflow_amount'];
            let current_stake = arr['current_stake'];
            let approval = arr['approval_status'];

            let member = createInvFundCashflowBodyTable(
              sheet_id,
              fund_id,
              fund_name,
              txn_date,
              cashflow_type,
              currency,
              cashflow_amount,
              current_stake,
              approval,

              'detail button',
            );

            rowsData.push(member);
          });

          // console.log(getFundCashFlowData);
          setOperationRows(rowsData);
          setIsLoadedRows(true);
        };
        getFundCashFlowRows();

        break;

      //************** NotFound ********************* */
      //------------------------------------//
      default:
        break;
    }
  };

  const handleOnSearchClick = async () => {
    console.log(currentSearchKeyword);
    let rowsData: any[] = [];


    switch (portalMode) {
      case 'company_profile':
        const requestCompanyMain: requestCompanyMainInterface = {
          page: 1,
          page_length: tableConfig['defaultRowsLength'],
          sort_by: '',
          order: OrderBy.asc,
          filter: currentSearchKeyword,
        };

        const getCompanyData: { [key: string]: any } = await getCompany(
          requestCompanyMain,
        );

        // console.log(getCompanyData);
        Object.keys(getCompanyData['data']).map((index: string) => {
          let sheet_id = getCompanyData['data'][index]['sheet_id'];
          let bi_id = getCompanyData['data'][index]['bi_id'];
          let company_id = getCompanyData['data'][index]['company_id'];
          let company_name_en = getCompanyData['data'][index]['company_name_en'];
          let company_name_th = getCompanyData['data'][index]['company_name_th'];
          let approval_status = getCompanyData['data'][index]['approval_status'];

          let member = createCompanyTableBodyData(
            sheet_id,
            bi_id,
            company_id,
            company_name_en,
            approval_status,
            'detail button',
          );

          rowsData.push(member);
        });
        setCompanyRows(rowsData);
        setIsLoadedRows(true);
        break;
      //------------------------------------//
      case 'fund_profile':
        const requestFundMain: requestFundMainInterface = {
          page: 1,
          page_length: tableConfig['defaultRowsLength'],
          sort_by: '',
          order: OrderBy.asc,
          filter: currentSearchKeyword,
        };

        const getFundData: { [key: string]: any } = await getFund(requestFundMain);

        // console.log(getFundData);
        Object.keys(getFundData['data']).map((index: string) => {
          let sheet_id = getFundData['data'][index]['sheet_id'];
          let bi_id = getFundData['data'][index]['bi_id'];
          let fund_id = getFundData['data'][index]['fund_id'];
          let fund_name_en = getFundData['data'][index]['fund_name'];
          let fund_name_th = getFundData['data'][index]['fund_thainame'];
          let approval_status = getFundData['data'][index]['approval_status'];

          let member = createFundTableBodyData(
            sheet_id,
            bi_id,
            fund_id,
            fund_name_en,
            approval_status,
            'detail button',
          );

          rowsData.push(member);
        });
        setFundRows(rowsData);
        setIsLoadedRows(true);
        break;
      //------------------------------------//
      case 'operation':
        switch (currentSheetName) {
        
    
          //?-------------------- Operation -------------------- */
    
          //-------------- 01 ------------------ */
    
          case 'invbankaccount':
            const getAccountRows = async () => {
              const requestAccountMain: requestAccountMainInterface = {
                page: 1,
                page_length: tableConfig['defaultRowsLength'],
                sort_by: '',
                order: OrderBy.asc,
                filter: currentSearchKeyword,
              };
    
              const getAccountData: { [key: string]: any } = await getAccount(
                requestAccountMain,
              );
    
              Object.keys(getAccountData['data']).map((index: string) => {
                let arr = getAccountData['data'][index];
    
                let sheet_id = arr['sheet_id'];
                // let bi_id = 'non';
    
                let id = arr['sheet_data']['id'];
                let name = arr['sheet_data']['name'];
                let date = arr['sheet_data']['date'];
                let account_no = arr['sheet_data']['account_no'];
                let account_name = arr['sheet_data']['account_name'];
                let account_bank_name = arr['sheet_data']['account_bank_name'];
                let approval = arr['approval_status'];
    
                let member = createInvBankAccountBodyTable(
                  sheet_id,
                  id,
                  name,
                  date,
                  account_no,
                  account_name,
                  account_bank_name,
                  approval,
    
                  'detail button',
                );
                rowsData.push(member);
              });
    
              // console.log(getAccountData);
              setOperationRows(rowsData);
              setIsLoadedRows(true);
            };
    
            getAccountRows();
    
            break;
    
          //--------------- 02 ------------------ */
    
          case 'invbankcapital':
            const getCapitalRows = async () => {
              const requestCapitalMain: requestCapitalMainInterface = {
                page: 1,
                page_length: tableConfig['defaultRowsLength'],
                sort_by: '',
                order: OrderBy.asc,
                filter: currentSearchKeyword,
              };
    
              const getCapitalData: { [key: string]: any } = await getCapital(
                requestCapitalMain,
              );
    
              Object.keys(getCapitalData['data']).map((index: string) => {
                let arr = getCapitalData['data'][index];
    
                let sheet_id = arr['sheet_id'];
                // let bi_id = 'non';
                // let id = arr['id'];
    
                let data_date = arr['data_date'];
                let cap_status = arr['status'];
                let tier_1_capital = arr['tier_1_capital'];
                let tier_2_capital = arr['tier_2_capital'];
                //TODO Change to Number to Calcurate
                let total = tier_1_capital + tier_2_capital;
                let approval = arr['approval_status'];
    
                let member = createInvBankCapitalBodyTable(
                  sheet_id,
                  data_date,
                  cap_status,
                  tier_1_capital,
                  tier_2_capital,
                  total,
                  approval,
                  'detail button',
                );
                rowsData.push(member);
              });
    
              // console.log(getCapitalData);
              setOperationRows(rowsData);
              setIsLoadedRows(true);
            };
            getCapitalRows();
    
            break;
    
          //---------------- 03 ----------------- */
          case 'invcertificate':
            const getCertificateRows = async () => {
              const requestCertificateMain: requestCertificateMainInterface = {
                page: 1,
                page_length: tableConfig['defaultRowsLength'],
                sort_by: '',
                order: OrderBy.asc,
                filter: currentSearchKeyword,
              };
    
              const getCertificateData: { [key: string]: any } = await getCertificate(
                requestCertificateMain,
              );
    
              Object.keys(getCertificateData['data']).map((index: string) => {
                let arr = getCertificateData['data'][index];
    
                let sheet_id = arr['sheet_id'];
                // let bi_id = 'non';
                let id = arr['id'];
    
                let name = arr['name'];
                let certificate_no = arr['certificate_no'];
                let currency = arr['currency'];
                let no_of_share = arr['no_of_share'];
                let book_value = arr['book_value'];
                let custodian = arr['custodian'];
                let approval = arr['approval_status'];
    
                let member = createInvCertificateBodyTable(
                  sheet_id,
                  id,
                  name,
                  certificate_no,
                  currency,
                  no_of_share,
                  book_value,
                  custodian,
                  approval,
    
                  'detail button',
                );
                rowsData.push(member);
              });
    
              // console.log(getCertificateData);
              setOperationRows(rowsData);
              setIsLoadedRows(true);
            };
            getCertificateRows();
    
            break;
    
          //---------------- 04 ----------------- */
    
          case 'invcompadjustequity':
            const getAdjEquityRows = async () => {
              const requestAdjEquityMain: requestAdjEquityMainInterface = {
                page: 1,
                page_length: tableConfig['defaultRowsLength'],
                sort_by: '',
                order: OrderBy.asc,
                filter: currentSearchKeyword,
              };
    
              const getAdjEquityData: { [key: string]: any } = await getAdjEquity(
                requestAdjEquityMain,
              );
              Object.keys(getAdjEquityData['data']).map((index: string) => {
                let arr = getAdjEquityData['data'][index];
    
                let sheet_id = arr['sheet_id'];
                let bi_id = 'non';
                let company_id = arr['company_id'];
                let product_name = arr['product_name'];
                let txn_date = arr['txn_date'];
                let investment_port = arr['investment_port'];
                let no_of_share = arr['no_of_share'];
                let allowance_permanent_thb = arr['allowance_permanent_thb'];
                let total_adjusted_equity = arr['total_adjusted_equity'];
                let adjusted_equity_posttransaction = arr['adjusted_equity_posttransaction'];
                let approval = arr['approval_status'];
    
                let member = createInvCompAdjustEquityBodyTable(
                  sheet_id,
                  bi_id,
                  company_id,
                  product_name,
                  txn_date,
                  investment_port,
                  no_of_share,
                  allowance_permanent_thb,
                  total_adjusted_equity,
                  adjusted_equity_posttransaction,
                  approval,
                  'detail button',
                );
                rowsData.push(member);
              });
    
              // console.log(getAdjEquityData);
              setOperationRows(rowsData);
              setIsLoadedRows(true);
            };
            getAdjEquityRows();
    
            break;
          //----------------- 05 ---------------- */
    
          case 'invcompadjustpar':
            const getAdjParRows = async () => {
              const requestAdjParMain: requestAdjParMainInterface = {
                page: 1,
                page_length: tableConfig['defaultRowsLength'],
                sort_by: '',
                order: OrderBy.asc,
                filter: currentSearchKeyword,
              };
    
              const getAdjParData: { [key: string]: any } = await getAdjPar(
                requestAdjParMain,
              );
    
              Object.keys(getAdjParData['data']).map((index: string) => {
                let arr = getAdjParData['data'][index];
    
                let sheet_id = arr['sheet_id'];
                let bi_id = 'non';
                let company_id = arr['company_id'];
                let product_name = arr['product_name'];
                let txn_date = arr['txn_date'];
                let adjusted_par_method = arr['adjusted_par_method'];
                let no_of_share_posttransaction = arr['no_of_share_posttransaction'];
                let investment_cost = arr['investment_cost'];
                let par_value_posttransaction = arr['par_value_posttransaction'];
                let approval = arr['approval_status'];
    
                let member = createInvCompAdjustParBodyTable(
                  sheet_id,
                  bi_id,
                  company_id,
                  product_name,
                  txn_date,
                  adjusted_par_method,
                  no_of_share_posttransaction,
                  investment_cost,
                  par_value_posttransaction,
                  approval,
                  'detail button',
                );
                rowsData.push(member);
              });
    
              // console.log(getAdjParData);
              setOperationRows(rowsData);
              setIsLoadedRows(true);
            };
            getAdjParRows();
    
            break;
    
          //---------------- 06 ----------------- */
    
          case 'invcompbot':
            const getBotRows = async () => {
              const requestBotMain: requestBotMainInterface = {
                page: 1,
                page_length: tableConfig['defaultRowsLength'],
                sort_by: '',
                order: OrderBy.asc,
                filter: currentSearchKeyword,
              };
    
              const getBotData: { [key: string]: any } = await getBot(requestBotMain);
    
              Object.keys(getBotData['data']).map((index: string) => {
                let arr = getBotData['data'][index];
    
                let sheet_id = arr['sheet_id'];
                let bi_id = 'non';
                let company_id = arr['company_id'];
                let recording_date = arr['recording_date'];
                let document_no = arr['document_no'];
                let request_type = arr['request_type'];
                let document_start_date = arr['document_start_date'];
                let document_end_date = arr['document_end_date'];
                let approval = arr['approval_status'];
    
                let member = createInvCompBotBodyTable(
                  sheet_id,
                  company_id,
                  bi_id,
                  recording_date,
                  document_no,
                  request_type,
                  document_start_date,
                  document_end_date,
                  approval,
                  'detail button',
                );
                rowsData.push(member);
              });
    
              // console.log(getBotData);
              setOperationRows(rowsData);
              setIsLoadedRows(true);
            };
            getBotRows();
    
            break;
    
          //--------------- 07 ------------------ */
    
          case 'invcompcashflow':
            const getCompCashFlowRows = async () => {
              const requestCompCashFlowMain: requestCompCashFlowMainInterface = {
                page: 1,
                page_length: tableConfig['defaultRowsLength'],
                sort_by: '',
                order: OrderBy.asc,
                filter: currentSearchKeyword,
              };
    
              const getCompCashFlowData: { [key: string]: any } = await getCompCashFlow(
                requestCompCashFlowMain,
              );
    
              Object.keys(getCompCashFlowData['data']).map((index: string) => {
                let arr = getCompCashFlowData['data'][index];
    
                let sheet_id = arr['sheet_id'];
                let bi_id = 'non';
    
                let company_id = arr['company_id'];
                let company_name = arr['company_name'];
                let txn_date = arr['txn_date'];
                let txn_type = arr['txn_type'];
                let currency = arr['currency'];
                let no_of_share = arr['no_of_share'];
                let price_per_share = arr['price_per_share'];
                let net_cashflow = arr['net_cashflow'];
                let approval = arr['approval_status'];
    
                let member = createInvCompCashFlowBodyTable(
                  sheet_id,
                  bi_id,
                  company_id,
                  company_name,
                  txn_date,
                  txn_type,
                  currency,
                  no_of_share,
                  price_per_share,
                  net_cashflow,
                  approval,
                  'detail button',
                );
                rowsData.push(member);
              });
    
              // console.log(getCompCashFlowData);
              setOperationRows(rowsData);
              setIsLoadedRows(true);
            };
            getCompCashFlowRows();
    
            break;
    
          //------------- 08 -------------------- */
          case 'invcompdirector':
            const getDirectorRows = async () => {
              const requestDirectorMain: requestDirectorMainInterface = {
                page: 1,
                page_length: tableConfig['defaultRowsLength'],
                sort_by: '',
                order: OrderBy.asc,
                filter: currentSearchKeyword,
              };
    
              const getDirectorData: { [key: string]: any } = await getDirector(
                requestDirectorMain,
              );
    
              Object.keys(getDirectorData['data']).map((index: string) => {
                let arr = getDirectorData['data'][index];
    
                let sheet_id = arr['sheet_id'];
                let bi_id = 'non';
    
                let company_id = arr['sheet_data']['company_id'];
                let committee_name = arr['sheet_data']['committee_name'];
                let bbl_representative = arr['sheet_data']['bbl_representative'];
                let bbl_representative_internalposition =
                  arr['sheet_data']['bbl_representative_internalposition'];
                let approval = arr['approval_status'];
    
                let bbl_representative_text = '';
    
                if (bbl_representative === true) {
                  bbl_representative_text = 'Yes';
                } else {
                  bbl_representative_text = 'No';
                }
    
                let member = createInvCompDirectorBodyTable(
                  sheet_id,
                  bi_id,
                  company_id,
                  committee_name,
                  bbl_representative_text,
                  bbl_representative_internalposition,
                  approval,
                  'detail button',
                );
                rowsData.push(member);
              });
    
              // console.log(getDirectorData);
              setOperationRows(rowsData);
              setIsLoadedRows(true);
            };
            getDirectorRows();
    
            break;
    
          //---------------- 09 ----------------- */
    
          case 'invcompdividend':
            const getDividendRows = async () => {
              const requestDividendMain: requestDividendMainInterface = {
                page: 1,
                page_length: tableConfig['defaultRowsLength'],
                sort_by: '',
                order: OrderBy.asc,
                filter: currentSearchKeyword,
              };
    
              const getDividendData: { [key: string]: any } = await getDividend(
                requestDividendMain,
              );
    
              Object.keys(getDividendData['data']).map((index: string) => {
                let arr = getDividendData['data'][index];
    
                let sheet_id = arr['sheet_id'];
                // let bi_id = 'non';
                let company_id = arr['company_id'];
                let company_name = arr['company_name'];
                let product_name = arr['product_name'];
                let data_date = arr['data_date'];
                let currency = arr['currency'];
                let no_of_shares = arr['no_of_shares'];
                let dividend = arr['dividend'];
                let dividend_amount = arr['dividend_amount'];
                let approval = arr['approval_status'];
    
                let member = createInvCompDividendBodyTable(
                  sheet_id,
                  company_id,
                  company_name,
                  product_name,
                  data_date,
                  currency,
                  no_of_shares,
                  dividend,
                  dividend_amount,
                  approval,
                  'detail button',
                );
                rowsData.push(member);
              });
    
              // console.log(getDividendData);
              setOperationRows(rowsData);
              setIsLoadedRows(true);
            };
            getDividendRows();
    
            break;
    
          //--------------- 10 ------------------ */
    
          case 'invcompfair':
            const getCompFairRows = async () => {
              const requestCompFairMain: requestCompFairMainInterface = {
                page: 1,
                page_length: tableConfig['defaultRowsLength'],
                sort_by: '',
                order: OrderBy.asc,
                filter: currentSearchKeyword,
              };
    
              const getCompFairData: { [key: string]: any } = await getCompFair(
                requestCompFairMain,
              );
    
              Object.keys(getCompFairData['data']).map((index: string) => {
                let arr = getCompFairData['data'][index];
    
                let sheet_id = arr['sheet_id'];
                // let bi_id = 'non';
                let company_id = arr['company_id'];
                let company_name = arr['company_name'];
                let data_date = arr['data_date'];
                let wacc = arr['wacc'];
                let terminal_growth = arr['terminal_growth'];
                let fair_value = arr['fair_value'];
                let approval = arr['approval_status'];
    
                let member = createInvCompFairBodyTable(
                  sheet_id,
                  company_id,
                  company_name,
                  data_date,
                  wacc,
                  terminal_growth,
                  fair_value,
                  approval,
    
                  'detail button',
                );
                rowsData.push(member);
              });
    
              // console.log(getCompFairData);
              setOperationRows(rowsData);
              setIsLoadedRows(true);
            };
            getCompFairRows();
    
            break;
    
          //---------------- 11 ----------------- */
    
          case 'invcompwarrant':
            const getWarrantRows = async () => {
              const requestWarrantMain: requestWarrantMainInterface = {
                page: 1,
                page_length: tableConfig['defaultRowsLength'],
                sort_by: '',
                order: OrderBy.asc,
                filter: currentSearchKeyword,
              };
    
              const getWarrantData: { [key: string]: any } = await getWarrant(
                requestWarrantMain,
              );
    
              Object.keys(getWarrantData['data']).map((index: string) => {
                let arr = getWarrantData['data'][index];
    
                let sheet_id = arr['sheet_id'];
                let bi_id = 'non';
                let company_id = arr['company_id'];
                let product_name = arr['product_name'];
                let txn_date = arr['txn_date'];
                let warrant_name = arr['warrant_name'];
                let warrant_type = arr['warrant_type'];
                let no_of_share_pretransaction = arr['no_of_share_pretransaction'];
                let approval = arr['approval_status'];
    
                let member = createInvCompWarrantBodyTable(
                  sheet_id,
                  bi_id,
                  company_id,
                  product_name,
                  txn_date,
                  warrant_name,
                  warrant_type,
                  no_of_share_pretransaction,
                  approval,
                  'detail button',
                );
    
                rowsData.push(member);
              });
    
              // console.log(getWarrantData);
              setOperationRows(rowsData);
              setIsLoadedRows(true);
            };
            getWarrantRows();
    
            break;
    
          //---------------- 12 ----------------- */
    
          case 'invfundcashflow':
            const getFundCashFlowRows = async () => {
              const requestFundCashFlowMain: requestFundCashFlowMainInterface = {
                page: 1,
                page_length: tableConfig['defaultRowsLength'],
                sort_by: '',
                order: OrderBy.asc,
                filter: currentSearchKeyword,
              };
    
              const getFundCashFlowData: { [key: string]: any } = await getFundCashFlow(
                requestFundCashFlowMain,
              );
    
              Object.keys(getFundCashFlowData['data']).map((index: string) => {
                let arr = getFundCashFlowData['data'][index];
    
                let sheet_id = arr['sheet_id'];
                // let bi_id = 'non';
    
                let fund_id = arr['fund_id'];
                let fund_name = arr['fund_name'];
                let txn_date = arr['txn_date'];
                let cashflow_type = arr['cashflow_type'];
                let currency = arr['currency'];
                let cashflow_amount = arr['cashflow_amount'];
                let current_stake = arr['current_stake'];
                let approval = arr['approval_status'];
    
                let member = createInvFundCashflowBodyTable(
                  sheet_id,
                  fund_id,
                  fund_name,
                  txn_date,
                  cashflow_type,
                  currency,
                  cashflow_amount,
                  current_stake,
                  approval,
    
                  'detail button',
                );
    
                rowsData.push(member);
              });
    
              // console.log(getFundCashFlowData);
              setOperationRows(rowsData);
              setIsLoadedRows(true);
            };
            getFundCashFlowRows();
    
            break;
    
          //************** NotFound ********************* */
          //------------------------------------//
          default:
            break;
        }

        break;
      //------------------------------------//
      default:
        break;
    }
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        {/* <Paper sx={{ width: '100%', mb: 2 }}> */}
        <Grid className={LayoutStyles.tablePortalCard}>
          <Toolbar className={LayoutStyles.TableToolBar}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              container
            >
              <Grid xs={9} sm={9} md={9} item>
                <SearchBar
                  portalMode={portalMode}
                  onSelectedDropDownList={handleSelectedDropDownList}
                  onSearchClick={handleOnSearchClick}
                />
              </Grid>
              <Grid xs={3} sm={3} md={3} justifyContent="flex-end" item container>
                <Grid item>
                  {isLoggedIn &&
                    (userProfile.team === 'COM_FUND' ||
                      userProfile.team === 'OPERATION' ||
                      userProfile.team === 'ADMIN') &&
                    (userProfile.role === 'MAKER' || userProfile.role === 'ADMIN') && (
                      <CreateButtonRender
                        portalMode={portalMode}
                        createOnClick={handleCreateModalOpen}
                        isLoadedRows={isLoadedRows}
                      />
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
          <br />
          {/* ------------------------------- */}
          {isLoadedRows ? (
            <Grid>
              <TableContainer>
                <Table
                  stickyHeader
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size="small"
                >
                  {/* <Table aria-labelledby="tableTitle" size="small"> */}
                  {/* //?------------------ Head ------------------------- */}
                  <TableHeadRender
                    rowHeadData={currentRowHeadData}
                    portalMode={portalMode}
                    currentSheetName={currentSheetName}
                  />
                  {/* //?------------------ Head ------------------------- */}
                  <TableBody>
                    {stableSort(currentRowBodyData, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const labelId = `row-data-id-${index}`;
                        const rowData: { [key: string]: any } = row;

                        let ObjId = '';
                        let ObjSheetId = '';
                        let ObjName = '';

                        let approvalStatusArr = rowData['approval'];

                        // console.log(approvalStatusArr);

                        if (portalMode === 'company_profile') {
                          ObjId = rowData['company_id'];
                          ObjSheetId = rowData['sheet_id'];
                          ObjName = rowData['company_name'];
                        } else if (portalMode === 'fund_profile') {
                          ObjId = rowData['fund_id'];
                          ObjSheetId = rowData['sheet_id'];
                          ObjName = rowData['fund_name'];
                        } else if (portalMode === 'operation') {
                          switch (currentSheetName) {
                            //?-------------------- Operation -------------------- */

                            //-------------- 01 ------------------ */
                            //TODO set Operation Detail State
                            case 'invbankaccount':
                              ObjId = rowData['id'];
                              ObjSheetId = rowData['sheet_id'];
                              ObjName = rowData['name'];
                              break;

                            //--------------- 02 ------------------ */

                            case 'invbankcapital':
                              ObjId = rowData['data_date'];
                              ObjSheetId = rowData['sheet_id'];
                              ObjName = rowData['data_date'];
                              break;

                            //---------------- 03 ----------------- */
                            case 'invcertificate':
                              ObjId = rowData['id'];
                              ObjSheetId = rowData['sheet_id'];
                              ObjName = rowData['name'];
                              break;

                            //---------------- 04 ----------------- */

                            case 'invcompadjustequity':
                              ObjId = rowData['company_id'];
                              ObjSheetId = rowData['sheet_id'];
                              ObjName = rowData['product_name'];
                              break;
                            //----------------- 05 ---------------- */

                            case 'invcompadjustpar':
                              ObjId = rowData['company_id'];
                              ObjSheetId = rowData['sheet_id'];
                              ObjName = rowData['product_name'];
                              break;

                            //---------------- 06 ----------------- */

                            case 'invcompbot':
                              ObjId = rowData['company_id'];
                              ObjSheetId = rowData['sheet_id'];
                              ObjName = rowData['recording_date'];
                              break;

                            //--------------- 07 ------------------ */

                            case 'invcompcashflow':
                              ObjId = rowData['company_id'];
                              ObjSheetId = rowData['sheet_id'];
                              ObjName = rowData['company_name'];
                              break;

                            //------------- 08 -------------------- */
                            case 'invcompdirector':
                              ObjId = rowData['company_id'];
                              ObjSheetId = rowData['sheet_id'];
                              ObjName = rowData['committee_name'];
                              break;

                            //---------------- 09 ----------------- */

                            case 'invcompdividend':
                              ObjId = rowData['company_id'];
                              ObjSheetId = rowData['sheet_id'];
                              ObjName = rowData['product_name'];
                              break;

                            //--------------- 10 ------------------ */

                            case 'invcompfair':
                              ObjId = rowData['company_id'];
                              ObjSheetId = rowData['sheet_id'];
                              ObjName = rowData['company_name'];
                              break;

                            //---------------- 11 ----------------- */

                            case 'invcompwarrant':
                              ObjId = rowData['company_id'];
                              ObjSheetId = rowData['sheet_id'];
                              ObjName = rowData['product_name'];
                              break;

                            //---------------- 12 ----------------- */

                            case 'invfundcashflow':
                              ObjId = rowData['fund_id'];
                              ObjSheetId = rowData['sheet_id'];
                              ObjName = rowData['fund_name'];
                              break;

                            //************** NotFound ********************* */
                            //------------------------------------//
                            default:
                              break;
                          }
                        } else {
                        }

                        // ?--------------------------- Body ------------------------/
                        return (
                          <TableBodyRender
                            key={currentSheetName + '_' + index}
                            rowData={rowData}
                            labelId={labelId}
                            portalMode={portalMode}
                            detailModalClick={handleMainDetailModalOpen(
                              ObjId,
                              ObjSheetId,
                              ObjName,
                              approvalStatusArr,
                            )}
                            approvalClick={handleApproval}
                            currentSheetName={currentSheetName}
                          />
                        );
                        // ?--------------------------- Body ------------------------/
                      })}

                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[GenRowPerPage]}
                component="div"
                count={currentRowBodyData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
              />
            </Grid>
          ) : (
            <ProgressLoading typoLoading="Loading content, please wait..." />
          )}

          {/* ------------------------------- */}
        </Grid>
        {/* </Paper> */}
      </Box>
    </>
  );
}
