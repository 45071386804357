import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
//api
import { getAccount } from '../../api/index';

//Layout
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

//Element
import MasterTable from '../common/table/MasterTable';
import LayoutStyles from '../../../styles/Layout.module.css';

import {
  OrderBy,
  createInvBankAccountBodyTable,
  requestAccountMainInterface,
  InvBankAccountBodyTableInterface,
} from '../../../models/index';

import {
  operationRowsState,
  operationHeadersState,
  currentOperationTableState,
  isLoadingTableState,
  isLoadedMasterRowsState,
} from '../../../states/index';

import {
  InvCertificateRows,
  InvCertificateHeaders,
  InvCompAdjustEquityRows,
  InvCompAdjustEquityHeaders,
  InvCompCashFlowRows,
  InvCompCashFlowHeaders,
  InvCompDividendRows,
  InvCompDividendHeaders,
  InvBankAccountRows,
  InvBankAccountHeaders,
  InvBankCapitalRows,
  InvBankCapitalHeaders,
  InvCompAdjustParRows,
  InvCompAdjustParHeaders,
  InvCompBotRows,
  InvCompBotHeaders,
  InvCompDirectorRows,
  InvCompDirectorHeaders,
  InvCompFairRows,
  InvCompFairHeaders,
  InvCompWarrantRows,
  InvCompWarrantHeaders,
  InvFundCashflowRows,
  InvFundCashflowHeaders,
} from '../../../states/index';

export default function OperationCard({
  onPaginationNextClick,
}: {
  onPaginationNextClick: (
    _currentSystemSheetName: string,
    _sheet_id: string,
    _rowsLengthTotal: number,
    _currentSearchKeyword: string,
    _currentSortBy: string,
  ) => void;
}) {
  const setIsLoadedRows = useSetRecoilState(isLoadedMasterRowsState);
  const [operationRows, setOperationRows] = useRecoilState(operationRowsState);
  const [operationHeaders, setOperationHeaders] = useRecoilState(operationHeadersState);

  const currentOperationTable = useRecoilValue(currentOperationTableState);

  useEffect(() => {
    (async () => {
      //Account Information id the default table in first load.
      let rowsData: InvBankAccountBodyTableInterface[] = [];
      const requestAccountMain: requestAccountMainInterface = {
        page: 1,
        page_length: 20,
        sort_by: '',
        order: OrderBy.asc,
        filter: '',
      };

      const getAccountData: { [key: string]: any } = await getAccount(
        requestAccountMain,
      );

      // console.log(getAccountData);

      Object.keys(getAccountData['data']).map((index: string) => {
        let arr = getAccountData['data'][index];

        let sheet_id = arr['sheet_id'];
        // let bi_id = 'non';
        let id = arr['sheet_data']['id'];
        let name = arr['sheet_data']['name'];
        let date = arr['sheet_data']['date'];
        let account_no = arr['sheet_data']['account_no'];
        let account_name = arr['sheet_data']['account_name'];
        let account_bank_name = arr['sheet_data']['account_bank_name'];
        let approval = arr['approval_status'];

        let member = createInvBankAccountBodyTable(
          sheet_id,
          id,
          name,
          date,
          account_no,
          account_name,
          account_bank_name,
          approval,

          'detail button',
        );
        rowsData.push(member);
      });

      setOperationRows(rowsData);
      setIsLoadedRows(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isLoadingTable, setIsLoadingTable] = useRecoilState(isLoadingTableState);

  let currentTableData = (_currentOperationTable: string) => {
    switch (_currentOperationTable) {
      //--------------------------------- */
      case 'invcertificate':
        return {
          currentRows: InvCertificateRows,
          currentHeaders: InvCertificateHeaders,
        };

      //--------------------------------- */
      case 'invcompadjustequity':
        return {
          currentRows: InvCompAdjustEquityRows,
          currentHeaders: InvCompAdjustEquityHeaders,
        };

      //--------------------------------- */
      case 'invcompcashflow':
        return {
          currentRows: InvCompCashFlowRows,
          currentHeaders: InvCompCashFlowHeaders,
        };

      //--------------------------------- */
      case 'invcompdividend':
        return {
          currentRows: InvCompDividendRows,
          currentHeaders: InvCompDividendHeaders,
        };

      //--------------------------------- */
      case 'invbankaccount':
        return {
          currentRows: InvBankAccountRows,
          currentHeaders: InvBankAccountHeaders,
        };

      //--------------------------------- */
      case 'invbankcapital':
        return {
          currentRows: InvBankCapitalRows,
          currentHeaders: InvBankCapitalHeaders,
        };

      //--------------------------------- */
      case 'invcompadjustpar':
        return {
          currentRows: InvCompAdjustParRows,
          currentHeaders: InvCompAdjustParHeaders,
        };

      //--------------------------------- */
      case 'invcompbot':
        return {
          currentRows: InvCompBotRows,
          currentHeaders: InvCompBotHeaders,
        };

      //--------------------------------- */
      case 'invcompdirector':
        return {
          currentRows: InvCompDirectorRows,
          currentHeaders: InvCompDirectorHeaders,
        };

      //--------------------------------- */
      case 'invcompfair':
        return {
          currentRows: InvCompFairRows,
          currentHeaders: InvCompFairHeaders,
        };

      //--------------------------------- */
      case 'invcompwarrant':
        return {
          currentRows: InvCompWarrantRows,
          currentHeaders: InvCompWarrantHeaders,
        };

      //--------------------------------- */
      case 'invfundcashflow':
        return {
          currentRows: InvFundCashflowRows,
          currentHeaders: InvFundCashflowHeaders,
        };

      //***************** NotFound ********************* */
      //------------------------------------//
      default:
        return {
          currentRows: {},
          currentHeaders: {},
        };
    }
  };

  if (isLoadingTable === true) {
    let currentTableJSON: { [key: string]: any } =
      currentTableData(currentOperationTable);
    setOperationRows(currentTableJSON['currentRows']);
    setOperationHeaders(currentTableJSON['currentHeaders']);
    setIsLoadingTable(false);
  }

  return (
    <Paper className={LayoutStyles.mainContentCard} square>
      <Grid container className={LayoutStyles.mainGridCard}>
        <Grid item sm={12} md={12} lg={12}>
          {/* //------------ Master Modal -------------- */}
          {isLoadingTable ? (
            <div></div>
          ) : (
            <MasterTable
              rowBodyData={operationRows}
              rowHeadData={operationHeaders}
              portalMode="operation"
              onPaginationNextClick={onPaginationNextClick}
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
