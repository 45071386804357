import { atom } from 'recoil';

//--------------------------- Current Master Table Search ----------------------
export const currentSearchKeywordState = atom({
  key: 'currentSearchKeyword',
  default: '',
});

export const isLoadedMasterRowsState = atom({
  key: 'isLoadedMasterRows',
  default: false,
});

//--------------------------- Current Master Table Loaded ----------------------

export const tableConfigState = atom({
  key: 'tableConfig',
  default: {
    defaultRowsLength: 240,
    lengthPerLoad: 80,
  },
});

export const currentRowsLengthState = atom({
  key: 'currentRowsLength',
  default: 240,
});

export const currentTablePageState = atom({
  key: 'currentTablePage',
  default: 0,
});

//------------------------- Current Name----------------------------

export const currentIsEmptyState = atom({
  key: 'currentIsEmpty',
  default: false,
});

export const currentIsMainApprovedState = atom({
  key: 'currentMainApproved',
  default: false,
});

export const currentIdState = atom({
  key: 'currentId',
  default: {
    main_name: '',
    main_id_type: '',
    main_id_value: '',
    main_sheet_id: '',
    sub_name: '',
    sub_id_type: '',
    sub_id_value: '',
    sub_sheet_id: '',
  },
});

export const currentSubIdState = atom({
  key: 'currentSubId',
  default: {
    id_type: '',
    id_value: '',
  },
});

export const currentModalHeaderState = atom({
  key: 'currentModalHeader',
  default: 'Bangkok Bank PCL',
});

export const currentSubNameState = atom({
  key: 'currentSubName',
  default: 'Product 01',
});

export const currentSubSheetapprovalJSONState = atom({
  key: 'currentSubSheetapprovalJSON',
  default: {},
});

export const currentapprovalJSONState = atom({
  key: 'currentapprovalJSON',
  default: {},
});

//--------- Current Sheet Info. -------------//

export const currentSheetNameState = atom({
  key: 'currentSheetName',
  default: 'non',
});

// primary | onToMany | transaction
export const currentSheetTypeState = atom({
  key: 'currentSheetType',
  default: 'primary',
});

//------------------------ Update Boolean --------------------------

export const isUpdateInputJSONState = atom({
  key: 'isUpdate',
  default: false,
});

export const isGenerateCompleteState = atom({
  key: 'isGenerateComplete',
  default: true,
});

export const currentIsApprovedState = atom({
  key: 'currentIsApprove',
  default: false,
});

//----------------------- Current Create Modal Status -----------------------

export const isFirstLoadFormState = atom({
  key: 'isFirstLoadForm',
  default: false,
});

export const isFirstResetState = atom({
  key: 'isFirstResetState',
  default: false,
});

export const currentInputJSONState = atom({
  key: 'inputJSON',
  default: {},
});

let _currentOnInputChangeValue: any;
export const currentOnInputChangeValueState = atom({
  key: 'currentOnInputChange',
  default: _currentOnInputChangeValue,
});

export const currentCreateModalModeState = atom({
  key: 'currentCreateModalMode',
  default: 'non',
});

// create | edit | approve | delete | close
export const currentCaseNameState = atom({
  key: 'currentCaseName',
  default: 'non',
});

export const previousCaseNameState = atom({
  key: 'previousCaseName',
  default: 'non',
});

const PageNumber = 1;
export const currentFillFormStepState = atom({
  key: 'fillFormStep',
  default: PageNumber,
});

export const isSubmitStepState = atom({
  key: 'isSubmitStep',
  default: false,
});

export const isFirstPageStepState = atom({
  key: 'isFirstPageStep',
  default: true,
});
