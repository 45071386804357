export const approvalName = function (sheetName: string) {
  switch (sheetName) {
    //?-------------------- Common -------------------- */

    //----------------- 01 ---------------- */
    case 'invcontact':
      return 'contact';

    //---------------- 02 ----------------- */
    case 'invboardapprovedmemo':
      return 'memo';

    //?-------------------- Company -------------------- */
    //---------------- 01 ----------------- */
    case 'invcompdefinitive':
      return 'company';
    //---------------- 02 ----------------- */
    case 'invcompproduct':
      return 'product';
    //------------------ 03 --------------- */
    case 'invcompfs':
      return 'fs';
    //--------------------------------- */

    //?-------------------- Fund -------------------- */
    //----------------- 01 ---------------- */
    case 'invfunddefinitive':
      return 'fund';
    //----------------- 02 ---------------- */
    case 'invfundnav':
      return 'nav';
    //----------------- 03 ---------------- */
    case 'invfundportfolio':
      return 'port';
    //----------------- 04 ---------------- */
    case 'invfundbenchmark':
      return 'benchmark';
    //---------------- 05 ----------------- */
    case 'invfundcommitmentandwatermark':
      return 'commitment';

    //?-------------------- Operation -------------------- */

    //-------------- 01 ------------------ */

    case 'invbankaccount':
      return 'account';

    //--------------- 02 ------------------ */

    case 'invbankcapital':
      return 'capital';

    //---------------- 03 ----------------- */
    case 'invcertificate':
      return 'certificate';

    //---------------- 04 ----------------- */

    case 'invcompadjustequity':
      return 'adjEquity';
    //----------------- 05 ---------------- */

    case 'invcompadjustpar':
      return 'adjPar';

    //---------------- 06 ----------------- */

    case 'invcompbot':
      return 'bot';

    //--------------- 07 ------------------ */

    case 'invcompcashflow':
      return 'compCashFlow';

    //------------- 08 -------------------- */
    case 'invcompdirector':
      return 'director';

    //---------------- 09 ----------------- */

    case 'invcompdividend':
      return 'dividend';

    //--------------- 10 ------------------ */

    case 'invcompfair':
      return 'compFair';

    //---------------- 11 ----------------- */

    case 'invcompwarrant':
      return 'warrant';

    //---------------- 12 ----------------- */

    case 'invfundcashflow':
      return 'fundCashFlow';

    //************** NotFound ********************* */
    //------------------------------------//
    default:
      return '';
  }
};
