//React
import React from 'react';

//React Router
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ConstructionIcon from '@mui/icons-material/Construction';

import LayoutStyles from '../styles/Layout.module.css';

function UnderConstructionPage() {
  return (
    <Grid
      className={LayoutStyles.OtherPage}
      item
      xs={12}
      sm={12}
      md={12}
      direction="column"
      justifyContent="center"
      alignItems="center"
      container
    >
      <Grid item>
        <ConstructionIcon sx={{ color: '#ffffff' , fontSize: 100}} />
      </Grid>

      <Grid item>
        <Typography sx={{ color: '#ffffff' }} variant="h3" component="div" gutterBottom>
          <b>Investment Portfolio</b>
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ color: '#ffffff' }} variant="h4" component="div" gutterBottom>
          is currently down for maintenance.
        </Typography>
      </Grid>
      <br />
      <br />
      <br />
      <Grid item>
        <Typography
          sx={{ color: '#ffffff' }}
          variant="body1"
          component="div"
          gutterBottom
        >
          we apologize for any inconveniences caused.
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          sx={{ color: '#ffffff' }}
          variant="body1"
          component="div"
          gutterBottom
        >
          Please come back and try again later.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default UnderConstructionPage;
