//---------------------------------------- Import React --------------------------------------
import ElementStyles from '../../../../styles/Element.module.css';

export default function CheckIcon() {
  return  <img
  alt="check"
  className={ElementStyles.CheckIcon}
  src="check.svg"
  width={150}
  height={150}
/>;
}


