import * as React from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import ButtonDetail from '../../button/ButtonDetail';
import ApprovalChip from '../../element/ApprovalChip';

import {
  CompanyTableHeadData,
  CompanyTableBodyData,
  Order,
} from '../../../../../models/index';

//?----------------------------------- Company Profile -------------------------
export function CompanyTableHeadComp({
  order,
  orderBy,
  onRequestSort,
  rowHeadData,
}: {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof CompanyTableBodyData,
  ) => void;
  order: Order;
  orderBy: string;
  rowHeadData: any;
}) {
  const createSortHandler =
    (property: keyof CompanyTableBodyData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const rowHead: CompanyTableHeadData[] = rowHeadData;

  return (
    <TableHead>
      <TableRow>
        {rowHead.map((headCell) => {
          let isDetail: boolean = false;

          if (headCell.id === 'detail') {
            isDetail = true;
          } else {
            isDetail = false;
          }

          return (
            <TableCell
              key={headCell.id}
              align={isDetail ? 'right' : 'left'}
              padding="normal"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export function CompanyTableBodyComp({
  rowData,
  labelId,
  detailModalClick,
  approvalClick,
}: {
  rowData: any;
  labelId: any;
  detailModalClick: any;
  approvalClick: any;
}) {
 

  
  return (
    <TableRow hover key={rowData['company_id']}>
      {/* <TableCell component="th" id={labelId} scope="rowData">
        {rowData['bi_id']}
      </TableCell> */}
      <TableCell align="left">{rowData['company_id']}</TableCell>
      <TableCell align="left">{rowData['company_name']}</TableCell>
      <TableCell align="left">
        <ApprovalChip sheetStauts={rowData['approval']['company']} />
      </TableCell>
      {/* <TableCell align="left">
        <ButtonApproval isApprovalStatus={false} onClick={approvalClick} />
      </TableCell> */}
      {/* <TableCell align='left'>{rowData['detail']}</TableCell> */}
      <TableCell align="right">
        <ButtonDetail onClick={detailModalClick} />
      </TableCell>
    </TableRow>
  );
}
