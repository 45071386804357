// ****************************************************************************************
// ******************************** Main Table ********************************************
// ****************************************************************************************

// ?------------------------------ Company Table ------------------------------------------
export interface CompanyTableBodyData {
  sheet_id: string,
  bi_id: string;
  company_id: string;
  company_name: string;
  approval: object;
  detail: string;
}

export function createCompanyTableBodyData(
  sheet_id: string,
  bi_id: string,
  company_id: string,
  company_name: string,
  approval: object,
  detail: string,
): CompanyTableBodyData {
  return {
    sheet_id,
    bi_id,
    company_id,
    company_name,
    approval,
    detail,
  };
}

//-------------------------//
export interface CompanyTableHeadData {
  id: keyof CompanyTableBodyData;
  label: string;
}
