import format from 'date-fns/format';
// import addYears from 'date-fns/addYears';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';

export const thaiDateTime = (date: Date): string => {
  return format(utcToZonedTime(date, 'Asia/Bangkok'), 'dd LLLL yyyy @ HH:mm');
};

export const thaiDate = (date: Date): string => {
  return format(utcToZonedTime(date, 'Asia/Bangkok'), 'dd LLLL yyyy');
};


export const isValidDate = (date: Date) =>{
  return date instanceof Date && !isNaN(date.getTime());
}
