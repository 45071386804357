//React
import React from 'react';

//React Router
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import LayoutStyles from '../styles/Layout.module.css';

function NotFoundPage() {
  return (
    <Grid
      className={LayoutStyles.OtherPage}
      item
      xs={12}
      sm={12}
      md={12}
      direction="column"
      justifyContent="center"
      alignItems="center"
      container
    >
      <Grid item>
        <ErrorOutlineIcon sx={{ color: '#ffffff', fontSize: 100 }} />
      </Grid>

      <Grid item>
        <Typography sx={{ color: '#ffffff' }} variant="h3" component="div" gutterBottom>
          <b>Sorry,</b>
        </Typography>
      </Grid>

      <Grid item>
        <Typography sx={{ color: '#ffffff' }} variant="h5" component="div" gutterBottom>
          We couldn't find that page.
        </Typography>
      </Grid>

      <br />
      <br />
      <Grid item>
        <Link style={{ textDecoration: 'none' }} to="/">
          <Chip color="secondary" label="Back to Home" clickable />
        </Link>
      </Grid>
    </Grid>
  );
}

export default NotFoundPage;
