import { atom } from 'recoil';
import {
  arrCurrency,
  arrEvaluation,
  arrPort,
  arrRedeemable,
  arrAssoSubFlag,
  arrHolding,
  arrNationality,
  arrYn,
  arrYnValue,
  arrIfrs9,
  arrTdr,
  arrMarketableSecurity,
  arrHedgeCurrency,
  arrFixedThbRate,
  arrProduct,
  arrOwnership,
} from '../common';

const _sheetName = 'invcompproduct';

//?------- Parameter Design ---------------
/*

------- Label / ID ------------
displayName = variable for display name in label.
sourceSystemName = variable for bank system name.
apiName = variable for call api. 

-------- Input ----------------
description = input placeholder.
inputLength = custom length limit of character.
inputType = (checkbox | date | radio | select | textfield) type of input.
inputClass = Custom format masking or validation.
possibleValue = (array) Value for dropdownlist / checkbox / radio.

-------- Boolean --------------
isRequire = (true/false) require field. can't be null.

isLinkValue = (true/false) Link the value from other input (linkValueId)
linkValueId = ID of input to Link data (display when isLinkValue == true).

isAutoGenerate = (true/false) auto generate value.
generateSource = source of auto generate (display when isAutoGenerate == true).

*/
/**
 textfield
 - NoLimitFree
 - noLimitCountNumber

 date
 - General

select
 - General

 */

const jsonData: { [key: string]: any } = {
  sheetName: _sheetName,
  inputInfo: [
    // ? --------------------------------- Start ---------------------------------------//

    // !---------------------------- 000 ---------------------------------//
    {
      displayName: 'Product id',
      sourceSystemName: 'PRODUCT_ID',
      apiName: 'product_id',

      description: 'Product id',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 001 ---------------------------------//
    {
      displayName: 'BI_ID',
      sourceSystemName: 'BI_ID',
      apiName: 'bi_id',

      description: 'Business ID from Investment',
      inputLength: '',
      inputType: 'select',
      inputClass: 'Autocomplete',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: true,
    },

    // ---------------------------- 002 ---------------------------------//
    {
      displayName: 'RM ID',
      sourceSystemName: 'RM_ID',
      apiName: 'rm_id',

      description: 'ID generated from bank',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromDefinitive',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 003 ---------------------------------//
    {
      displayName: 'Company ID',
      sourceSystemName: 'COMPANY_ID',
      apiName: 'company_id',

      description: 'Company ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromDefinitive',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 004 ---------------------------------//
    {
      displayName: 'Company Name',
      sourceSystemName: 'COMPANY_NAME',
      apiName: 'company_name',

      description: 'Full legal name of the company',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromDefinitive',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 005 ---------------------------------//
    {
      displayName: 'Product Name',
      sourceSystemName: 'PRODUCT_NAME',
      apiName: 'product_name',

      description: 'Company name classified by type of products',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 006 ---------------------------------//
    {
      displayName: 'Equity-Short Name',
      sourceSystemName: 'EQUITY_SHORT_NAME',
      apiName: 'equity_short_name',

      description: 'Bloomberg Ticker',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromDefinitive',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 007 ---------------------------------//
    {
      displayName: 'Date',
      sourceSystemName: 'DATA_DATE',
      apiName: 'data_date',

      description: 'Date analysts obtain the data',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 008 ---------------------------------//
    {
      displayName: 'First Investment Date',
      sourceSystemName: 'FIRST_INVESTMENT_DATE',
      apiName: 'first_investment_date',

      description: 'The date BBL made the first transfer to the firm',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 009 ---------------------------------//
    {
      displayName: 'Market listed',
      sourceSystemName: 'MARKET_LISTED',
      apiName: 'market_listed',

      description: 'Name or ticker of listed market. For example, SET, NASDAQ, NYSE',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 010 ---------------------------------//
    {
      displayName: 'Remark',
      sourceSystemName: 'REMARK',
      apiName: 'remark',

      description:
        'Remark (If any) - In case there is no concrete figure agreed at Day 1',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 011 ---------------------------------//
    {
      displayName: 'Coupon Rate (%)',
      sourceSystemName: 'COUPON_RATE',
      apiName: 'coupon_rate',

      description: 'Interest rate/Coupon rate',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 012 ---------------------------------//
    {
      displayName: 'Conversion Price',
      sourceSystemName: 'CONVERSION_PRICE',
      apiName: 'conversion_price',

      description: 'Conversion Price of convertible bond',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 013 ---------------------------------//
    {
      displayName: 'Issue Date',
      sourceSystemName: 'ISSUE_DATE',
      apiName: 'issue_date',

      description: 'Issuance Date',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 014 ---------------------------------//
    {
      displayName: 'Maturity Date',
      sourceSystemName: 'MATURITY_DATE',
      apiName: 'maturity_date',

      description: 'Maturity Date/Last Exercise Date of convertible bond and warrant',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 015 ---------------------------------//
    {
      displayName: 'Conversion Ratio',
      sourceSystemName: 'CONVERSION_RATIO',
      apiName: 'conversion_ratio',

      description:
        'Exercise Ratio (Security:Underlying) of convertible bond and warrant',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 016 ---------------------------------//
    {
      displayName: 'Underlying Security',
      sourceSystemName: 'UNDERLYING',
      apiName: 'underlying',

      description: 'Stock Underlying of convertible bond and warrant',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 017 ---------------------------------//
    {
      displayName: 'Conversion Period',
      sourceSystemName: 'CONVERSION_PERIOD',
      apiName: 'conversion_period',

      description:
        'schedule of conversion ie. Last working day of Mar, Jun, Sep & Dec. of convertible bond and warrant',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 018 ---------------------------------//
    {
      displayName: 'Strike Price',
      sourceSystemName: 'STRIKE_PRICE',
      apiName: 'strike_price',

      description: 'Conversion Price of warrant',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 019 ---------------------------------//
    {
      displayName: 'BBL Internal Acccount Code AC',
      sourceSystemName: 'INTERNAL_ACCTCODE',
      apiName: 'internal_acctcode',

      description:
        'BBL Internal account code for accounting purpose when there is a transaction occurred',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 020 ---------------------------------//
    {
      displayName: 'Account Description AC',
      sourceSystemName: 'INTERNAL_ACCDES',
      apiName: 'internal_accdes',

      description: 'Description of BBL Internal Account Code',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 021 ---------------------------------//
    {
      displayName: 'BBL Internal Product Code AC',
      sourceSystemName: 'INTERNAL_PROCODE',
      apiName: 'internal_procode',

      description:
        'BBL Internal product code for accounting purpose gen from Issuer Nationality, Listed, Product Type, Funds sub-type, TDR',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 022 ---------------------------------//
    {
      displayName: 'Product Description AC',
      sourceSystemName: 'INTERNAL_PRODES',
      apiName: 'internal_prodes',

      description: 'Description of BBL Internal Product Code',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 023 ---------------------------------//
    {
      displayName: 'Impair Account Code AC',
      sourceSystemName: 'IMPAIR_ACCOUNT_CODE',
      apiName: 'impair_account_code',

      description: 'Impairment Account Code',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 024 ---------------------------------//
    {
      displayName: 'Interest Impair Account Code AC',
      sourceSystemName: 'INTEREST_IMPAIR_ACCOUNT_CODE',
      apiName: 'interest_impair_account_code',

      description: 'Accrued Interest Impairment Account Code',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 025 ---------------------------------//
    {
      displayName: 'Intra Group Code AC',
      sourceSystemName: 'INFRA_GROUP_CODE',
      apiName: 'infra_group_code',

      description: 'Intra Group Code for subsidiary company',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 026 ---------------------------------//
    {
      displayName: 'Currency AC',
      sourceSystemName: 'CURRENCY',
      apiName: 'currency',

      description: 'Denominated Currency',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrCurrency,
      displayValue: arrCurrency,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 027 ---------------------------------//
    {
      displayName: 'Valuation Method AC',
      sourceSystemName: 'VALUATION_METHOD',
      apiName: 'valuation_method',

      description: 'Method for BBL valuation',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrEvaluation,
      displayValue: arrEvaluation,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 028 ---------------------------------//
    {
      displayName: 'Portfolio Classification AC',
      sourceSystemName: 'INVESTMENT_PORT',
      apiName: 'investment_port',

      description: 'BBL internal accounting record for accounting purpose',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrPort,
      displayValue: arrPort,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 029 ---------------------------------//
    {
      displayName: 'Redeemable AC',
      sourceSystemName: 'REDEEMABLE',
      apiName: 'redeemable',

      description: 'Whether the fund could be redeemable or not',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrRedeemable,
      displayValue: arrRedeemable,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 030 ---------------------------------//
    {
      displayName: 'Asso/Sub Flag',
      sourceSystemName: 'ASSO_SUB_FLAG',
      apiName: 'asso_sub_flag',

      description: 'Asso/Sub Flag',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrAssoSubFlag,
      displayValue: arrAssoSubFlag,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 031 ---------------------------------//
    {
      displayName: 'Investment Instrument AC',
      sourceSystemName: 'INVESTMENT_INSTRUMENT',
      apiName: 'investment_instrument',

      description: 'Type of securities BBL hold in the company',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrHolding,
      displayValue: arrHolding,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 032 ---------------------------------//
    {
      displayName: 'Issue Location AC',
      sourceSystemName: 'ISSUE_LOCATION',
      apiName: 'issue_location',

      description: 'Classify a nationality of issue location for accounting purpose',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrNationality,
      displayValue: arrNationality,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 033 ---------------------------------//
    {
      displayName: 'Issuer Nationality AC',
      sourceSystemName: 'ISSUER_NATIONALITY',
      apiName: 'issuer_nationality',

      description: 'Classify a nationality of the company for accounting purpose',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrNationality,
      displayValue: arrNationality,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 034 ---------------------------------//
    {
      displayName: 'listed Company Flag',
      sourceSystemName: 'LISTED_COMPANY_FLAG',
      apiName: 'listed_company_flag',

      description: 'Whether the company is listed in stock markets',
      inputLength: '',
      inputType: 'radio',
      inputClass: 'General',
      possibleValue: arrYnValue,
      displayValue: arrYn,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 035 ---------------------------------//
    {
      displayName: 'IFRS9 Instrument AC',
      sourceSystemName: 'IFRS9_INSTRUMENT',
      apiName: 'ifrs9_instrument',

      description: 'Define to be Equity or Debt instrument by IFRS9 method',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrIfrs9,
      displayValue: arrIfrs9,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 036 ---------------------------------//
    {
      displayName: 'Type of Investment AC',
      sourceSystemName: 'INVESTMENT_TYPE',
      apiName: 'investment_type',

      description:
        'Classify if the company is recorded under either TDR or non-TDR for accouting purpose',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrTdr,
      displayValue: arrTdr,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 037 ---------------------------------//
    {
      displayName: 'Marketable Security AC',
      sourceSystemName: 'MARKETABLE_SECURITY',
      apiName: 'marketable_security',

      description: 'Whether the company is listed in stock markets',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrMarketableSecurity,
      displayValue: arrMarketableSecurity,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 038 ---------------------------------//
    {
      displayName: 'Currency Hedging AC',
      sourceSystemName: 'HEDGE_CURRENCY',
      apiName: 'hedge_currency',

      description: 'Hedging currency for Accounting',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrHedgeCurrency,
      displayValue: arrHedgeCurrency,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 039 ---------------------------------//
    {
      displayName: 'Fixed Rate AC',
      sourceSystemName: 'FIXED_RATE_THB_FLAG',
      apiName: 'fixed_rate_thb_flag',

      description: 'Fixed FX rate BOT or Non-BOT',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrFixedThbRate,
      displayValue: arrFixedThbRate,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 040 ---------------------------------//
    {
      displayName: 'Product Type AC',
      sourceSystemName: 'PRODUCT_TYPE',
      apiName: 'product_type',

      description: 'Product classification for accounting purpose',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrProduct,
      displayValue: arrProduct,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 041 ---------------------------------//
    {
      displayName: 'Ownership Type AC',
      sourceSystemName: 'OWNERSHIP_TYPE',
      apiName: 'ownership_type',

      description:
        'Can be either Direct (Directly owned by BBL). or Indirect (Held by subsidiary/branch)',
      inputLength: '',
      inputType: 'select',
      inputClass: 'General',
      possibleValue: arrOwnership,
      displayValue: arrOwnership,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 042 ---------------------------------//
    {
      displayName: 'Trade to Subsidiary AC',
      sourceSystemName: 'TRADED_TO_SUBSIDIARY',
      apiName: 'traded_to_subsidiary',

      description: 'Whether the company is traded to subsidiary',
      inputLength: '',
      inputType: 'radio',
      inputClass: 'General',
      possibleValue: arrYnValue,
      displayValue: arrYn,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 043 ---------------------------------//
    {
      displayName: 'No. of Share',
      sourceSystemName: 'NO_OF_SHARE',
      apiName: 'no_of_share',

      description: 'Number of share',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromOtherSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 044 ---------------------------------//
    {
      displayName: 'Investment Cost (Orginal CCY) AC',
      sourceSystemName: 'BOOK_VALUE',
      apiName: 'book_value',

      description: 'Investment Cost (Orginal CCY) AC',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromOtherSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 045 ---------------------------------//
    {
      displayName: 'Alowance Impairment (Orginal CCY) AC',
      sourceSystemName: 'ALLOWANCE_IMPAIR_CCY',
      apiName: 'allowance_impair_ccy',

      description: 'Allowance Impairment (Tempoary) (Original CCY)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromOtherSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 046 ---------------------------------//
    {
      displayName: 'Net Cost (Orginal CCY) AC',
      sourceSystemName: 'NET_COST_CCY',
      apiName: 'net_cost_ccy',

      description: 'Net Cost (Orginal CCY)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromOtherSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 047 ---------------------------------//
    {
      displayName: 'Investment Cost (THB) AC',
      sourceSystemName: 'INVESTMENT_COST_THB',
      apiName: 'investment_cost_thb',

      description: 'Investment Cost (THB)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 048 ---------------------------------//
    {
      displayName: 'Alowance Impairment (THB) AC',
      sourceSystemName: 'ALLOWANCE_IMPAIR_THB',
      apiName: 'allowance_impair_thb',

      description: 'Allowance Impairment (Tempoary) (Original THB)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 059 ---------------------------------//
    {
      displayName: 'Allowance Permanent (THB) AC',
      sourceSystemName: 'ALLOWANCE_PERMANENT',
      apiName: 'allowance_permanent',

      description: 'Allowance Permanent',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 050 ---------------------------------//
    {
      displayName: 'Net Cost (THB) AC',
      sourceSystemName: 'NET_COST_THB',
      apiName: 'net_cost_thb',

      description: 'Net Cost (THB)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromOtherSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 051 ---------------------------------//
    {
      displayName: '% Hold (Current Stake)',
      sourceSystemName: 'CURRENT_STAKE',
      apiName: 'current_stake',

      description: '% current holding in the firm for each products of the company',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 052 ---------------------------------//
    {
      displayName: 'Amortize (TH)',
      sourceSystemName: 'AMORTIZE_TH',
      apiName: 'amortize_th',

      description: 'Amortize (TH)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 053 ---------------------------------//
    {
      displayName: 'Interest Accrued (TH)',
      sourceSystemName: 'INTEREST_ACCRUED_TH',
      apiName: 'interest_accrued_th',

      description: 'Interest Accrued (TH)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 054 ---------------------------------//
    {
      displayName: 'Interest Receive (TH)',
      sourceSystemName: 'INTEREST_RECEIVE_TH',
      apiName: 'interest_receive_th',

      description: 'Interest Receive (TH)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 055 ---------------------------------//
    {
      displayName: 'Withholding Tax (TH)',
      sourceSystemName: 'WITHHOLDING_TAX_TH',
      apiName: 'withholding_tax_th',

      description: 'Withholding Tax (TH)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 056 ---------------------------------//
    {
      displayName: 'Net Interest Received (TH)',
      sourceSystemName: 'NET_INTEREST_RECEIVED_TH',
      apiName: 'net_interest_received_th',

      description: 'Net Interest Received (TH)',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromFormula',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 057 ---------------------------------//
    {
      displayName: 'Amortize',
      sourceSystemName: 'AMORTIZE',
      apiName: 'amortize',

      description: 'Amortize',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 058 ---------------------------------//
    {
      displayName: 'Interest Accrued',
      sourceSystemName: 'INTEREST_ACCRUED',
      apiName: 'interest_accrued',

      description: 'Interest Accrued',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 059 ---------------------------------//
    {
      displayName: 'Interest Receive',
      sourceSystemName: 'INTEREST_RECEIVE',
      apiName: 'interest_receive',

      description: 'Interest Receive',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 060 ---------------------------------//
    {
      displayName: 'Withholding Tax',
      sourceSystemName: 'WITHHOLDING_TAX',
      apiName: 'withholding_tax',

      description: 'Withholding Tax',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 061 ---------------------------------//
    {
      displayName: 'Net Interest Received',
      sourceSystemName: 'NET_INTEREST_RECEIVED',
      apiName: 'net_interest_received',

      description: 'Net Interest Received',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ? --------------------------------- End ---------------------------------------//
  ],
};

export const invcompproduct = atom({
  key: 'invcompproduct',
  default: jsonData,
});
