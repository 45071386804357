import { atom } from 'recoil';
import { arrCurrency, arrYn, arrYnValue } from '../common';

const _sheetName = 'invcertificate';

//?------- Parameter Design ---------------
/*

------- Label / ID ------------
displayName = variable for display name in label.
sourceSystemName = variable for bank system name.
apiName = variable for call api. 

-------- Input ----------------
description = input placeholder.
inputLength = custom length limit of character.
inputType = (checkbox | date | radio | select | textfield) type of input.
inputClass = Custom format masking or validation.
possibleValue = (array) Value for dropdownlist / checkbox / radio.

-------- Boolean --------------
isRequire = (true/false) require field. can't be null.

isLinkValue = (true/false) Link the value from other input (linkValueId)
linkValueId = ID of input to Link data (display when isLinkValue == true).

isAutoGenerate = (true/false) auto generate value.
generateSource = source of auto generate (display when isAutoGenerate == true).

*/
/**
 textfield
 - NoLimitFree
 - NoLimitCountNumber

 date
 - General

select
 - General

 */

const jsonData: { [key: string]: any } = {
  sheetName: _sheetName,
  inputInfo: [
    // ? --------------------------------- Start ---------------------------------------//
    // !---------------------------- 000.1 ---------------------------------//
    {
      displayName: 'Product ID',
      sourceSystemName: 'PRODUCT_ID',
      apiName: 'product_id',

      description: 'Product ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'name',

      isAutoGenerate: true,
      isActive: false,
    },

    // !---------------------------- 000.2 ---------------------------------//
    {
      displayName: 'Company ID',
      sourceSystemName: 'COMPANY_ID',
      apiName: 'company_id',

      description: 'Company ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: ['/fs/list'],
      displayValue: ['/fs/list'],

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 001 ---------------------------------//
    {
      displayName: 'ID',
      sourceSystemName: 'ID',
      apiName: 'id',

      description: 'Fund ID/Company ID',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFromMaterSheet',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 002 ---------------------------------//
    {
      displayName: 'Product Name',
      sourceSystemName: 'NAME',
      apiName: 'name',

      description:
        'Fund/Company name classified by type of products (Without Account Code TDR and Non-TDR)',
      inputLength: '',
      inputType: 'select',
      inputClass: 'Autocomplete',
      possibleValue: ['/product/list'],
      displayValue: ['/product/list'],

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: true,
      isActive: true,
    },

    // ---------------------------- 003 ---------------------------------//
    {
      displayName: 'Date',
      sourceSystemName: 'DATA_DATE',
      apiName: 'data_date',

      description: 'Date',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 004 ---------------------------------//
    {
      displayName: 'Certificate No.',
      sourceSystemName: 'CERTIFICATE_NO',
      apiName: 'certificate_no',

      description: 'Certificate number',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 005 ---------------------------------//
    {
      displayName: 'Registered Date',
      sourceSystemName: 'REGISTERED_DATE',
      apiName: 'registered_date',

      description: 'Registered date of the fund/company',
      inputLength: '',
      inputType: 'date',
      inputClass: 'General',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 006 ---------------------------------//
    {
      displayName: 'Registered ID',
      sourceSystemName: 'REGISTERED_ID',
      apiName: 'registered_id',

      description: 'Registered ID of the fund/company',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitCountNumber',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 007 ---------------------------------//
    {
      displayName: 'Investment Cost (Orginal CCY) AC',
      sourceSystemName: 'BOOK_VALUE',
      apiName: 'book_value',

      description: 'Investment cost before temporary allowance',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 008 ---------------------------------//
    {
      displayName: 'No. of Share',
      sourceSystemName: 'NO_OF_SHARE',
      apiName: 'no_of_share',

      description: 'number of shares that invest/sell in transactions occurred in date',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFloat',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 009 ---------------------------------//
    {
      displayName: 'Par',
      sourceSystemName: 'PAR_PER_SHARE',
      apiName: 'par_per_share',

      description: 'Par value per share',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: ['/fs/list'],
      displayValue: ['/fs/list'],

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 010 ---------------------------------//
    {
      displayName: 'Total Share',
      sourceSystemName: 'TOTAL_SHARE',
      apiName: 'total_share',

      description: 'Sum a number of outstanding shares',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 011 ---------------------------------//
    {
      displayName: 'Currency',
      sourceSystemName: 'CURRENCY',
      apiName: 'currency',

      description: 'Currency',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'AutogenFormSelect',
      possibleValue: arrCurrency,
      displayValue: arrCurrency,

      isRequire: false,

      isLinkValue: true,
      linkValueId: 'name',

      isAutoGenerate: true,
      isActive: false,
    },

    // ---------------------------- 012 ---------------------------------//
    {
      displayName: 'Custodian',
      sourceSystemName: 'CUSTIDIAN',
      apiName: 'custodian',

      description: 'hold sharse via custodian If there is no share certificate',
      inputLength: '',
      inputType: 'radio',
      inputClass: 'General',
      possibleValue: arrYnValue,
      displayValue: arrYn,

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 013 ---------------------------------//
    {
      displayName: 'Custodian Name',
      sourceSystemName: 'CUSTIDIAN_NAME',
      apiName: 'custodian_name',

      description: 'Custodian name of the shares',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ---------------------------- 014 ---------------------------------//
    {
      displayName: 'Remark',
      sourceSystemName: 'REMARK',
      apiName: 'remark',

      description: 'Additional reminder if any',
      inputLength: '',
      inputType: 'textfield',
      inputClass: 'NoLimitFree',
      possibleValue: '',
      displayValue: '',

      isRequire: false,

      isLinkValue: false,
      linkValueId: '',

      isAutoGenerate: false,
      isActive: true,
    },

    // ? --------------------------------- End ---------------------------------------//
  ],
};

export const invcertificate = atom({
  key: 'invcertificate',
  default: jsonData,
});
