import { useRecoilValue } from 'recoil';
import DetailHeader from './DetailHeader';
import SubTable from '../table/SubTable';
import { previouslyRowsState, previouslyHeadersState } from '../../../../states/index';



function PreviouslyInformation({ typoHeader }: { typoHeader: string }) {
  const previouslyRows = useRecoilValue(previouslyRowsState);
  const previouslyHeaders = useRecoilValue(previouslyHeadersState);

 
  return (
    <>
      <DetailHeader typoHeader={typoHeader } headerType="general" isPending={false} />
      {/* -------- Row 1 ---------- */}
      <br />
      {/* <SubTable
        rowBodyData={previouslyRows}
        rowHeadData={previouslyHeaders}
        tableMode="previously_table"
      /> */}
    </>
  );
}

export default PreviouslyInformation;
